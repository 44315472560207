import React, { useState } from 'react'
// import { observer } from 'mobx-react-lite'
import { compose } from 'recompose'
import { injectIntl } from 'react-intl'
import {
  Form, Input, Row, Col, message, Result, Spin, Icon, Button,
} from 'antd'
import * as S from "~/styles";

import { useQuery, useMutation } from '@apollo/client'

import { Link, useHistory} from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'

import moment from 'moment'

import { useStores } from '~/contexts'

import {ArrowLeftOutlined} from "@ant-design/icons";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import userByPasswordResetQuery from '~/graphql/queries/userByPasswordResetToken.gql'
import updateAdminUserMutation from '~/graphql/mutations/updateAdminUser.gql'
import { useScreenView } from '../hooks/useFirebaseAnalytics';

const PasswordReset = compose(
  Form.create(),
  injectIntl,
)(({
  form: {
    getFieldDecorator, validateFields, getFieldValue,
  },
}) => {
  useScreenView('auth-password-reset')
  const history = useHistory ()
  const intl = useIntl()
  const { routerStore } = useStores()
  const [passwordReset, setPasswordReset] = useState(false)
  const { token: resetPasswordToken } = routerStore.location.query
  const { loading, data } = useQuery(userByPasswordResetQuery, {
    variables: {
      resetPasswordToken,
    },
  })
  const [updateAdminUser] = useMutation(updateAdminUserMutation)

  const handleSubmit = (e) => {
    const { userByResetPasswordToken: { _id } } = data
    e.preventDefault()
    validateFields((err, values) => {
      if (!err) {
        delete values.record.confirmPassword
        const { record: { password } } = values

        updateAdminUser({
          variables: {
            _id,
            record: {
              password,
              tokenExpiredOn: moment(),
            },
          },
        }).then(() => {
          // message.info(`Successfully Edit Profile `)
          setPasswordReset(true)
          history.push("/login");
        }).catch((err) => {
          err.graphQLErrors.forEach((error) => message.error(error.message, 5))
        })
      }
    })
  }

  const checkConfirm = (rule, value, callback) => {
    if (value) {
      validateFields(['record.confirmPassword'], { force: true })
    }
    callback()
  }
  const checkPassword = (rule, value, callback) => {
    if (value && value !== getFieldValue('record.password')) {
      callback('Two passwords that you enter is inconsistent!') // eslint-disable-line
    } else {
      callback()
    }
  }

  if (loading) {
    return (
      <div style={{ margin: '100px', textAlign: 'center' }}>
        <Spin />
      </div>
    )
  }

  if (data && !data.userByResetPasswordToken) {
    return (
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
      />
    )
  }

  if (data && (moment() > moment(data.userByResetPasswordToken.tokenExpiredOn))) {
    return (
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
      />
    )
  }

  return (
    // <Layout style={{ background: '#fff' }}>
    //   <Layout.Content>
    //     <Row type="flex" justify="center" align="middle">
    //       <Col xs={22} md={8} lg={6} style={{ borderRadius: '10px', boxShadow: '0 2px 8px -3px rgba(0, 0, 0, 0.2)', marginTop: '100px' }}>
    //         <Form
    //           onSubmit={handleSubmit}
    //           className="loginForm"
    //           style={{
    //             width: '75%', margin: 'auto', marginTop: '2em', textAlign: 'center',
    //           }}
    //         >
    //           <div style={{
    //             width: '45px', height: '45px', margin: 'auto', backgroundColor: '#ffaf38', borderRadius: '50%', marginBottom: '50px',
    //           }}
    //           >
    //             {passwordReset
    //               ? <Icon type="check" style={{ fontSize: '25px', transform: 'translatey(10px)' }} />
    //               : <Icon type="lock" style={{ fontSize: '25px', transform: 'translatey(10px)' }} />}
    //           </div>
    //           {passwordReset
    //             ? (
    //               <React.Fragment>
    //                 <h2 style={{ color: '#000', marginBottom: '20px' }}>Your password has been reset</h2>
    //                 {/* <h2 style={{ color: '#f08fae', marginBottom: '20px' }}>E-mail sent!</h2> */}
    //                 <p>Try logging in</p>
    //                 <p>กรุณาเข้าสู่ระบบอีกครั้ง</p>
    //               </React.Fragment>
    //             )
    //             : (
    //               <React.Fragment>
    //                 <h2 style={{ color: '#000', marginBottom: '20px' }}>Password Reset</h2>
    //                 <p>Please enter your new password</p>
    //                 <p>กรุณากรอกรหัสผ่านใหม่</p>
    //                 {/* <p>Enter your e-mail address and wait for a password reset e-mail.</p>
    //           <p>After you get the e-mail, follow the instruction to reset your password.</p> */}
    //               </React.Fragment>
    //             )}
    //           {/* <Form.Item>
    //           {getFieldDecorator('email', {
    //             rules: [
    //               { required: true, message: 'Please enter your e-mail!' },
    //               { type: 'email', message: 'Please enter a correct e-mail!' }
    //             ]
    //           })(
    //             <Input prefix={<FontAwesomeIcon icon={['far', 'envelope']} />} placeholder="E-mail" style={{ display: passwordReset ? 'none' : 'inline-block' }} />
    //           )}
    //         </Form.Item> */}
    //           {!passwordReset && (
    //           <React.Fragment>
    //             <Form.Item label="">
    //               {getFieldDecorator('record.password', {
    //                 rules: [{
    //                   validator: checkConfirm,
    //                 },
    //                 // , {
    //                 //   min: 8,
    //                 //   message: 'Password must be 8 or more characters long'
    //                 // }
    //                 ],
    //               })(
    //                 <Input prefix={<Icon type="lock" />} type="password" placeholder="New Password" />,
    //               )}
    //             </Form.Item>

    //             <Form.Item label="">
    //               {getFieldDecorator('record.confirmPassword', {
    //                 rules: [{
    //                   validator: checkPassword,
    //                 }],
    //               })(
    //                 <Input prefix={<Icon type="lock" />} type="password" placeholder="Confirm Password" />,
    //               )}
    //             </Form.Item>
    //           </React.Fragment>
    //           )}
    //           <Form.Item>
    //             {!passwordReset && (
    //             <Button type="primary" htmlType="submit" className="loginFormButton" style={{ width: '100%', marginBottom: '10px', display: passwordReset ? 'none' : 'inline-block' }}>
    //               Submit
    //             </Button>
    //             )}
    //             {passwordReset && (
    //             <Link to="/login">
    //               <Button style={{ width: '100%' }}>
    //                 Log in
    //               </Button>
    //             </Link>
    //             )}
    //           </Form.Item>
    //         </Form>
    //       </Col>
    //     </Row>
    //   </Layout.Content>
    // </Layout>

<S.Container fluid>
<S.LoginWrapper>
  <div className="pixmoryBanner">
    <div style={{ paddingBottom: "20px" }}>
      <Link to="/">
        <img
          style={{ width: "300px", textAlign: "center" }}
          src="/images/mangomango_logo2.svg"
          alt="Pixmory"
        />
      </Link>
    </div>
    <div style={{ paddingBottom: "50px", display: "inline-flex" }}>
      <div
        style={{
          borderRadius: "25px",
          background: "#FFED8D",
        }}
      >
        <Col
          style={{
            // width: "350px",
            borderRadius: "25px 25px 0 0",
            paddingTop: 32,
            paddingBottom: 26,
            paddingLeft: 32,
            paddingRight: 32,
            background: "#fff",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            gap: 12,
            display: "flex",
          }}
        >
          <div
            style={{
              alignSelf: "stretch",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 12,
              display: "inline-flex",
            }}
          >
            <Link
              to="/login"
              style={{
                width: 'auto',
                padding: 8,
                background: "#FDBE1A",
                borderRadius: 1000,
                overflow: "hidden",
                justifyContent: "center",
                alignItems: "center",
                gap: 12,
                display: "flex",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  color: "#111111",
                  fontSize: 14,
                  fontWeight: "400",
                  lineHeight: 1,
                  wordWrap: "break-word",
                }}
              >
                <ArrowLeftOutlined /> <FormattedMessage id='app.back'/>
              </div>
            </Link>
          </div>
          <p
            style={{
              width: "100%",
              textAlign: "left",
              color: "#111111",
              fontSize: 32,
              fontWeight: "700",
              lineHeight: 1,
              wordWrap: "break-word",
            }}
          >
            <FormattedMessage id='app.setPassword'/>
          </p>
          <Form
            onSubmit={handleSubmit}
            style={{
            width: "100%",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            display: "flex",
            }}>
                  <div
            style={{
              alignSelf: "stretch",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              // gap: 16,
              display: "flex",
            }}
          >
            {!passwordReset && (
            <><Form.Item  style={{
              // background: "rgba(17, 17, 17, 0.05)",
              alignSelf: "stretch",
              // height: 48,
              // padding: 16,
              borderRadius: 1000,
              color: "rgba(17, 17, 17, 0.60)",
              fontSize: 16,
              fontWeight: "400",
              // lineHeight: 20,
              wordWrap: "break-word",
            }}>
          {getFieldDecorator('record.password', {
                  rules: [{
                    validator: checkConfirm,
                  },
                  {
                    min: 8,
                    message: 'Password must be 8 or more characters long'
                  }
                  ],
                })(
          <Input
            type="password" 
            style={{
              background: "rgba(17, 17, 17, 0.05)",
              alignSelf: "stretch",
              height: 48,
              padding: 16,
              borderRadius: 1000,
              color: "rgba(17, 17, 17, 0.60)",
              fontSize: 16,
              fontWeight: "400",
              // lineHeight: 20,
              wordWrap: "break-word",
            }}
            placeholder={intl.formatMessage({
              id: "app.password",
              defaultMessage: "Password",
            })}
          />
          )} </Form.Item>
          <Form.Item  
            style={{
              // background: "rgba(17, 17, 17, 0.05)",
              alignSelf: "stretch",
              // height: 48,
              // padding: 16,
              borderRadius: 1000,
              color: "rgba(17, 17, 17, 0.60)",
              fontSize: 16,
              fontWeight: "400",
              // lineHeight: 20,
              wordWrap: "break-word",
            }}>
            {getFieldDecorator('record.confirmPassword', {
                  rules: [{
                    validator: checkPassword,
                  }],
                })(
          <Input
            type="password" 
            style={{
              background: "rgba(17, 17, 17, 0.05)",
              alignSelf: "stretch",
              height: 48,
              padding: 16,
              borderRadius: 1000,
              color: "rgba(17, 17, 17, 0.60)",
              fontSize: 16,
              fontWeight: "400",
              // lineHeight: 20,
              wordWrap: "break-word",
            }}
            placeholder={intl.formatMessage({
              id: "app.confirmPassword",
              defaultMessage: "Password",
            })}
          />
          )} </Form.Item></>)}
          </div>
          <div
            style={{
              alignSelf: "stretch",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 12,
              display: "inline-flex",
            }}
          >
          </div>

          {!passwordReset && (<Button
            type="primary"
            htmlType="submit"
            style={{
              width: "100%",
              height: 50,
              paddingLeft: 24,
              paddingRight: 24,
              background: "#111111",
              borderRadius: 1000,
              overflow: "hidden",
              gap: 12,
              textAlign: "left",
              fontSize: 16,
              fontWeight: "400",
              wordWrap: "break-word",
            }}
          >
            <FormattedMessage id='app.resetPassword'/>
          </Button>  )}
          </Form>
      
        </Col>
        <Col className="desktopOnly">
          <Col
            style={{
              borderRadius: "25px",
              background: "#FFED8D",
              width: "450px",
              height: "100%",
              paddingTop: 16,
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 12,
                display: "inline-flex",
              }}
            >
              <p
                style={{
                  width: "100%",
                  color: "rgba(17, 17, 17, 0.60)",
                  fontSize: 16,
                  fontWeight: "400",
                  // lineHeight: 3,
                  wordWrap: "break-word",
                  paddingBottom: 16,
                }}
              >
                <FormattedMessage id='app.mango.text1'/>
                <FormattedMessage id='app.mango.text2'/>
              </p>
            </div>
            <Row
              style={{
                // alignSelf: "stretch",
                justifyContent: "center",
                alignItems: "center",
                display: "inline-flex",
              }}
            >
              <Col span={8} style={{ marginLeft: 10 }}>
                <img
                  style={{ width: "100px", borderRadius: 5 }}
                  src="/images/QRcode_facebook_mangomango.png"
                />
              </Col>
              <Col
                span={12}
                style={{
                  textAlign: "left",
                  paddingBottom: 10,
                  fontSize: 16,
                  fontWeight: "400",
                }}
              >
                <div style={{ paddingBottom: 15 }}>
                  <img
                    style={{ width: "27px" }}
                    src="/images/email.svg"
                  />
                  hello@mangomango.app
                </div>
                <div>
                <img style={{ width: '30px' }} src='/images/facebook.svg' /> MangoMango Thailand
                </div>
              </Col>
              <div>
                <img
                  style={{
                    width: "100px",
                    height: "130px",
                  }}
                  src="/images/Component2.svg"
                />
              </div>
            </Row>
          </Col>
        </Col>
        <Col className="mobileOnly">
          <Col
            style={{
              borderRadius: "25px",
              background: "#FFED8D",
              width: "350px",
              height: "100%",
              paddingTop: 16,
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 12,
                display: "inline-flex",
              }}
            >
              <p
                style={{
                  width: "100%",
                  textAlign: "center",
                  paddingBottom: 16,
                  color: "rgba(17, 17, 17, 0.60)",
                  fontSize: 14,
                  fontWeight: "400",
                  wordWrap: "break-word",
                }}
              >
                <FormattedMessage id='app.mango.text1'/><br/>
                <FormattedMessage id='app.mango.text2'/>
              </p>
            </div>
            <div className="pixmoryBanner">
              <img
                style={{
                  width: "100px",
                  borderRadius: 10,
                  paddingBottom: 16,
                }}
                src="/images/QRcode_facebook_mangomango.png"
              />
              <div style={{ textAlign: "left", paddingLeft: 90 }}>
                <div style={{ paddingBottom: 20 }}>
                  <img
                    style={{ width: "30px" }}
                    src="/images/email.svg"
                  />{" "}
                  hello@mangomango.app
                </div>
                <div>
                <img style={{ width: '30px' }} src='/images/facebook.svg' /> MangoMango Thailand
                </div>
              </div>
              <div>
                <img
                  style={{
                    borderRadius: "25px",
                    width: "100%",
                    height: "auto",
                  }}
                  src="/images/Frame507.svg"
                />
              </div>
            </div>
          </Col>
        </Col>
      </div>
    </div>
  </div>
</S.LoginWrapper>
</S.Container>
  )
})

export default PasswordReset
