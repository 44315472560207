/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import _get from 'lodash/get'
import _uniqBy from 'lodash/uniqBy'
import moment from 'moment'
import { Link } from 'react-router-dom'
import {
  Button,
  Progress,
  Empty,
  List,
} from 'antd'

import { useStores } from '~/contexts'
import photoDownloadsQuery from '~/graphql/queries/photoDownloads.gql'
import photoDownloadSubscription from '~/graphql/subscriptions/photoDownload.gql'

import * as S from '~/styles'

const PhotoDownloads = () => {
  const { userStore: user } = useStores()
  const { subscribeToMore, error, data } = useQuery(photoDownloadsQuery)
  const photoDownloads = _uniqBy(_get(data, 'photoDownloads', []), '_id').reverse()

  useEffect(() => {
    window.scrollTo(0, 0)
    subscribeToMore({
      document: photoDownloadSubscription,
      variables: { userId: user._id },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev
        const newFeedItem = subscriptionData.data.photoDownload
        return {
          ...prev,
          photoDownloads: [...prev.photoDownloads, newFeedItem],
        }
      },
    })
  }, [])

  const handleClick = (url) => {
    const downloadLink = document.createElement('a')
    downloadLink.href = url
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }

  if (error) return 'Error...'
  if (data && data.photoDownloads.length === 0) return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />

  return (
    <S.Container>
      <List
        itemLayout="horizontal"
        dataSource={photoDownloads}
        renderItem={(item) => (
          <List.Item
            actions={
              item.status === 'completed'
                ? [
                  <Button icon="download" type="primary" onClick={() => handleClick(item.url)}>
                    ดาวน์โหลด {item.totalCount} ภาพ
                  </Button>,
                ]
                : [
                  <Button disabled loading>
                    กำลังบีบอัด {item.processedCount}/{item.totalCount} ภาพ
                  </Button>,
                ]
            }
          >
            <List.Item.Meta
              title={<Link to={`/events/${item.event._id}/manage`}>{item.event.title}</Link>}
              description={
                item.status === 'completed' ? (
                  `กรุณาดาวน์โหลดก่อน ${moment(item.expiresAt).format('lll')}`
                ) : (
                  <Progress percent={Number(((item.processedCount / item.totalCount) * 100).toFixed(2))} />
                )
              }
            />
          </List.Item>
        )}
      />
    </S.Container>
  )
}

export default PhotoDownloads
