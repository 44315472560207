import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import _get from 'lodash/get'
import { Button, message } from 'antd'
import { useMutation } from '@apollo/client'
import { useIntl } from 'react-intl'

import purchaseEventCreditsMutation from '~/graphql/mutations/purchaseEventCredits.gql'
import payOrderMutation from '~/graphql/mutations/payOrder.gql'
import * as S from '~/styles'

const Linepay = observer(({
  orderTarget,
  price,
  eventId,
  orderId,
  packageId,
  returnUri,
}) => {
  const intl = useIntl()
  const [paymentLoading, setPaymentLoading] = useState(false)
  const [purchaseEventCredits] = useMutation(purchaseEventCreditsMutation)
  const [payOrder] = useMutation(payOrderMutation)

  const handleCreditCheckout = async () => {
    try {
      setPaymentLoading(true)
      const { data, errors } = await purchaseEventCredits({
        variables: {
          eventId,
          packageId,
          method: 'linepay_web',
          params: {
            returnUri,
          },
        },
      })
      if (errors && errors.length > 0) {
        errors.forEach((error) => {
          message.error(intl.formatMessage({
            id: `api.${error.extensions.code}.${error.message}`,
            defaultMessage: 'Unknown Error',
          }), 5)
        })
      } else {
        const { status, error } = _get(data, 'purchaseEventCredits')
        if (status === 'failed') {
          message.error(intl.formatMessage({
            id: `api.${error.extensions.code}.${error.message}`,
            defaultMessage: 'Unknown Error',
          }), 5)
        }
        const paymentUrl = _get(data, 'purchaseEventCredits.data.linepay_web.info.paymentUrl.web')
        if (paymentUrl) {
          window.location.href = paymentUrl
        }
      }
    } catch (error) {
      message.errror(error.message)
    } finally {
      setPaymentLoading(false)
    }
  }

  const handlePhotoCheckout = async () => {
    try {
      setPaymentLoading(true)
      const { data, errors } = await payOrder({
        variables: {
          orderId,
          method: 'linepay_web',
          params: {
            returnUri,
          },
        },
      })
      if (errors && errors.length > 0) {
        errors.forEach((error) => {
          message.error(intl.formatMessage({
            id: `api.${error.extensions.code}.${error.message}`,
            defaultMessage: 'Unknown Error',
          }), 5)
        })
      } else {
        const { status, error } = _get(data, 'payOrder')
        if (status === 'failed') {
          message.error(intl.formatMessage({
            id: `api.${error.extensions.code}.${error.message}`,
            defaultMessage: 'Unknown Error',
          }), 5)
        }
        const paymentUrl = _get(data, 'payOrder.data.linepay_web.info.paymentUrl.web')
        if (paymentUrl) {
          window.location.href = paymentUrl
        }
      }
    } catch (error) {
      message.errror(error.message)
    } finally {
      setPaymentLoading(false)
    }
  }

  const content = (
    <>
      <img src="/images/linepay_logo_238x54_v5_th.png" alt="" />
      <Button
        type="primary"
        onClick={orderTarget === 'credit'
          ? handleCreditCheckout
          : orderTarget === 'photo'
            ? handlePhotoCheckout
            : null}
        loading={paymentLoading}
        disabled={paymentLoading}
      >{`ชำระ ${price} บาท`}
      </Button>
    </>
  )

  return (
    <S.EventCard title="แรปบิทไลน์เพย์">
      <div className="desktopOnly flexCenter column">
        {content}
      </div>
      <div className="mobileOnly column">
        {content}
      </div>
    </S.EventCard>
  )
})

export default Linepay
