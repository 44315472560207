import { useEffect } from "react"
import { logEvent as _logEvent } from "@firebase/analytics";
import { analytics } from "../services/firebase"
const logEvent = (...rest) => {
  return _logEvent(analytics, ...rest)
}
const useFirebaseAnalytics = (event, params, options) => {
  useEffect(() => {
    if (event) {
      logEvent(event, params, options)
    }
    // logEvent('page_view', { page_path: pathname });
  }, [event, params, options])
  return {
    logEvent
  }
}
export const useScreenView = (pathname, params, options) => {
  useFirebaseAnalytics('screen_view', {
    firebase_screen: pathname,
    firebase_screen_class: pathname,
    screen_name: pathname,
    screen_class: pathname,
    ...params
  }, options)
}

export default useFirebaseAnalytics
