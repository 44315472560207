import React from 'react'
import { Result } from 'antd'

const PageNotFound = () => (
  <Result
    status="404"
    title="404"
    // subTitle="Sorry, the page you visited does not exist."
    subTitle="ขออภัย..ไม่มีหน้าที่คุณเยี่ยมชม"
  />
)

export default PageNotFound
