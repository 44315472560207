import React from 'react'
import { useIntl } from 'react-intl'
import { Result } from 'antd'

const PageNoContent = () => {
  const intl = useIntl()
  return (
    <Result
      status={'404'}
      title={'204'}
      subTitle={intl.formatMessage({ id: 'app.somethingWentWrong', defaultMessage: 'Sorry, something went wrong.' })}
    />
  )
}

export default PageNoContent
