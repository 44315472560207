import { useState } from 'react'
import { useMutation } from '@apollo/client'
import _get from 'lodash/get'
import useScript from '~/hooks/useScript'
import payCreditOrderMutation from '~/graphql/mutations/payCreditOrder.gql'

const publicKey = process.env.REACT_APP_OMISE_PUBLIC_KEY

const useOmiseOffsiteCredit = (method, onError) => {
  const [loaded, error] = useScript('https://cdn.omise.co/omise.js.gz')
  error && console.error(error)
  const [paymentLoading, setPaymentLoading] = useState(false)
  const [payCreditOrder] = useMutation(payCreditOrderMutation)
  const pay = async (sourceType, {
    creditOrderId, returnUri, tokenId,
  }, src) => {
    setPaymentLoading(true)
    // eslint-disable-next-line no-undef
    Omise.setPublicKey(publicKey)
    try {
      const source = ['promptpay'].includes(sourceType) && await new Promise((resolve, reject) => {
        // eslint-disable-next-line no-undef
        Omise.createSource(sourceType, {
          currency: 'thb',
          ...src,
        }, (statusCode, response) => {
          if (statusCode !== 200) {
            return reject(response.message)
          }
          return resolve(response)
        })
      })
      const { data, error } = await payCreditOrder({
        variables: {
          orderId: creditOrderId,
          method,
          params: {
            sourceId: source?.id,
            returnUri,
            tokenId,
          },
        },
      })
      if (error && error.length > 0) {
        error.forEach((err) => onError(err))
      }
      const { _id, error: transactionError, status } = _get(data, 'payCreditOrder')
      if (status === 'failed') {
        if (transactionError && transactionError.code) {
          return onError(transactionError.code)
        }
        return onError('UNKNOWN_ERROR')
      }
      const authorizeUri = _get(data, `payCreditOrder.data.${method}.authorize_uri`)
      const uri = data?.payCreditOrder?.data?.[`${method}`]?.source?.scannable_code?.image?.download_uri
      if (['omise_promptpay'].includes(method)) {
        return { paymentId: _id, uri }
      }
      if (authorizeUri) {
        window.location.href = authorizeUri
      }
      return { paymentId: _id }
    } catch (err) {
      onError(err)
    } finally {
      setPaymentLoading(false)
    }
  }
  return [loaded, paymentLoading, pay]
}

export default useOmiseOffsiteCredit
