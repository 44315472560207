import { clarity } from "clarity-js"

// clarity.start({
//   projectId: 'ivvigf447y',
//   upload: 'https://e.clarity.ms/collect',
//   track: true,
//   content: true,
//   // cookies: ['user_id', 'user_email', 'user_name']
// })

export default clarity