import React, { useState } from 'react'
import compose from 'recompose/compose'
import { observer } from 'mobx-react-lite'
import _get from 'lodash/get'
import { useIntl } from 'react-intl'
import { useMutation } from '@apollo/client'
import { Button, Form, message, Modal } from 'antd'

import { useStores } from '~/contexts'
import useScript from '~/hooks/useScript'
import * as S from '~/styles'
import CardInput from '~/components/Payments/CardInput'
import '~/styles/card.css'
import purchaseEventCreditsMutation from '~/graphql/mutations/purchaseEventCredits.gql'
import payOrderMutation from '~/graphql/mutations/payOrder.gql'

const publicKey = process.env.REACT_APP_OMISE_PUBLIC_KEY

const OmiseCard = ({
  form: { getFieldDecorator, validateFields },
  orderTarget,
  price,
  eventId,
  orderId,
  packageId,
  returnUri,
  refetchQueries,
}) => {
  const intl = useIntl()
  const [paymentLoading, setPaymentLoading] = useState(false)
  const { routerStore: { history } } = useStores()
  const [loaded] = useScript('https://cdn.omise.co/omise.js.gz')
  const [purchaseEventCredits] = useMutation(purchaseEventCreditsMutation)
  const [payOrder] = useMutation(payOrderMutation, {
    refetchQueries: ({ data, error }) => {
      if (!error) {
        return refetchQueries
      }
      return data
    },
  })
  const method = 'omise_card'
  const handleCreditCheckout = async ({ tokenId }) => {
    try {
      const { data, errors } = await purchaseEventCredits({
        variables: {
          eventId,
          packageId,
          method,
          params: {
            tokenId,
            returnUri,
          },
        },
      })
      if (errors && errors.length > 0) {
        errors.forEach((error) => {
          message.error(intl.formatMessage({
            id: `api.${error?.extensions.code}.${error?.message}`,
            defaultMessage: 'Unknown Error',
          }), 5)
        })
      } else {
        const authorizeUri = _get(
          data,
          `purchaseEventCredits.data.${method}.authorize_uri`,
        )
        if (authorizeUri) {
          window.location.href = authorizeUri
          return
        }
        const { orderId, status, error } = _get(data, 'purchaseEventCredits')
        // console.log({ status, error })
        if (status === 'failed') {
            return Modal.error({
              content: intl.formatMessage({
                id: `api.errors.payment.${error?.code}`,
                defaultMessage: 'Unknown Error',
              })
            })
            // return message.error(intl.formatMessage({
            //   id: `api.errors.payment.${error.code}`,
            //   defaultMessage: 'Unknown Error',
            // }), 5)


        }
        history.replace(`/topups/${orderId}/completed`)
      }
    } catch (error) {
      message.error(error?.message, 5)
    } finally {
      setPaymentLoading(false)
    }
  }

  const handlePhotoCheckout = async ({ tokenId }) => {
    try {
      const { data, errors } = await payOrder({
        variables: {
          orderId,
          method: 'omise_card',
          params: {
            tokenId,
            returnUri,
          },
        },
      })
      if (errors && errors.length > 0) {
        errors.forEach((error) => {
          message.error(intl.formatMessage({
            id: `api.${error?.extensions.code}.${error?.message}`,
            defaultMessage: 'Unknown Error',
          }), 5)
        })
      } else {
        const { status, error } = _get(data, 'payOrder')
        if (status === 'failed') {
          message.error(intl.formatMessage({
            id: `api.${error?.extensions.code}.${error?.message}`,
            defaultMessage: 'Unknown Error',
          }), 5)
        } else {
          message.success('payment success', 5)
        }
      }
    } catch (error) {
      message.error(error?.message, 5)
    } finally {
      setPaymentLoading(false)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    validateFields(async (err, values) => {
      if (!err) {
        setPaymentLoading(true)
        // eslint-disable-next-line no-undef
        Omise.setPublicKey(publicKey)
        const {
          newCard: {
            number,
            name,
            expiry,
            cvc,
          },
        } = values
        const [mm = '', yy = ''] = expiry.replace(/\s/g, '').split('/')
        const expirationMonth = mm
        const expirationYear = `20${yy.substr(-2)}`
        const cardData = {
          name: name.trim(),
          number: number.replace(/\s/g, ''),
          expiration_year: expirationYear,
          expiration_month: expirationMonth,
          security_code: cvc.replace(/\s/g, ''),
        }
        const token = await new Promise((resolve, reject) => {
          // eslint-disable-next-line no-undef
          Omise.createToken('card', cardData, (statusCode, response) => {
            if (statusCode !== 200) {
              message.error(response.message, 5)
              setPaymentLoading(false)
              return reject(response.message)
            }
            return resolve(response)
          })
        })
        if (orderTarget === 'credit') {
          handleCreditCheckout({ tokenId: token.id })
        }
        if (orderTarget === 'photo') {
          handlePhotoCheckout({ tokenId: token.id })
        }
      }
    })
  }

  const button = (
    <Button
      type="primary"
      onClick={handleSubmit}
      loading={!loaded || paymentLoading}
      disabled={!loaded || paymentLoading}
    >{`ชำระ ${price.toLocaleString()} บาท`}
    </Button>
  )
  const newCardDecorator = getFieldDecorator('newCard')
  return (
    <S.EventCard title="บัตรเครดิต / เดบิต">
      <Form layout="vertical">
        {newCardDecorator(<CardInput />)}
        <div className="desktopOnly flexCenter">
          {button}
        </div>
        <div className="mobileOnly column">
          {button}
        </div>
      </Form>
    </S.EventCard>
  )
}

export default compose(
  Form.create(),
  observer,
)(OmiseCard)
