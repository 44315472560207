import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getAuth } from 'firebase/auth'

const firebaseConfigList = {
  mangomango: {
    apiKey: 'AIzaSyBXmyrWMN9q9XiNmf-CUaVkyJ7b6YcdgUE',
    authDomain: 'mangomango.firebaseapp.com',
    projectId: 'mangomango',
    storageBucket: 'mangomango.appspot.com',
    messagingSenderId: '496990473636',
    appId: '1:496990473636:web:52776b4ecf7d24649f0531',
    measurementId: 'G-KMW935MJWR',
  },
  'mangomango-staging': {
    apiKey: 'AIzaSyAvFUN5CMMF0TUbvCTCUo7lVPzB5XJEp_A',
    authDomain: 'mangomango-staging.firebaseapp.com',
    projectId: 'mangomango-staging',
    storageBucket: 'mangomango-staging.appspot.com',
    messagingSenderId: '26000065087',
    appId: '1:26000065087:web:4ca06676030710794f2e8a',
    measurementId: 'G-FG53SSW9RX',
  },
}

const app = initializeApp(firebaseConfigList[process.env.REACT_APP_AUTH_PROJECT_ID])
export default app
export const analytics = getAnalytics(app)
export const auth = getAuth(app)
