import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useQuery } from '@apollo/client'
import { Button, Col, Divider, Icon, Row, Spin } from 'antd'
import moment from 'moment'
import paymentQuery from '~/graphql/queries/payment.gql'
import { useStores } from '~/contexts'
import * as S from '~/styles'

const BuyCreditStep3 = ({ match }) => {
  const intl = useIntl()
  const {
    routerStore: { history },
  } = useStores()
  const { paymentId } = match.params
  const {
    data: paymentData,
    loading: paymentLoading,
    stopPolling,
  } = useQuery(paymentQuery, {
    variables: {
      _id: paymentId,
      ...(sessionStorage.getItem('flag_hidden_packages') && { hidden: true }),
    },
    pollInterval: 5000,
  })

  const { completedAt, creditOrder, status } = paymentData?.payment ?? {}
  const { couponCodes, creditAmount, package: packageData } = creditOrder ?? {}
  const [couponCode] = couponCodes ?? []
  const { code, couponCodeType } = couponCode ?? {}
  const { discount: couponCodeTypeDiscount, discountType: couponCodeTypeDiscountType } = couponCodeType ?? {}
  const { currency = 'THB', name: packageName, price: amount } = packageData ?? {}
  const couponDiscount = couponCodeTypeDiscountType === 'percentage' ? (couponCodeTypeDiscount * amount) / 100 : couponCodeTypeDiscount ?? 0
  const vat = Number(((amount - couponDiscount) * 0.07).toFixed(2))
  const totalPrice = amount - couponDiscount + vat
  const numberOption = {
    currency,
    currencyDisplay: 'code',
    maximumFractionDigits: 2,
    style: 'currency',
  }
  useEffect(() => {
    if (status === 'completed') {
      stopPolling()
    }
  }, [status, stopPolling])
  const handleOnClick = () => {
    history.push('/events')
  }

  if (paymentLoading) {
    return <Spin style={{ display: 'block', lineHeight: '100vh' }} />
  }

  return (
    <S.Package>
      <Row className='description'>{`${intl.formatMessage({ id: 'app.step', defaultMessage: 'Step' })} 2/2`}</Row>
      <Row className='header'>{intl.formatMessage({ id: 'app.payment', defaultMessage: 'Payment' })}</Row>
      <div className='box'>
        <Row style={{ width: '100%', textAlign: 'center', marginTop: '1em' }}>
          {status === 'completed' && <Icon type='check-circle' theme='filled' style={{ color: '#28C61A', fontSize: '4em' }} />}
          {status === 'failed' && <Icon type='close-circle' theme='filled' style={{ color: '#C61A28', fontSize: '4em' }} />}
          {status === 'pending' && <Icon type='clock-circle' theme='filled' style={{ color: '#C66A1A', fontSize: '4em' }} />}
        </Row>
        <Row style={{ marginTop: '1em', textAlign: 'center', width: '100%' }}>
          <Col span={24}>{intl.formatMessage({ id: 'app.thankUsingMango', defaultMessage: 'Thank you for using Mango Mango!' })}</Col>
          <Col className='description' span={24}>
            {intl.formatMessage(
              { id: 'app.addPhotoQuota', defaultMessage: '{amount, number} photos have been added to your photo quota.' },
              { amount: creditAmount }
            )}
          </Col>
        </Row>
        <Row style={{ marginTop: '0.75em', textAlign: 'center', width: '100%' }}>
          <Col span={24} style={{ color: '#D7972C', fontSize: '0.8em' }}>
            {intl.formatMessage({ id: 'app.yourNewQuota', defaultMessage: 'Your new quota' })}
          </Col>
          <Col className='price' span={24}>
            {intl.formatMessage({ id: 'app.amountPhotos', defaultMessage: '{amount, number} photos' }, { amount: creditAmount })}
          </Col>
        </Row>
        <Row className='summary' style={{ color: 'rgba(17, 17, 17, 0.60)' }}>
          <Col span={14}>{packageName}</Col>
          <Col span={10} style={{ textAlign: 'end' }}>
            {intl.formatNumber(amount, { ...numberOption })}
          </Col>
          <Col span={14}>{`VAT 7%`}</Col>
          <Col span={10} style={{ textAlign: 'end' }}>
            {intl.formatNumber(vat, { ...numberOption })}
          </Col>
          {code && (
            <>
              <Col span={6}>{intl.formatMessage({ id: 'app.discount', defaultMessage: 'Discount' })}</Col>
              <Col span={18} style={{ color: 'red', textAlign: 'end' }}>
                {`${
                  couponCodeTypeDiscountType === 'percentage'
                    ? `[${intl.formatMessage(
                        { id: 'app.percentDiscount', defaultMessage: '{discount}% discount' },
                        { discount: couponCodeTypeDiscount }
                      )}] `
                    : ''
                }-${intl.formatNumber(couponDiscount, numberOption)}`}
              </Col>
            </>
          )}
          <Col span={14} style={{ color: 'rgba(17, 17, 17, 0.60)' }}>
            {intl.formatMessage({ id: 'app.totalAmountDue', defaultMessage: 'Total amount due' })}
          </Col>
          <Col span={10} style={{ textAlign: 'end' }}>
            {intl.formatNumber(totalPrice, { ...numberOption })}
          </Col>
          <Col span={24}>
            <Divider style={{ margin: '1em 0' }} />
          </Col>
          <Col span={14}>{intl.formatMessage({ id: 'app.paymentTime', defaultMessage: 'Payment time' })}</Col>
          <Col span={10} style={{ textAlign: 'end' }}>
            {moment(completedAt).format('D MMM YY HH:mm')}
          </Col>
          <Col span={6}>{intl.formatMessage({ id: 'app.orderId', defaultMessage: 'OrderId' })}</Col>
          <Col span={18} style={{ textAlign: 'end' }}>
            {creditOrder._id}
          </Col>
        </Row>
        <Row className='submit'>
          <Button
            className='yellow-button'
            onClick={handleOnClick}
            type='primary'
            style={{ width: '100%', height: '3em', display: 'block', padding: '0 1.5em' }}
          >
            {intl.formatMessage({ id: 'app.goBackHome', defaultMessage: 'Go back to Home' })}
          </Button>
        </Row>
      </div>
    </S.Package>
  )
}

export default BuyCreditStep3
