import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useQuery } from '@apollo/client'
import _get from 'lodash/get'
import _pickBy from 'lodash/pickBy'

import { useStores } from '~/contexts'
import ResponsiveGallery from '~/components/ResponsiveGallery'
import PhotoView from '~/components/PhotoView'
import PhotoPagination from '~/components/PhotoPagination'
import searchPhotosQuery from '~/graphql/queries/searchPhotos.gql'
import * as S from '~/styles'

import { Divider } from 'antd'


const SearchResults = observer(({
  eventId,
  photographerId,
  showDefaultPhotos
}) => {
  const [currentPhoto, setCurrentPhoto] = useState()
  const { routerStore: { location } } = useStores()
  const { query: { pId, p: page = 1, u } } = location
  const unidentified = parseInt(u, 10) === 0
  const { loading, error, data } = useQuery(searchPhotosQuery, {
    variables: {
      eventId,
      photographerId,
      unidentified,
      page: parseInt(page, 10) || 1,
    },
  })

  const handleThumbnailClick = (item) => {
    const { photo } = item
    setCurrentPhoto(photo._id)
  }

  const handlePhotoChange = (photo) => {
    setCurrentPhoto(photo && photo._id)
  }
  const handlePageChange = (page) => {
    location.query = _pickBy({
      ...location.query,
      p: page,
    })
  }

  useEffect(() => {
    if (currentPhoto !== pId) {
      location.query = _pickBy({
        ...location.query,
        pId: currentPhoto,
      })
    }
  }, [currentPhoto]) // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return 'Loading...'
  if (error) return 'Error...'
  if (data && !data.searchPhotos) return 'No Result'

  const photos = _get(data, 'searchPhotos.items', [])
  const {
    currentPage,
    perPage,
    pageCount,
    itemCount,
  } = _get(data, 'searchPhotos.pageInfo', {})

  return (
    <S.Results>
      {photos.length > 0 && (
      <>
        {/* <Divider>{`ภาพทั้งหมด ${photos.length} ภาพ`}</Divider> */}
        <ResponsiveGallery
          photos={photos}
          onClick={handleThumbnailClick}
        />
        <PhotoView
          photos={photos}
          photoId={pId}
          onPhotoChange={handlePhotoChange}
        />
      </>
      )}
      <PhotoPagination
        pageSize={perPage}
        page={currentPage || page}
        pageCount={pageCount}
        onPageChange={handlePageChange}
        itemCount={itemCount}
      />
    </S.Results>
  )
})

export default SearchResults
