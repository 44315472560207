/* eslint-disable arrow-body-style */
import React from 'react'
import { Route } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import AppLayout from '~/components/AppLayout'

const HeadlessRoute = observer(({ component: Component, ...rest }) => {
  return (
      <Route
        {...rest}
        render={(props) => {
          return <Component {...props} />
        }}
      />
  )
})

export default HeadlessRoute
