import { useState } from 'react'
import { useMutation } from '@apollo/client'
import useScript from '~/hooks/useScript'
import _get from 'lodash/get'
import payOrderMutation from '~/graphql/mutations/payOrder.gql'

const publicKey = process.env.REACT_APP_OMISE_PUBLIC_KEY

const useOmiseOffsitePhoto = (method, onError) => {
  const [loaded, error] = useScript('https://cdn.omise.co/omise.js.gz')
  error && console.error(error)
  const [paymentLoading, setPaymentLoading] = useState(false)
  const [payOrder] = useMutation(payOrderMutation)
  const pay = async (sourceType, {
    orderId, returnUri,
  }, src) => {
    setPaymentLoading(true)
    // eslint-disable-next-line no-undef
    Omise.setPublicKey(publicKey)
    try {
      const source = await new Promise((resolve, reject) => {
        // eslint-disable-next-line no-undef
        Omise.createSource(sourceType, {
          currency: 'thb',
          ...src,
          amount: Math.round(src.amount * 100),
        }, (statusCode, response) => {
          if (statusCode !== 200) {
            return reject(response.message)
          }
          return resolve(response)
        })
      })
      const { data, errors } = await payOrder({
        variables: {
          orderId,
          method,
          params: {
            sourceId: source.id,
            returnUri,
          },
        },
      })
      const { status, error } = _get(data, 'payOrder')
      if (status === 'failed') {
        if (error && error.code) {
          return onError(error.code)
        }
        return onError('UNKNOWN_ERROR')
      }
      if (errors && errors.length > 0) {
        errors.forEach((err) => onError(err))
      }
      const authorizeUri = _get(
        data,
        `payOrder.data.${method}.authorize_uri`,
      )
      if (authorizeUri) window.location.href = authorizeUri
    } catch (err) {
      onError(err)
    } finally {
      setPaymentLoading(false)
    }
  }
  return [loaded, paymentLoading, pay]
}

export default useOmiseOffsitePhoto
