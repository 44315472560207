/* eslint-disable max-classes-per-file */
import { observable, action } from 'mobx'
import { version, format } from 'mobx-sync'

class Product {
  constructor({ photo, sizeLabel }) {
    this.photo = photo
    this.sizeLabel = sizeLabel
  }
}

@version(1)
class CartStore {
  @format(
    JSON.parse,
    JSON.stringify,
  ) @observable items = [] // TODO: should be cartStore.items
  @observable email = ''
  @observable couponCodeIds = []
  @observable eventId

  @action addProduct(p) {
    const product = new Product(p)
    if (this.items.length > 0) {
      const eventIds = [...new Set(this.items.map((p) => p?.photo?.eventId))]
      if (eventIds.length > 1 || !eventIds.includes(p?.photo?.eventId)) {
        throw new Error('CART_PHOTO_EVENT_CONFLICT')
      }
    }
    if (p?.photo?.deleteScheduled) {
      throw new Error('PHOTO_DELETED')
    }
    this.eventId = product?.photo?.eventId
    this.items.push(product)
  }

  @action removeProduct(pId) {
    this.items = this.items.filter((p) => p.photo._id !== pId)
  }

  @action clear() {
    this.items = []
  }

  photoInCart(photoId) {
    return this.items.find(({ photo }) => photo._id === photoId)
  }
}
export { CartStore }
// export default new CartStore()
