import React, { useEffect, useRef, useState } from "react";
import compose from "recompose/compose";
import { useHistory } from "react-router-dom";
import * as S from "~/styles";
import {
  Button,
  Icon,
  Card,
  Input,
  Select,
  Col,
  Row,
  Pagination,
  Modal,
  message,
  Form,
} from "antd";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import createCreditTransferMutation from "~/graphql/mutations/createCreditTransfer.gql";
import currentAdminUserQuery from "~/graphql/queries/currentAdminUser.gql";
import { useMutation, useQuery } from "@apollo/client";
import adminUsers from "~/graphql/queries/adminUsers.gql";
import { observer } from "mobx-react-lite";
import currentAdminUserCreditsQuery from "~/graphql/queries/currentAdminUserCredits.gql";
import creditTransferQuery from "~/graphql/queries/creditTransfer.gql";
import _get from "lodash/get";
import { CSVLink } from "react-csv";

const MyCredit = ({ form }) => {
  // Form.create()
  const intl = useIntl();
  const history = useHistory();
  const countryInformations = [
    { code: "AD", label: "Andorra", phone: "376" },
    { code: "AE", label: "United Arab Emirates", phone: "971" },
    { code: "AF", label: "Afghanistan", phone: "93" },
    { code: "AG", label: "Antigua and Barbuda", phone: "1-268" },
    { code: "AI", label: "Anguilla", phone: "1-264" },
    { code: "AL", label: "Albania", phone: "355" },
    { code: "AM", label: "Armenia", phone: "374" },
    { code: "AO", label: "Angola", phone: "244" },
    { code: "AQ", label: "Antarctica", phone: "672" },
    { code: "AR", label: "Argentina", phone: "54" },
    { code: "AS", label: "American Samoa", phone: "1-684" },
    { code: "AT", label: "Austria", phone: "43" },
    { code: "AU", label: "Australia", phone: "61", suggested: true },
    { code: "AW", label: "Aruba", phone: "297" },
    { code: "AX", label: "Alland Islands", phone: "358" },
    { code: "AZ", label: "Azerbaijan", phone: "994" },
    { code: "BA", label: "Bosnia and Herzegovina", phone: "387" },
    { code: "BB", label: "Barbados", phone: "1-246" },
    { code: "BD", label: "Bangladesh", phone: "880" },
    { code: "BE", label: "Belgium", phone: "32" },
    { code: "BF", label: "Burkina Faso", phone: "226" },
    { code: "BG", label: "Bulgaria", phone: "359" },
    { code: "BH", label: "Bahrain", phone: "973" },
    { code: "BI", label: "Burundi", phone: "257" },
    { code: "BJ", label: "Benin", phone: "229" },
    { code: "BL", label: "Saint Barthelemy", phone: "590" },
    { code: "BM", label: "Bermuda", phone: "1-441" },
    { code: "BN", label: "Brunei Darussalam", phone: "673" },
    { code: "BO", label: "Bolivia", phone: "591" },
    { code: "BR", label: "Brazil", phone: "55" },
    { code: "BS", label: "Bahamas", phone: "1-242" },
    { code: "BT", label: "Bhutan", phone: "975" },
    { code: "BV", label: "Bouvet Island", phone: "47" },
    { code: "BW", label: "Botswana", phone: "267" },
    { code: "BY", label: "Belarus", phone: "375" },
    { code: "BZ", label: "Belize", phone: "501" },
    { code: "CA", label: "Canada", phone: "1", suggested: true },
    { code: "CC", label: "Cocos (Keeling) Islands", phone: "61" },
    { code: "CD", label: "Congo, Democratic Republic of the", phone: "243" },
    { code: "CF", label: "Central African Republic", phone: "236" },
    { code: "CG", label: "Congo, Republic of the", phone: "242" },
    { code: "CH", label: "Switzerland", phone: "41" },
    { code: "CI", label: "Cote d'Ivoire", phone: "225" },
    { code: "CK", label: "Cook Islands", phone: "682" },
    { code: "CL", label: "Chile", phone: "56" },
    { code: "CM", label: "Cameroon", phone: "237" },
    { code: "CN", label: "China", phone: "86" },
    { code: "CO", label: "Colombia", phone: "57" },
    { code: "CR", label: "Costa Rica", phone: "506" },
    { code: "CU", label: "Cuba", phone: "53" },
    { code: "CV", label: "Cape Verde", phone: "238" },
    { code: "CW", label: "Curacao", phone: "599" },
    { code: "CX", label: "Christmas Island", phone: "61" },
    { code: "CY", label: "Cyprus", phone: "357" },
    { code: "CZ", label: "Czech Republic", phone: "420" },
    { code: "DE", label: "Germany", phone: "49", suggested: true },
    { code: "DJ", label: "Djibouti", phone: "253" },
    { code: "DK", label: "Denmark", phone: "45" },
    { code: "DM", label: "Dominica", phone: "1-767" },
    { code: "DO", label: "Dominican Republic", phone: "1-809" },
    { code: "DZ", label: "Algeria", phone: "213" },
    { code: "EC", label: "Ecuador", phone: "593" },
    { code: "EE", label: "Estonia", phone: "372" },
    { code: "EG", label: "Egypt", phone: "20" },
    { code: "EH", label: "Western Sahara", phone: "212" },
    { code: "ER", label: "Eritrea", phone: "291" },
    { code: "ES", label: "Spain", phone: "34" },
    { code: "ET", label: "Ethiopia", phone: "251" },
    { code: "FI", label: "Finland", phone: "358" },
    { code: "FJ", label: "Fiji", phone: "679" },
    { code: "FK", label: "Falkland Islands (Malvinas)", phone: "500" },
    { code: "FM", label: "Micronesia, Federated States of", phone: "691" },
    { code: "FO", label: "Faroe Islands", phone: "298" },
    { code: "FR", label: "France", phone: "33", suggested: true },
    { code: "GA", label: "Gabon", phone: "241" },
    { code: "GB", label: "United Kingdom", phone: "44" },
    { code: "GD", label: "Grenada", phone: "1-473" },
    { code: "GE", label: "Georgia", phone: "995" },
    { code: "GF", label: "French Guiana", phone: "594" },
    { code: "GG", label: "Guernsey", phone: "44" },
    { code: "GH", label: "Ghana", phone: "233" },
    { code: "GI", label: "Gibraltar", phone: "350" },
    { code: "GL", label: "Greenland", phone: "299" },
    { code: "GM", label: "Gambia", phone: "220" },
    { code: "GN", label: "Guinea", phone: "224" },
    { code: "GP", label: "Guadeloupe", phone: "590" },
    { code: "GQ", label: "Equatorial Guinea", phone: "240" },
    { code: "GR", label: "Greece", phone: "30" },
    {
      code: "GS",
      label: "South Georgia and the South Sandwich Islands",
      phone: "500",
    },
    { code: "GT", label: "Guatemala", phone: "502" },
    { code: "GU", label: "Guam", phone: "1-671" },
    { code: "GW", label: "Guinea-Bissau", phone: "245" },
    { code: "GY", label: "Guyana", phone: "592" },
    { code: "HK", label: "Hong Kong", phone: "852" },
    { code: "HM", label: "Heard Island and McDonald Islands", phone: "672" },
    { code: "HN", label: "Honduras", phone: "504" },
    { code: "HR", label: "Croatia", phone: "385" },
    { code: "HT", label: "Haiti", phone: "509" },
    { code: "HU", label: "Hungary", phone: "36" },
    { code: "ID", label: "Indonesia", phone: "62" },
    { code: "IE", label: "Ireland", phone: "353" },
    { code: "IL", label: "Israel", phone: "972" },
    { code: "IM", label: "Isle of Man", phone: "44" },
    { code: "IN", label: "India", phone: "91" },
    { code: "IO", label: "British Indian Ocean Territory", phone: "246" },
    { code: "IQ", label: "Iraq", phone: "964" },
    { code: "IR", label: "Iran, Islamic Republic of", phone: "98" },
    { code: "IS", label: "Iceland", phone: "354" },
    { code: "IT", label: "Italy", phone: "39" },
    { code: "JE", label: "Jersey", phone: "44" },
    { code: "JM", label: "Jamaica", phone: "1-876" },
    { code: "JO", label: "Jordan", phone: "962" },
    { code: "JP", label: "Japan", phone: "81", suggested: true },
    { code: "KE", label: "Kenya", phone: "254" },
    { code: "KG", label: "Kyrgyzstan", phone: "996" },
    { code: "KH", label: "Cambodia", phone: "855" },
    { code: "KI", label: "Kiribati", phone: "686" },
    { code: "KM", label: "Comoros", phone: "269" },
    { code: "KN", label: "Saint Kitts and Nevis", phone: "1-869" },
    {
      code: "KP",
      label: "Korea, Democratic People's Republic of",
      phone: "850",
    },
    { code: "KR", label: "Korea, Republic of", phone: "82" },
    { code: "KW", label: "Kuwait", phone: "965" },
    { code: "KY", label: "Cayman Islands", phone: "1-345" },
    { code: "KZ", label: "Kazakhstan", phone: "7" },
    { code: "LA", label: "Lao People's Democratic Republic", phone: "856" },
    { code: "LB", label: "Lebanon", phone: "961" },
    { code: "LC", label: "Saint Lucia", phone: "1-758" },
    { code: "LI", label: "Liechtenstein", phone: "423" },
    { code: "LK", label: "Sri Lanka", phone: "94" },
    { code: "LR", label: "Liberia", phone: "231" },
    { code: "LS", label: "Lesotho", phone: "266" },
    { code: "LT", label: "Lithuania", phone: "370" },
    { code: "LU", label: "Luxembourg", phone: "352" },
    { code: "LV", label: "Latvia", phone: "371" },
    { code: "LY", label: "Libya", phone: "218" },
    { code: "MA", label: "Morocco", phone: "212" },
    { code: "MC", label: "Monaco", phone: "377" },
    { code: "MD", label: "Moldova, Republic of", phone: "373" },
    { code: "ME", label: "Montenegro", phone: "382" },
    { code: "MF", label: "Saint Martin (French part)", phone: "590" },
    { code: "MG", label: "Madagascar", phone: "261" },
    { code: "MH", label: "Marshall Islands", phone: "692" },
    {
      code: "MK",
      label: "Macedonia, the Former Yugoslav Republic of",
      phone: "389",
    },
    { code: "ML", label: "Mali", phone: "223" },
    { code: "MM", label: "Myanmar", phone: "95" },
    { code: "MN", label: "Mongolia", phone: "976" },
    { code: "MO", label: "Macao", phone: "853" },
    { code: "MP", label: "Northern Mariana Islands", phone: "1-670" },
    { code: "MQ", label: "Martinique", phone: "596" },
    { code: "MR", label: "Mauritania", phone: "222" },
    { code: "MS", label: "Montserrat", phone: "1-664" },
    { code: "MT", label: "Malta", phone: "356" },
    { code: "MU", label: "Mauritius", phone: "230" },
    { code: "MV", label: "Maldives", phone: "960" },
    { code: "MW", label: "Malawi", phone: "265" },
    { code: "MX", label: "Mexico", phone: "52" },
    { code: "MY", label: "Malaysia", phone: "60" },
    { code: "MZ", label: "Mozambique", phone: "258" },
    { code: "NA", label: "Namibia", phone: "264" },
    { code: "NC", label: "New Caledonia", phone: "687" },
    { code: "NE", label: "Niger", phone: "227" },
    { code: "NF", label: "Norfolk Island", phone: "672" },
    { code: "NG", label: "Nigeria", phone: "234" },
    { code: "NI", label: "Nicaragua", phone: "505" },
    { code: "NL", label: "Netherlands", phone: "31" },
    { code: "NO", label: "Norway", phone: "47" },
    { code: "NP", label: "Nepal", phone: "977" },
    { code: "NR", label: "Nauru", phone: "674" },
    { code: "NU", label: "Niue", phone: "683" },
    { code: "NZ", label: "New Zealand", phone: "64" },
    { code: "OM", label: "Oman", phone: "968" },
    { code: "PA", label: "Panama", phone: "507" },
    { code: "PE", label: "Peru", phone: "51" },
    { code: "PF", label: "French Polynesia", phone: "689" },
    { code: "PG", label: "Papua New Guinea", phone: "675" },
    { code: "PH", label: "Philippines", phone: "63" },
    { code: "PK", label: "Pakistan", phone: "92" },
    { code: "PL", label: "Poland", phone: "48" },
    { code: "PM", label: "Saint Pierre and Miquelon", phone: "508" },
    { code: "PN", label: "Pitcairn", phone: "870" },
    { code: "PR", label: "Puerto Rico", phone: "1" },
    { code: "PS", label: "Palestine, State of", phone: "970" },
    { code: "PT", label: "Portugal", phone: "351" },
    { code: "PW", label: "Palau", phone: "680" },
    { code: "PY", label: "Paraguay", phone: "595" },
    { code: "QA", label: "Qatar", phone: "974" },
    { code: "RE", label: "Reunion", phone: "262" },
    { code: "RO", label: "Romania", phone: "40" },
    { code: "RS", label: "Serbia", phone: "381" },
    { code: "RU", label: "Russian Federation", phone: "7" },
    { code: "RW", label: "Rwanda", phone: "250" },
    { code: "SA", label: "Saudi Arabia", phone: "966" },
    { code: "SB", label: "Solomon Islands", phone: "677" },
    { code: "SC", label: "Seychelles", phone: "248" },
    { code: "SD", label: "Sudan", phone: "249" },
    { code: "SE", label: "Sweden", phone: "46" },
    { code: "SG", label: "Singapore", phone: "65" },
    { code: "SH", label: "Saint Helena", phone: "290" },
    { code: "SI", label: "Slovenia", phone: "386" },
    { code: "SJ", label: "Svalbard and Jan Mayen", phone: "47" },
    { code: "SK", label: "Slovakia", phone: "421" },
    { code: "SL", label: "Sierra Leone", phone: "232" },
    { code: "SM", label: "San Marino", phone: "378" },
    { code: "SN", label: "Senegal", phone: "221" },
    { code: "SO", label: "Somalia", phone: "252" },
    { code: "SR", label: "Suriname", phone: "597" },
    { code: "SS", label: "South Sudan", phone: "211" },
    { code: "ST", label: "Sao Tome and Principe", phone: "239" },
    { code: "SV", label: "El Salvador", phone: "503" },
    { code: "SX", label: "Sint Maarten (Dutch part)", phone: "1-721" },
    { code: "SY", label: "Syrian Arab Republic", phone: "963" },
    { code: "SZ", label: "Swaziland", phone: "268" },
    { code: "TC", label: "Turks and Caicos Islands", phone: "1-649" },
    { code: "TD", label: "Chad", phone: "235" },
    { code: "TF", label: "French Southern Territories", phone: "262" },
    { code: "TG", label: "Togo", phone: "228" },
    { code: "TH", label: "Thailand", phone: "66" },
    { code: "TJ", label: "Tajikistan", phone: "992" },
    { code: "TK", label: "Tokelau", phone: "690" },
    { code: "TL", label: "Timor-Leste", phone: "670" },
    { code: "TM", label: "Turkmenistan", phone: "993" },
    { code: "TN", label: "Tunisia", phone: "216" },
    { code: "TO", label: "Tonga", phone: "676" },
    { code: "TR", label: "Turkey", phone: "90" },
    { code: "TT", label: "Trinidad and Tobago", phone: "1-868" },
    { code: "TV", label: "Tuvalu", phone: "688" },
    { code: "TW", label: "Taiwan, Province of China", phone: "886" },
    { code: "TZ", label: "United Republic of Tanzania", phone: "255" },
    { code: "UA", label: "Ukraine", phone: "380" },
    { code: "UG", label: "Uganda", phone: "256" },
    { code: "US", label: "United States", phone: "1", suggested: true },
    { code: "UY", label: "Uruguay", phone: "598" },
    { code: "UZ", label: "Uzbekistan", phone: "998" },
    { code: "VA", label: "Holy See (Vatican City State)", phone: "379" },
    { code: "VC", label: "Saint Vincent and the Grenadines", phone: "1-784" },
    { code: "VE", label: "Venezuela", phone: "58" },
    { code: "VG", label: "British Virgin Islands", phone: "1-284" },
    { code: "VI", label: "US Virgin Islands", phone: "1-340" },
    { code: "VN", label: "Vietnam", phone: "84" },
    { code: "VU", label: "Vanuatu", phone: "678" },
    { code: "WF", label: "Wallis and Futuna", phone: "681" },
    { code: "WS", label: "Samoa", phone: "685" },
    { code: "XK", label: "Kosovo", phone: "383" },
    { code: "YE", label: "Yemen", phone: "967" },
    { code: "YT", label: "Mayotte", phone: "262" },
    { code: "ZA", label: "South Africa", phone: "27" },
    { code: "ZM", label: "Zambia", phone: "260" },
    { code: "ZW", label: "Zimbabwe", phone: "263" },
  ];
  const [search, setSearch] = useState("");
  const [searchCountry, setSearchCountry] = useState("");
  const [clickedSearch, setClickedSearch] = useState(false);
  const [currentAdminID, setCurrentAdminID] = useState("");
  const [createCreditTransfer] = useMutation(
    createCreditTransferMutation
  );
  const pageSize = 8;
  const [currentPage, setCurrentPage] = useState(1);
  const { getFieldDecorator, validateFields, getFieldValue, resetFields } =
    form;
  const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false);
  const [isModalErrorOpen, setIsModalErrorOpen] = useState(false);
  const [isPhoneExist, setIsPhoneExist] = useState(false);
  const { data, loading } = useQuery(adminUsers);
  const { loading: currentAdminLoading, data: dataCurrentAdminUser } = useQuery(
    currentAdminUserQuery,
    {
      onCompleted: () => {
        setCurrentAdminID(dataCurrentAdminUser.currentAdminUser);
      },
    }
  );
  const [records, setRecords] = useState([]);
  const [typeFilter, setTypeFilter] = useState("");
  const [recordsSlicedExport, setRecordsSlicedExport] = useState([]);
  const {
    loading: transferLoading,
    data: dataTransferHistory,
    refetch,
  } = useQuery(creditTransferQuery, {
    skip: !currentAdminID._id,
    variables: {
      transferorId: currentAdminID._id,
      receiverId: currentAdminID._id,
    },
    onCompleted: () => {
      setRecords(dataTransferHistory.findCreditTransfer);
    },
  });
  const [error, setError] = useState("");
  const handleCancel = () => {
    setIsModalConfirmOpen(false);
    setIsModalErrorOpen(false);
  };

  const {
    error: userError,
    loading: userLoading,
    data: userData,
    refetch: refetchUserData,
  } = useQuery(currentAdminUserCreditsQuery, {
    fetchPolicy: "network-only",
  });

  function formatDateString(dateString) {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleString("th-TH", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    });

    return formattedDate;
  }

  function formatPhoneNumber(phoneNumber) {
    const replacedCodeCountryNumber = phoneNumber.replace(/^\+66/, 0);
    const firstThreeDigits = replacedCodeCountryNumber.slice(0, 3);
    const lastFourDigits = replacedCodeCountryNumber.slice(-4);
    const formattedNumber = `${firstThreeDigits}-xxx${lastFourDigits}`;
    return formattedNumber;
  }
  const handleSelectTypeFilter = (type) => {
    setTypeFilter(type);
    if (type === "ซื้อแพ็กเกจ") {
      setRecords(
        dataTransferHistory.findCreditTransfer.filter(
          (data) => data.creditAmount === "ซื้อแพ็กเกจ"
        )
      );
    } else if (type === "โอน (Transfer)") {
      if (search === "") {
        setRecords(
          dataTransferHistory.findCreditTransfer.filter(
            (data) => currentAdminID._id === data.transferorId
          )
        );
      } else {
        setRecords(
          dataTransferHistory.findCreditTransfer.filter((data) => {
            return (
              currentAdminID._id === data.transferorId &&
              data.receiverPhone.includes(search)
            );
          })
        );
      }
    } else {
      // setMockDataHistory(mockDataHistory.filter((data) => data.type === type));
    }
  };

  const handleSearch = () => {
    // message.info(search);
    setClickedSearch(true);
    if (typeFilter === "") {
      setRecords(
        dataTransferHistory.findCreditTransfer.filter((data) =>
          data.receiverPhone.includes(search)
        )
      );
    } else if (typeFilter === "โอน (Transfer)") {
      setRecords(
        dataTransferHistory.findCreditTransfer.filter((data) => {
          return (
            currentAdminID._id === data.transferorId &&
            data.receiverPhone.includes(search)
          );
        })
      );
    }
  };

  const headers = [
    { label: "วันที่ - เวลา", key: "createdAt" },
    { label: "ประเภท", key: "transferorId" },
    { label: "ชนิด", key: "lastname" },
    { label: "From", key: "transferorPhone" },
    { label: "To", key: "receiverPhone" },
    { label: "จำนวน", key: "creditAmount" },
    { label: "Payment", key: "email" },
  ];
  const handleDownloadCSV = () => {
    if (typeFilter !== "" || clickedSearch) {
      setRecordsSlicedExport(
        recordsSliced.map((data) => ({
          ...data,
          createdAt: formatDateString(data.createdAt),
          transferorId: "โอน",
        }))
      );
    } else {
      setRecordsSlicedExport(
        records.map((data) => ({
          ...data,
          createdAt: formatDateString(data.createdAt),
          transferorId: "โอน",
        }))
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    validateFields(async (err, values) => {
      if (!err) {
        const { countryCode, phone } = values;
        const { phone: code } = countryInformations.find(
          (countryInformation) =>
            countryInformation.code.toLowerCase() === countryCode.toLowerCase()
        );
        const fullPhoneNumber = `+${code}${
          phone[0] === "0" ? phone.slice(1) : phone
        }`;
        setIsPhoneExist(async (prevState) => {
          const isHaveNumber = data?.adminUsers.some(
            (user) => user.profile.phone === fullPhoneNumber
          );
          const receiverId = data?.adminUsers.find(
            (user) => user.profile.phone === fullPhoneNumber
          );

          const { _id } = receiverId ?? {};

          if (
            getFieldValue("creditAmount") >
            userData?.currentAdminUser.creditBalance.total
          ) {
            setError("เครดิตไม่เพียงพอทำรายการ");
            setIsModalConfirmOpen(false);
            setIsModalErrorOpen(true);
          } else if (isHaveNumber === false) {
            setError("ไม่พบเบอร์โทรนี้ในระบบ");
            setIsModalConfirmOpen(false);
            setIsModalErrorOpen(true);
          } else if (currentAdminID.profile.phone === fullPhoneNumber) {
            setError("ไม่สามารถโอนเครดิตให้ตัวเองได้");
            setIsModalConfirmOpen(false);
            setIsModalErrorOpen(true);
          } else {
            try {
              await createCreditTransfer({
                variables: {
                  transferorId: currentAdminID._id,
                  transferorPhone: currentAdminID.profile.phone,
                  receiverId: _id,
                  receiverPhone: fullPhoneNumber,
                  creditAmount: Number(getFieldValue("creditAmount")),
                  createdAt: Date.now(),
                },
              });
              message.info("Transfer success");
              setIsModalConfirmOpen(false);
              resetFields();
              refetch();
              refetchUserData();
            } catch (err) {
              message.error(err.message);
            }
          }
          return isHaveNumber;
        });
      }
      // else{
      //   message.error(err)
      // }
    });
  };

  useEffect(() => {
    if (dataTransferHistory) {
      if (typeFilter === "") {
        setRecords(dataTransferHistory.findCreditTransfer);
      } else if (typeFilter === "โอน (Transfer)") {
        setRecords(
          dataTransferHistory.findCreditTransfer.filter(
            (data) => currentAdminID._id === data.transferorId
          )
        );
      }
    }
  }, [currentAdminID, typeFilter, dataTransferHistory]);

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, records.length);
  const recordsReverse = [...records].reverse();
  const recordsSliced = recordsReverse.slice(startIndex, endIndex);

  return (
    <S.MyCredit>
      <div className="container">
        <Modal
          visible={isModalConfirmOpen}
          onCancel={handleCancel}
          footer={null}
        >
          <Row style={{ textAlign: "center", marginTop: "2em" }}>
            <Col style={{ padding: "1em 0em" }}>
              {" "}
              <h1>
                {intl.formatMessage({
                  id: "app.transfer",
                  defaultMessage: "transfer",
                })}{" "}
              </h1>
            </Col>
            <Col style={{ color: "rgba(17, 17, 17, 0.60)" }}>
              <p>
                {" "}
                {intl.formatMessage({
                  id: "app.transferCreditsToNumber",
                  defaultMessage: "transfer credits to number",
                })}{" "}
                {getFieldValue("phone")}
              </p>
            </Col>
            <Col style={{ color: "rgba(17, 17, 17, 0.60)" }}>
              <p>
                {intl.formatMessage({
                  id: "app.transferAmount",
                  defaultMessage: "amount",
                })}{" "}
                {isNaN(getFieldValue("creditAmount"))
                  ? 0
                  : intl.formatNumber(getFieldValue("creditAmount"))}{" "}
                {intl.formatMessage({
                  id: "app.credit",
                  defaultMessage: "credit",
                })}{" "}
              </p>
            </Col>
            <Row gutter={[16, 0]} style={{ marginTop: "2em" }}>
              <Col span={12}>
                <Button
                  className="yellow-button"
                  onClick={() => setIsModalConfirmOpen(false)}
                  type="primary"
                  style={{
                    backgroundColor: "rgba(17, 17, 17, 0.05)",
                    border: "none",
                    color: "black",
                    fontSize: "16px",
                    height: "48px",
                    width: "100%",
                  }}
                >
                  {intl.formatMessage({
                    id: "app.cancel",
                    defaultMessage: "Cancel",
                  })}
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  className="yellow-button"
                  onClick={handleSubmit}
                  type="primary"
                  style={{
                    backgroundColor: "#FDBE1A",
                    border: "#FDBE1A",
                    color: "black",
                    fontSize: "16px",
                    height: "48px",
                    width: "100%",
                  }}
                >
                  {intl.formatMessage({
                    id: "app.transfer",
                    defaultMessage: "Transfer",
                  })}
                </Button>
              </Col>
            </Row>
          </Row>
        </Modal>

        <Modal
          visible={isModalErrorOpen}
          onCancel={handleCancel}
          footer={[
            <Button onClick={() => setIsModalErrorOpen(false)}>
              <FormattedMessage id="app.ok" defaultMessage="OK" />
            </Button>,
          ]}
        >
          <Row style={{ textAlign: "center" }}>
            <Col style={{ padding: "1em 0em" }}>
              {" "}
              <h1 style={{ color: "#F94005", marginTop: "1em" }}>
                {intl.formatMessage({
                  id: "api.errors.Failed",
                  defaultMessage: "Error",
                })}
              </h1>
            </Col>
            <Col
              style={{
                color: "rgba(17, 17, 17)",
                marginTop: "1.25em",
                fontSize: "16px",
              }}
            >
              <p>
                {error === "เครดิตไม่เพียงพอทำรายการ" ? (
                  <>
                    {intl.formatMessage({
                      id: "api.errors.creditNotEnough",
                      defaultMessage: "Credit is not enough",
                    })}
                  </>
                ) : error === "ไม่พบเบอร์โทรนี้ในระบบ" ? (
                  <>
                    {intl.formatMessage({
                      id: "api.errors.notFoundPhoneNumber",
                      defaultMessage: "This number was not found",
                    })}
                  </>
                ) : (
                  <>
                    {intl.formatMessage({
                      id: "api.errors.unableToTransferCredit",
                      defaultMessage: "unable to transfer credit",
                    })}
                  </>
                )}
              </p>
            </Col>
            <Col
              style={{ color: "rgba(17, 17, 17, 0.60)", marginTop: "0.5em" }}
            >
              {error === "เครดิตไม่เพียงพอทำรายการ" ? (
                <p>
                  {intl.formatMessage({
                    id: "app.pleaseBuyCredit",
                    defaultMessage: "please buy credit",
                  })}
                </p>
              ) : (
                <p>
                  {intl.formatMessage({
                    id: "app.pleaseTryAgain",
                    defaultMessage: "please try again",
                  })}
                </p>
              )}
            </Col>
          </Row>
        </Modal>

        <div className="leftBox">
          <h1>
            {" "}
            {intl.formatMessage({
              id: "app.yourCredit",
              defaultMessage: "your credit",
            })}
          </h1>
          <div className="secondaryLeftBox" >
            <div className="box" style={{marginRight:'1em'}}>
              <p style={{ fontSize: "16px", fontWeight: "500" }}>
                {" "}
                {intl.formatMessage({
                  id: "app.yourRemainingQuota",
                  defaultMessage: "your remaining quota",
                })}
              </p>
              <div className="thirdLeftBox">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "85%",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "end" }}>
                    <p style={{ fontSize: "40px", fontWeight: "500" }}>
                      {intl.formatNumber(
                        userData
                          ? userData.currentAdminUser.creditBalance.total
                          : 0
                      )}{" "}
                    </p>
                    <span
                      style={{ marginLeft: "0.5em" }}
                    >
                      {intl.formatMessage({
                        id: "app.credit",
                        defaultMessage: " credit",
                      })}
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "end",
                  }}
                >
                  <Button
                    onClick={() => history.push(`/credits/buy/step1`)}
                    className="yellow-button"
                    type="primary"
                    style={{ width: "100%", height: "3em", padding: "0 1.5em" }}
                  >
                    <span style={{ textAlign: "left", padding: "0px 0.5em" }}>
                      <Icon type="dollar" />
                    </span>
                    <span style={{ textAlign: "right" }}>
                      {intl.formatMessage({
                        id: "app.buyMoreCredit",
                        defaultMessage: "buy credit",
                      })}
                    </span>
                  </Button>
                </div>
              </div>
            </div>

            <div className="box">
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  marginBottom: "0.5em",
                }}
              >
                {intl.formatMessage({
                  id: "app.transferToOtherAccount",
                  defaultMessage: "transfer to other account",
                })}
              </p>
              <Form
                // onSubmit={handleSubmit}
                style={{ width: "100%", margin: "16px 0 0 0" }}
              >
                <S.SelectWrapper>
                  <Form.Item style={{ width: "100%" }}>
                    {getFieldDecorator("countryCode", {
                      initialValue: "TH",
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage({
                            id: "app.enterYourCountry",
                            defaultMessage: "Please Enter Country",
                          }),
                        },
                      ],
                    })(
                      <Select
                        showSearch
                        onChange={() => setSearchCountry()}
                        onSearch={(v) => setSearchCountry(v)}
                        onSelect={() => setSearchCountry()}
                        filterOption={false}
                        placeholder={intl.formatMessage({
                          id: "app.country",
                          defaultMessage: "Country",
                        })}
                        style={{ fontSize: "16px" }}
                      >
                        {countryInformations
                          .filter(
                            (country) =>
                              !searchCountry ||
                              country.phone === searchCountry ||
                              country.label
                                .toLowerCase()
                                .includes(searchCountry)
                          )
                          .map((country) => (
                            <Select.Option
                              key={country.label}
                              value={country.code}
                            >
                              <span
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "600",
                                  color: "rgba(17, 17, 17, 0.60)",
                                }}
                              >
                                {country.label}
                              </span>
                              <span
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "200",
                                  color: "#111111",
                                }}
                              >{` (+${country.phone})`}</span>
                            </Select.Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                </S.SelectWrapper>
                <Form.Item style={{ width: "100%" }}>
                  {getFieldDecorator("phone", {
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: "app.enterYourPhone",
                          defaultMessage: "Enter your phone",
                        }),
                        min: 9,
                        pattern: new RegExp(/^[0-9]+$/),
                      },
                    ],
                  })(
                    <Input
                      style={{
                        height: "48px",
                        fontSize: "16px",
                        fontWeight: "400",
                        background: "rgba(17, 17, 17, 0.05)",
                        border: "none",
                        borderRadius: "1000px",
                        color: "rgba(17, 17, 17, 0.60)",
                        padding: "16px",
                      }}
                      placeholder={intl.formatMessage({
                        id: "app.phoneNumber",
                        defaultMessage: "Phone number",
                      })}
                    />
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator("creditAmount", {
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: "app.enterYourTransferCredits",
                          defaultMessage: "Enter your credits",
                        }),
                        min: 1,
                        pattern: new RegExp(/^[0-9]+$/),
                      },
                    ],
                  })(
                    <Input
                      // onChange={(e) => setCreditsToTransfer(e.target.value)}
                      style={{
                        height: "48px",
                        fontSize: "16px",
                        fontWeight: "400",
                        background: "rgba(17, 17, 17, 0.05)",
                        border: "none",
                        borderRadius: "1000px",
                        color: "rgba(17, 17, 17, 0.60)",
                        padding: "16px",
                        marginTop: "4px",
                      }}
                      placeholder={intl.formatMessage({
                        id: "app.creditAmount",
                        defaultMessage: "Credit amount",
                      })}
                    />
                  )}
                </Form.Item>
                <Button
                  onClick={() => setIsModalConfirmOpen(true)}
                  className="yellow-button"
                  type="primary"
                  style={{
                    width: "100%",
                    height: "3em",
                    padding: "0 1.5em",
                    marginTop: "4px",
                  }}
                >
                  <span style={{ textAlign: "right" }}>
                    {intl.formatMessage({
                      id: "app.transfer",
                      defaultMessage: "Transfer",
                    })}
                  </span>
                </Button>
              </Form>
            </div>
          </div>
        </div>
        <div className="rightBox">
          <h1>
            {" "}
            {intl.formatMessage({
              id: "app.creditUsed",
              defaultMessage: "credit used",
            })}
          </h1>
          <div className="box" style={{ maxHeight: "1000px" }}>
            <Row>
              <Col span={12}>
                <p style={{ fontSize: "16px", fontWeight: "500" }}>
                  {" "}
                  {intl.formatMessage({
                    id: "app.transactionHistory",
                    defaultMessage: " transaction history",
                  })}{" "}
                </p>
              </Col>
              <Col span={12} style={{ display: "flex", justifyContent: "end" }}>
                <Button
                  onClick={handleDownloadCSV}
                  className="black-button"
                  type="primary"
                  style={{ height: "2.75em" }}
                >
                  <CSVLink
                    data={recordsSlicedExport}
                    headers={headers}
                    filename="Transaction_history.csv"
                  >
                    {" "}
                    <Icon type="download" style={{ paddingRight: "0.5em" }} />
                    {intl.formatMessage({
                      id: "app.exportCSV",
                      defaultMessage: "`Export CSV",
                    })}
                  </CSVLink>
                </Button>
              </Col>
            </Row>

            <Card style={{ borderRadius: "8px" }}>
              <Row gutter={6}>
                <Col span={9}>
                  <Select
                    onChange={handleSelectTypeFilter}
                    style={{ width: "100%" }}
                    placeholder={intl.formatMessage({
                      id: "app.type",
                      defaultMessage: "Type",
                    })}
                  >
                    <Select.Option value="ซื้อแพ็กเกจ">
                    {intl.formatMessage({
                        id: "app.BuyPackage",
                        defaultMessage: "Buy Package",
                      })}
                    </Select.Option>
                    {/* <Select.Option value="เติมแพ็คเกจ" disabled>
                      เติมแพ็คเกจ
                    </Select.Option>
                    <Select.Option value="ถอน" disabled>
                      ถอน
                    </Select.Option> */}
                    <Select.Option value="โอน (Transfer)">
                    {intl.formatMessage({
                        id: "app.transfer",
                        defaultMessage: "transfer",
                      })}
                    </Select.Option>
                  </Select>
                </Col>
                <Col span={10} >
                  <Input
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder={intl.formatMessage({
                      id: "app.search",
                      defaultMessage: "Search",
                    })}
                    style={{
                      width: "90%",
                      margin: "0px 12px",
                      height: "40px",
                    }}
                  />
                </Col>

                <Col span={5}>
                  <Button
                    onClick={handleSearch}
                    className="yellow-button"
                    type="primary"
                    style={{ height: "2.75em", width: "100%" }}
                  >
                    <Icon type="search" />
                    <span className="searchText">
                      {intl.formatMessage({
                        id: "app.search",
                        defaultMessage: "Search",
                      })}
                    </span>
                  </Button>
                </Col>
              </Row>
            </Card>
            <Card style={{ borderRadius: "8px" }}>
              {transferLoading ? (
                <> Loading . . . </>
              ) : records.length === 0 ? (
                <div style={{ textAlign: "center" }}>
                  {intl.formatMessage({
                    id: "app.noTransactionHistory",
                    defaultMessage: "no transaction history",
                  })}
                </div>
              ) : (
                <>
                  {recordsSliced.map((data, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          borderBottom: "1px solid #D9D9D9",
                          padding:
                            index === 0 ? "0px 0px 16px 0px" : "16px 0px",
                        }}
                      >
                        <Row>
                          <Col span={12}>
                            {currentAdminID._id === data.transferorId ? (
                              <>
                                {" "}
                                {intl.formatMessage({
                                  id: "app.transferCredit",
                                  defaultMessage: "Transfer credit",
                                })}
                              </>
                            ) : (
                              <>
                                {intl.formatMessage({
                                  id: "app.receiveCredit",
                                  defaultMessage: "receive credit",
                                })}
                              </>
                            )}
                          </Col>
                          <Col
                            span={12}
                            style={{
                              textAlign: "right",
                              color: "rgba(17, 17, 17, 0.60)",
                            }}
                          >
                            {formatDateString(data.createdAt)}
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            span={12}
                            style={{ fontSize: "16px", fontWeight: "500" }}
                          >
                            {currentAdminID._id === data.transferorId ? (
                              <>
                                {intl.formatMessage({
                                  id: "app.toAccount",
                                  defaultMessage: "to account",
                                })}{" "}
                                {formatPhoneNumber(data.receiverPhone)}
                              </>
                            ) : (
                              <>
                                {intl.formatMessage({
                                  id: "app.fromAccount",
                                  defaultMessage: "form account",
                                })}{" "}
                                {formatPhoneNumber(data.transferorPhone)}
                              </>
                            )}
                          </Col>
                          <Col span={12} style={{ textAlign: "right" }}>
                            <span
                              style={{
                                color:
                                  currentAdminID._id !== data.transferorId
                                    ? "#28C61A"
                                    : "#F94005",
                              }}
                            >
                              {currentAdminID._id !== data.transferorId
                                ? "+"
                                : "-"}
                              {data.creditAmount}
                            </span>
                            <span
                              style={{ color: "#949494", paddingLeft: "4px" }}
                            >
                              {intl.formatMessage({
                                id: "app.credits",
                                defaultMessage: "credits",
                              })}
                            </span>
                          </Col>
                        </Row>
                      </div>
                    );
                  })}
                </>
              )}

              <Pagination
                size="small"
                style={{ marginTop: "1em", textAlign: "right" }}
                defaultCurrent={1}
                current={currentPage}
                pageSize={8}
                total={records.length}
                onChange={(e) => setCurrentPage(e)}
              />
            </Card>
          </div>
        </div>
      </div>
    </S.MyCredit>
  );
};

export default compose(Form.create(), observer)(MyCredit);
