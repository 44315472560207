import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { observer } from 'mobx-react-lite'
import { useMutation, useQuery } from '@apollo/client'
// import { PhotoSwipe } from 'react-photoswipe'
import { Button, Checkbox, Col, Modal, Pagination, Row, message } from 'antd'
import _get from 'lodash/get'
import { useStores, useUser} from '~/contexts'
import eventQuery from '~/graphql/queries/event.gql'
import getPhotoDownloadUrlQuery from '~/graphql/queries/getPhotoDownloadUrl.gql'
import photoItemsQuery from '~/graphql/queries/photoItems.gql'
// import listPhotosQuery from '~/graphql/queries/listPhotos.gql'
import deletePhotosMutation from '~/graphql/mutations/deletePhotos.gql'
import * as S from '~/styles'

const limit = 160

const PhotoViewModal = ({ event, onClose, photo, visible }) => {
  const intl = useIntl()
  const {
    routerStore: { location },
    userStore: user,
  } = useStores()
  const { page = 1 } = location.query
  const { loading, data } = useQuery(getPhotoDownloadUrlQuery, {
    variables: {
      _id: photo?._id,
    },
    fetchPolicy: 'network-only',
    skip: !photo?._id,
  })
  const [deletePhotos] = useMutation(deletePhotosMutation, {
    refetchQueries: [
      {
        query: photoItemsQuery,
        variables: {
          deleted: false,
          eventId: event._id,
          // limit,
          // skip: (page - 1) * limit,
          page: parseInt(page, 10) || 1,
          ...(event.createdByUserId === user._id ? {} : { photographerId: user._id }),
        },
      },
      {
        query: eventQuery,
        variables: { _id: event._id },
      },
    ],
  })
  const handleDelete = async () => {
    try {
      await deletePhotos({
        variables: { _ids: [photo._id] },
      })
      onClose()
    } catch (error) {
      message.error(error.message, 5)
    }
  }
  const { photographer } = photo ?? {}
  const { username } = photographer ?? {}

  return (
    <Modal bodyStyle={{ padding: '24px' }} closable footer={null} onCancel={() => onClose(false)} visible={visible} width={360}>
      <div style={{ color: '#111111', fontSize: '12px', lineHeight: '18px' }}>
        <p style={{ color: 'rgba(17, 17, 17, 0.60)' }}>{intl.formatMessage({ id: 'app.uploadedBy', defaultMessage: 'Uploaded by' })}</p>
        <p style={{ fontSize: '16px' }}>{`${username ? username : '-'}`}</p>
      </div>
      <div style={{ margin: '16px 0px' }}>
        <img src={photo?.view?.preview?.url} alt='view' style={{ borderRadius: '8px', width: '100%' }} />
      </div>
      <Row gutter={[16, 0]} type='flex'>
        <Col span={12}>
          <Button
            onClick={handleDelete}
            style={{
              backgroundColor: '#F94005',
              border: 'none',
              color: 'white',
              fontSize: '16px',
              height: '40px',
              width: '100%',
            }}
          >
            {intl.formatMessage({ id: 'app.delete', defaultMessage: 'Delete' })}
          </Button>
        </Col>
        <Col span={12}>
          <Button
            loading={loading}
            style={{
              backgroundColor: 'black',
              border: 'none',
              color: 'white',
              fontSize: '16px',
              height: '40px',
              width: '100%',
            }}
          >
            <a target='_blank' rel='noopener noreferrer' href={data?.getPhotoDownloadUrl?.url}>
              {intl.formatMessage({ id: 'app.download', defaultMessage: 'Download' })}
            </a>
          </Button>
        </Col>
      </Row>
    </Modal>
  )
}

const PhotoList = observer(({ event, handleInvitationPhotographerModal, handleUploadPhotoModal, onSelectPhoto, photoCount, photos, selectable, selectedPhotos,isOwner }) => {
  const intl = useIntl()
  const [visible, setVisible] = useState(false)
  const [photoId, setPhotoId] = useState()
  const {
    routerStore: { location },
  } = useStores()
  const {
    query: { page = 1 },
  } = location
  const { user } = useUser()

  // const onPageChange = (page) => {
  //   location.query = {
  //     ...location.query,
  //     page,
  //   }
  // }
  const handlePhotoViewModal = (id) => {
    if (!id) {
      setVisible(false)
    } else {
      setVisible(true)
      setPhotoId(id)
    }
  }

  const filteredPhotos = isOwner ? photos : photos.filter(photo => photo.photographer.username === user.username)

  return (
    <>
      <PhotoViewModal
        event={event}
        onClose={handlePhotoViewModal}
        photo={photos.find((p) => p._id === photoId)}
        visible={visible}
      />
      {filteredPhotos.length > 0 ? (
        <>
          <S.PhotoList>
            {filteredPhotos.map((photo) => (
              <div className='photoList__photo-layout' key={photo._id}>
                {(selectable && !photo.deleted) && (
                  <Checkbox value={photo._id} checked={selectedPhotos.includes(photo._id)} onChange={(e) => onSelectPhoto(e.target.value)} />
                )}
                <img src={_get(photo, 'view.thumbnail.url')} alt='' onClick={() => handlePhotoViewModal(photo._id)} aria-hidden='true' />
              </div>
            ))}
          </S.PhotoList>
          {/* <S.PhotoPagination>
            <span className='paging'>
              <Pagination size='small' defaultCurrent={1} pageSize={limit} total={photoCount} onChange={onPageChange} current={Number(page)} />
            </span>
          </S.PhotoPagination> */}
        </>
      ) : (
        <>
          <Row align='middle' justify='center' gutter={[16, 0]} type='flex' style={{ color: '#111111', textAlign: 'center' }}>
            <Col span={24}>
              <img src='/images/art-7.svg' alt='no-result' />
            </Col>
            <Col span={24} style={{ margin: '6px 0px 12px 0px' }}>
              <p>{intl.formatMessage({ id: 'app.noPhotosUploadedYet', defaultMessage: 'No photos have been uploaded yet.' })}</p>
            </Col>
            <Col xs={24} sm={16} lg={10} xl={8} align='middle' justify='center' gutter={[16, 0]} type='flex' style={{ textAlign: 'center' }}>
              <Col span={8}>
                <Button
                  onClick={handleUploadPhotoModal}
                  style={{
                    backgroundColor: '#FDBE1A',
                    border: '#FDBE1A',
                    color: 'black',
                    fontSize: '16px',
                    height: '45px',
                    width: '100%',
                  }}
                >
                  {intl.formatMessage({ id: 'app.upload', defaultMessage: 'Upload' })}
                </Button>
              </Col>
              <Col span={16}>
                <Button
                  onClick={handleInvitationPhotographerModal}
                  style={{
                    backgroundColor: 'black',
                    border: 'none',
                    color: 'white',
                    fontSize: '16px',
                    height: '45px',
                    width: '100%',
                  }}
                >
                  {intl.formatMessage({ id: 'app.invitephotographers', defaultMessage: 'Invite photographers' })}
                </Button>
              </Col>
            </Col>
          </Row>
        </>
      )}

    </>
  )
})

export default PhotoList
