/* eslint-disable arrow-body-style */
import React from 'react'
import { Route } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import AppLayout from '~/components/AppLayout'

const PublicRoute = observer(({ component: Component, ...rest }) => {
  return (
    <AppLayout
      backgroundColor='#FDBE1A'
      siderMenu={false}
      headerAffix
      layoutPaddingTop="0"
      layoutContentPadding="0"
    >
      <Route
        {...rest}
        render={(props) => {
          return <Component {...props} />
        }}
      />
    </AppLayout>
  )
})

export default PublicRoute
