import React, { useMemo } from 'react'
import { compose } from 'recompose'
import { useApolloClient, useQuery } from '@apollo/client'
import _get from 'lodash/get'
import FineUploaderS3 from 'fine-uploader-wrappers/s3'
// import sha256 from 'hash.js/lib/hash/sha/256'
import moment from 'moment-timezone'
import { Form, message, Result, Spin } from 'antd'
import { useIntl } from 'react-intl'

import '~/styles/PhotoUpload.css'
import ThairunUploader from '~/components/ThairunUploader'
import processDuplicatePhotoMutation from '~/graphql/mutations/processDuplicatePhoto.gql'
import currentAdminUserCreditsQuery from '~/graphql/queries/currentAdminUserCredits.gql'
import { useUser } from '~/contexts'

const ALLOWED_MIME = 'image/jpeg'
const ALLOWED_EXTENSIONS = ['jpg', 'jpeg']
const IMAGE_MIN_DIMENSION = 900
// const IMAGE_MAX_DIMENSION = 7200

const PhotoUploadTools = ({ event }) => {
  const intl = useIntl()
  const { token } = useUser()
  const { error, loading } = useQuery(currentAdminUserCreditsQuery, {
    skip: true,
  })

  // const creditTargets = _get(event, 'creditTargets')
  const eventCredit = _get(event, 'createdByUser.creditBalance.total', 0)
  // const photographerCredit = _get(data, 'currentAdminUser.creditBalance.total', 0)
  // const creditsAvailable = creditTargets === 'event' ? eventCredit : photographerCredit
  const photoDownloadMode = _get(event, 'photoDownloadMode')
  const creditsAvailable = photoDownloadMode === 'priced' ? undefined : eventCredit
  // const creditsAvailable = photoDownloadMode === 'priced' ? eventCredit : undefined // temporary

  const client = useApolloClient()
  const uploader = useMemo(
    () =>
      new FineUploaderS3({
        options: {
          maxConnections: 10,
          resume: {
            // enabled: true
            preventRetryResponseProperty: 'stop',
          },
          chunking: {
            enabled: false,
            // concurrent: {
            //   enabled: true
            // }
            // requires success.endpoint, e.g. REACT_APP_UPLOAD_SUCCESS_URI
          },
          cors: {
            expected: true,
          },
          request: {
            endpoint: `https://storage.googleapis.com/${process.env.REACT_APP_UPLOAD_BUCKET}`,
            accessKey: process.env.REACT_APP_UPLOAD_ACCESS_KEY,
          },
          signature: {
            customHeaders: {
              Authorization: `Bearer ${token}`,
            },
            // version: 4,
            endpoint: process.env.REACT_APP_UPLOAD_SIGNATURE_URI,
          },
          objectProperties: {
            // acl: 'private',
            bucket: process.env.REACT_APP_UPLOAD_BUCKET,
          },
          uploadSuccess: {
            customHeaders: {
              Authorization: `Bearer ${token}`,
            },
            endpoint: process.env.REACT_APP_UPLOAD_SUCCESS_URI,
          },
          retry: {
            enableAuto: false,
            // maxAutoAttempts: 5
          },
          validation: {
            ...(creditsAvailable !== undefined && { itemLimit: creditsAvailable }),
            acceptFiles: ALLOWED_MIME,
            allowedExtensions: ALLOWED_EXTENSIONS,
            stopOnFirstInvalidFile: false,
            ...(event.photoDownloadMode !== 'free' && {
              image: {
                minHeight: IMAGE_MIN_DIMENSION,
                minWidth: IMAGE_MIN_DIMENSION,
                // maxHeight: IMAGE_MAX_DIMENSION,
                // maxWidth: IMAGE_MAX_DIMENSION,
              },
            }),
          },
          messages: {
            tooManyItemsError: intl.formatMessage({ id: 'app.messages.fineuploader.tooManyItemsError' }),
            typeError: intl.formatMessage({ id: 'app.messages.fineuploader.typeError' }),
            minHeightImageError: intl.formatMessage({ id: 'app.messages.fineuploader.minHeightImageError' }),
            minWidthImageError: intl.formatMessage({ id: 'app.messages.fineuploader.minWidthImageError' }),
            // maxHeightImageError: intl.formatMessage({ id: 'app.messages.fineuploader.maxHeightImageError' }),
            // maxWidthImageError: intl.formatMessage({ id: 'app.messages.fineuploader.maxWidthImageError' }),
          },
          callbacks: {
            // onValidateBatch: async (files) => {
            //   console.log({ files })
            //   const response = await client.query({ query: currentAdminUserCreditsQuery })
            //   console.log({ response })
            //   return false
            // },
            // TODO: use onValidate instead?
            // eslint-disable-next-line consistent-return
            onUpload: async (fileId) => {
              const file = uploader.methods.getFile(fileId)
              // const hash = await new Promise((resolve, reject) => {
              //   const fileReader = new FileReader()
              //   fileReader.onload = (e) => { // file has been read successfully
              //     const buffer = e.target.result
              //     const message = new Uint8Array(buffer) // the data to be digested
              //     // console.log({ message })
              //     const hash = sha256().update(message).digest('hex')
              //     resolve(hash)
              //   }
              //   fileReader.readAsArrayBuffer(file)
              // })
              const response = await client.mutate({
                mutation: processDuplicatePhotoMutation,
                variables: { eventId: event._id, fileName: file.name },
              }) // handle duplicate photo
              const isDuplicate = _get(response, 'data.processDuplicatePhoto')
              if (isDuplicate) {
                // TODO: show messages in ui
                message.error(`ไฟล์ "${file.name}" อยู่ในรายการอัปโหลดแล้ว กรุณาเปลี่ยนชื่อไฟล์ใหม่`, 10)
                return uploader.methods.cancel(fileId)
              }

              // uploader.methods.setCustomHeaders({
              //   filename: file.name,
              // }, fileId)

              uploader.methods.setUploadSuccessParams(
                {
                  eventId: event._id,
                  // featured: form.getFieldValue('featured'),
                  // hash,
                },
                fileId
              )
            },
          },
        },
      }),
    [creditsAvailable] // eslint-disable-line react-hooks/exhaustive-deps
  ) 

  if (error) return 'Error...'
  if (loading) return <Spin style={{ display: 'block' }} />
  if (creditsAvailable <= 0) {
    return (
      <Result
        status='warning'
        title={intl.formatMessage({ id: 'app.notice.insufficientCredits', defaultMessage: 'Insufficient credits available' })}
        subTitle={intl.formatMessage(
          { id: 'app.notice.insufficientCreditsLeft', defaultMessage: 'Add more credits to continue uploading' },
          { creditAmount: intl.formatNumber(creditsAvailable) }
        )}
      />
    )
  }

  return (
    <>
      {event.uploadable ? (
        <ThairunUploader
          uploader={uploader}
          minSize={event.photoDownloadMode !== 'free' && { width: IMAGE_MIN_DIMENSION, height: IMAGE_MIN_DIMENSION }}
        />
      ) : (
        <div style={{ textAlign: 'center', margin: '50px 0' }}>
          {intl.formatMessage(
            { id: 'app.uploadableAfter', defaultMessage: 'You can upload photos after {date}' },
            { date: moment(event.uploadableAt).format('DD/MM/YYYY HH:mm') }
          )}
        </div>
      )}
    </>
  )
}

export default compose(Form.create())(PhotoUploadTools)
