/* eslint-disable no-return-assign */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Button ,message,Modal } from "antd";
import { FormattedMessage } from "react-intl";
import { useStores, useUser } from '~/contexts'
import { useHeader } from '../services/header'
import * as S from "~/styles";
import { HashLink } from 'react-router-hash-link';

// const fackbookUrl = 'https://www.facebook.com/pixmorydotcom'
// const emailContract = 'mailto:hello@mangomango.app'
// const lineUrl = 'https://liff.line.me/1645278921-kWRPP32q/?accountId=487lvzct'
const fackbookMangoMangoUrl = 'https://www.facebook.com/mangomangoapp'

const Footer = () => {

  const { routerStore: { history } } = useStores()
  // const { userStore: user } = useStores()
  // const currentUser = user.initUser() && user
  const { user, updateUser } = useUser()
  const { createEvent } = useHeader()

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <S.Footer>
       <Modal title="Contact Email" visible={isModalOpen} onCancel={handleCancel} footer={[
             <Button onClick={handleOk}>
                <FormattedMessage id="app.ok" defaultMessage="OK" />
             </Button>
          ]}>
              <p>hello@mangomango.app</p>
          </Modal>
      {/* <Row style={{paddingBottom: 8}}>
        <Col>
          <Link to='/'>
            <img src='/images/mangomango_logo3.svg' alt='Pixmory' style={{ width: '90px' }} />
          </Link>   
         
        </Col>
      </Row> */}
      {/* <Row style={{paddingBottom: 8}}>
        <Col>
          <p style={{color: 'white', fontSize: 12 }}>MangoMango where moments become memories.</p>
        </Col>
      </Row> */}
      {/* <Row style={{paddingBottom: 8}}> 
        {!user || !user._id ?(
          <Link to='/register'>
          <Button type='primary' style={{ background: 'rgba(255, 255, 255, 0.10)', border: 'black', fontSize: '14px', height: '40px', padding: '0px 30px' }}>
          <FormattedMessage id="app.createAccount" defaultMessage="สร้างบัญชี" />
        </Button></Link>) : (
           <Button  onClick={() => createEvent.open()} type='primary' style={{ background: 'rgba(255, 255, 255, 0.10)', border: 'black', fontSize: '14px', height: '40px', padding: '0px 30px' }}>
           <FormattedMessage id="app.starFreeTrial" defaultMessage="สร้างงาน" />
         </Button>     
        )}
      </Row> */}
      <div className="footerInfo" >
        <div className="pages">
          <p style={{cursor:'pointer'}} ><HashLink smooth to="/#about-us">About us </HashLink></p>
          <p style={{cursor:'pointer'}} ><HashLink smooth to="/#feature">Features</HashLink></p>
          <p style={{cursor:'pointer'}} ><HashLink smooth to="/#pricing">Pricing</HashLink></p>
          <p >Terms of Service</p>
          <p >Privacy Policy</p>
        </div>
        <div className="organization">
          <p>บริษัท ไทยดอทรัน จำกัด </p>
          <p>ที่อยู่เลขที่ 809  โครงการ Stadium one</p>
          <p> ถนนพระราม 6 แขวงวังใหม่ เขตปทุมวัน</p>
          <p> กรุงเทพมหานคร 10330</p>
        </div>
        <div className="contact">
          <p>FB : MangoMango Thailand</p>
          <p>Email : hello@mangomango.app</p>
        </div>

      </div>
      <hr style={{ border: "1px solid rgba(255, 255, 255, 0.20)"}}/>
      <Row align='middle' justify='space-between' type='flex' style={{paddingBottom: 8 }} className="desktopOnly">
        <Col>
          <p style={{color: 'white', fontSize: 12}}>@ 2023 Thaidotrun. All rights reserved.</p>
        </Col>
        <Col>
          <Button type='primary' style={{  margin: '0px 12px' }}shape="circle" onClick={showModal}>
            <img
            style={{ width: "30px"}}
            src="/images/email-2.svg"
            />
          </Button>
          <Button type='primary' shape="circle" onClick={() => window.location.href = fackbookMangoMangoUrl}>
            <img style={{ width: "30px" }} src="/images/facebookFill.svg" />
          </Button>  
        </Col>
      </Row>
      <Row align='middle' justify='center' type='flex' style={{paddingBottom: 8 }} className="mobileOnly">
        <Col>
          <p style={{color: 'white', fontSize: 12}}>@ 2023 Thaidotrun. All rights reserved.</p>
        </Col>
      </Row>  
      <Row align='middle' justify='center' type='flex' style={{paddingBottom: 8 }} className="mobileOnly">
      <Col>
          <Button type='primary' style={{  margin: '0px 12px' }}shape="circle" onClick={showModal}>
            <img
            style={{ width: "30px"}}
            src="/images/email-2.svg"
            />
          </Button>
          <Button  type='primary' shape="circle" onClick={() => window.location.href = fackbookMangoMangoUrl}>
            <img style={{ width: "30px" }} src="/images/facebookFill.svg" />
          </Button>  
        </Col>
      </Row>
    </S.Footer>
  );
};

export default Footer;
