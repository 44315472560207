import React from 'react'
import { compose } from 'recompose'
import { useQuery } from '@apollo/client'
import _get from 'lodash/get'
import {
  Form,
  Button,
  Row,
  Col,
  Result,
  Divider,
  Tag,
} from 'antd'

import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { useStores } from '~/contexts'
import * as S from '~/styles'
import packagesQuery from '~/graphql/queries/packages.gql'

const Credits = ({ match }) => {
  const {
    routerStore: { history },
    userStore: { role },
  } = useStores()
  const { error, loading, data } = useQuery(packagesQuery, {
    fetchPolicy: 'network-only',
    ...(sessionStorage.getItem('flag_hidden_packages') && {
      variables: { hidden: true }
    })
  })
  if (loading) return 'Loading...'
  if (error) return 'Error...'
  const packages = _get(data, 'packages').filter((e) => (role === 'admin' ? e._id : e._id !== 'ECP00')).sort((a, b) => a.price - b.price)
  const onSelectPackges = (id) => {
    history.push(`/events/${match.params.eventId}/credits/buy?package=${id}`)
  }
  return (
    <S.Container fluid>
      <Row type="flex" align="middle" gutter={[0, 30]}>
        <Col span={24}>
          <S.PageHeader>
            <h1><FormattedMessage id="app.buyCredits"/></h1>
            <Link to={`/events/${match.params.eventId}/manage`}>
              <Button type="primary"><FormattedMessage id="app.backToEventPage" defaultMessage="Back to event page" /></Button>
            </Link>
          </S.PageHeader>
        </Col>
        <Col span={24}>
          <div style={{ width: '100%', overflowX: 'auto' }}>
            <S.EventCard title={<FormattedMessage id="app.selectPackage" defaultMessage="selectPackage" />} style={{ minWidth: '768px' }}>
              <S.EventPackage>
                {packages
                  ? (
                    packages.map((e) => (
                      <div key={e._id}>
                        <Row type="flex">
                          <Col span={11}>
                            <h2>{e.creditAmount}</h2>
                            <p><FormattedMessage id="app.uploadImagesMaximum" /> {e.creditAmount}</p>
                          </Col>
                          <Col span={1} style={{ textAlign: 'center' }}>
                            <Divider type="vertical" />
                          </Col>
                          <Col span={5}>
                            <div className="flexCenter">
                              {e.creditAmount === 3000 ? (
                                <div style={{textAlign: 'center'}}>
                                <Tag color="red">Special price</Tag>
                                <br />
                                <strike>
                                  <h2 style={{display: 'inline'}}>5000</h2>
                                  <h3 style={{display: 'inline'}}><FormattedMessage id="app.baht" /></h3>
                                </strike>
                                &nbsp;&nbsp;
                                <h2 style={{display: 'inline'}}>{e.price}</h2>
                                <h3 style={{display: 'inline'}}><FormattedMessage id="app.baht" /></h3>
                                </div>
                              ) : (
                                <>
                                <h2>{e.price}</h2>
                                <h3><FormattedMessage id="app.baht" /></h3>
                                </>
                              )}
                            </div>
                          </Col>
                          <Col span={1} style={{ textAlign: 'center' }}>
                            <Divider type="vertical" />
                          </Col>
                          <Col span={5}>
                            <div className="flexCenter">
                              <Button onClick={() => onSelectPackges(e._id)}><FormattedMessage id="app.onSelectPackage" /></Button>
                            </div>
                          </Col>
                        </Row>
                        <Divider />
                      </div>
                    ))
                  ) : (
                    <Result
                      status="error"
                      title={<FormattedMessage id="app.noPackage" />}
                      subTitle={<FormattedMessage id="app.sorryNoPackage" />}
                    />
                  )}
                <Row type="flex">
                  <Col span={packages ? 24 : 0}>
                    <Row type="flex">
                      <Col span={24}>
                        <div className="eventPackage__footer">
                          <p><FormattedMessage id="app.creditsMessage1" /></p>
                          <p><FormattedMessage id="app.creditsMessage2" /></p>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </S.EventPackage>
            </S.EventCard>
          </div>
        </Col>
      </Row>
    </S.Container>
  )
}

export default compose(
  Form.create(),
)(Credits)
