import styled from 'styled-components'
import {
  Button,
  Input,
  Select,
  Progress,
  Layout,
  Card,
  DatePicker,
} from 'antd'

const themeColor = '#000000'
const themeColorLight = '#ebf5f7'
const themeColorSecondary = '#1B1464'
// const themeColorSecondary = '#be508a'
const themeTextColor = '#707070'

// ------------------------ Old Component ------------------------
export const LocaleSwitcher = styled.span`
  font-size: 1em;
  // color: #000;
  width: 100px;
  text-align: right;
  .locale.active {
    border-bottom: 2px solid #000;
    cursor: pointer;
  }
  .localeSeparator {
    display: inline-block;
    width: 2px;
    height: 0.5em;
    background: #000;
    margin: 0 8px;
  }
  .locale {
    cursor: pointer;
    padding: 2px;
  }
`
export const PhotoPagination = styled.div`
  margin-top: 1em;
  margin-bottom: 1em;
  display: flex;
  align-items: flex-end;
  .slider {
    flex-grow: 1;
    width: 100%;
  }
  .paging {
    margin: auto;
    text-align: center;
    .ant-pagination-simple {
      .ant-pagination-item-link {
        color: white;
        background: none;
      }
      .ant-pagination-simple-pager {
        color: white;
        input {
          background: none;
        }
      }
    }
  }
  @media screen and (max-width: 575px) {
    flex-direction: column;
  }
`
export const Notice = styled.div`
  display: inline-block;
  font-size: 1rem;
  font-weight: lighter;
  background-color: #244596;
  color: white;
  margin: 18px;
  padding: 8px 16px;
  img {
    vertical-align: middle;
  }
  border-radius: 4px;
`
export const SearchBar = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  // color: #000;
  // background-color: #000;
  text-align: center;
  .searchButton {
    width: 100%;
  }
  .searchMethod {
    margin-top: -2px;
  }
  .photographerSearch {
    .ant-select-selection {
      height: 33px;
      width: 100%;
      font-size: 1.5em;
      font-weight: 500;
      color: #000;
      border-radius: 20px !important;
      border-color: ${themeTextColor};
      vertical-align: middle;
      .ant-select-selection__rendered {
        font-size: 0.8em;
        // margin-top: 6px;
        .ant-select-selection__placeholder {
          // color: #000;
          text-align: center;
        }
      }
    }
  }
  .actionButton {
    height: 2em;
    width: 100%;
    font-size: 0.8em;
    font-weight: 500;
    color: #000;
    border-radius: 20px !important;
    border-color: ${themeTextColor};
    vertical-align: middle;
  }
  .activeActionButton {
    border-radius: 20px 0px 0px 20px !important;
    width: 80%;
  }
  .cancelButton {
    height: 2em;
    font-size: 0.8em;
    font-weight: 500;
    // color: #000;
    border-radius: 0px 20px 20px 0px !important;
    border-color: ${themeTextColor};
    vertical-align: middle;
    width: 20%;
  }
  .actionButton:hover {
    // color: #F9A11E;
    // color: #000;
    border-color: ${themeTextColor};
  }
  .cancelButton:hover {
    // color: #F9A11E;
    // color: #000;
    border-color: ${themeTextColor};
  }
  .actionButton:disabled {
    color: ${themeTextColor};
  }
`

// ------------------------ Component ------------------------
export const Event = styled.div`
  font-size: 16px;
  max-width: 1140px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0 calc(50em * 0.0625) calc(100em * 0.0625) calc(50em * 0.0625);
  margin: 0 auto;
  background-color: #f5f5f5;
  .header {
    font-size: 2.5em;
    font-weight: 600;
  }
  .description {
    font-size: 1em;
    color: rgba(17, 17, 17, 0.40);
  }
  .divider {
    width: 0.1em;
    height: 3em;
    margin: 0 1em;
    background-color: rgba(17, 17, 17, 0.10);
  }
  .events-page {
    padding-top: calc(27.5em * 0.0625);
    font-size: calc(50em * 0.0625);
    font-weight: 500;
  }
  .event-box {
    height: calc(340em * 0.0625);
    display: flex;
    flex-direction: column;
    justify-content: end;
    margin-top: calc(100em * 0.0625);
    padding: 0 0 3.5em 0;
    z-index: 1;
  }
  .event-title {
    font-size: 3em;
    line-height: 1.2em;
  }
  .event-title-button {
    display: flex;
    justify-content: end;
  }
  .title-row {
    color: white;
    word-wrap: break-word;
    margin-top: 0.5em;
    font-weight: 500;
  }
  .manage-row {
    background: white;
    border-radius: 1.25em;
    box-shadow: 0em 0em 0.5em 0.125em rgba(85, 85, 85, 0.10);
    margin-top: 1.4em;
    padding: 1em;
  }
  .event-photos-row {
    margin-top: 2em;
  }
  .event-list-row {
    margin-top: 2.5em;
  }
  .search-column {
    border-radius: 1.6em;
    font-size: 1em;
  }
  .face-search-result {
    margin-top: ;
  }
  .pagination {
    display: block;
    text-align: center;
    margin: 1em;
  }
  .black-button {
    background-color: black;
    border: black;
    color: white;
  }
  .grey-button {
    background-color: rgba(17, 17, 17, 0.05);
    border: black;
    color: black;
  }
  .red-button {
    background-color: #F94005;
    border: #F94005;
    color: white;
  }
  .transparent-button:hover {
    background-color: #FDBE1A;
    color: black;
  }
  .transparent-button {
    background-color: rgba(255, 255, 255, 0.1);
    border: black;
    color: white;
  }
  .yellow-button {
    background-color: #FDBE1A;
    border: #FDBE1A;
    color: black;
  }
  .input input {
    height: 3.5em;
    background-color: rgba(17, 17, 17, 0.05);
    border: none;
    border-radius: 1.6em;
    padding: 0 1.5em;
  }
  .input input ::placeholder {
    color: rgba(17, 17, 17, 0.40);
  }
  .select {
    height: 3.5em;
    background-color: rgba(17, 17, 17, 0.05);
    border: none;
    border-radius: 1.6em;
    padding: 0 1.5em;
  }

  @media screen and (max-width: 1199px) {
    //
  }

  @media screen and (max-width: 991px) {
    padding: 0 1.4em 20em 1.4em;
    .divider {
      background-color: rgba(17, 17, 17, 0.10);
      height: 0.1em;
      margin: 0.5em 0 1em 0;
      width: 100%;
    }
    .events-page {
      padding-top: calc(20em * 0.0625);
      font-size: 2em;
      font-weight: 500;
    }
    .event-box {
      margin-top: ${({isOwner}) => isOwner ? 'calc(320em * 0.0625);': 'calc(140em * 0.0625);'};
      padding: 0 0 0.5em 0;
      ${({isOwner})=> !isOwner  && 'height:280px;'}
    }
    .event-title-button {
      justify-content: start;
    }
  }

  @media screen and (max-width: 767px) {
    .divider {
      background-color: rgba(17, 17, 17, 0.10);
      height: 0.1em;
      margin: 0.5em 0 1em 0;
      width: 100%;
    }
  }

  @media screen and (max-width: 575px) {
    font-size: 14px;
    .header {
      font-size: 1.5em;
      font-weight: 600;
    }
    .description {
      font-size: 1em;
    }
    .divider {
      width: 100%;
      height: 0.1em;
      margin: 0.5em 0 1em 0;
      background-color: rgba(17, 17, 17, 0.10);
    }
    .event-box {
      margin-top: ${({isOwner}) => isOwner ? 'calc(140em * 0.0625);': 'calc(20em * 0.0625);'};
      padding: 0 0 0.5em 0;
      ${({isOwner})=> !isOwner  && 'height:80px;'}
    }
    .event-title {
      font-size: 2.25em;
    }
    .event-title-button {
      justify-content: start;
    }
    .event-photos-row {
      margin-top: 4em;
    }
    .event-list-row {
      margin-top: 0;
    }
    .search-column {
      margin: 1.5em 0;
    }
  }
`
export const EventCover = styled.div`
  .event-cover-box {
    font-size: 16px;
    width: 1240px;
    height: 440px;
    position: absolute;
    top: 80px;
    left: 50%;
    transform: translate(-50%, 0%);
  }
  .event-cover {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%), url(${({ image }) => `${image}`});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    height: 100%;
  }
  .event-cover-background {
    height: 0em;
    background: rgba(0, 0, 0, 1);
  }
  
  @media screen and (max-width: 1240px) {
    .event-cover-box {
      width: 100%;
    }
    .event-cover {
      background-size: auto;
    }
  }

  @media screen and (max-width: 991px) {
    .event-cover-background {
      ${({isOwner}) => isOwner? 'height: 15em;' : 'height:0px;'})
    }
  }

  @media screen and (max-width: 575px) {
    .event-cover{
      height: ${({isOwner}) => isOwner ? '65%;' : '25%;'})
    }
    .event-cover-background {
      ${({isOwner}) => isOwner? 'height: 10em;' : 'height:0px;'})

    }
  }
`
export const Package = styled.div`
  font-size: 16px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  max-width: 1140px;
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center;
  overflow: hidden;
  padding: calc(100em * 0.0625) calc(80em * 0.0625);
  .ant-radio-button-wrapper {
    border-radius: 1.25em !important;
  }
  .ant-radio-button-wrapper:not(:first-child)::before {
    width: 0;
  }
  .box {
    width: 29em;
    padding: 1em 1.5em 1.5em 1.5em;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 0.75em 0;
    margin: 1.75em 0 0 0;
    background: white;
    border-radius: 1.25em;
    box-shadow: 0 0 0.5em 0.15em rgba(85, 85, 85, 0.10);
  }
  .description {
    font-size: 1em;
    font-weight: 400;
    color: rgba(17, 17, 17, 0.60);
  }
  .grey-input {
    height: 3em;
    font-size: 1em;
    font-weight: 300;
    background-color: rgba(17, 17, 17, 0.05);
    border: none;
    border-radius: 2em;
  }
  .header {
    font-size: 3em;
    font-weight: 600;
    line-height: 1.25em;
  }
  .list {
    width: 29em;
    padding: 1em 1.5em 1.5em 1.5em;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    margin: 1.75em 0 0 0;
    background: white;
    border-radius: 1.25em;
    box-shadow: 0 0 0.5em 0.15em rgba(85, 85, 85, 0.10);
  }
  .payment-method {
    width: 100%;
    height: 100%;
    display: block;
    line-height: 4em;
    font-size: 1em;
    font-weight: 600;
    padding: 0 1.5em;
    white-space: pre-wrap;
  }
  .price {
    font-size: 1.25em;
    color: black;
  }
  .radio {
    width: 100%;
    height: 100%;
    font-weight: 600;
    display: block;
    line-height: 1.75em;
    padding: 0.5em 0.5em 0.75em 0.5em;
    margin: 0.75em 0;
    border-radius: 0.75em;
    border: 1px #DFDFDF solid;
  }
  .radio::before {
    width: 0;
  }
  .radio-outer-checked {
    width: 1em;
    height: 1em;
    display: inline-flex;
    flexDirection: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 1em;
    margin: 0.3em 0 0 0;
    background: #FDBE1A;
    border: 1px #D7972C solid;
    border-radius: 999em;
  }
  .radio-inner-checked {
    width: 0.5em;
    height: 0.5em;
    position: relative;
    background: white;
    border: 1px #D7972C solid;
    border-radius: 999em;
  }
  .radio-unchecked {
    width: 1em;
    height: 1em;
    position: absolute;
    right: 1em;
    margin: 0.3em 0 0 0;
    background: white;
    border: 1px #DFDFDF solid;
    border-radius: 999em;
  }
  .submit {
    width: 100%;
    margin: 1.5em 0 0 0;
  }
  .summary {
    width: 100%;
    font-weight: 200;
    padding: 1em;
    margin: 1em 0 0 0;
    background: rgba(17, 17, 17, 0.05);
    border-radius: 0.75em;
  }
  .title {
    font-size: 0.85em;
    color: black;
  }
  .yellow-button {
    font-size: 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fdbe1a;
    border: #fdbe1a;
    color: black;
  }

  @media screen and (max-width: 1199px) {
    //
  }

  @media screen and (max-width: 991px) {
    //
  }

  @media screen and (max-width: 767px) {
    //
  }

  @media screen and (max-width: 575px) {
    font-size: 14px;
    padding: 3em 1em 5.5em 1em;
    .description {
      font-size: 1em;
    }
    .header {
      font-size: 2.25em;
    }
    .box {
      width: 100%;
    }
  }
`
export const Container = styled.div`
  ${({ fluid, noPadBottom }) => (fluid ? `
    background-color: #FDBE1A;
    overflow: hidden;
    width: 100%;
    height: 100%;
  ` : `
    overflow: hidden;
    padding: ${noPadBottom ? '10px 5px 0px 5px':'10px 5px 5px 5px'};
    max-width: 1140px;
    background-color: #FDBE1A;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  `)}
  @media screen and (max-width: 575px) {
    ${({ fluid }) => (!fluid && 'max-width: 100%;')}
  }
  @media screen and (max-width: 767px) { 
    ${({ fluid }) => (!fluid && 'max-width: 540px;')}
  }
  @media screen and (max-width: 991px) {
    ${({ fluid }) => (!fluid && 'max-width: 720px;')}
  }
  @media screen and (max-width: 1199px) {
    ${({ fluid }) => (!fluid && 'max-width: 960px;')}
  }
`
export const Header = styled(Layout.Header)`
  ${({ headercolor }) => `background-color: ${headercolor};`}
  align-items: center;
  display: flex;
  font-size: 16px;
  height: 80px;
  padding: 0;
  .header {
    ${({ headercolor }) => headercolor === 'white' && `box-shadow: 0px 0px 8px 4px rgba(85, 85, 85, 0.20);`}
    height: 80px;
    padding: 0 40px;
    width: 100%;
    z-index: 1;
  }

  @media screen and (max-width: 575px) {
    .header {
      padding: 0 20px;
    }
  }

  @media screen and (max-width: 350px) {
    .header {
      padding: 0 10px;
    }
    .smallDisplay {
      display: flex;
    }
  }
`
// export const Header = styled(Layout.Header)`
//   background-color: white;
//   font-size: 16px;
//   height: 80px;
//   padding: 0;
//   width: 100%;
//   .header {
//     align-items: center;
//     box-shadow: 0px 0px 8px 4px rgba(85, 85, 85, 0.20);
//     display: flex;
//     height: 80px;
//     justify-content: end;
//     padding: 0 40px;
//   }
//   .header .ant-page-header-heading-extra {
//     align-items: center;
//     display: flex;
//     justify-content: end;
//     padding-top: 0px;
//   }
  
//   @media screen and (max-width: 575px) {
//     .header {
//       padding: 0 20px;
//     }
//     .header .ant-page-header-heading {
//       display: flex;
//     }
//   }

//   @media screen and (max-width: 350px) {
//     height: 140px;
//     .header {
//       height: 140px;
//     }
//     .header .ant-page-header-heading-title {
//       margin-bottom: 15px;
//     }
//     .header .ant-page-header-heading {
//       display: block;
//     }
//   }
// `
export const Footer = styled(Layout.Footer)`
  text-align: center;
  // display: flex;
  // height: 100px
  // vertical-align: bottom;
  // justify-content: space-between;
  background-color: #000;
  // margin-top: 2px;
  a {
    color: #fff;
  }
  .footer__link{
    border-bottom: 2px solid #1B1464;
    display: inline-block;
    text-align: center;
  }
  .footer__left-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .footer__logo {
      img {
        width: 200px;
      }
      p {
        margin-top: -10px;
        margin-left: 7px;
      }
    }
    .footer__address {
      margin-left: 7px;
    }
  }
  .footer__right-section {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
    a {
      text-decoration: underline;
      line-height: 25px;
    }
    .ant-btn {
      width: 30px;
      margin-top: 10px;
    }
  }
  .footerInfo{
    display:flex;
    text-align:start;
    padding:20px 0;
    gap:100px;
  }
  .pages{
    color:#FFF;
    font-size:16px;
  }
  .organization{
    color:rgba(255, 255, 255, 0.60);
  }
  .contact{
    color:rgba(255, 255, 255, 0.60);
  }
  @media screen and (max-width: 575px) {
    .footer__left-section {
      .footer__logo {
        img {
          width: 170px;
        }
      }
      .footer__address {
        margin-top: 15px;
      }
    }
    .footer__right-section {
      align-items: flex-start;
      margin: 15px 0 0 7px;
    }
  }
  @media screen and (min-width: 975px) {
    .footer{
      margin: 0 80px;
      padding: 0 80px;
    }
    .footer__link{
      border-bottom: 2px solid #1B1464;
      display: inline-block;
      text-align: center;
    }
    .footer__left-section {
      .footer__logo {
        img {
          width: 170px;
        }
      }
      .footer__address {
        margin-top: 15px;
      }
    }
    .footer__right-section {
      align-items: flex-start;
      margin: 15px 0 0 7px;
    }
  
  }
  @media screen and (min-width: 1575px) {
    .footer{
      margin: 0 190px;
      padding: 0 250px;
    }
    .footer__link{
      border-bottom: 2px solid #1B1464;
      display: inline-block;
      text-align: center;
    }
    .footer__left-section {
      .footer__logo {
        img {
          width: 170px;
        }
      }
      .footer__address {
        margin-top: 15px;
      }
    }
    .footer__right-section {
      align-items: flex-start;
      margin: 15px 0 0 7px;
    }
  }
  @media screen and (max-width: 771px) {
    .footerInfo{
      display:unset;
    }
    .organization{
      margin-top:1.25em;
    }
    .contact{
      margin:1.25em 0;
    }
  }
`
export const PageHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h1 {
    font-size: 1.5rem;
    font-weight: bold;
    color: ${themeColor};
  }
  .ant-btn {
    font-size: 1.1rem;
    height: auto;
    padding: 8px 25px;
  }
  @media screen and (max-width: 575px) {
    padding: 0 10px;
    h1 {
      font-size: 1.2rem;
    }
    .ant-btn {
      font-size: 0.9rem;
      padding: 5px 15px;
    }
  }
  @media screen and (max-width: 1575px) {
    padding: 0 10px;
    h1 {
      font-size: 1.2rem;
    }
    .ant-btn {
      font-size: 0.9rem;
      padding: 5px 15px;
    }
  }
`
export const PageTitle = styled.div`
  text-align: center;
  font-weight: 600;
  margin-top: 20px;
  h1 {
    color: ${themeTextColor};
    font-size: 1.5rem;
    margin: 0;
  }
  h2 {
    // color: #be528a;
    color: #1B1464;
    font-size: 1.2rem;
  }
  @media screen and (max-width: 575px) {
    margin-top: 0;
    h1 {
      font-size: 1.1rem;
    }
    h2 {
      font-size: 0.9rem;
      padding: 0 10px;
    }
  }
`
export const SiderMenuButton = styled(Button)`
  background-color: ${themeColorLight} !important;
  font-size: 1rem;
  border-radius: 50%;
  height: 45px;
  position: absolute;
  right: 0px;
  top: 0;
  transform: translate(50%, 0);
  z-index: 1000;
  .openIcon {
    // color: #000;
    transform: translate(50%, 0);
  }
  .closeIcon {
    // color: #000;
  }
`
export const TextButton = styled.p`
  color: ${({ color }) => (color === 'secondary' ? themeColorSecondary : themeColor)};
  display: inline;
  word-spacing: 5px;
  text-decoration: underline;
  cursor: pointer;
  margin-right: 10px;
`
export const EventCard = styled(Card)`
  border: 1px solid ${themeColor};
  height: 100%;
  display: flex;
  flex-direction: column;
  .ant-card-head {
    background-color: ${themeColorLight};
  }
  .ant-card-body {
    height: 100%;
  }
  .ant-card-head-title {
    font-size: 1.1rem;
    font-weight: bold;
    color: ${themeTextColor};
    padding: 10px 0;
  }
  .ant-card-extra {
    font-size: 1.1rem;
    font-weight: bold;
    color: ${themeColor};
    /* text-decoration: underline; */
    cursor: pointer;
    padding: 0;
    .anticon {
      margin-right: 7px
    }
  }
  .ant-card-actions > li > span {
    color: ${themeColor}
  }
  .flexCenter {
    height: 100%;
    flex-grow: 1;
    .statsNumber {
      font-size: 1.5rem;
    }
    .statsButton {
      // background: #be508a;
      background: #1B1464;
      // border-color: #ca006c;
      // border-color: #000;
      color: #fff;
      margin-bottom: 10px;
      &:hover {
        background: #1B1464;
        // background: #db71aa;
        // border-color: #000;
        // border-color: #ca006c;
        color: #fff;
      }
      &:disabled {
        border-color: #d9d9d9;
        background: #f5f5f5;
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }
  
  @media screen and (max-width: 1100px) {
    .ant-form {
      width: 100%;
      padding: 0 15%;
    }
  }
  @media screen and (max-width: 575px) {
    .ant-card-head-title,
    .ant-card-extra {
      font-size: 1rem;
    }
    .ant-card-body {
      padding: 12px;
    }
  }
`
export const EventBanner = styled.div`
  width: 100%;
  height: auto;
  padding: 8px 24px;
  background-color: rgba(0, 0, 0, 0.65);
  color: #fff;
  position: absolute;
  bottom: 6px;
  left: 0px;
  .eventBanner__title {
    font-weight: 500;
    font-size: 1.3rem;
  }
  .eventBanner__content {
    font-weight: 200;
    font-size: 1rem;
  }
  @media screen and (max-width: 575px) {
    padding: 8px 16px;
    .eventBanner__title {
      font-weight: 500;
      font-size: 1rem;
      line-height: 1;
    }
    .eventBanner__content {
      font-weight: 200;
      font-size: 0.8rem;
      margin-top: 5px;
    }
  }
`
export const EventPackage = styled.div`
  h2 {
    color: ${themeTextColor};
    font-size: 1.3rem;
  }
  h3 {
    color: ${themeTextColor};
    margin: 5px 0px 0px 5px;
  }
  .eventPackage__footer {
    padding: 10px 30px;
    text-align: center;
  }
  .ant-btn {
    font-size: 0.9rem;
  }
  .ant-divider-vertical {
    background-color: ${themeColor};
    height: 100%;
  }
  .ant-divider-horizontal {
    background-color: ${themeColor};
  }
`
export const EventPayment = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  .ant-divider-horizontal {
    margin: 16px 0;
    background-color: ${themeColor};
  }
  .eventPayment__detail {
    flex-grow: 1;
  }
  .eventPayment__total-price {
    div {
      display: flex;
      justify-content: flex-end;
    }
  }
  .eventPayment__receipt {
    display: flex;
    justify-content: center;
  }
`
export const Thumbnail = styled.div`
  margin: ${({ margin }) => margin && `${margin}px`};
  height: ${({ height }) => height && `${height}px`};
  width: ${({ width }) => width && `${width}px`};
  position: ${({ column }) => (column ? 'absolute' : 'relative')};
  left: ${({ column, left }) => (column && left) && `${left}px`};
  top: ${({ column, top }) => (column && top) && `${top}px`};
  background-color: #fff;
  cursor: pointer;
  overflow: hidden;
  .thumbnail__tag {
    position: absolute;
    top: 6px;
    right: 6px;
    z-index: 1;
  }
  .thumbnail__cart {
    position: absolute;
    top: 6px;
    right: 6px;
    z-index: 1;
  }
  .ant-btn-primary {
    background-color: ${themeColorSecondary};
    border-color: ${themeColorSecondary};
  }
  &:hover {
    outline: ${({ isSelected }) => !isSelected && '2px solid #000'};
  }
`
export const CartFooter = styled.div`
  background-color: #000;
  border-top: 1px solid #000;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  padding: 10px 16px;
  .ant-divider-horizontal {
    margin: 16px 0px;
    /* background-color: ${themeColor}; */
  }
  .cartFooter__total-price {
    font-weight: bold;
    color: ${themeColor};
  }
`
export const MobileHeader = styled.div`
  display: flex;
  flex-direction: column;
  // align-items: center;
  .ant-avatar {
      background-color: #eee;
      /* border: 1px solid #777; */
    }
  .mobileHeader__layout {
    margin-top: 10px;
    line-height: 20px;
    text-align: center;
    .mobileHeader__user-role {
      text-transform: uppercase;
    }
    .mobileHeader__username {
      color: ${themeTextColor};
    }
    .mobileHeader__logout-button {
      margin-top: 10px;
    }
  }
`
export const Results = styled.div`
  margin-bottom: 20px;
  .ant-divider-horizontal.ant-divider-with-text-center {
    &::before,
    &::after {
      border-top: 2px solid ${themeColor};
    }
  }
`
export const PixmoryProgress = styled(Progress).attrs({
  strokeColor: {
    from: themeColor,
    to: themeColorSecondary,
  },
})``
export const PhotoList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .photoList__photo-layout {
    width: 118px;
    height: 118px;
    margin: 2px;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .ant-checkbox {
      position: absolute;
      top: 6px;
      left: 6px;
    }
    img {
      width: auto;
      height: auto;
      min-height: 100%;
    }
  }
  @media screen and (max-width: 575px) { 
    justify-content: center;
    .photoList__photo-layout {
      width: 77px;
      height: 77px;
      img {
        width: auto;
        height: auto;
        min-height: 100%;
      }
    }
  }
`
export const BorderlessInput = styled(Input)`
  border-radius: 0;
  border-top: none;
  border-right: none;
  border-left: none;
  border-color: #B9B9B9 !important;
  &:focus {
    border-top: none;
    border-right: none;
    border-left: none;
    box-shadow: none !important;
  }
`
export const BorderlessSelect = styled(Select)`
  .ant-select-selection {
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: 0;
    border-color: #B9B9B9 !important;
    box-shadow: none !important;
  }
`

export const BlueBorderSelect = styled(Select)`
  .ant-select-selection {
    display: flex;
    // border: 2px solid #1D81C4 !important;
    border: none;
    border-radius: 20px;
    box-shadow: none !important;
    // background-color: #fff;
    // color: #1D81C4;
    .ant-select-selection__rendered {
      margin-right: 11px;
      border-radius: 20px;
    }
  }
  .ant-select-arrow {
    display: none;
    // color: #1D81C4;
  }
`

export const BorderlessRangePicker = styled(DatePicker.RangePicker)`
  .ant-input {
    border-radius: 0;
    border-top: none;
    border-right: none;
    border-left: none;
    border-color: #B9B9B9 !important;
    box-shadow: none !important;
  }
`
export const PixmoryBrander = styled.div`
  font-family: "DBHeavent";
  width: 100%;
  height: 100%;
  position: relative;
  img {
    height: auto;
    width: 100%;
  }
  .branderLayout {
    width: 100%;
    height: auto;
    position: absolute;
    display: flex;
    flex-direction: column;
    h1 {
      font-size: 11.5vw;
      color: #fff;
      margin: 6% auto 0 auto;
      letter-spacing: 15px;
      font-weight: bold;
    }
    h2 {
      font-size: 4.5vw;
      color: #fff;
      margin: -1% auto 0 auto;
      transform: translate(-72%, 0);
      font-weight: 300;
      letter-spacing: 3px;
    }
    .group {
      text-align: right;
      width: 28%;
      margin: -1% auto 0 auto;
      padding: 6% 1% 0 0;
      transform: translate(-93%, 0);
      border-right: 0.3vw solid #fff;
      line-height: 1.1;
      font-family: 'DBHeavent-Light';
      h3 {
        font-size: 3.5vw;
        color: #fff;
        word-wrap: break-word;
      }
      h4 {
        font-size: 3vw;
        color: ${themeColor};
      }
    }
  }
  @media screen and (max-width: 575px) {
    .branderLayout {
      h1 {
        font-size: 13vw;
        margin: 10% auto 0 auto;
        letter-spacing: 2px;
      }
      h2 {
        font-size: 6vw;
        color: #fff;
        margin: -1% auto 0 auto;
        transform: translate(-20%, 0);
        font-weight: 300;
        letter-spacing: 3px;
      }
      .group {
        text-align: right;
        width: 28%;
        margin: -1% auto 0 auto;
        padding: 6% 1% 0 0;
        transform: translate(-93%, 0);
        border-right: 0.3vw solid #fff;
        line-height: 1.1;
        font-family: 'DBHeavent-Light';
        h3 {
          font-size: 3.5vw;
          color: #fff;
          word-wrap: break-word;
        }
        h4 {
          font-size: 3vw;
          color: ${themeColor};
        }
      }
    }
  }
`
// ------------------------ Wrapper ------------------------
export const HomeWrapper = styled.div`
  .ant-carousel .slick-dots li button {
    background-color: ${themeColor};
    opacity: 0.4;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 0 5px;
  }
  .ant-carousel .slick-dots li.slick-active button {
    background: ${themeColor};
    opacity: 1;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 0 5px;
  }
  .ant-carousel .slick-dots-bottom {
    bottom: -15px;
  }
  .desktopOnly {
    background-color: #FDBE1A;
    .pixmoryBanner {
      // background-color: #FDBE1A;
      // font-family: "DBHeavent";
      text-align: center;
      width: 100%;
      height: 100%;
      position: relative;
      img {
        height: auto;
        width: 100%;
      }
      .bannerLayout {
        background-color: #FDBE1A;
        text-align: center;
        width: 100%;
        height: auto%;
        // position: absolute;
        // display: flex;
        // flex-direction: column;
        img {
          height: 50%;
          width: 50%;
        }
        h4 {
          font-size: 1.5vw;
          color: #fff;
          text-align: center;
          // padding: 0 10%;
        }
        h1 {
          font-size: 2vw;
          color: #fff;
          margin: 9% 6%;
          text-align: center;
          padding: 20% 30%;
          transform: translate(40%, 0);
          // font-weight: 300;
          // letter-spacing: 3px;
        }
        .group {
          text-align: right;
          width: 28%;
          margin: -1% auto 0 auto;
          padding: 6% 1% 0 0;
          transform: translate(-93%, 0);
          border-right: 0.3vw solid #fff;
          line-height: 1.1;
          font-family: 'DBHeavent-Light';
          h2 {
            font-size: 3.5vw;
            color: #fff;
            word-wrap: break-word;
          }
          h3 {
            font-size: 3vw;
            color: ${themeColor};
          }
        }
      }
      @media screen and (max-width: 991px) {
        .bannerLayout > h4 {
          font-size: 10vw;
        }
      }
    }
    .description {
      font-size: 1.3vw;
      text-align: center;
      h1 {
        padding: 0 10%;
        color: ${themeTextColor};
      }
      @media screen and (max-width: 991px) {
        font-size: 1.6vw;
      }
    }
    .withEasySteps {
      div {
        text-align: center;
        font-size: 1vw;
        img {
          width: 95%;
          height: auto;
        }
        h3 {
          padding: 0 5%;
          color: ${themeTextColor};
        }
        span {
          font-weight: bold;
        }
      }
      @media screen and (min-width: 991px) {
        .space-align-block {
          width: 100%;
          height: 200px;
          flex: none;
          display: inline-block;
        }
        div {
          font-size: 1.4vw;
        }
      }
      @media screen and (max-width: 991px) {
        .space-align-block {
          width: 100%;
          height: 130px;
          flex: none;
          display: inline-block;
        }
        div {
          font-size: 1.4vw;
        }
      }
      @media screen and (min-width: 1301px) {
        .space-align-block {
          width: 100%;
          height: 230px;
          flex: none;
          display: inline-block;
        }
        div {
          font-size: 1.4vw;
        }
      }
      @media screen and (min-width: 1519px) {
        .space-align-block {
          width: 100%;
          height: 310px;
          flex: none;
          display: inline-block;
        }
        div {
          font-size: 1.4vw;
        }
      }
      
      @media screen and (min-width: 2119px) {
        .space-align-block {
          width: 100%;
          height: 430px;
          flex: none;
          display: inline-block;
        }
        div {
          font-size: 1.4vw;
        }
      }
    }
    .space-align-block {
      width: 100%;
      height: 210px;
      flex: none;
      display: inline-block;
    }
    .testimonail {
      .ant-divider-horizontal.ant-divider-with-text-center {
        // color: #be508a;
        color: #1B1464;
        text-align: center;
        margin-top: -15px;
        font-size: 3.5rem;
        &::before,
        &::after {
        border-top: 3px solid ${themeColor};
        }
      }
      .artboard{
        text-align: center;
        img {
          width: 60%;
          height: auto
          justify-content: center;
        }
        h2 {
          padding: 0 20%;
          font-family: "Kanit";
          color: #000;
          font-size: 1.3vw;
          // display: inline-block;
        }
        h3 {
          padding: 25px 10%;
          font-family: "Kanit";
          color: #707070;
          font-size: 1.2vw;
          // display: inline-block;
        }
      }
      img {
        width: 100%;
        height: auto;
      }
      h2 {
        font-size: 3rem;
        color: #000;
        font-family: 'DBHeavent-Light';
        padding-left: 50%;
       
      }
    }
    .easiestWay {
      .container {
        position: relative;
        img {
          width: 100%;
          height: auto;
        }
        .textSection {
          position: absolute;
          top: 45%;
          left: 58%;
          transform: translate(-50%, -50%);
          h2 {
            font-size: 2.3vw;
            color: #000;
          }
          h3 {
            font-size: 1.7vw;
            color: #000;
          }
          p {
            font-size: 1.7vw;
          }
          span {
            color: #000;
            text-decoration: underline;
          }
        }
      }
      @media screen and (max-width: 991px) {
        .container > .textSection {
          h2 {
            font-size: 2.6vw;
          }
          h3,p {
            font-size: 1.7vw;
          }
        }
      }
      /* @media screen and (min-width: 1199px) {
        .container > .textSection {
          h1 {
            font-size: 2.8rem;
          }
          h3,p {
            font-size: 1.7rem;
          }
        }
      } */
    }
    .pricing {
      .ant-col {
        text-align: center;
      }
      img {
        width: 70%;
        height: auto;
      }
    }
  }
  .mobileOnly {
    background-color: #FDBE1A;
    .pixmoryBanner {
      // background-color: #FDBE1A;
      // font-family: "DBHeavent";
      text-align: center;
      width: 100%;
      height: 100%;
      position: relative;
      img {
        height: auto;
        width: 100%;
      }
      .bannerLayout {
        width: 100%;
        height: auto;
        position: absolute;
        display: flex;
        flex-direction: column;
        img {
          margin: 80% auto 0 auto;
          height: auto;
          width: 45%;
        }
        h4 {
          font-size: 12vw;
          color: #fff;
          margin: 80% auto 0 auto;
          letter-spacing: 2px;
          font-weight: bold;
        }
        .group {
          text-align: center;
          width: 90%;
          margin: -1% auto 0 auto;
          padding: 0 1% 0 0;
          // transform: translate(-24%, 0);
          // border-right: 1vw solid #fff;
          // line-height: 1.1;
          font-family: 'DBHeavent-Light';
          h1 {
            font-size: 6.5vw;
            color: #fff;
            margin: -1% auto 0 auto;
            /* transform: translate(-72%, 0); */
            font-weight: 300;
            letter-spacing: 1px;
          }
          h2 {
            font-size: 5vw;
            color: #fff;
            word-wrap: break-word;
            font-weight: 300;
            margin: -1% auto 0 auto;
            // padding-left: 30%;
          }
        }
        h3 {
          font-size: 5vw;
          color: ${themeColor};
          margin: 51% auto 0 auto;
          transform: translate(-55%, 0);
        }
      }
    }
    .description {
      font-size: 1rem;
      text-align: center;
      h1 {
        color: ${themeTextColor};
      }
    }
    .withEasySteps {
      div {
        text-align: center;
        font-size: 1rem;
        img {
          width: 50%;
          height: auto;
        }
        h3 {
          padding: 0 15%;
          color: ${themeTextColor};
        }
      }
    }
    .testimonail {
      .artboard{
        text-align: center;
        img {
          width: 100%;
          height: auto  
        }
        h2 {
          padding: 0 10%;
          font-family: "Kanit";
          // color:#000;
          font-size: 4vw;
          // display: inline-block;
        }
        h3 {
          padding: 20px 15% 0 15%;
          font-family: "Kanit";
          color: #707070;
          font-size: 4vw;
          // display: inline-block;
        }
      }
      .ant-divider-horizontal.ant-divider-with-text-center {
        // color: #be508a;
        // color: #1B1464;
        text-align: center;
        margin-top: -15px;
        font-size: 2rem;
        &::before,
        &::after {
        border-top: 3px solid ${themeColor};
        }
      }
      img {
        width: 100%;
        height: auto;
      }
      h2 {
        font-size: 2rem;
        color: #be508a;
        font-family: 'DBHeavent-Light';
        padding-left: 50%;
       
      }
    }
    .easiestWay {
      margin-top: 50px;
      text-align: center;
      img {
        width: 95%;
        height: auto;
      }
    }
    .pricing {
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .contact {
    background-color: #FDBE1A;
    margin-bottom: 50px;
    padding-bottom: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
    img {
      width: 80%;
      height: auto;
      margin: 20% 0 0 0;
    }
      h2 {
        color: #fff;
        margin: 50px 0 0 60px;
        font-size: 3rem;
      }
      h4 {
        color: #fff;
      }
      hr {
        width: 35%;
        height: auto;
        margin: 0 0 15px 60px;
        border-bottom: 0.2vw solid #000;
        cursor: pointer;
      }
      p {
        color: #fff;
        margin: 0 0 0 60px;
      }
    .borderBottomContact {
      // border-bottom: 1vw solid #fff;
      // text-align: center;
      &::before,
      &::after {
      width: 70%;
      height: 50px;
      }
    }
    .footer__right-section {
      display: flex;
      flex-direction: column;
      margin: 0 0 0 60px;
      a {
        text-decoration: underline;
        line-height: 25px;
      }
      .ant-btn {
        width: 30px;
        margin-top: 10px;
      }
    }
    .sentContactButton {
      width: 70%;
      height: 50px;
      font-size: 16px;
    }
    @media screen and (max-width: 575px) {
      .sentContactButton {
        width: 100%;
        height: 35px;
        font-size: 16px;
      }
    }
  }
`
export const LoginWrapper = styled.div`
  background-color: #FDBE1A;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  .loginForm {
    width: 300px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .loginFormTitle {
    font-size: 2em;
    font-weight: 600;
    color: ${themeTextColor};
  }
  .loginFormForgot {
    float: right;
  }
  .loginFormButton {
    width: 100%;
  }
`
export const RegisterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .registerForm {
    width: 350px;
    transform: translate(-15%, 0px);
  }
  .registerFormForgot {
    float: right;
  }
  .registerFormButton {
    width: 100%;
  }
  .registerFormTitle {
    font-size: 2em;
    font-weight: 600;
    color: ${themeTextColor};
  }
  @media screen and (max-width: 575px) {
  .registerForm {
    transform: translate(0px, 0px);
  }
}
`
export const PaymentsWrapper = styled.div`
  .paymentTitle {
    text-align: center;
    font-size: 1.1rem;
    font-weight: bold;
    padding: 10px 0;
  }
  .paymentSelection {
    display: flex;
    justify-content: space-between;
    .paymentButton {
      width: 100%;
      // border: 1px solid #000;
      // color: #000;
      text-align: center;
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        background-color: #ebf5f7;
      }
      svg {
        font-size: 2rem;
      }
    }
  }
  @media screen and (max-width: 575px) {
    .paymentTitle {
      padding: 0;
    }
  }
`

// ------------------------ Ant Design Wrapper ------------------------
export const LayoutWrapper = styled.div`
  .ant-layout-content {
    background-color: #f5f5f5;
  }
`
export const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  .tableLayout {
    min-width: 991px;
  }
`
export const FormWrapper = styled.div`
  .ant-form {
    width: 800px;
  }
  .ant-form-item {
    margin: 5px;
  }
  .ant-form-item-label > label {
    font-weight: 500;
  }
  .photo-upload-button {
    height: auto;
  }
  @media screen and (max-width: 1199px) {
    .ant-form {
      width: 100%;
    }
}
`
export const SelectWrapper = styled.div`
  .ant-select-selection--single {
    height: 48px;
  }
  .ant-select-selection__rendered {
    height: 48px;
  }
  .ant-select-selection-selected-value {
    line-height: 48px;
  }
`

export const AboutUs = styled.div`
 
  .containerAboutUs{
    background-color:black;
    color:white;
    padding-bottom:80px;
  }
 
  .main-box{
    padding:80px 202px 0px 202px;

  }
  .font3{
    font-size:16px;
    color:rgba(255, 255, 255, 0.60);
  }
  .titleFont{
    font-size:56px;
  }
  .logo{
    width:120px;
    height:100px;
    margin:8px;
  }

  .logo-box{
    margin-top: 4em;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .logo-box-2{
    margin-top: 2em;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }


  @media screen and (max-width: 1199px) {
    //
  }

  @media screen and (max-width: 991px) {
    //
  }

  @media screen and (max-width: 767px) {
    .main-box{
      padding:80px 24px 0px 24px;
    }
    .main-box{
      width:100%;
    }
    .titleFont{
      font-size:32px;
    }
    .logo{
      width:80px;
      height:70px;
    }
    .logo-box{
      justify-content: space-between;
    }

  }
  }

  @media screen and (max-width: 575px) {
   
  
  }

`

export const Feature = styled.div`

  padding:80px 202px;
  background-color:white;
  .slick-next{
    right:-50px;
  }
  .slick-prev {
    left: -50px;
  }
  .slick-dots li button:before {
          font-family: 'slick';
          font-size: 24px;
          line-height: 20px;
          margin-top:1.25em;
      }
  .slick-dots li.slick-active button:before {
    color:#FDBE1A;
  }

  .containerFeature{
    background-color:white;
  }

  .font3{
    font-size:16px;
    color:rgba(17, 17, 17, 0.60);
  }
  .titleFont{
    font-size:56px;
  }
  .titleFont2{
    font-size:24px;
  }
  .titleFont3{
    font-size:24px;
    margin-top:2em;
  }
  .descriptionFont{
      font-size:16px;
      color:rgba(17, 17, 17, 0.60);
      margin-top:1em;

  }
  .mainContent{
    display:flex;
    margin-top:2em;
  }


  @media screen and (max-width: 1199px) {
    .responsiveImage{
      width: 100%;
      height: auto;
      min-height:350px;
      min-width:350px;
  
    }
  }

  @media screen and (max-width: 991px) {
    padding:80px 48px;
    .containerFeature{
      background-color:white;
    }
  }

  @media screen and (max-width: 767px) {
    padding:80px 24px;
    .mainContent{
      display:unset;
    }
    .containerFeature{
    }
    .titleFont{
      font-size:32px;
    }
    .titleFont2{
      margin-top:2em;
    }

    .responsiveImage{
      margin-top:2em;
      min-height:200px;
      min-width:200px;
    }
  
  }

  @media screen and (max-width: 575px) {
  }

`

export const Usescase = styled.div`
  .imageUseCases{
    border-radius: 16px;
  }
  .containerUsescase{
    background-color:black;
    color:white;
    padding:80px 202px;
  }
  .descriptionBox{
    display: flex;
    align-items: center;
  }

  .font3{
    font-size:16px;
    color:rgba(255, 255, 255, 0.60);
  }
  .titleFont{
    font-size:56px;
    color:#FFF;
  }
  .titleFont2{
    font-size:24px;
  }
  .descriptionFont{
      font-size:16px;
      color:rgba(255, 255, 255, 0.60);
      margin-top:2em;

  }
  .mainContent{
    display:flex;
    margin-top:2em;
  }
  .mainContentConference{
    display:flex;
    margin-top:2em;
  }
  .textDescription{
    margin-right:2em;
  }
  .title{
    text-align:center;
  }


  @media screen and (max-width: 1199px) {
    .responsiveImage{
      width: 100%;
      height: auto;
      min-height:250px;
      min-width:250px;
  
    }
    .textDescription{
      width:200%;
    }
  }

  @media screen and (max-width: 991px) {
    .containerUsescase{
      padding:80px 48px;
    }
  }

  @media screen and (max-width: 767px) {
    .mainContent{
      display:unset;
    }
    .mainContentConference{
      display:flex;
      flex-direction:column-reverse;
    }
    .containerUsescase{
      padding:80px 24px;
    }
    .titleFont{
      font-size:32px;
    }
    .titleFont2{
      margin-top:2em;
    }

    .responsiveImage{
      margin-top:2em;
      min-height:100px;
      min-width:100px;
    }
    .textDescription{
      margin-right:unset;
      width:unset;
    }
    .title{
      text-align:start;
    }
  
  }

  @media screen and (max-width: 575px) {
 
  }

`

export const Pricing = styled.div`
 
  .containerPricing{
    background-color:#FFDB7D;
    padding:80px 202px;
  }
  .topBox{
    text-align:center;
  }
  .font3{
    font-size:16px;
    color:rgba(17, 17, 17, 0.60);
  }
  .titleFont{
    font-size:56px;
    font-weight: 520;
  }
  .yellow-button {
    background-color: #FDBE1A;
    border: #FDBE1A;
    color: black;
    fontSize: 16px;
    height: 40px;
    margin-top: 1em;
    width:100%;
  }

  .midBox{
    display:flex;
    flex-wrap:wrap;
    gap:20px;
    justify-content:space-evenly;
    margin: 64px 0px;
    text-align:center;
  }
  .bottomBox{
    text-align:center;
  }
  .titleFont2{
    font-size:24px;
  }
  .amountCredit{
    font-size:16px;
    color:#777;
  }
  .price{
    font-size:40px;
    color:#111;
    margin-top:0.25em;
  }
  .vat{
    font-size:14px;
    color:#777;
  }
  .card-price{
    border-radius:24px; 
    min-width:200px;
    max-width:300px;
 }

 @media screen and (max-width: 1199px) {
  .containerPricing{
   padding:80px 80px;
  }
 }

 @media screen and (max-width: 991px) {
 
 }

 @media screen and (max-width: 767px) {
   .containerPricing{
     padding:80px 50px;
    }
   .card-price{
     margin-top:1em;
     min-width:250px;
   }
   .bottomBox{
     margin-top:4em;
   }

 }

 @media screen and (max-width: 575px) {
  
 }`

export const MyCredit = styled.div`
  .ant-select-selection--single {
    height: 48px;
  }
  .ant-select-selection__rendered {
    height: 48px;
  }
  .ant-select-selection-selected-value {
    line-height: 48px;
  }
  justify-content: center;
  display: flex;
  align-items: center; 
  padding: calc(30em * 0.0625) calc(80em * 0.0625);

  .searchText{
    text-align:right;
  }
  .yellow-button {
    background-color: #FDBE1A;
    border: #FDBE1A;
    color: black;
    fontSize: 16px;
    height: 40px;
    // margin-top: 1em;
    width:100%;
  }

  .container{
    width:80%;
    min-width:1200px;
    display:flex;
    padding: 0px 8em;
  }
  .leftBox{
    width:60%;
    padding:0px 1em;
  }
  .thirdLeftBox{
    display:flex;
    justify-content:space-between;
  }
  .rightBox{
    width:100%;
    min-width:600px;
    .box{
      .ant-select-selection--single {
        height: 40px;
      }
      .ant-select-selection__rendered {
        height: 40px;
      }
      .ant-select-selection-selected-value {
        line-height: 40px;
      }
    }

  }
  .box {
    width: 100%;
    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.75em 0;
    margin: 1.75em 0 0 0;
    background: white;
    border-radius: 1.25em;
    box-shadow: 0 0 0.5em 0.15em rgba(85, 85, 85, 0.10);
  }

  @media screen and (max-width: 1199px) {

  }

  @media screen and (max-width: 991px) {
    
    .container{
      padding:0px;
      min-width:unset;
      display:block;
      // padding: 0px 6em;
    }
    .leftBox{
      width:100%;
      padding:0px;
    }
    .secondaryLeftBox{
      display:flex;

    }
    .thirdLeftBox{
      height:100%;
      display:unset;
      justify-content:unset;
    }
    .rightBox{
      min-width:unset;
      margin-top:2em;
    }
    font-size: 14px;
    padding: 3em 1em 5.5em 1em;
    }

  

  @media screen and (max-width: 767px) {
    .container{
      padding:0px;
    }
    .searchText{
       display:none;
      // visibility: hidden;
    }
  }
  @media screen and (max-width: 575px) {
   
    
  }

`
