import { observable, action } from 'mobx'
import { Cookies } from 'react-cookie'

const cookie = new Cookies()

export class I18NStore {
  @observable locale = cookie.get('locale') || 'th'
  @action
  setLocale(locale) {
    this.locale = locale
    cookie.set('locale', locale, { path: '/' })
  }
}

