import React, { useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useQuery } from '@apollo/client'
import { Spin } from 'antd'
import { useStores, useUser } from '~/contexts'
import AppLayout from './AppLayout'
import currentAdminUserQuery from '../graphql/queries/currentAdminUser.gql'

const MainRoute = observer(({ component: Component, path, ...rest }) => {
  const header = ![].includes(path)
  const headerColor = ['/', '/login', '/register', '/verify/phone', '/forgotpassword', '/checkemail', '/passwordreset'].includes(path) ? '#FDBE1A' : 'white'
  const {
    routerStore: { location },
  } = useStores()
  const { updateUser, user, userLoading } = useUser()
  const { data, loading } = useQuery(currentAdminUserQuery, { skip: userLoading })
  const { currentAdminUser } = data ?? {}
  const { _id, username, role } = currentAdminUser ?? {}
  useEffect(() => {
    const isUserValid = !user || !user._id
    if (isUserValid && !loading && !userLoading) {
      updateUser({ _id, role, username })
    }
  }, [_id, loading, role, updateUser, user, userLoading, username])
  const { query: { invitationToken } } = location
  return (
    <AppLayout
      header={header}
      headerColor={headerColor}
      headerAffix
    >
      <Route
        {...rest}
        render={(props) => {
          if ((!user && _id) || loading || userLoading) {
            return <Spin tip='Loading...' style={{ display: 'block', lineHeight: '100vh' }} />
          }
          if (!user && invitationToken) {
            return <Redirect to={{ pathname: '/passwordcreate', search: `?invitationToken=${invitationToken}`, state: { from: props.location } }} />
          }
          if ((!user && !_id)) {
            return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
          }
          return <Component {...props} />
        }}
      />
    </AppLayout>
  )
})

export default MainRoute
