import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import { observer } from 'mobx-react'
import AppLayout from './AppLayout'
import { useUser } from '~/contexts'
import currentAdminUserQuery from '../graphql/queries/currentAdminUser.gql'
import { useQuery } from '@apollo/client'

let limitUserUpdate = 0

const MainRoute = observer(({ component: Component, path, ...rest }) => {
  const header = ![].includes(path)
  const headerColor = ['/', '/login', '/register', '/verify/phone', '/forgotpassword', '/checkemail', '/passwordreset'].includes(path) ? '#FDBE1A' : 'white'
  const { updateUser, user, userLoading } = useUser()
  const { data, loading } = useQuery(currentAdminUserQuery, { skip: userLoading })

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    if (searchParams.get('enable_flag')) {
      sessionStorage.setItem(`flag_${searchParams.get('enable_flag')}`, 'true')
    }
    // window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const { currentAdminUser } = data ?? {}
    const { _id, username, role } = currentAdminUser ?? {}
    const isUserValid = !user || !user._id

    if (limitUserUpdate < 5) {
      if (isUserValid && !loading && !userLoading) {
        updateUser({ _id, role, username })
      }
      limitUserUpdate += 1
    }
  }, [data, loading, userLoading, updateUser, user])

  
  return (
    <AppLayout
      header={header}
      headerColor={headerColor}
      headerAffix
    >
      <Route
        {...rest}
        path={path}
        render={(props) => {
          return <Component {...props} />
        }}
      />
    </AppLayout>
  )
})

export default MainRoute
