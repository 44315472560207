import React, { Component } from 'react'
import { graphql } from '@apollo/client/react/hoc'
import { Link } from 'react-router-dom'
import {
  Form, Input, Row, Col, message, Button,
} from 'antd'
// import logo from './assets/images/logo.png'
import { inject, observer } from 'mobx-react'
import * as S from "~/styles";

// import jsonwebtoken from 'jsonwebtoken'
import { injectIntl } from 'react-intl'
import {ArrowLeftOutlined} from "@ant-design/icons";
import { FormattedMessage } from 'react-intl'

import authAdminMutation from '~/graphql/mutations/authAdminUser.gql'
import resetPasswordMutation from '~/graphql/mutations/resetPassword.gql'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { GradientButton, WhiteButton, GradientText } from '~/styles'
// import phoneImg from  '~/assets/images/phone.png'

@inject('user')
@inject('router')
@injectIntl
@Form.create()
@graphql(authAdminMutation, { name: 'authUser' })
@graphql(resetPasswordMutation, { name: 'resetPassword' })
@observer
class ForgotPassword extends Component {
  state = {
    emailSent: false,
    submitLoading: false,
  }
  // handleSendEmail = () => {
  //   this.setState({ emailSent: true })
  // }
  handleSubmit = (e) => {
    this.setState({ submitLoading: true })
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.resetPassword({
          variables: values,
        }).then(() => {
          this.setState({ emailSent: true })
        }).catch((err) => err.graphQLErrors.forEach((error) => message.error(this.props.intl.formatMessage({
          id: `api.errors.${error.message}`,
          defaultMessage: 'Error',
        })),
        ))
      }
      // this.setState({ emailSent: true })
    })
  }
  render() {
    // const { router } = this.props
    // const { userType = 'photographer' } = router.location.query
    const { getFieldDecorator } = this.props.form
    return (
      <S.Container fluid>
      <S.LoginWrapper>
        <div className="pixmoryBanner">
          <div style={{ paddingBottom: "20px" }}>
            <Link to="/">
              <img
                style={{ width: "300px", textAlign: "center" }}
                src="/images/mangomango_logo2.svg"
                alt="Pixmory"
              />
            </Link>
          </div>
          <div style={{ paddingBottom: "50px", display: "inline-flex" }}>
            <div
              style={{
                borderRadius: "25px",
                background: "#FFED8D",
              }}
            >
              <Col
                style={{
                  // width: "350px",
                  borderRadius: "25px 25px 0 0",
                  paddingTop: 32,
                  paddingBottom: 26,
                  paddingLeft: 32,
                  paddingRight: 32,
                  background: "#fff",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  gap: 12,
                  display: "flex",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 12,
                    display: "inline-flex",
                  }}
                >
                  <Link
                    to="/login"
                    style={{
                      width: 'auto',
                      padding: 8,
                      background: "#FDBE1A",
                      borderRadius: 1000,
                      overflow: "hidden",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 12,
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        color: "#111111",
                        fontSize: 14,
                        fontWeight: "400",
                        lineHeight: 1,
                        wordWrap: "break-word",
                      }}
                    >
                      <ArrowLeftOutlined /> <FormattedMessage id='app.back'/>
                    </div>
                  </Link>
                </div>

                {this.state.emailSent
                  ? (
                    <div  style={{
                      // background: "rgba(17, 17, 17, 0.05)",
                      alignSelf: "stretch",
                      borderRadius: 1000,
                      wordWrap: "break-word",
                      }}>
                      <p style={{ color: '#000',fontSize: 14, paddingBottom:17, wordWrap: "break-word", }}><FormattedMessage id="app.enterForgotPassword2"/></p> 
                      <Link to='/login'><Button
                        type="primary"
                        style={{
                          width: "100%",
                          height: 50,
                          paddingLeft: 24,
                          paddingRight: 24,
                          background: "#111111",
                          borderRadius: 1000,
                          overflow: "hidden",
                          gap: 12,
                          textAlign: "left",
                          fontSize: 16,
                          fontWeight: "400",
                          wordWrap: "break-word",
                        }}
                      >
                        <FormattedMessage id='app.login'/>
                      </Button></Link>  
                    </div>
                  )
                  : (
                    <>
                      <p
                  style={{
                    width: "100%",
                    textAlign: "left",
                    color: "#111111",
                    fontSize: 32,
                    fontWeight: "700",
                    lineHeight: 1,
                    wordWrap: "break-word",
                  }}
                >
                  <FormattedMessage id='app.forgotpassword'/>
                </p>
                <p
                  style={{
                    width: "100%",
                    textAlign: "left",
                    color: "rgba(17, 17, 17, 0.60)",
                    fontSize: 14,
                    fontWeight: "200",
                    lineHeight: 1,
                    wordWrap: "break-word",
                  }}
                >
                 <FormattedMessage id='app.enterEmail'/>
                </p>
                <Form
                onSubmit={this.handleSubmit}
                style={{
                  width: "100%",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  display: "flex",
                  }}>
                        <div
                  style={{
                    alignSelf: "stretch",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: 16,
                    display: "flex",
                  }}
                >
              
                  <Form.Item style={{
                      background: "rgba(17, 17, 17, 0.05)",
                      alignSelf: "stretch",
                      borderRadius: 1000,
                      // lineHeight: 20,
                      wordWrap: "break-word",
                    }}>
                  {getFieldDecorator('email', {
                    rules: [
                      { required: true, message: 'Please enter your e-mail address!' },
                      { type: 'email', message: 'Please enter a valid e-mail address!' },
                    ],
                  })(
                  <Input
                    style={{
                      background: "rgba(17, 17, 17, 0.05)",
                      alignSelf: "stretch",
                      height: 48,
                      padding: 16,
                      borderRadius: 1000,
                      color: "rgba(17, 17, 17, 0.60)",
                      fontSize: 16,
                      fontWeight: "400",
                      // lineHeight: 20,
                      wordWrap: "break-word",
                    }}
                    placeholder='email'
                  
                  />
                  )} </Form.Item>
                </div>
                <div
                  style={{
                    alignSelf: "stretch",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 12,
                    display: "inline-flex",
                  }}
                >
                </div>

                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    width: "100%",
                    height: 50,
                    paddingLeft: 24,
                    paddingRight: 24,
                    background: "#111111",
                    borderRadius: 1000,
                    overflow: "hidden",
                    gap: 12,
                    textAlign: "left",
                    fontSize: 16,
                    fontWeight: "400",
                    wordWrap: "break-word",
                  }}
                >
                  <FormattedMessage id='app.requestPassword'/>
                </Button>
                </Form>

                    </>
                  )}
             
            
              </Col>
              <Col className="desktopOnly">
                <Col
                  style={{
                    borderRadius: "25px",
                    background: "#FFED8D",
                    width: "450px",
                    height: "100%",
                    paddingTop: 16,
                  }}
                >
                  <div
                    style={{
                      alignSelf: "stretch",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: 12,
                      display: "inline-flex",
                    }}
                  >
                    <p
                      style={{
                        width: "100%",
                        color: "rgba(17, 17, 17, 0.60)",
                        fontSize: 16,
                        fontWeight: "400",
                        // lineHeight: 3,
                        wordWrap: "break-word",
                        paddingBottom: 16,
                      }}
                    >
                      <FormattedMessage id='app.mango.text1'/>
                      <FormattedMessage id='app.mango.text2'/>
                    </p>
                  </div>
                  <Row
                    style={{
                      // alignSelf: "stretch",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "inline-flex",
                    }}
                  >
                    <Col span={8} style={{ marginLeft: 10 }}>
                      <img
                        style={{ width: "100px", borderRadius: 5 }}
                        src="/images/QRcode_facebook_mangomango.png"
                      />
                    </Col>
                    <Col
                      span={12}
                      style={{
                        textAlign: "left",
                        paddingBottom: 10,
                        fontSize: 16,
                        fontWeight: "400",
                      }}
                    >
                      <div style={{ paddingBottom: 15 }}>
                        <img
                          style={{ width: "27px" }}
                          src="/images/email.svg"
                        />
                        hello@mangomango.app
                      </div>
                      <div>
                      <img style={{ width: '30px' }} src='/images/facebook.svg' /> MangoMango Thailand
                      </div>
                    </Col>
                    <div>
                      <img
                        style={{
                          width: "100px",
                          height: "130px",
                        }}
                        src="/images/Component2.svg"
                      />
                    </div>
                  </Row>
                </Col>
              </Col>
              <Col className="mobileOnly">
                <Col
                  style={{
                    borderRadius: "25px",
                    background: "#FFED8D",
                    // width: "350px",
                    height: "100%",
                    paddingTop: 16,
                  }}
                >
                  <div
                    style={{
                      alignSelf: "stretch",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: 12,
                      display: "inline-flex",
                    }}
                  >
                    <p
                      style={{
                        width: "100%",
                        textAlign: "center",
                        paddingBottom: 16,
                        color: "rgba(17, 17, 17, 0.60)",
                        fontSize: 14,
                        fontWeight: "400",
                        wordWrap: "break-word",
                      }}
                    >
                      <FormattedMessage id='app.mango.text1'/><br/>
                      <FormattedMessage id='app.mango.text2'/>
                    </p>
                  </div>
                  <div className="pixmoryBanner">
                    <img
                      style={{
                        width: "100px",
                        borderRadius: 10,
                        paddingBottom: 16,
                      }}
                      src="/images/QRcode_facebook_mangomango.png"
                    />
                    <div style={{ textAlign: "left", paddingLeft: 90 }}>
                      <div style={{ paddingBottom: 20 }}>
                        <img
                          style={{ width: "30px" }}
                          src="/images/email.svg"
                        />{" "}
                        hello@mangomango.app
                      </div>
                      <div>
                      <img style={{ width: '30px' }} src='/images/facebook.svg' /> MangoMango Thailand
                      </div>
                    </div>
                    <div>
                      <img
                        style={{
                          borderRadius: "25px",
                          width: "100%",
                          height: "auto",
                        }}
                        src="/images/Frame507.svg"
                      />
                    </div>
                  </div>
                </Col>
              </Col>
            </div>
          </div>
        </div>
      </S.LoginWrapper>
    </S.Container>
     
    )
  }
}

export default ForgotPassword
