import { useEffect } from "react"
import { useHistory } from "react-router"
import { analytics } from "../services/firebase"
import { logEvent } from "firebase/analytics";
import useClarity from "../hooks/useClarity";
// import clarity from '../services/clarity'
// const logCurrentPage = () => {
//   // setCurrentScreen(analytics, window.location.pathname)
//   logEvent(analytics, 'screen_view')
// };
  
const Tracker = ({ children }) => {
  useClarity()
  const history = useHistory()
  const page = history.location.pathname
  // console.log('top', page)
  // const match = useRouteMatch()
  // console.log('match', match, history)
  logEvent(analytics, 'screen_view', {
    screen_name: page,
    screen_class: page,
  })
  useEffect(() => {
    // logCurrentPage(); // log the first page visit
    const unlisten = history.listen((location, prevLocation, ...r) => {
      const prevPage = prevLocation.pathname
      const page = location.pathname
      if (prevPage !== page) {
        // console.log('listen', page)
        // logCurrentPage();
        // setCurrentScreen(analytics, page)
        logEvent(analytics, 'screen_view', {
          screen_name: page,
          screen_class: page,
        })
      }
      // console.log(location.query, { prevPage, page, location, prevLocation, r })
      // logCurrentPage();
    });
    return () => {
      unlisten();
    }
  }, [history]);
  return children;
}

export default Tracker