import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useMutation } from '@apollo/client'
import { Button, Col, Icon, message, Row, Spin } from 'antd'
import { compose } from 'recompose'
import registerAdminUserMutation from '~/graphql/mutations/registerAdminUser.gql'
import { useScreenView } from '../hooks/useFirebaseAnalytics'
import { useStores } from '~/contexts'
import OtpInput from '../components/OtpInput'
import * as S from '~/styles'

const Register = () => {
  useScreenView('auth-register')
  const intl = useIntl()
  const {
    routerStore: { history },
  } = useStores()
  const [otp, setOtp] = useState('')
  const [loading, setLoading] = useState(false)
  const [registerAdminUser] = useMutation(registerAdminUserMutation)
  const { from, phoneNumber } = history.location.state
  const handleBack = async () => {
    history.goBack()
  }
  const handleChange = (value) => {
    setOtp(value)
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      const result = await window.confirmationResult.confirm(otp)
      const { user: { phoneNumber, uid } = {} } = result ?? {}
      try {
        await registerAdminUser({
          variables: {
            record: {
              username: phoneNumber,
              role: 'organizer',
              uid,
              profile: {
                phone: phoneNumber,
              },
            },
            phoneOnly: true,
          },
        })
        message.success('Phone number sign-up successful')
        history.push(from)
      } catch (error) {
        error.graphQLErrors.forEach((err) => message.error(err.message))
      }
    } catch (error) {
      message.error(intl.formatMessage({ id: `api.errors.${error.code}`, defaultMessage: `${error.message}` }))
    }
  }

  return (
    <S.Container fluid>
      <S.LoginWrapper>
        <div className='pixmoryBanner'>
          <div style={{ paddingBottom: '50px' }}>
            <Link to='/'>
              <img src='/images/logo.svg' alt='mangomango' style={{ width: '300px', textAlign: 'center' }} />
            </Link>
          </div>
          <div style={{ background: '#FFED8D', borderRadius: '25px', marginBottom: '50px' }}>
            <Row
              align='middle'
              justify='center'
              type='flex'
              style={{
                maxWidth: '450px',
                background: '#ffffff',
                borderRadius: '25px 25px 0 0',
                padding: '36px 32px',
              }}
            >
              <Col span={24} style={{ textAlign: 'left' }}>
                <Button onClick={handleBack} style={{ background: '#FDBE1A', borderRadius: '1000px', padding: '4px 8px', wordSpacing: '12px' }}>
                  <Icon type='arrow-left' /> {intl.formatMessage({ id: 'app.back', defaultMessage: 'Back' })}
                </Button>
              </Col>
              <Col span={24} style={{ margin: '5px 0 0 0', textAlign: 'left' }}>
                <p style={{ fontSize: '32px', fontWeight: '700', color: '#111111' }}>
                  {intl.formatMessage({ id: 'app.verifyPhoneNumber', defaultMessage: 'Verify your phone number' })}
                </p>
                <div style={{ fontWeight: '200', color: 'rgba(17, 17, 17, 0.60)', margin: '2px 0 0 0', lineHeight: '25px' }}>
                  {intl.formatMessage({ id: 'app.sendOtpToPhone', defaultMessage: 'A 6-digit OTP has been sent to {phoneNumber}' }, { phoneNumber })}
                </div>
              </Col>
              <Col span={24}>
                <OtpInput onChange={handleChange} otpLength={6} />
              </Col>
              <Col span={24}>
                <Button
                  onClick={handleSubmit}
                  style={{
                    width: '100%',
                    height: '50px',
                    fontSize: '16px',
                    fontWeight: '400',
                    background: '#111111',
                    borderRadius: '9999px',
                    color: 'white',
                    margin: '12px 0 0 0',
                    padding: '0 24px',
                  }}
                >
                  <p style={{ float: 'left' }}>{intl.formatMessage({ id: 'app.verify', defaultMessage: 'Verify' })}</p>
                  <p style={{ float: 'right' }}>{loading ? <Spin /> : <Icon type='arrow-right' />}</p>
                </Button>
              </Col>
              <Col span={24} style={{ fontWeight: '200', margin: '36px 0 0 0', textAlign: 'left', wordSpacing: '12px' }}>
                <span>{intl.formatMessage({ id: 'app.notReceivedOtp', defaultMessage: `Haven't received the OTP?` })}</span>{' '}
                <span style={{ fontWeight: '600', color: '#FDBE1A' }}>
                  {intl.formatMessage({ id: 'app.sendAgain', defaultMessage: 'Send again' })}
                </span>
              </Col>
            </Row>
            <Row className='desktopOnly'>
              <Col
                style={{
                  width: '450px',
                  height: '100%',
                  background: '#FFED8D',
                  borderRadius: '25px',
                  paddingTop: '16px',
                }}
              >
                <div
                  style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    gap: '12px',
                  }}
                >
                  <p
                    style={{
                      width: '100%',
                      fontSize: '16px',
                      fontWeight: '400',
                      color: 'rgba(17, 17, 17, 0.60)',
                      paddingBottom: '16px',
                      wordWrap: 'break-word',
                    }}
                  >
                    {intl.formatMessage({ id: 'app.mango.text1', defaultMessage: 'If you encounter any issues,' })}
                    {intl.formatMessage({ id: 'app.mango.text2', defaultMessage: 'Please contact us through the channels below.' })}
                  </p>
                </div>
                <Row
                  style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Col span={8} style={{ marginLeft: '10px' }}>
                    <img style={{ width: '100px', borderRadius: '5px' }} src='/images/QRcode_facebook_mangomango.png' />
                  </Col>
                  <Col
                    span={12}
                    style={{
                      fontSize: '16px',
                      fontWeight: '400',
                      paddingBottom: '10px',
                      textAlign: 'left',
                    }}
                  >
                    <div style={{ paddingBottom: '15px' }}>
                      <img style={{ width: '27px' }} src='/images/email.svg' />
                      hello@mangomango.app
                    </div>
                    <div>
                    <img style={{ width: '30px' }} src='/images/facebook.svg' /> MangoMango Thailand
                    </div>
                  </Col>
                  <div>
                    <img
                      style={{
                        width: '100px',
                        height: '130px',
                      }}
                      src='/images/Component2.svg'
                    />
                  </div>
                </Row>
              </Col>
            </Row>
            <Row className='mobileOnly'>
              <Col
                style={{
                  width: '350px',
                  height: '100%',
                  background: '#FFED8D',
                  borderRadius: '25px',
                  paddingTop: '16px',
                }}
              >
                <div
                  style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    gap: '12px',
                  }}
                >
                  <p
                    style={{
                      width: '100%',
                      fontSize: '14px',
                      fontWeight: '400',
                      color: 'rgba(17, 17, 17, 0.60)',
                      paddingBottom: '16px',
                      textAlign: 'center',
                      wordWrap: 'break-word',
                    }}
                  >
                    {intl.formatMessage({ id: 'app.mango.text1', defaultMessage: 'If you encounter any issues,' })}
                    <br />
                    {intl.formatMessage({ id: 'app.mango.text2', defaultMessage: 'Please contact us through the channels below.' })}
                    <br />
                  </p>
                </div>
                <div className='pixmoryBanner'>
                  <img
                    style={{
                      width: '100px',
                      borderRadius: '10px',
                      paddingBottom: '16px',
                    }}
                    src='/images/QRcode_facebook_mangomango.png'
                  />
                  <div style={{ paddingLeft: '90px', textAlign: 'left' }}>
                    <div style={{ paddingBottom: '20px' }}>
                      <img style={{ width: '30px' }} src='/images/email.svg' /> hello@mangomango.app
                    </div>
                    <div>
                      <img style={{ width: '30px' }} src='/images/facebook.svg' /> MangoMango Thailand
                    </div>
                  </div>
                  <div>
                    <img
                      style={{
                        width: '100%',
                        height: 'auto',
                        borderRadius: '25px',
                      }}
                      src='/images/Frame507.svg'
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </S.LoginWrapper>
    </S.Container>
  )
}

export default compose(observer)(Register)
