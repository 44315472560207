import React, { useEffect, useState, useRef } from 'react'
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'
import { CopyToClipboard } from 'react-copy-to-clipboard'
// import { observer } from 'mobx-react-lite'
import { inject, observer } from 'mobx-react'
import { useMutation, useQuery } from '@apollo/client'
import { Button, Col, Divider, Form, Icon, Input, Modal, Row, Spin, message, Upload } from 'antd'
import QRCode from 'qrcode'
import { compose } from 'recompose'
import eventBySlugQuery from '~/graphql/queries/eventBySlug.gql'
import updateEventMutation from '~/graphql/mutations/updateEvent.gql'
// import EventPhotosView from '../components/EventPhotosView.js'
import * as S from '~/styles'
// import PhotoUploadTools from '../components/PhotoUploadTools.jsx'
// import { useStores } from '../contexts'

import _get from 'lodash/get'
import photoS3UploadMutation from '~/graphql/mutations/photoS3Upload.gql'
import loadImage from 'blueimp-load-image'
import axios from 'axios'
import SearchResults from '~/components/SearchResults'
import FaceSearchResults from '~/components/FaceSearchResults'

import MaintainanceSVG from '../assets/images/maintainance.svg'
import FaceSearchSVG from '../assets/images/face_search.svg'
import NotFoundFaceSVG from '../assets/images/notfoundface.svg'

import '../customCss.css'
import useFirebaseAnalytics, { useScreenView } from '../hooks/useFirebaseAnalytics'

const MAX_DIMENSION = 720
const IMAGE_QUALITY = 0.8

const Frame = ({ record }) => {
  const imgRef = useRef(null)
  useEffect(() => {
    const drawQRCode = async () => {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      const width = 220
      const height = 220
      // const title = `${record.title}`
      // const titleWidth = ctx.measureText(title).width
      // const footerText1 = 'ผู้ร่วมงานสามารถสแกน QR Code'
      // const footerText2 = 'รับภาพได้ผ่านทาง LINE OA PIXMORY'
      // const imageList = [await QRCode.toDataURL(`${process.env.REACT_APP_BASE_URI}/l/${record._id}`, { margin: 0 })]
      const qrDataUrl = await QRCode.toDataURL(`${process.env.REACT_APP_BASE_URI}/join/${record.code}`, { margin: 0 })
      const loadImage = (image) =>
        new Promise((resolve, reject) => {
          const img = new Image()
          img.src = image
          img.onload = () => {
            resolve(img)
          }
        })

      setCanvas(canvas, width, height)

      const [qrCodeImage] = await loadImage(qrDataUrl)
      ctx.imageSmoothingEnabled = false
      ctx.drawImage(qrCodeImage, (width - 205) / 2, 5, 200, 200)

      ctx.fillStyle = '#000'
      // ctx.font = titleWidth < 190 ? '1.8em Kanit' : '1.4em Kanit'
      ctx.textAlign = 'center'
      // ctx.fillText(title, width / 2, 105)

      ctx.font = '1.8em Kanit'
      // ctx.fillText(footerText1, width / 2, 375)
      // ctx.fillText(footerText2, width / 2, 405)
      imgRef.current.src = canvas.toDataURL()
    }
    drawQRCode()
  }, [record._id, record.title, record.code])

  const setCanvas = (canvas, width, height) => {
    const ctx = canvas.getContext('2d')
    const scale = window.devicePixelRatio
    canvas.style.width = width + 'px'
    canvas.style.height = height + 'px'
    canvas.width = width * scale
    canvas.height = height * scale
    ctx.scale(scale, scale)
  }

  return <img ref={imgRef} width='440' height='470' alt='QRCode' style={{width:'100%', height:'100%'}} />
}

const resizeImg = async (file, { width, height }, quolity = 1) => {
  
    return new Promise((resolve, reject) => {
      loadImage(file, canvas => {
        if (canvas.type === 'error') {
          return Promise.reject(new Error('Incorrect Image'))
        }
        const dataUrl = canvas.toDataURL('image/jpeg', quolity)
        const blob = dataURLtoBlob(dataUrl)
        // blob.name = file.name
        // blob.lastModified = Date.now()
        resolve(blob)
      }, {
        maxWidth: width,
        maxHeight: height,
        contain: true,
        orientation: true,
        canvas: true
      })
    })
}

const dataURLtoBlob = (dataURL) => {
    const BASE64_MARKER = ';base64,'
    // let parts, contentType, raw
    if (dataURL.indexOf(BASE64_MARKER) === -1) {
      const [prefix, suffix] = dataURL.split(',')
      const contentType = prefix.split(':')[1]
      const raw = decodeURIComponent(suffix)
  
      return new Blob([raw], { type: contentType })
    }
  
    const [prefix, suffix] = dataURL.split(BASE64_MARKER)
    const contentType = prefix.split(':')[1]
    const raw = window.atob(suffix)
    const rawLength = raw.length
  
    const uInt8Array = new Uint8Array(rawLength)
  
    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i)
    }
  
    return new Blob([uInt8Array], { type: contentType })
}

const SearchFaceModal = ({ onClose, visible, callbackSearch }) => {

  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  
  const [base64Preview,setBase64Preview] = useState('')
  const tmpFile = useRef()

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const handleClose = () => {
    onClose(false)
  }

  const handleChangeUpload = (e) => {
    getBase64(e.file.originFileObj, imageUrl => setBase64Preview(imageUrl))
  }

  const removeUploadCurrent = (e) => {
    setBase64Preview('')
  }

  const customRequestTemp = async ({ file }) => {
    tmpFile.current = {file:file};
    console.log(tmpFile)
  }
  
  return (
    <Modal bodyStyle={{ padding: '24px' }} closable footer={null} onCancel={handleClose} visible={visible} width={360} style={{ top: '3em' }}>
      <h1><FormattedMessage id='app.faceSearch'/></h1>
      <p style={{ color: 'rgba(17, 17, 17, 0.40)', marginBottom: '10px' }}><FormattedMessage id='app.faceSearchCond'/></p>
      
      
      
      {base64Preview !== '' 
      ? (
        <div>
          <div onClick={removeUploadCurrent} style={{backgroundColor:'red', width:'fit-content', position:'absolute', display:'block', right:'8%', padding:'6px 10px 6px 10px', borderRadius:'25px 25px 25px 25px'}}><Icon type='close' style={{ color: '#fff'}} /></div>
          <img src={`${base64Preview}`} style={{width:'100%', borderRadius:'15px'}} />
        </div>
      ) : (
        <Upload onChange={handleChangeUpload} customRequest={customRequestTemp} showUploadList={false}>
          <div style={{
            width: '100%',
            height: '238.57px',
            padding: '15px',
            borderRadius: '12px',
            border: '1px dashed rgb(253, 190, 26)',
            backgroundColor:'rgba(253, 190, 26, 0.1)',
            color:'rgba(17, 17, 17, 0.6)',
            textAlign:'center',
            paddingTop: '25%'
          }} className='antd-face-modal'>
            <Icon type='upload' style={{ backgroundColor: '#FDBE1A', borderRadius: '99px', padding: '8px' }} />
            <p>
              <strong style={{ color: '#FDBE1A' }}><FormattedMessage id='app.uploadCover' defaultMessage='Click to upload' /></strong>&nbsp;
              <FormattedMessage id='app.orDragAndDrop' defaultMessage='or drag and drop files here' />
            </p>
            <p><FormattedMessage id='app.JPGonly' defaultMessage='JPG files only' /></p>
            </div>
        </Upload>
      )}
      
      
      <Row gutter={[16, 0]} type='flex' style={{ marginTop: '20px' }}>
        <Col span={12}>
          <Button
            loading={loading}
            onClick={() => handleClose()}
            style={{
              backgroundColor: 'rgba(17, 17, 17, 0.05)',
              border: 'none',
              color: 'black',
              fontSize: '16px',
              height: '40px',
              width: '100%',
            }}
          >
            {intl.formatMessage({ id: 'app.cancel', defaultMessage: 'Cancel' })}
          </Button>
        </Col>
        <Col span={12}>
          <Button
            disabled={!tmpFile.current}
            onClick={() => {callbackSearch(tmpFile.current); handleClose(); }}
            loading={loading}
            style={{
              backgroundColor: 'black',
              border: 'none',
              color: 'white',
              fontSize: '16px',
              height: '40px',
              width: '100%',
            }}
          >
            <FormattedMessage id="app.search"/>
          </Button>
        </Col>
      </Row>
    </Modal>
  )
}

const QRCodeSharing = ({ event, onClose, visible }) => {

  const intl = useIntl()

  const qrCodeRef = useRef(null)
  const [qrCode, setQrCode] = useState(null)
  const [loading, setLoading] = useState(false)
  const handleClose = () => {
    onClose(false)
  }
  const downloadQR = () => {
    const downloadLink = document.createElement('a')
    downloadLink.href = qrCodeRef.current?.querySelector('img')?.src
    downloadLink.download = `${event.slug}.png`
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }

  return (
    <Modal bodyStyle={{ padding: '24px' }} closable footer={null} onCancel={handleClose} visible={visible} width={360} style={{ top: '3em' }}>
      <h1><FormattedMessage id='app.searchWithLine'/></h1>
      <p style={{ color: 'rgba(17, 17, 17, 0.40)', marginBottom: '10px' }}><FormattedMessage id='app.searchWithLineDetail'/></p>
      {loading ? (
        <Spin style={{ display: 'block' }} />
      ) : (
        <div ref={qrCodeRef} style={{ textAlign: 'center' }}>
          {/* <img src={qrCode} alt='invitation-qrcode' style={{ width: 200 }} /> */}
          <Frame record={event} />
        </div>
      )}
     
      
      <Row gutter={[16, 0]} type='flex' style={{ marginTop: '20px' }}>
        <Col span={12}>
          <Button
            loading={loading}
            onClick={() => handleClose()}
            style={{
              backgroundColor: 'rgba(17, 17, 17, 0.05)',
              border: 'none',
              color: 'black',
              fontSize: '16px',
              height: '40px',
              width: '100%',
            }}
          >
            {intl.formatMessage({ id: 'app.cancel', defaultMessage: 'Cancel' })}
          </Button>
        </Col>
        <Col span={12}>
          <Button
            loading={loading}
            onClick={(e) => downloadQR(e)}
            style={{
              backgroundColor: 'black',
              border: 'none',
              color: 'white',
              fontSize: '16px',
              height: '40px',
              width: '100%',
            }}
          >
            <FormattedMessage id="app.saveQRCode"/> <Icon type='download' />
          </Button>
        </Col>
      </Row>
    </Modal>
  )
}

const EventView = compose(
  inject('i18n'),
  observer
)(({ match, history, awsAccount,i18n }) => {
  useScreenView('event-photos')
  const { logEvent } = useFirebaseAnalytics()
  const intl = useIntl()
//   const { routerStore: { location } } = useStores()
//   const { query: { i } } = location

  const { location } = history
  const searchParams = new URLSearchParams(location.search)

  const [imageId, setImageId] = useState(searchParams.get('i') ? searchParams.get('i') : '')
  const [faceFound ,setFaceFound] = useState(0);

  const [NotFoundFaceSearch,setNotFoundFaceSearch] = useState()

  const [qrCodelLineShow, setQRCodeLineShow] = useState(false)
  const [searchFaceShow, setSearchFaceShow] = useState(false)
  const handleQRCodeLine = (v) => {
    setQRCodeLineShow(v)
  }
  
  const handleSearchFaceModal = (v) => {
    setSearchFaceShow(v)
  }
  
  const { error, loading, data } = useQuery(eventBySlugQuery, {
    variables: {
      slug: match.params.eventSlug,
    },
    fetchPolicy: 'network-only',
  })

  console.log(data?.eventBySlug)


  useEffect(() => {
    if (data?.eventBySlug) {
      logEvent('view_event', {
        event_id: data.eventBySlug._id,
        event_title: data.eventBySlug.title
      })

      i18n.setLocale(data.eventBySlug.language)
    }
  }, [data?.eventBySlug, logEvent, i18n])
  
  const [getUpload] = useMutation(photoS3UploadMutation)

  const [fileName, setFileName] = useState(searchParams.get('i') ? searchParams.get('i') : '');

  const handleChangeUpload = (e) => {
    setFileName(e.file.name)
  }

  const onImageUploadCompleted = (id) => {
    logEvent('event_face_search', {
      image_id: id,
      event_id: data?.eventBySlug?._id,
      event_title: data?.eventBySlug?.title
    })
    searchParams.delete('i')
    setImageId(id);
    searchParams.set('i', id)
    history.push({
      ...location,
      search: searchParams.toString()
    })
  }

  const cancelFaceSearch = () => {
    setFileName('');
    setImageId('');
    setNotFoundFaceSearch(null);
    searchParams.delete('i')
    history.push({
        ...location,
        search: searchParams.toString()
      })
  }

  

  const customRequest = async ({ file }) => {
    setFileName(file.name)
    const { data } = await getUpload({ variables: { awsAccount } })
    const uploadUrl = _get(data, 'photoS3Upload.url')
    const id = _get(data, 'photoS3Upload._id')
    if (uploadUrl) {
      const blob = await resizeImg(file, { width: MAX_DIMENSION, height: MAX_DIMENSION }, IMAGE_QUALITY)
      await axios.put(uploadUrl, blob, { headers: { 'Content-Type': 'image/jpeg' } })
      onImageUploadCompleted(id);
    }
  }

  if (error) return 'Error...'
  if (loading) return <Spin style={{ display: 'block', lineHeight: '100vh' }} />

  const { eventBySlug: event } = data ?? {}

  // const eventCredit = _get(event, 'creditBalance.total', 0)
  // const photoDownloadMode = _get(event, 'photoDownloadMode')
  // const creditsAvailable = photoDownloadMode === 'priced' ? undefined : eventCredit


  return (
    <>
      <S.EventCover image={event.coverUrl ?? '/images/default-event-cover.jpg'}>
        <div className='event-cover-box'>
          <div className='event-cover' />
          <div className='event-cover-background' />
        </div>
      </S.EventCover>
      <S.Event>
        <div className='event-box'>
          <Row
            align='bottom'
            className='title-row'
            gutter={[0, 12]}
            justify='start'
            type='flex'
          >
            <Col className='event-title' xs={24} lg={20}>
              {event.title}
            </Col>
            <Col xs={24} lg={4}>
              {event.faceSearchEnabled && (
                <>
                  {fileName === '' ? (
                    <Button
                      className='yellow-button'
                      onClick={() => handleSearchFaceModal(true)}
                      style={{ borderRadius: '1.6em', fontSize: '1em', height: '3em' }}
                    >
                      <Icon type='search' />
                      <FormattedMessage id='app.faceSearch' tagName='span' style={{ paddingLeft: '4px' }} />
                    </Button>
                  ) : (
                    <Button
                      className='red-button'
                      onClick={() => cancelFaceSearch()}
                      style={{ borderRadius: '1.6em', fontSize: '1em', height: '3em' }}
                    >
                      <FormattedMessage id='app.clear' defaultMessage='Clear' />
                    </Button>
                  )}
                </>
              )}
            </Col>
          </Row>
          <Row className='manage-row' align='middle' justify='start' type='flex' >
            {!imageId && event.showDefaultPhotos ? (
              <Col sm={12}>
                <Row align='bottom' justify='start' type='flex'>
                  <Col span={24} style={{ color: '#D7972C' }}>
                    <FormattedMessage id='app.totalEventPhotos' defaultMessage='Total event photos' />
                  </Col>
                  <Col span={24} style={{ fontSize: '20px', fontWeight: 400 }}>
                    <FormattedMessage id='app.photoCount' defaultMessage='{count} photos' values={{ count: event.photoCount }} />
                  </Col>
                </Row>
              </Col>
            ) : (
              !event.faceSearchEnabled && (
                <Col sm={12}>
                  <Row align='bottom' justify='start' type='flex'>
                    <Col span={24} style={{ color: '#D7972C' }}>
                      <FormattedMessage id='app.photoInEvent' />
                    </Col>
                    <Col span={24} style={{ fontSize: '20px', fontWeight: 400 }}>
                      <FormattedNumber value={event.photoCount} /> <FormattedMessage id='app.photos' />
                    </Col>
                  </Row>
                </Col>
              )
            )}
            {event.faceSearchEnabled && (
              <>
                {event.showDefaultPhotos === false && event.faceSearchEnabled ? (
                  <></>
                ) : (
                  !imageId && (
                    <Col
                      xs={24}
                      sm={1}
                      style={{
                        textAlign: 'center',
                        visibility: event.showDefaultPhotos === false && event.faceSearchEnabled ? 'hidden' : 'visible',
                      }}
                    >
                      <div className='divider' />
                    </Col>
                  )
                )}
                <Col sm={11}>
                  <Row align='bottom' justify='start' type='flex'>
                    <Col span={24} style={{ color: '#D7972C' }}>
                      <FormattedMessage id='app.selfFace' />
                    </Col>
                    <Col span={24} style={{ fontSize: '20px', fontWeight: 400 }}>
                      {fileName === '' ? (
                        <FormattedMessage id='app.notFindFace' />
                      ) : (
                        <FormattedMessage id='app.photoCount' defaultMessage='{count} photos' values={{ count: faceFound }} />
                      )}
                    </Col>
                  </Row>
                </Col>
              </>
            )}
          </Row>
        </div>
        <Row className='event-photos-row'>
          {event.showDefaultPhotos
            ? ((event.faceSearchEnabled && imageId)
              ? <FaceSearchResults eventId={event._id} imageId={imageId} onFaceFound={setFaceFound} NotFoundFaceSearch={setNotFoundFaceSearch}/>
              : <SearchResults eventId={event._id} showDefaultPhotos={event.showDefaultPhotos} />
            )
            : (event.faceSearchEnabled 
              ? (imageId 
                ? <FaceSearchResults eventId={event._id} imageId={imageId} onFaceFound={setFaceFound} NotFoundFaceSearch={setNotFoundFaceSearch} />
                : <Row align='middle' justify='center' type='flex' >
                <Col sm={24} style={{ textAlign: 'center' }}>
                  <img src={FaceSearchSVG} />
                </Col>
                <Col sm={24} style={{ textAlign: 'center', marginTop: '1em' }}>
                  <FormattedMessage id="app.easierToFind" tagName="span" style={{ color: 'rgb(17, 17, 17, 0.5)' }}/>
                </Col>
                <Col sm={24} style={{ textAlign: 'center', marginTop: '1em' }}>
                  <Button
                    className='yellow-button'
                    onClick={() => handleSearchFaceModal(true)}
                    style={{ borderRadius: '1.6em', fontSize: '1em', height: '3em' }}
                  >
                    <Icon type='search' />
                    <FormattedMessage id="app.faceSearch" tagName="span" style={{ paddingLeft: '4px' }}/>
                  </Button>
                </Col>               
              </Row> 
              )
              : <Row align='middle' justify='center' type='flex' >
                  <Col sm={24} style={{ textAlign: 'center' }}>
                    <img src={MaintainanceSVG}/>
                  </Col>
                  <Col sm={24} style={{ textAlign: 'center', marginTop: '1em' }}>
                    <FormattedMessage id="app.notAllowFaceSearch" tagName="span" style={{ color: 'rgb(17, 17, 17, 0.5)' }}/>
                  </Col>
                  <Col sm={24} style={{ textAlign: 'center', marginTop: '1em' }}>
                    <Button
                      className='yellow-button'
                      onClick={() => handleQRCodeLine(true)}
                      style={{ borderRadius: '1.6em', fontSize: '1em', height: '3em' }}
                    >
                      <Icon type='search' />
                      <FormattedMessage id="app.searchWithLine" tagName="span" style={{ paddingLeft: '4px' }}/>
                    </Button>
                  </Col>   
                  <QRCodeSharing
                    event={event}
                    onClose={handleQRCodeLine}
                    visible={qrCodelLineShow}
                  />            
                </Row> 
            )
          }
          {imageId && NotFoundFaceSearch?.searchPhotosByFace.count === 0 && (
            <Row align='middle' justify='center' type='flex'>
              <Col sm={24} style={{ textAlign: 'center' }}>
                <img src={NotFoundFaceSVG} />
              </Col>
              <Col sm={24} style={{ textAlign: 'center', marginTop: '1em' }}>
                <span style={{ color: 'rgb(17, 17, 17, 0.5)' }}>
                  <FormattedMessage id='app.notFoundFace' />
                </span>
              </Col>
              <Col sm={24} style={{ textAlign: 'center', marginTop: '1em' }}>
                <Button
                  className='yellow-button'
                  onClick={() => handleSearchFaceModal(true)}
                  style={{ borderRadius: '1.6em', fontSize: '1em', height: '3em' }}
                >
                  <Icon type='search' />
                  <FormattedMessage id='app.searchNew' tagName='span' style={{ paddingLeft: '4px' }} />
                </Button>
              </Col>
            </Row>
          )}
        </Row>
        {/* <EventPhotosView id={event._id} imageId={imageId} showDefaultPhotos={event.showDefaultPhotos} faceSearchEnabled={event.faceSearchEnabled} /> */}
      </S.Event>
      <SearchFaceModal
        onClose={handleSearchFaceModal}
        visible={searchFaceShow}
        callbackSearch={customRequest}
      />
    </>
  )
})

export default EventView
