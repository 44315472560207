/* eslint-disable react/destructuring-assignment */
import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { useStores } from '~/contexts'
import AppLayout from '~/components/AppLayout'

const PrivateRoute = observer(({ component: Component, ...rest }) => {
  const {
    userStore: user,
    routerStore: { location },
  } = useStores()
  const currentUser = user.initUser() && user
  const { query: { invitationToken } } = location
  return (
    <AppLayout
      siderMenu
      headerAffix={false}
      // layoutPaddingTop="40px"
      // layoutContentPadding="0 35px"
    >
      <Route
        {...rest}
        render={(props) => {
          if (!currentUser && invitationToken) {
            return <Redirect to={{ pathname: '/passwordcreate', search: `?invitationToken=${invitationToken}`, state: { from: props.location } }} />
          }
          if (!currentUser) {
            return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
          }
          return <Component {...props} />
        }}
      />
    </AppLayout>
  )
})

export default PrivateRoute
