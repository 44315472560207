import React, { useState } from 'react'
import compose from 'recompose/compose'
import { Link, useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useQuery } from '@apollo/client'
import { Alert, Button, Card, Col, Icon, Input, Pagination, Row, Spin, Select, Form } from 'antd'
import moment from 'moment'
import _get from 'lodash/get'
import { useStores, useUser } from '~/contexts'
import { useHeader } from '../services/header'
import { FormattedMessage } from 'react-intl'
import eventsQuery from '~/graphql/queries/events.gql'
import currentAdminUserCreditsQuery from '~/graphql/queries/currentAdminUserCredits.gql'
import EventCreateModal from '../components/EventCreateModal'
import * as S from '~/styles'
import { useScreenView } from '../hooks/useFirebaseAnalytics'

const limit = 30

const Events = compose(
  Form.create()
)(({
  // history,
  // location,
  form: { getFieldDecorator, validateFields, setFieldsValue, getFieldValue },
  // publicKey
  // events
}) => {
  useScreenView('event-list')
  const intl = useIntl()
  // const { routerStore: { location } } = useStores()
  const history = useHistory()
  const { location } = history
  const { user } = useUser()
  const { createEvent } = useHeader()
  const [visible, setVisible] = useState(true)
  const userAdmin = user.role === 'admin'
  // const searchParams = new URLSearchParams(location.search)
  // const START_YEAR = 2016
  const _year = moment().year()
// const { query: { s: titleSearch, y: year, m: month } } = location
  const START_YEAR = moment().year() - 8
  const years = Array.from({ length: _year - START_YEAR + 1 }).map((v, k) => _year - k)
  const searchParams = new URLSearchParams(location.search)
  // const titleSearch = searchParams.get('s')
  // const year = searchParams.get('y')
  // const month = searchParams.get('m')
  const s = searchParams.get('s')
  const y = searchParams.get('y')
  const m = searchParams.get('m')
  const page = searchParams.get('p') ?? 1
  const titleSearch = searchParams.get('s') ?? ''
  const year = searchParams.get('y')
  const month = searchParams.get('m')
  // const { s, y, m } = location.query

  const [_loading, setLoading] = useState(false)
  // const [search, setSearch] = useState(false)

  const { error: eventsError, loading: eventsLoading, data: eventsData } = useQuery(eventsQuery, {
    variables: {
      // approved: true,
      limit,
      myEvent: !userAdmin,
      skip: (page - 1) * limit,
      titleSearch,
      year: year && parseInt(year),
      month: month && parseInt(month),
    },
    fetchPolicy: 'network-only',
  })
  const { error: userError, loading: userLoading, data: userData } = useQuery(currentAdminUserCreditsQuery, {
    fetchPolicy: 'network-only',
  })

  const error = eventsError || userError
  const loading = eventsLoading || userLoading
  if (error) return 'Error...'
  if (loading) return <Spin style={{ display: 'block', lineHeight: '100vh' }} />

  const { eventCount, events } = eventsData

  const { currentAdminUser } = userData
  const creditsAvailable = _get(currentAdminUser, 'creditBalance.total', 0)
  const handleClose = () => {
    setVisible(false)
  }
  // const handleSearch = () => {
  //   const { p, ...query } = location.query
  //   const {
  //     titleSearch,
  //     year,
  //     month
  //   } = getFieldsValue()
  //   location.query = {
  //     ...query,
  //     s: titleSearch, 
  //     y: year,
  //     m: month
  //   }
  // }
  const handleSubmit = async (e) => {
    e.preventDefault()
    console.log('searching')
    setLoading(true)
    // const { query } = location
    validateFields(async (err, values) => {
      if (!err) {
        // console.log('not err')
        const {
          titleSearch,
          year,
          month
        } = values
        if (titleSearch) {
          // query.s = titleSearch
          searchParams.set('s', titleSearch)
        } else {
          searchParams.delete('s')
          // delete (query.s)
        }
        if (year) {
          // query.y = year
          searchParams.set('y', year)
        } else {
          // delete (query.y)
          searchParams.delete('y')
        }
        if (month) {
          // query.m = month
          searchParams.set('m', month)
        } else {
          // delete (query.m)
          searchParams.delete('m')
        }
        history.push({
          ...location,
          search: searchParams.toString()
        })
        // location.query = query
      }
      setLoading(false)
    })
  }

  const onPageChange = (page) => {
    searchParams.set('p', page)
    history.push({
      ...location,
      search: searchParams.toString()
    })
  }

  const handleCreateEvent = () => {
    createEvent.open()
  }

  return (
    <S.Event>
      <div className='events-page'>
        <FormattedMessage id='app.welcome' />
      </div>
      <p className='description'><FormattedMessage id='app.home.text1' /></p>
      <Row style={{ background: 'white', border: '1px', borderRadius: '25px', padding: '20px', marginTop: '20px' }}>
        <Col sm={12}>
          <Row>
            <Col style={{ color: '#D7972C', marginTop: '-10px' }}>
            <FormattedMessage id='app.home.text2' />
            </Col>
          </Row>
          <Row style={{ fontSize: '22px' }}>
            {`${intl.formatNumber(creditsAvailable)} ${intl.formatMessage({
              id: 'app.photos',
              defaultMessage: 'photos',
            })}`}
          </Row>
        </Col>
        <Col sm={12} style={{ textAlign: 'right' }}>
          <div style={{ display: 'inline-block', margin: '0px 4px' }}>
            <Button type='primary' style={{ backgroundColor: '#FDBE1A', border: '#FDBE1A', color: 'black', fontSize: '16px', height: '40px' }}>
              <Link to='/credits/buy/step1'><FormattedMessage id='app.BuyPackage' /></Link>
            </Button>
          </div>
          {/* <div style={{ display: 'inline-block', margin: '0px 4px' }}>
            <Button type='primary' style={{ backgroundColor: 'black', border: 'black', fontSize: '16px', height: '40px' }}>
              <Link to='/credits'><FormattedMessage id='app.seeHistory' /></Link>
            </Button>
          </div> */}
        </Col> 
        {visible && (
          <Col sm={24} style={{ marginTop: '10px' }}>
            <Alert
              message={
                <div>
                  <Icon type='info-circle' theme='filled' style={{ color: '#28C61A', fontSize: '20px' }} />
                  <span style={{ marginLeft: '8px' }}><FormattedMessage id='app.littleTip' /></span>
                </div>
              }
              description={
                <p className='description' style={{ marginTop: '10px' }}><FormattedMessage id='app.home.text3' /></p>
              }
              type='success'
              closable
              afterClose={handleClose}
              style={{ backgroundColor: 'rgba(40, 198, 26, 0.10)', border: '1px', borderRadius: '25px' }}
            />
          </Col>
        )}
      </Row>
      <Row align='bottom' type='flex' style={{ marginTop: '50px' }}>
        <Col span={12}>
          <p className='header'>
            {intl.formatMessage(
              { id: 'app.yourEventCount', defaultMessage: 'You have {count, number} {count, plural, one {event} other {events} }' },
              { count: eventCount }
            )}
          </p>
          <p className='description'><FormattedMessage id='app.mango.text4' /></p>
        </Col>
      </Row>
        {/* <Form onSubmit={handleSubmit}>
        <Col style={{ marginLeft: '20px', marginRight: '20px' }}>
          <Select
            allowClear
            showSearch
            // onSearch={handleSearch}
            style={{ width: 120 }}
            placeholder={<FormattedMessage id="app.selectYear" defaultMessage="Select year" />}
          >
            {years.map(_year =><Select.Option key={_year} value={_year}>{_year}</Select.Option>)}
          </Select>
        </Col>
        <Col style={{ marginLeft: '20px', marginRight: '20px' }}>
          <Select
            allowClear
            showSearch
            // onSearch={handleSearch}
            style={{ width: 120 }}
            placeholder={<FormattedMessage id="app.selectMonth" defaultMessage="Select month" />}
          >
            {moment.months().map((_month, i) =><Select.Option key={i} value={i + 1}>{_month}</Select.Option>)}
          </Select>
        </Col>
        <Col>
          <Input.Search
            allowClear
            className='input'
            defaultValue={titleSearch}
            // onSearch={handleSearch}
            placeholder={intl.formatMessage({ id: 'app.searchEvent', defaultMessage: 'Search Event' })}
          />
        </Col>
        
        <Col>
          <Button
            // onClick={handleSearch}
            placeholder={intl.formatMessage({ id: 'app.searchEvent', defaultMessage: 'Search Event' })}
          >krmjner</Button>
        </Col>
        </Form> */}
        <Form onSubmit={handleSubmit}>
          <Row  type='flex' style={{margin: 10, justifyItems: 'center', display: 'flex', textAlign: 'center'}}>
          <Col xs={24} md={8} lg={8}>
          <Form.Item style={{ marginLeft: "10px"}}>
            {getFieldDecorator('titleSearch', {
              initialValue: s
            })(
              <Input
                  allowClear
                  size='large'
                  style={{ height: '3.5em',
                    // backgroundColor: 'rgba(17, 17, 17, 0.05)',
                    border: 'none',
                    borderRadius: '1.6em'}}
                  dataSource={getFieldValue('titleSearch')}
                  placeholder={intl.formatMessage({ id: 'app.searchEvent', defaultMessage: 'Search Event' })}
                />            
            )}
          </Form.Item>
        </Col>
        <Col xs={24} md={8} lg={6} style={{ textAlign: 'center'}}>
          <S.SelectWrapper> 
            <Form.Item style={{ marginLeft: "10px" }}>
            {getFieldDecorator('year', {
              initialValue: parseInt(y) || undefined
              // initialValue: y || moment().year()
            })(
              <Select
                allowClear
                size='large'
                style={{
                  width: '100%',
                  textAlign: 'center',
                  wordWrap: "break-word"}}
                placeholder={<FormattedMessage id="app.selectYear" defaultMessage="Select year" />}
                
              >
                {years.map(_year =>
                  <Select.Option style={{
                    borderRadius: 1000,
                    height: 48,
                    padding: 16,
                    wordWrap: "break-word"}} key={_year} value={_year}>{_year}</Select.Option>
                )}
              </Select>
            )}
          </Form.Item></S.SelectWrapper>
         
        </Col>
        <Col xs={24} md={8} lg={7} style={{ textAlign: 'center'}}>
        <S.SelectWrapper>
        <Form.Item style={{ marginLeft: "10px" }}>
            {getFieldDecorator('month', {
              initialValue: parseInt(m) || undefined
              // initialValue: parseInt(m) || moment().month() + 1
            })(
              <Select
                allowClear
                size='large'
                style={{  width: '100%',
                textAlign: 'center',
                wordWrap: "break-word"}}
                placeholder={<FormattedMessage id="app.selectMonth" defaultMessage="Select month" />}
              >
                {moment.months().map((_month, i) =>
                  <Select.Option  style={{
                    borderRadius: 1000,
                    height: 48,
                    padding: 16,
                    wordWrap: "break-word"}}  key={i} value={i + 1}>{_month}</Select.Option>
                )}
              </Select>
            )}
          </Form.Item>
        </S.SelectWrapper>   
        </Col>
        <Col xs={24} lg={3} style={{ textAlign: 'center'}}>
              <Form.Item style={{ marginLeft: "10px" }}>
                <Button
                  size='large'
                  loading={_loading}
                  htmlType="submit"
                  style={{
                    width: '100%',
                    height: '50px',
                    fontSize: '16px',
                    fontWeight: '400',
                    color:'white',
                    background: '#111111',
                    textAlign: 'center',
                  }}
                >
                  <span><FormattedMessage id="app.search" defaultMessage="Search" /></span>
                </Button>
              </Form.Item>
            </Col>
        </Row>
      </Form>
      {!eventCount ? (
        <Row className='event-list-row'>
          <Col sm={24} style={{ textAlign: 'center' }}>
            <img src='/images/art-7.svg' alt='not_found' style={{ width: '150px' }} />
          </Col>
          {!titleSearch ? (
            <>
              <Col sm={24} style={{ color: 'rgba(17, 17, 17, 0.40)', paddingTop: '10px', textAlign: 'center' }}>
                <p><FormattedMessage id='app.home.text5' /><br/><FormattedMessage id='app.home.text6' /></p>
              </Col>
              <Col sm={24} style={{ paddingTop: '10px', textAlign: 'center' }}>
                <Button type='primary' onClick={handleCreateEvent} style={{ backgroundColor: 'black', border: 'black', fontSize: '16px', height: '40px' }}>
                  <FormattedMessage id='app.starFreeTrial' />
                </Button>
              </Col>
            </>
          ) : (
            <Col sm={24} style={{ color: 'rgba(17, 17, 17, 0.40)', paddingTop: '10px', textAlign: 'center' }}>
              <FormattedMessage id='app.home.text7' />
            </Col>
          )}
        </Row>
      ) : (
        <div className='event-list-row'>
          <Row align='middle' gutter={[10, 10]} justify='center' type='flex'>
            {events.map((event) => {
              return (
                <Col key={event._id} xs={24} sm={8} style={{ borderRadius: '20px', height: '360px' }}>
                  <Link to={`events/${event._id}/manage`}>
                    <Card
                      bodyStyle={{ padding: '12px 0px' }}
                      cover={
                        <div style={{ borderRadius: '20px', height: '200px', objectFit: 'cover', overflow: 'hidden', padding: '', width: '100%' }}>
                          <img alt='event-cover' src={event?.coverUrl ?? '/apple-touch-icon.png'} style={{ borderRadius: '20px', width: '100%' }} />
                        </div>
                      }
                      hoverable
                      style={{ borderRadius: '20px', boxShadow: '0px 0px 8px 2px rgba(85, 85, 85, 0.10)', height: '100%', padding: '8px 8px 12px 8px' }}
                    >
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {
                        user.role === 'admin' ? (
                            <p
                              style={{
                                background: 'rgba(40, 198, 26, 0.10)',
                                borderRadius: '1000px',
                                color: '#28C61A',
                                fontSize: '12px',
                                padding: '0px 4px',
                                textAlign: 'center',
                              }}
                            >
                            <Icon type='user' /> admin
                            {/* {`${intl.formatMessage({ id: 'app.roleOrganizer'})}`} */}
                            </p>
                          ): (
                        user._id === event.createdByUserId ? 
                          (
                            <p
                              style={{
                                background: 'rgba(40, 198, 26, 0.10)',
                                borderRadius: '1000px',
                                color: '#28C61A',
                                fontSize: '12px',
                                padding: '0px 4px',
                                textAlign: 'center',
                              }}
                            >
                            <Icon type='user' />
                            {`${intl.formatMessage({ id: 'app.roleOrganizer'})}`}
                            </p>
                          ) : (
                            <p
                            style={{
                              background: 'rgba(253, 190, 26, 0.10)',
                              borderRadius: '1000px',
                              color: '#FDBE1A',
                              fontSize: '12px',
                              padding: '0px 4px',
                              textAlign: 'center',
                            }}
                            >
                            <Icon type='user' />
                            {`${intl.formatMessage({ id: 'app.rolePhotographer'})}`}
                            </p>
                          ))
                        }
                        <div style={{width:'100%' , height:'60px' ,wordBreak:'break-word'}}>
                        <p style={{ fontSize: '16px', fontWeight: '600', marginTop: '8px', width: '100%' }}>{event.title}</p>
                        </div>
                        <p style={{ color: 'rgba(17, 17, 17, 0.60)', fontSize: '12px', fontWeight: '400', width: '100%' }}>{`${intl.formatNumber(event.photoCount)} ${intl.formatMessage({
                          id: "app.photos",
                          defaultMessage: "photos",
                        })} • ${intl.formatNumber(event.uploadCount)} ${intl.formatMessage({
                          id: "app.photographers",
                          defaultMessage: "photographers",
                        })}`}</p>
                        <p style={{ color: 'rgba(17, 17, 17, 0.60)', fontSize: '12px', fontWeight: '400', width: '100%' }}>{intl.formatMessage({ id: 'app.createdAt'})} {moment(event.createdAt).format('D MMM YY')}</p>
                      </div>
                    </Card>
                  </Link>
                </Col>
              )
            })}
          </Row>
          <div className='pagination'>
            <Pagination size='small' defaultCurrent={1} pageSize={limit} total={eventCount} onChange={onPageChange} current={Number(page)} />
          </div>
        </div>
      )}
      <EventCreateModal onClose={() => createEvent.close()} visible={createEvent.isOpen} />
    </S.Event>
  )
})

export default Events
