import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faSmile,
  faUserCircle,
  faCreditCard,
} from '@fortawesome/free-regular-svg-icons'

import {
  faSearch,
  faCamera,
  faTimes,
  faMapMarkerAlt,
  faHandsHelping,
  faShoppingCart,
  faChevronDown,
  faBars,
  faEnvelope,
  faUser,
  faMapMarkedAlt,
  faCalendarAlt,
  faUserTie,
  faChartArea,
  faHome,
  faSignOutAlt,
  faSignInAlt,
  faUserSecret,
  faQrcode,
  faWallet,
} from '@fortawesome/free-solid-svg-icons'

import {
  faInstagramSquare,
  faFacebookSquare,
  faYoutubeSquare,
  faTwitterSquare,
  faFacebookF,
  faLine,
} from '@fortawesome/free-brands-svg-icons'

library.add(
  faSmile,
  faSearch,
  faCamera,
  faTimes,
  faCalendarAlt,
  faMapMarkerAlt,
  faHandsHelping,
  faShoppingCart,
  faBars,
  faInstagramSquare,
  faFacebookSquare,
  faYoutubeSquare,
  faTwitterSquare,
  faUserCircle,
  faChevronDown,
  faEnvelope,
  faFacebookF,
  faUser,
  faLine,
  faMapMarkedAlt,
  faUserTie,
  faChartArea,
  faHome,
  faSignOutAlt,
  faSignInAlt,
  faUserSecret,
  faCreditCard,
  faQrcode,
  faWallet,
)
