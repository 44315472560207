import React, { useRef } from 'react'
// import _get from 'lodash/get'
import { Button, Dropdown, Menu, Tag } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'

// import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import getPhotoDownloadUrlQuery from '~/graphql/queries/getPhotoDownloadUrl.gql'

const DownloadLink = ({ photoId, extra, children }) => {
  const { data } = useQuery(getPhotoDownloadUrlQuery, {
    variables: {
      _id: photoId,
      extra
    },
    fetchPolicy: 'network-only'
  })
  if (data?.getPhotoDownloadUrl?.url) {
    return <a target="_blank" rel="noopener noreferrer" href={data.getPhotoDownloadUrl.url}>
      {children}
    </a>
  }
  return <span>{children}</span>
}

const PhotoDownload = ({ photo, extras = [] }) => {
  const intl = useIntl()
  // console.log({ extras })
  const { _id, purchasedSizeLabel = intl.formatMessage({ id: 'app.originalPhoto', defaultMessage: 'Original Photo' }) } = photo
  const popupContainerRef = useRef()
  return (
    <div ref={popupContainerRef}>
      <Dropdown
        trigger={['click']}
        placement="topCenter"
        getPopupContainer={() => popupContainerRef.current}
        overlay={<Menu>
          <Menu.Item key="photo">
            <DownloadLink photoId={_id}>{purchasedSizeLabel}</DownloadLink>
          </Menu.Item>
          {extras.map(extra =>
            <Menu.Item key={extra}>
              <DownloadLink photoId={_id} extra={extra}>
                {purchasedSizeLabel} <Tag color="orange" >+ {extra}</Tag>
              </DownloadLink>
            </Menu.Item>
          )}
        </Menu>}
      >
        <Button
          type="primary"
          icon="download"
          className="add-to-cart action"
          // type={photoInCart && 'primary'}
          style={{ width: '100%' }}
        >
          <FormattedMessage id="app.downloadPhoto" defaultMessage="Download Photo" tagName="span" />
        </Button>
      </Dropdown>
    </div>
  )
}

export default PhotoDownload
