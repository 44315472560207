import React, { useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Affix, Layout } from 'antd'
import Header from '~/components/Header'
import Footer from '~/components/Footer'
import * as S from '~/styles'

const AppLayout = observer(({ children, header, headerAffix, headerColor }) => {
  // const [headerHeight, setHeaderHeight] = useState(0)
  // const [footerHeight, setFooterHeight] = useState(0)
  const headerRef = useRef(null)
  const footerRef = useRef(null)

  // useEffect(() => {
  //   if (headerRef.current) {
  //     setHeaderHeight(headerRef.current.offsetHeight)
  //   }
  //   if (footerRef.current) {
  //     setFooterHeight(footerRef.current.offsetHeight)
  //   }
  // }, [headerRef, footerRef])

  return (
    <>
        {header && (
          <>
            {headerAffix ? (
              <Affix>
                <div ref={headerRef}>
                  <Header headerColor={headerColor} />
                </div>
              </Affix>
            ) : (
              <div ref={headerRef}>
                <Header headerColor={headerColor} />
              </div>
            )}
          </>
        )}
        <S.LayoutWrapper>
          <Layout>
              <Layout.Content>{children}</Layout.Content>
          </Layout>
        </S.LayoutWrapper>
        <div ref={footerRef}>
          <Footer />
        </div>
    </>
  )
})

export default AppLayout
