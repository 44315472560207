import React from 'react'
import { observer } from 'mobx-react-lite'
import { Button, Tag } from 'antd'
import _get from 'lodash/get'

import { useStores } from '~/contexts'
import * as S from '~/styles'

const imgStyle = {
  transition: 'transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s',
}
const selectedImgStyle = {
  transform: 'translateZ(0px) scale3d(0.9, 0.9, 1)',
  transition: 'transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s',
}

const Thumbnail = observer(({
  index,
  photo,
  margin,
  direction,
  top,
  left,
  onClick,
}) => {
  const { cartStore: cart } = useStores()
  const photoInCart = cart.photoInCart(photo.photo._id)
  const downloadMode = _get(photo, 'photo.downloadMode')

  // calculate x,y scale
  const sx = (100 - (30 / photo.width) * 100) / 100
  const sy = (100 - (30 / photo.height) * 100) / 100
  selectedImgStyle.transform = `translateZ(0px) scale3d(${sx}, ${sy}, 1)`

  const handleClick = (e) => {
    onClick(e, { index, photo })
  }

  return (
    <S.Thumbnail
      margin={margin}
      height={photo.height}
      width={photo.width}
      isSelected={photoInCart}
      column={direction === 'column'}
      left={direction === 'column' && left}
      top={direction === 'column' && top}
    >
      {/* {downloadMode === 'free' && (
        <Tag color="blue" className="thumbnail__tag">Free</Tag>
      )} */}
      {downloadMode === 'priced' && (
        <Button
          type={photoInCart && 'primary'}
          shape="circle"
          icon="shopping-cart"
          className="thumbnail__cart"
          onClick={() => handleClick('select')}
        />
      )}
      <img
        alt={photo.title}
        style={photoInCart ? { ...imgStyle, ...selectedImgStyle } : { ...imgStyle }}
        {...photo}
        onClick={() => handleClick('preview')}
        aria-hidden="true"
      />
    </S.Thumbnail>
  )
})

export default Thumbnail
