import React from 'react'
import { Col, Row } from 'antd'
import { FormattedMessage } from 'react-intl'

import * as S from '~/styles'

const ManualLine = () => (
  <S.Container>
    <Row type="flex">
      <Col span={24}>
        <S.PageHeader>
          <h1><FormattedMessage id="app.receivingImagesFromLine" /></h1>
        </S.PageHeader>
      </Col>
      <Col span={24}>
        <img src="/images/manual-line-photo-2022.png" alt="" className="desktopOnly" style={{ width: '100%', height: 'auto' }} />
        <img src="/images/manual-line-photo-mobile.png" alt="" className="mobileOnly" style={{ width: '100%', height: 'auto' }} />
      </Col>
    </Row>
  </S.Container>
)

export default ManualLine
