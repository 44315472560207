import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Divider, Spin } from 'antd'
import { useQuery } from '@apollo/client'
import { useIntl } from 'react-intl'
import _get from 'lodash/get'
import _orderBy from 'lodash/orderBy'
import _pickBy from 'lodash/pickBy'

import searchPhotosByFaceQuery from '~/graphql/queries/searchPhotosByFace.gql'
import ResponsiveGallery from '~/components/ResponsiveGallery'
import PhotoView from '~/components/PhotoView'
import { useStores } from '~/contexts'
import * as S from '~/styles'

const FaceSearchResults = observer(({ eventId, photographerId, imageId, onFaceFound, NotFoundFaceSearch = false }) => {
  const intl = useIntl()

  const [currentPhoto, setCurrentPhoto] = useState()
  const { routerStore: { location } } = useStores()
  const { query: { pId } } = location
  const { loading, error, data } = useQuery(searchPhotosByFaceQuery, {
    variables: {
      eventId,
      photographerId,
      imageId,
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (currentPhoto !== pId) {
      location.query = _pickBy({
        ...location.query,
        pId: currentPhoto,
      })
    }
  }, [currentPhoto]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleThumbnailClick = (item) => {
    const { photo } = item
    setCurrentPhoto(photo._id)
  }

  const handlePhotoChange = (photo) => {
    setCurrentPhoto(photo && photo._id)
  }

  if (loading) return <Spin style={{ display: 'block', lineHeight: '10vh' }} />
  if (error) return 'Error...'
  if (data && !data.searchPhotosByFace) return 'No Result'

  const photos = _orderBy(_get(data, 'searchPhotosByFace.items', []), ['similarity'], ['desc'])
  const featuredPhotos = photos.filter((photo) => photo.featured)
  const regularPhotos = photos.filter((photo) => !photo.featured)
  const matchedPhotos = regularPhotos.filter((photo) => photo.similarity >= 94)
  const likelyPhotos = regularPhotos.filter((photo) => photo.similarity < 94).slice(0, 20)
  
  if (onFaceFound) {
    onFaceFound(matchedPhotos.length)
  }
  if(NotFoundFaceSearch) {
    NotFoundFaceSearch(data)
  }
  
  return (
    <S.Results>
      {featuredPhotos.length > 0 && (
        <>
          <div className="sectionHeader" key="separator" style={{ color: '#1F81C5', fontSize: '1.3rem', textAlign: 'center' }}>ภาพบรรยากาศงาน</div>,
          <div className="galleryContainer" key="photos">
            <ResponsiveGallery
              photos={featuredPhotos}
              onClick={handleThumbnailClick}
            />
          </div>
        </>
      )}
      {matchedPhotos.length > 0 ? (
        <>
          <Divider>{`  ${intl.formatMessage({ id: 'app.matchedPhotos' })} ${matchedPhotos.length} ${intl.formatMessage({ id: 'app.photos' })}`}</Divider>
          <div className="galleryContainer" key="photos">
            <ResponsiveGallery
              photos={matchedPhotos}
              onClick={handleThumbnailClick}
            />
          </div>
        </>
      ) : ('')/*S.Notice*/} 
      {likelyPhotos.length > 0 ? (
        <>
          <Divider>{`${intl.formatMessage({ id: 'app.weFound' })} ${likelyPhotos.length} ${intl.formatMessage({ id: 'app.imageSimilarToYou' })}`}</Divider>
          <div className="galleryContainer" key="likelyPhotos">
            <ResponsiveGallery
              photos={likelyPhotos}
              onClick={handleThumbnailClick}
            />
          </div>
        </>
      ) : (!matchedPhotos.length && ('')/*S.Notice*/)}
      <PhotoView
        photos={photos}
        photoId={pId}
        onPhotoChange={handlePhotoChange}
      />
    </S.Results>
  )
})

export default FaceSearchResults
