import React, { useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { observer } from 'mobx-react-lite'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import { Button, Col, Icon, Input, Radio, Row, Spin, message } from 'antd'
import createCreditOrderMutation from '../graphql/mutations/createCreditOrder.gql'
import packagesQuery from '~/graphql/queries/packages.gql'
import verifyCouponCodeQuery from '~/graphql/queries/verifyCouponCode.gql'
import PageNoContent from './PageNoContent'
import { useStores } from '~/contexts'
import * as S from '~/styles'
import _get from 'lodash/get'

const currencyList = ['THB', 'USD']

const BuyCreditStep1 = observer(() => {
  const client = useApolloClient()
  const intl = useIntl()
  const couponInputRef = useRef()
  const {
    routerStore: { history },
  } = useStores()
  const [couponState, setCouponState] = useState()
  const [loading, setLoading] = useState()
  const [selectedPackage, setSelectedPackage] = useState()
  const [currency, setCurrency] = useState('THB')
  const [createCreditOrder] = useMutation(createCreditOrderMutation)
  const {
    error,
    loading: packagesLoading,
    data,
  } = useQuery(packagesQuery, {
    variables: {
      currency,
      ...(sessionStorage.getItem('flag_hidden_packages') && { hidden: true }),
    },
  })
  const packages = data?.packages ?? {}
  const handleSelectedPackage = (e) => {
    const value = e.target.value
    setSelectedPackage(packages.find((p) => p._id === value))
  }
  const handleCurrency = (e) => {
    const value = e.target.value
    setCurrency(value)
  }
  const handleCouponCodeCheck = async () => {
    setLoading(true)
    const code = couponInputRef.current.state.value
    try {
      const {
        data: { verifyCouponCode },
      } = await client.query({
        query: verifyCouponCodeQuery,
        fetchPolicy: 'network-only',
        variables: {
          code,
        },
      })
      const {
        _id,
        couponCodeType: { discount, discountType },
      } = verifyCouponCode
      setCouponState({ _id, discount, discountType })
    } catch (err) {
      let errorMessage = []
      const { graphQLErrors } = err
      if (graphQLErrors) {
        graphQLErrors.forEach((error) => {
          const [text, minItems, maxItems] = error.message.split(':')
          const limit = minItems ? minItems : maxItems
          errorMessage.push([text, limit])
        })
      } else {
        errorMessage.push([err.message])
      }
      setCouponState({ code, errors: errorMessage })
    }
    setLoading(false)
  }
  const handleCouponCodeClear = () => {
    couponInputRef.current.state.value = ''
    setCouponState()
  }
  const handleNext = async () => {
    setLoading(true)
    const {
      data: {
        createCreditOrder: {
          error,
          record: { _id },
        },
      },
    } = await createCreditOrder({
      variables: {
        couponCodeIds: [...(couponState?._id ? [couponState._id] : [])],
        packageId: selectedPackage?._id ?? defaultPackage._id,
      },
    }).catch((error) =>
      error.graphQLErrors.forEach((err) => message.error(intl.formatMessage({ id: `api.errors.${err.message}`, defaultMessage: 'Unknown error' })))
    )
    if (!error) {
      setLoading(false)
      return history.push(`/credits/buy/step2/${_id}`)
    }
    setLoading(false)
    message.error(intl.formatMessage({ id: `api.errors.${error.message}`, defaultMessage: 'Unknown error' }))
  }

  if (error) return <PageNoContent />
  if (packagesLoading) return <Spin style={{ display: 'block', lineHeight: '100vh' }} />

  const defaultPackage = packages?.[0]
  const amount = selectedPackage?.price ?? defaultPackage.price
  const creditAmount = selectedPackage?.creditAmount ?? defaultPackage.creditAmount
  const couponDiscount = couponState?.discountType === 'percentage' ? (couponState.discount * amount) / 100 : couponState?.discount ?? 0
  const vat = Number(((amount - couponDiscount) * 0.07).toFixed(2))
  const totalPrice = amount - couponDiscount + vat
  const numberOption = {
    currency,
    currencyDisplay: 'symbol',
    maximumFractionDigits: 2,
    style: 'currency',
  }
  const sizeSuggestions = {
    'S': intl.formatMessage({ id: 'app.suggestionForSizeS' }),
    'M': intl.formatMessage({ id: 'app.suggestionForSizeM' }),
    'L': intl.formatMessage({ id: 'app.suggestionForSizeL' }),
    'XL': intl.formatMessage({ id: 'app.suggestionForSizeXL' }),
  };
  const getSuggestion = (size) => sizeSuggestions[size]

  return (
    <S.Package>
      <Row className='description'>{`${intl.formatMessage({ id: 'app.step', defaultMessage: 'Step' })} 1/2`}</Row>
      <Row className='header'>{intl.formatMessage({ id: 'app.selectAPackage', defaultMessage: 'Select a package' })}</Row>
      <div className='box'>
        <Radio.Group
          buttonStyle='solid'
          onChange={handleCurrency}
          size='large'
          value={currency}
          style={{ backgroundColor: '#d9d9d9', borderRadius: '100px', marginTop: '8px', padding: '4px', textAlign: 'center', width: '100%' }}
        >
          <Row align='middle' gutter={[16, 0]} justify='center' type='flex'>
            {currencyList.map((c) => (
              <Col key={c} span={12}>
                <Radio.Button
                  key={c}
                  value={c}
                  style={{
                    backgroundColor: c === currency ? 'white' : '#d9d9d9',
                    border: 'none',
                    color: c === currency ? '#111111' : 'rgba(17, 17, 17, 0.60)',
                    width: '100%',
                  }}
                >
                  {intl.formatMessage({ id: 'app.selectedCurrency', defaultMessage: 'Billed in {currency}' }, { currency: c })}
                </Radio.Button>
              </Col>
            ))}
          </Row>
        </Radio.Group>
        <Row style={{ width: '100%' }}>
          <Radio.Group buttonStyle='solid' defaultValue={defaultPackage._id} onChange={handleSelectedPackage} style={{ width: '100%' }}>
            {packages.map((p) => (
              <Radio.Button
                className='radio'
                value={p._id}
                key={p._id}
                style={{ background: p._id === (selectedPackage?._id ?? defaultPackage._id) ? 'rgba(253, 190, 26, 0.10)' : 'white' }}
              >
                {p._id === (selectedPackage?._id ?? defaultPackage._id) ? (
                  <>
                    <div className='radio-outer-checked'>
                      <div className='radio-inner-checked' />
                    </div>
                    <div style={{display:'flex', justifyContent:'space-between'}}>
                      <div style={{display:'flex', alignItems:'center'}}>
                        <div>
                          <p className='title'>
                            {intl.formatMessage({ id: 'app.quotaPhotos', defaultMessage: 'Quota of {credit, number} photos' }, { credit: p.creditAmount })}
                          </p>
                          <p className='price'>{intl.formatNumber(p.price, { ...numberOption, maximumFractionDigits: 0 })}</p>
                        </div> 

                      </div>

                      <div style={{marginRight:'30px', textAlign:'end',width:'130px'}}>
                        <p className='title' style={{fontSize:'24px'}}>
                          {_get(p,"name","-")}
                        </p>
                        <p style={{color: 'rgba(17, 17, 17, 0.60)' ,fontSize:'12px',fontWeight:400}}>
                          {intl.formatMessage({ id: 'app.suggestionFor' })}
                        </p>
                        <p style={{color: 'rgba(17, 17, 17, 0.60)' ,fontSize:'12px',fontWeight:400}}>
                        {(p.name !== '' || p.name ===null) && getSuggestion(p.name)}
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className='radio-unchecked' style={{ background: 'white' }} />
                    <div style={{display:'flex' , justifyContent:'space-between'}}>
                      <div style={{display:'flex', alignItems:'center'}}>
                        <div>
                          <p className='title' style={{ color: 'rgba(17, 17, 17, 0.60)' }}>
                            {intl.formatMessage({ id: 'app.quotaPhotos', defaultMessage: 'Quota of {credit, number} photos' }, { credit: p.creditAmount })}
                          </p>
                          <p className='price' style={{ color: 'rgba(17, 17, 17, 0.60)' }}>
                            {intl.formatNumber(p.price, { ...numberOption, maximumFractionDigits: 0 })}
                          </p>
                        </div>
                      </div>
                      <div style={{marginRight:'30px', textAlign:'end',width:'130px'}}>
                        <p className='title' style={{ color: 'rgba(17, 17, 17, 0.60)' ,fontSize:'24px'}}>
                        {_get(p,"name","-")}

                        </p>
                        <p style={{color: 'rgba(17, 17, 17, 0.60)' ,fontSize:'12px',fontWeight:400}}>

                          {intl.formatMessage({ id: 'app.suggestionFor' })}
                        </p>
                        <p style={{color: 'rgba(17, 17, 17, 0.60)' ,fontSize:'12px',fontWeight:400}}>
                        {(p.name !== '' || p.name ===null) && getSuggestion(p.name)}
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Row>
        {/* <Row className='summary'>
          <Col span={14}>{intl.formatMessage({ id: 'app.packagePrice', defaultMessage: 'Package Price' })}</Col>
          <Col span={10} style={{ textAlign: 'end' }}>
            {intl.formatNumber(selectedPackage?.price, { ...numberOption })}
          </Col>
          <Col span={14}>{intl.formatMessage({ id: 'app.vat', defaultMessage: 'VAT {amount} %' }, { amount: 7 })}</Col>
          <Col span={10} style={{ textAlign: 'end' }}>
            {intl.formatNumber(selectedPackage?.price * (VAT - 1), { ...numberOption })}
          </Col>
          <Col span={14}>{intl.formatMessage({ id: 'app.totalAmountDue', defaultMessage: 'Total amount due' })}</Col>
          <Col span={10} style={{ textAlign: 'end' }}>
            {intl.formatNumber(selectedPackage?.price * VAT, { ...numberOption })}
          </Col>
        </Row> */}
        <Row className='summary' style={{ color: 'rgba(17, 17, 17, 0.60)' }}>
          <Col span={14}>{intl.formatMessage({ id: 'app.buyQuotaPhotos', defaultMessage: 'Quota of {credit, number} photos' }, { credit: creditAmount })}
{" "}({selectedPackage?.name ?? defaultPackage?.name})</Col>
          <Col span={10} style={{ textAlign: 'end' }}>
            {intl.formatNumber(amount, { ...numberOption })}
          </Col>
          <Col span={14}>{`VAT 7%`}</Col>
          <Col span={10} style={{ textAlign: 'end' }}>
            {intl.formatNumber(vat, { ...numberOption })}
          </Col>
          {couponState?._id && (
            <>
              <Col span={6}>{intl.formatMessage({ id: 'app.discount', defaultMessage: 'Discount' })}</Col>
              <Col span={18} style={{ color: 'red', textAlign: 'end' }}>
                {`${
                  couponState.discountType === 'percentage'
                    ? `[${intl.formatMessage(
                        { id: 'app.percentDiscount', defaultMessage: '{discount}% discount' },
                        { discount: couponState.discount }
                      )}] `
                    : ''
                }-${intl.formatNumber(couponDiscount, numberOption)}`}
              </Col>
            </>
          )}
          <Col span={14} style={{ color: 'rgba(17, 17, 17, 0.60)' }}>
            {intl.formatMessage({ id: 'app.totalAmountDue', defaultMessage: 'Total amount due' })}
          </Col>
          <Col span={10} style={{ textAlign: 'end' }}>
            {intl.formatNumber(totalPrice, { ...numberOption })}
          </Col>
        </Row>
        <Row style={{ width: '100%', marginTop: '0.5em' }}>
          <Input
            disabled={!!couponState?._id || loading}
            ref={couponInputRef}
            onPressEnter={handleCouponCodeCheck}
            placeholder={intl.formatMessage({ id: 'app.couponCode', defaultMessage: 'Coupon code' })}
            type='text'
            style={{
              backgroundColor: 'rgba(17, 17, 17, 0.05)',
              border: 'none',
              borderRadius: '100px',
              color: 'rgba(17, 17, 17, 0.60)',
              fontSize: '16px',
              fontWeight: 200,
              padding: '1.5em 1em',
              width: '100%',
              ...(couponState?._id && { color: 'green' }),
              ...(couponState?.errors?.length > 0 && { border: '1px solid red', color: 'red' }),
            }}
          />
          <span
            onClick={couponState ? handleCouponCodeClear : handleCouponCodeCheck}
            style={{ color: '#fdbe1a', cursor: 'pointer', position: 'absolute', right: '1em', transform: 'translateY(50%)' }}
          >
            {intl.formatMessage({ id: `app.${couponState ? 'cancel' : 'ok'}`, defaultMessage: couponState ? 'Cancel' : 'OK' })}
          </span>
          {couponState?.errors?.length > 0 && (
            <div>
              {couponState.errors.map(([message, limit]) => (
                <div style={{ color: 'red', paddingLeft: '1em' }}>
                  {intl.formatMessage({ id: `api.errors.${message}` }, { limit, code: couponState.code })}
                </div>
              ))}
              {/* {couponState.errors.map(([message, limit]) => (
                <Alert key={message} message={intl.formatMessage({ id: `api.errors.${message}` }, { limit, code: couponState.code })} type='error' />
              ))} */}
            </div>
          )}
        </Row>
        <Row className='submit' style={{ marginTop: '0.5em' }}>
          <Button className='yellow-button' onClick={handleNext} type='primary' style={{ width: '100%', height: '3em', padding: '0 1.5em' }}>
            <p style={{ textAlign: 'left' }}>{intl.formatMessage({ id: 'app.proceedPayment', defaultMessage: 'Proceed to payment' })}</p>
            <p style={{ textAlign: 'right' }}>
              <Icon type='arrow-right' />
            </p>
          </Button>
        </Row>
      </div>
    </S.Package>
  )
})

export default BuyCreditStep1
