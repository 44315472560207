import React from 'react'
import {
  Alert,
  Col,
  Row,
  Button,
} from 'antd'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import _get from 'lodash/get'
// import _groupBy from 'lodash/groupBy'
import { Pie, Column } from '@ant-design/charts'
import moment from 'moment'
import { useIntl, FormattedMessage } from 'react-intl'
import { useStores } from '~/contexts'
import eventsQuery from '~/graphql/queries/events.gql'
import monthSummaryQuery from '~/graphql/queries/monthSummary.gql'
// import orderItemsQuery from '~/graphql/queries/orderItems.gql'
// import listPhotosQuery from '~/graphql/queries/listPhotos.gql'

import * as S from '~/styles'

const Dashboard = () => {
  const {
    userStore: user,
    routerStore: { history },
  } = useStores()
  const intl = useIntl()
  // history.replace('/events')
  const userAdmin = user.role === 'admin'
  const { error: eventsError, loading: eventsLoading, data: eventsData } = useQuery(eventsQuery, {
    variables: {
      skip: 0,
      limit: 30,
      approved: true,
      myEvent: !userAdmin,
    },
    fetchPolicy: 'network-only',
  })
  const { error: summaryError, loading: summaryLoading, data: summaryData } = useQuery(monthSummaryQuery, {
    variables: {
      page: 1,
      perPage: 13,
    },
  })
  // const { error: orderItemsError, loading: orderItemsLoading, data: orderItemsData } = useQuery(orderItemsQuery, {
  //   variables: {
  //     orderStatus: 'PAYMENT_COMPLETED',
  //     photographerId: user._id,
  //     // page: 1,
  //     // perPage: 20,
  //   },
  // })
  if (eventsLoading || summaryLoading) return 'Loading...'
  if (eventsError || summaryError) return 'Error...'
  // if (eventsLoading || summaryLoading || orderItemsLoading) return 'Loading...'
  // if (eventsError || summaryError || orderItemsError) return 'Error...'

  const events = _get(eventsData, 'events')
  // const invitationAlerts = !userAdmin && events.filter((e) => e.userUpload.joined === 'waiting')
  const lowCreaditBalanceAlerts = events.filter((e) => e.photoDownloadMode === 'free' && e.creditBalance.total <= e.creditBalance.credit * 0.1)
  const monthSummaryItems = _get(summaryData, 'monthSummary.items').map((e) => ({ ...e, month: moment.months()[e.month - 1] })).reverse()
  // const orderItems = _groupBy(_get(orderItemsData, 'orderItems.items'), 'orderId')
  // const orders = Object.values(orderItems)
  const pieChartData = [
    {
      type: intl.formatMessage({ id: 'app.dashb.priced', defaultMessage: 'Priced Photo' }),
      value: events.filter((e) => e.photoDownloadMode === 'priced').length,
    },
    {
      type: intl.formatMessage({ id: 'app.dashb.free', defaultMessage: 'Free Photo' }),
      value: events.filter((e) => e.photoDownloadMode === 'free').length,
    },
  ]

  const pieChartConfig = {
    appendPadding: 10,
    data: pieChartData,
    angleField: 'value',
    colorField: 'type',
    // color: ['#49a3b4', '#be508a'],
    color: ['#49a3b4', '#1B1464'],
    radius: 1,
    innerRadius: 0.64,
    label: {
      type: 'inner',
      offset: '-50%',
      style: {
        textAlign: 'center',
        fontSize: 14,
      },
      autoRotate: false,
      content: '{value}\n({percentage})',
    },
    interactions: [
      { type: 'element-selected' },
      { type: 'element-active' },
      { type: 'pie-statistic-active' },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: 20,
        },
        formatter: function formatter() {
          return(`${events.length}\n ${intl.formatMessage({ id: 'app.TotalEvents', defaultMessage: 'Total Events' })}`
   )
        },
      },
    },
  }

  const columnChartConfig = {
    data: monthSummaryItems,
    xField: 'month',
    yField: 'totalSales',
    label: {
      position: 'middle',
      style: {
        fill: '#FFFFFF',
        opacity: 0.6,
      },
    },
    meta: {
      month: { alias: 'เดือน' },
      totalSales: { alias: 'ยอดขาย' },
    },
    columnStyle: {
      // fill: 'l(90) 0:#ac0056 1:#4100aa',
      fill: 'l(90) 0:#29ABE2 1:#4100aa',
      color: '#4100aa',
    },
  }

  // const tableColumns = [
  //   {
  //     title: 'ชื่องาน',
  //     key: 'title',
  //     render: (value, record) => <span>{record[0].event.title}</span>,
  //   },
  //   {
  //     title: 'จำนวนภาพที่อัปโหลด',
  //     key: 'photoCount',
  //     render: (value, record) => {
  //       const { photoCount } = _get(record[0], 'event.uploads', []).find((e) => e.photographerId === user._id)
  //       return <span>{photoCount}</span>
  //     },
  //   },
  //   {
  //     title: 'จำนวนภาพที่ขายได้',
  //     key: 'photoSold',
  //     render: (value, record) => <span>{record.length}</span>,
  //   },
  // ]

  return (
    <S.Container fluid>
      <Row type="flex" gutter={[15, 30]}>
        <Col span={24}>
          {/* {!userAdmin && invitationAlerts.map((e) => (
            <Alert
              key={e._id}
              message={(
                <p>คุณได้รับคำเชิญให้ร่วมงาน {e.title} &nbsp;
                  <u
                    aria-hidden="true"
                    onClick={() => history.push(`/events/${e._id}/upload`)}
                    style={{ cursor: 'pointer' }}
                  >
                    คลิกเพื่อดูรายละเอียด
                  </u>
                </p>
              )}
              type="info"
              showIcon
              style={{
                backgroundColor: '#ebf5f7',
                borderColor: '#7cbcc8',
                marginBottom: 10,
              }}
            />
          ))} */}
          {userAdmin && lowCreaditBalanceAlerts.map((e) => (
            <Alert
              key={e._id}
              message={(
                <p><FormattedMessage
                id="app.totalCredits1"
                defaultMessage="Amount Credits for"/>{e.title}
                <FormattedMessage id="app.totalCredits2"
                defaultMessage="is about to expire, Buy credit to photographers can upload images."
                />&nbsp;
                  <u
                    aria-hidden="true"
                    onClick={() => history.push(`/events/${e._id}/credits`)}
                    style={{ cursor: 'pointer' }}
                  >
                   <FormattedMessage id="app.buyCredits" defaultMessage="Buy Credits" />
                  </u>
                </p>
              )}
              type="info"
              showIcon
              style={{
                backgroundColor: '#ebf5f7',
                borderColor: '#7cbcc8',
                marginBottom: 10,
              }}
            />
          ))}
        </Col>
        <Col span={24}>
          <S.PageHeader>
            <h1><FormattedMessage id="app.dashboard" defaultMessage="Dashboard" /></h1>
            <Link to="/events/create">
              <Button type="primary"><FormattedMessage id="app.createEvent" defaultMessage="Create Event" /></Button>
            </Link>
          </S.PageHeader>
        </Col>
        <Col xs={24} md={12}>
          <S.EventCard title={<FormattedMessage id="app.totalEvent" defaultMessage="Total Event" />}>
            <Row type="flex">
              <Col span={24}>
                <Pie {...pieChartConfig} />
              </Col>
            </Row>
          </S.EventCard>
        </Col>
        <Col xs={24} md={12}>
          <S.EventCard title={<FormattedMessage id="app.totalEventSales" defaultMessage="Total event sales for the last 12 months (photo sales)" />}>
            <Row type="flex">
              <Col span={24}>
                <Column {...columnChartConfig} />
              </Col>
            </Row>
          </S.EventCard>
        </Col>
        {/* <Col span={24}>
          <S.EventCard
            title="รายการ ขายภาพ 20 รายการล่าสุด"
            extra="within 3-month"
          >
            <Row type="flex">
              <Col span={24}>
                <Table
                  rowKey={(e, i) => i}
                  columns={tableColumns}
                  dataSource={orders}
                  loading={orderItemsLoading}
                  scroll={{ x: 1200 }}
                />
              </Col>
            </Row>
          </S.EventCard>
        </Col> */}
        {/* <Col span={24}>
          <S.EventCard title="รายการ เครดิต 20 รายการล่าสุด">
            <Row type="flex">
              <Col span={24}>
                <Table
                  rowKey={(e) => e.orderId}
                  columns={tableColumns}
                  dataSource={orderItems}
                  loading={orderItemsLoading}
                  scroll={{ x: 1200 }}
                />
              </Col>
            </Row>
          </S.EventCard>
        </Col> */}
      </Row>
    </S.Container>
  )
}

export default Dashboard
