import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import compose from 'recompose/compose'
import { useIntl } from 'react-intl'
import { useMutation } from '@apollo/client'
import { useHeader } from '../services/header'
import { Row, Col, Form, Button, message, Icon, Card, Modal } from 'antd'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useStores,useUser } from '~/contexts'
import { i18nStore } from '../contexts'

import contactMypicMutation from '~/graphql/mutations/contactMypic.gql'
import * as S from '~/styles'
import { useScreenView } from '../hooks/useFirebaseAnalytics'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const Home = compose(
  Form.create(),
  observer
)(({ form: { getFieldDecorator, validateFields, resetFields }, targetSectionId }) => {
  useScreenView('home')
  const [emailSent, setEmailSent] = useState(false)
  const intl = useIntl()
  const {
    routerStore: { history },
  } = useStores()
  const { userStore: userStores } = useStores()
  const currentUser = userStores.initUser() && userStores
  const { createEvent } = useHeader()
  const [contactMypic] = useMutation(contactMypicMutation)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const { user, userLoading } = useUser()


  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }
  // const fackbookUrl = 'https://www.facebook.com/pixmorydotcom'
  // const emailContract = 'mailto:photo@thai.run?subject=ติดต่อเปิดงาน Pixmory'
  const handleSubmit = (e) => {
    e.preventDefault()
    validateFields((err, values) => {
      if (!err) {
        contactMypic({
          variables: values,
        })
          .then(() => {
            setEmailSent(true)
            resetFields()
          })
          .catch((err) =>
            err.graphQLErrors.forEach((error) =>
              message.error(
                intl.formatMessage({
                  id: `api.errors.${error.message}`,
                  defaultMessage: 'Error',
                }),
                5
              )
            )
          )
      }
    })
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 3 },
      sm: { span: 3 },
    },
    wrapperCol: {
      xs: { span: 15 },
      sm: { span: 15 },
    },
  }
  const contentStyle = {
    height: 'auto',
    color: '#fff',
    lineHeight: 'auto',
    textAlign: 'center',
  }

  const Label = ({ text }) => <span style={{ color: '#fff', fontSize: '16px', fontWeight: 300 }}>{text}</span>

  // console.log("home", targetSectionId);
  // function SampleNextArrow(props) {
  //   const { className, style, onClick } = props
  //   return (
  //     <div
  //       className={className}
  //       style={{
  //         ...style,
  //         display: 'block',
  //         background: '#FDBE1A',
  //         borderRadius: '50px',
  //         width: '20px',
  //         height: '20px',
  //         paddingRight: '2em',
  //       }}
  //       onClick={onClick}
  //     />
  //   )
  // }

  // function SamplePrevArrow(props) {
  //   const { className, style, onClick } = props
  //   return (
  //     <div
  //       className={className}
  //       style={{
  //         ...style,
  //         display: 'block',
  //         background: '#FDBE1A',
  //         borderRadius: '50px',
  //         width: '20px',
  //         height: '20px',
  //         paddingLeft: '2em',
  //       }}
  //       onClick={onClick}
  //     />
  //   )
  // }
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    autoplay: true,
    autoplaySpeed: 3000,
    // speed: 2000,
    cssEase: 'linear',
    pauseOnHover: true,
    // prevArrow: <SampleNextArrow />,
    // nextArrow: <SamplePrevArrow />,
  }

  return (
    <>
      <Modal
        title='Contact Email'
        visible={isModalOpen}
        onCancel={handleCancel}
        footer={[
          <Button onClick={handleOk}>
            <FormattedMessage id='app.ok' defaultMessage='OK' />
          </Button>,
        ]}
      >
        <p>hello@mangomango.app</p>
      </Modal>
      <div style={{ backgroundColor: '#FDBE1A' }}>
        <S.Container noPadBottom={true}>
          <S.HomeWrapper>
            <Row type='flex' justify='center' gutter={[0, 70]} className='desktopOnly'>
              <Col span={24}>
                <div className='pixmoryBanner'>
                  <p
                    style={{
                      width: 'auto',
                      textAlign: 'center',
                      color: 'white',
                      fontSize: 20,
                      paddingTop: '36px',
                      paddingBottom: '14px',
                      wordWrap: 'break-word',
                    }}
                  >
                    MangoMango
                  </p>
                  <p
                    style={{
                      textAlign: 'center',
                      color: '#111111',
                      fontSize: 56,
                      fontWeight: '450',
                      paddingLeft: 200,
                      paddingRight: 200,
                      wordWrap: 'break-word',
                    }}
                  >
                    <FormattedMessage id='app.effortlesslyshareEvent' defaultMessage='effortlesslyshareEvent' />
                  </p>
                  <p
                    style={{
                      width: '100%',
                      textAlign: 'center',
                      color: 'rgba(17, 17, 17, 0.60)',
                      fontSize: 18,
                      paddingTop: '26px',
                      paddingBottom: '14px',
                      fontWeight: '200',
                    }}
                  >
                    <FormattedMessage id='app.home.description19' defaultMessage='effortlesslyshareEvent' />
                    <br />
                    <FormattedMessage id='app.home.description20' defaultMessage='effortlesslyshareEvent' />
                  </p>

                  {(!user || !user._id) && (
                    <div style={{ width: '100%' }}>
                      <Link to='/login'>
                        <Button
                          type='primary'
                          style={{
                            backgroundColor: 'black',
                            border: 'black',
                            fontSize: '16px',
                            height: '50px',
                            padding: '0px 30px',
                            margin: '40px 0px',
                          }}
                        >
                          <FormattedMessage id='app.home.description21' defaultMessage='start FREE trial!' />
                        </Button>
                      </Link>
                    </div>
                  )}

                  <img
                    style={{
                      width: '50%',
                      // height:'80%',
                      paddingTop: '36px',
                    }}
                    src='/images/section-2.png'
                  />
                </div>
              </Col>
            </Row>

            <Row type='flex' justify='center' gutter={[0, 30]} className='mobileOnly'>
              <Col span={24}>
                <div className='pixmoryBanner'>
                  <p
                    style={{
                      width: '100%',
                      textAlign: 'center',
                      color: 'white',
                      fontSize: 20,
                      paddingTop: '36px',
                      paddingBottom: '14px',
                      wordWrap: 'break-word',
                    }}
                  >
                    MangoMango
                  </p>
                  {i18nStore.locale === 'en' ? (
                    <p
                      style={{
                        textAlign: 'center',
                        color: '#111111',
                        fontSize: 42,
                        fontWeight: '450',
                      }}
                    >
                      <FormattedMessage id='app.mo.effortlesslyshareEvent3' />
                      <br />
                      <FormattedMessage id='app.mo.effortlesslyshareEvent4' />
                      <br />
                      <FormattedMessage id='app.mo.effortlesslyshareEvent5' />
                    </p>
                  ) : (
                    <p
                      style={{
                        textAlign: 'center',
                        color: '#111111',
                        fontSize: 50,
                        fontWeight: '450',
                        margin: '0 20px',
                      }}
                    >
                      <FormattedMessage id='app.effortlesslyshareEvent' defaultMessage='effortlesslyshareEvent' />
                    </p>
                  )}
                  {/* {currentUser ? (
                      <Link to="/events">
                        <Button
                          style={{
                            width: "280px",
                            height: "70px",
                            paddingLeft: 26,
                            lineHeight: 2,
                            paddingRight: 26,
                            background: "white",
                            borderRadius: 2046.04,
                            overflow: "hidden",
                            border: "1px #121212 solid",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 10,
                            display: "inline-flex",
                          }}
                        >
                          {i18nStore.locale === "en" ? (
                            <div
                              style={{
                                textAlign: "center",
                                color: "#111111",
                                fontSize: 42,
                                fontWeight: "450",
                                lineHeight: 34,
                                wordWrap: "break-word",
                              }}
                            >
                              <FormattedMessage
                                id="app.tryForFree"
                                defaultMessage="tryForFree"
                              />
                            </div>
                          ) : (
                            <div
                              style={{
                                textAlign: "center",
                                color: "#111111",
                                fontSize: 46,
                                fontWeight: "450",
                                lineHeight: 34,
                                wordWrap: "break-word",
                              }}
                            >
                              <FormattedMessage
                                id="app.tryForFree"
                                defaultMessage="tryForFree"
                              />
                            </div>
                          )}
                        </Button>
                      </Link>
                    ) : (
                      <Link to="/register">
                        <Button
                          style={{
                            width: "280px",
                            height: "70px",
                            paddingLeft: 26,
                            lineHeight: 2,
                            paddingRight: 26,
                            background: "white",
                            borderRadius: 2046.04,
                            overflow: "hidden",
                            border: "1px #121212 solid",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 10,
                            display: "inline-flex",
                          }}
                        >
                          {i18nStore.locale === "en" ? (
                            <div
                              style={{
                                textAlign: "center",
                                color: "#111111",
                                fontSize: 42,
                                fontWeight: "450",
                                lineHeight: 34,
                                wordWrap: "break-word",
                              }}
                            >
                              <FormattedMessage
                                id="app.register"
                                defaultMessage="register"
                              />
                            </div>
                          ) : (
                            <div
                              style={{
                                textAlign: "center",
                                color: "#111111",
                                fontSize: 46,
                                fontWeight: "450",
                                lineHeight: 34,
                                wordWrap: "break-word",
                              }}
                            >
                              <FormattedMessage
                                id="app.register"
                                defaultMessage="register"
                              />
                            </div>
                          )}
                        </Button>
                      </Link>
                    )} */}
                  <p
                    style={{
                      width: '100%',
                      textAlign: 'center',
                      color: 'rgba(17, 17, 17, 0.60)',
                      fontSize: 18,
                      paddingTop: '26px',
                      paddingBottom: '14px',
                      fontWeight: '200',
                    }}
                  >
                    <FormattedMessage id='app.home.description17' defaultMessage='effortlesslyshareEvent' />
                    <br />
                    <FormattedMessage id='app.home.description18' defaultMessage='effortlesslyshareEvent' />
                  </p>
                  {currentUser ? (
                    <Button
                      onClick={() => createEvent.open()}
                      type='primary'
                      style={{
                        backgroundColor: 'black',
                        border: 'black',
                        fontSize: '16px',
                        height: '50px',
                        padding: '0px 30px',
                      }}
                    >
                      <FormattedMessage id='app.starFreeTrial' defaultMessage='starFreeTrial' />
                    </Button>
                  ) : (
                    <Link to='/register'>
                      <Button
                        type='primary'
                        style={{
                          backgroundColor: 'black',
                          border: 'black',
                          fontSize: '16px',
                          height: '50px',
                          padding: '0px 30px',
                        }}
                      >
                        <FormattedMessage id='app.starFreeTrial' defaultMessage='starFreeTrial' />
                      </Button>
                    </Link>
                  )}
                  <img style={{ paddingTop: '36px', paddingBottom: '34px' }} src='/images/section-2.png' />
                </div>
              </Col>
            </Row>
          </S.HomeWrapper>
        </S.Container>
      </div>

      <S.AboutUs id={'about-us'}>
        <div className='containerAboutUs'>
          <div className='main-box'>
            <div>
              <p className='font3'>About Us</p>
              <p className='titleFont'>
                <FormattedMessage id='app.shareMagicMoments' />
              </p>
              <Row gutter={[16, 32]} style={{ marginTop: '0.5em' }}>
                <Col xs={24} md={12}>
                  <div>
                    <img style={{ width: '64px', height: '64px' }} src='/images/face-detection.svg' />
                    <p style={{ fontSize: '24px', marginTop: '1em' }}>
                      <FormattedMessage id='app.facialRecognition' />
                    </p>
                    <p className='font3'>
                      <FormattedMessage id='app.facialRecognitionAccuracy' />
                    </p>
                  </div>
                </Col>
                <Col xs={24} md={12}>
                  <div>
                    <img style={{ width: '64px', height: '64px' }} src='/images/clock.svg' />{' '}
                    <p style={{ fontSize: '24px', marginTop: '1em' }}>
                      <FormattedMessage id='app.timeSaver' />
                    </p>
                    <p className='font3'>
                      <FormattedMessage id='app.timeSaverDescription' />
                    </p>
                  </div>
                </Col>
                <Col xs={24} md={12}>
                  <div>
                    <img style={{ width: '64px', height: '64px' }} src='/images/secure.svg' />
                    <p style={{ fontSize: '24px', marginTop: '1em' }}>
                      <FormattedMessage id='app.trustByLeadingCompanies' />
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
  
          <div className='logo-box'
          >
            <img src='/images/thaiprakan.png' className='logo' />
            <img src='/images/tceb.png' className='logo' />
            <img src='/images/roxy.png' className='logo' />
            <img src='/images/rev.png' className='logo' />
            <img src='/images/redcross.png' className='logo' />
          </div>
          <div  className='logo-box-2'>
            <img src='/images/nightmusuem-1.png' className='logo' />
            <img src='/images/garmin.png' className='logo' />
            <img src='/images/cwx.png' className='logo' />
            <img src='/images/costopia.png' className='logo' />
            <img src='/images/chula.png' className='logo' />
            <img src='/images/amnuaysilpa.png' className='logo' />
          </div>
        </div>
      </S.AboutUs>

      <S.Feature id={'feature'}>
        <Slider {...settings}>
          <div className='containerFeature'>
            <p className='font3'>Features</p>

            <p className='titleFont'>
              <FormattedMessage id='app.sayGoodbyeWastingTime' />
            </p>
            <div className='mainContent'>
              <div
                className='leftBox'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div style={{ width: '90%' }}>
                  <p className='titleFont2'>
                    <FormattedMessage id='app.throughChatApp' />
                  </p>

                  <p className='descriptionFont'>
                    <FormattedMessage id='app.throughChatAppDescription' />
                  </p>
                </div>
              </div>
              <div className='rightBox' style={{ display: 'flex', alignItems: 'center' }}>
                <img src='/images/feature-1.png' className='responsiveImage'></img>
              </div>
            </div>
          </div>
          <div className='containerFeature'>
            <p className='font3'>Features</p>

            <p className='titleFont'>
              <FormattedMessage id='app.noDownloadsRequired' />
            </p>
            <div className='mainContent'>
              <div
                className='leftBox'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div style={{ width: '90%' }}>
                  <p className='titleFont2'>
                    <FormattedMessage id='app.throughChatApp' />
                  </p>

                  <p className='descriptionFont'>
                    <FormattedMessage id='app.throughChatAppDescription' />
                  </p>
                  <p className='titleFont3'>
                    <FormattedMessage id='app.NoInstallationRequired' />
                  </p>

                  <p className='descriptionFont'>
                    <FormattedMessage id='app.NoInstallationRequiredDescription' />
                  </p>
                </div>
              </div>
              <div className='rightBox' style={{ display: 'flex', alignItems: 'center' }}>
                <img src='/images/feature-2.png' className='responsiveImage'></img>
              </div>
            </div>
          </div>
          <div className='containerFeature'>
            <p className='font3'>Features</p>

            <p className='titleFont'>
              <FormattedMessage id='app.AutoFrame' />
            </p>
            <div className='mainContent'>
              <div
                className='leftBox'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div style={{ width: '90%' }}>
                  <p className='titleFont2'>
                    <FormattedMessage id='app.throughChatApp' />
                  </p>

                  <p className='descriptionFont'>
                    <FormattedMessage id='app.throughChatAppDescription' />
                  </p>
                  <p className='titleFont3'>
                    <FormattedMessage id='app.NoInstallationRequired' />
                  </p>

                  <p className='descriptionFont'>
                    <FormattedMessage id='app.NoInstallationRequiredDescription' />
                  </p>
                  <p className='titleFont3'>
                    <FormattedMessage id='app.AutoFrame' />
                  </p>

                  <p className='descriptionFont'>
                    <FormattedMessage id='app.AutoFrameDescription' />
                  </p>
                </div>
              </div>
              <div className='rightBox' style={{ display: 'flex', alignItems: 'center' }}>
                <img src='/images/feature-3.png' className='responsiveImage'></img>
              </div>
            </div>
          </div>
        </Slider>
      </S.Feature>

      <S.Usescase id={'use-case'}>
        <div className='containerUsescase'>
          <div className='title'>
            <p className='font3'>Uses cases</p>

            <h1 className='titleFont'>
              <FormattedMessage id='app.completeExperience' />
            </h1>
          </div>

          <div className='mainContent'>
            <div className='textDescription'>
              <img style={{ borderRadius: '16px' }} src='/images/wedding.jpg' className='responsiveImage' height={320} width={486}></img>
            </div>
            <div className='descriptionBox'>
              <div style={{ width: '100%' }}>
                <p className='titleFont2'>
                  <FormattedMessage id='app.married' />
                </p>

                <p className='descriptionFont'>
                  <FormattedMessage id='app.marriedDescription' />
                </p>
              </div>
            </div>
          </div>
          <div className='mainContentConference'>
            <div
              className='descriptionBox'
              style={{
                width: '100%',
              }}
            >
              <div style={{ width: '90%' }}>
                <p className='titleFont2'>
                  <FormattedMessage id='app.conference' />
                </p>

                <p className='descriptionFont'>
                  <FormattedMessage id='app.conferenceDescription' />
                </p>
              </div>
            </div>
            <div className='textDescription'>
              <img style={{ borderRadius: '16px' }} src='/images/meeting.jpg' className='responsiveImage' height={320} width={486}></img>
            </div>
          </div>
          <div className='mainContent'>
            <div className='textDescription'>
              <img style={{ borderRadius: '16px' }} src='/images/festival.jpg' className='responsiveImage' height={320} width={486}></img>
            </div>
            <div className='descriptionBox'>
              <div style={{ width: '100%' }}>
                <p className='titleFont2'>
                  <FormattedMessage id='app.festival' />
                </p>

                <p className='descriptionFont'>
                  <FormattedMessage id='app.festivalDescription' />
                </p>
              </div>
            </div>
          </div>
        </div>
      </S.Usescase>

      <S.Pricing id={'pricing'}>
        <div className='containerPricing'>
          <div className='topBox'>
            <p className='font3'>Pricing</p>
            <p className='titleFont'>
              <FormattedMessage id='app.startYourFreeTrialNow' />
            </p>
          </div>
          <div className='midBox'>
            {/* <div className="cardBox"> */}
            <Card className='card-price'>
              <p className='titleFont2'>Free</p>
              <p className='amountCredit'>
                <FormattedMessage id='app.100photoCredits' />
              </p>
              <p className='price'>฿0</p>
              <p className='vat' style={{ visibility: 'hidden' }}>
                <FormattedMessage id='app.vatIncluded' />
              </p>
              <Button type='primary' className='yellow-button'>
                <Link to='/login'>
                  <FormattedMessage id='app.startFree' />
                </Link>
              </Button>
            </Card>
            <Card className='card-price'>
              <p className='titleFont2'>Party</p>
              <p className='amountCredit'>
                <FormattedMessage id='app.1000photoCredits' />
              </p>
              <p className='price'>฿1,000</p>
              <p className='vat'>
                <FormattedMessage id='app.vatIncluded' />
              </p>
              <Button type='primary' className='yellow-button'>
                <Link to={(!user || !user._id) ? '/login':'/credits/buy/step1'}>
                  <FormattedMessage id='app.Buy' />
                </Link>
              </Button>
            </Card>
            <Card className='card-price'>
              <p className='titleFont2'>Event</p>
              <p className='amountCredit'>
                <FormattedMessage id='app.5000photoCredits' />
              </p>
              <p className='price'>฿4,500</p>
              <p className='vat'>
                <FormattedMessage id='app.vatIncluded' />
              </p>
              <Button type='primary' className='yellow-button'>
                <Link to={(!user || !user._id) ? '/login':'/credits/buy/step1'}>
                  <FormattedMessage id='app.Buy' />
                </Link>
              </Button>
            </Card>
            <Card className='card-price'>
              <p className='titleFont2'>Festival</p>
              <p className='amountCredit'>
                <FormattedMessage id='app.10000photoCredits' />
              </p>
              <p className='price'>฿8,000</p>
              <p className='vat'>
                <FormattedMessage id='app.vatIncluded' />
              </p>
              <Button type='primary' className='yellow-button'>
                <Link to={(!user || !user._id) ? '/login':'/credits/buy/step1'}>
                  <FormattedMessage id='app.Buy' />
                </Link>
              </Button>
            </Card>
            <Card className='card-price'>
              <p className='titleFont2'>Mega-Festival</p>
              <p className='amountCredit'>
                <FormattedMessage id='app.20000photoCredits' />
              </p>
              <p className='price'>฿14,000</p>
              <p className='vat'>
                <FormattedMessage id='app.vatIncluded' />
              </p>
              <Button type='primary' className='yellow-button'>
                <Link to={(!user || !user._id) ? '/login':'/credits/buy/step1'} >
                  <FormattedMessage id='app.Buy' />
                </Link>
              </Button>
            </Card>
            {/* </div> */}
          </div>
          <div className='bottomBox'>
            <p className='titleFont2'>
              <FormattedMessage id='app.lookingLargerScale' />
            </p>
            <Button type='primary' style={{ margin: '20px 0px', height: '48px' }} onClick={showModal}>
              <img style={{ width: '30px', marginRight: '10px' }} src='/images/email-2.svg' />
              Contact Sales
            </Button>
          </div>
        </div>
      </S.Pricing>
    </>
  )
})

export default Home
