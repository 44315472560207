import React from 'react'
import { StoresProvider } from '~/contexts'
import I18nProvider from '~/providers/I18nProvider'

const withI18n = (children) => (
  <StoresProvider>
    <I18nProvider>
      {children}
    </I18nProvider>
  </StoresProvider>
)

export default withI18n
