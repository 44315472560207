import React from 'react'
import { Result } from 'antd'

const PageNotAuthorized = () => (
  <Result
    status="403"
    title="403"
    // subTitle="Sorry, you are not authorized to access this page."
    subTitle="ขออภัย..คุณไม่ได้รับอนุญาตให้เข้าถึงหน้านี้"
    // extra={<Button type="primary">Back Home</Button>}
  />
)

export default PageNotAuthorized
