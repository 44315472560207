import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useQuery, useMutation } from '@apollo/client'
import liff from '@line/liff'
import registerEventMutation from '~/graphql/mutations/registerEvent.gql'
import eventQuery from '~/graphql/queries/event.gql'

import {
  Button,
} from 'antd'

import '../lineliff.css'
// import useFirebaseAnalytics, { useScreenView } from '../hooks/useFirebaseAnalytics'

const LineEventJoin = observer(({ match, history }) => {
  // useScreenView('line-event-join')
  // const { logEvent } = useFirebaseAnalytics()
  const [userId, setUserId] = useState(null);
  const [connectOA, setConnectOA] = useState(false);
  const [loadText, setLoadText] = useState('...Loading...');
  // const [event, setEvent] = useState(null)

  const [registerEvent] = useMutation(registerEventMutation);

  const { location } = history;
  const searchParams = new URLSearchParams(location.search);


  const eventId = searchParams.get('eventId');
  const cct = searchParams.get('cct');

  const textLineLiff = {
    en:{
      headTab:"Confirm to join",
      buttonSubmit: "Confirm!"
    },
    th:{
      headTab:"ยืนยันเพื่อเข้าร่วมงาน",
      buttonSubmit: "ยืนยันการเข้าร่วม"
    }
  }


  useEffect(() => {

    liff.init({ 
      liffId: `${process.env.REACT_APP_LIFF_ID}`
    }).then(async () => {
      if (!liff.isLoggedIn() && !liff.isInClient()) {   

        liff.openWindow({
          url: "https://line.me/R/ti/p/" + process.env.REACT_APP_OA_ID,
          external: false
        });

      } else {     

        const[{ friendFlag }] = await Promise.all([
          liff.getFriendship()
        ]);

        const[ { userId }] = await Promise.all([
          liff.getProfile()
        ]);

        setUserId(userId);
        setConnectOA(friendFlag && true);

        if(friendFlag && userId) {

        } else {
          liff.openWindow({
            url: "https://line.me/R/ti/p/" + process.env.REACT_APP_OA_ID,
            external: false
          });
        }
      
      }
    }).catch((error) => {
      // alert(error);
      // alert(error.stack)
      setLoadText("Error.. ");
      console.log(`liff.init() failed: ${error}`);
    });
  },[]);
  
  const { error, loading, data } = useQuery(eventQuery, {
    variables: {
      _id: eventId,
    },
    skip: !eventId
  });

  const submitRegisterEvent = async () => {
    try {
      const { data } = await registerEvent({
        variables: {
          oAuthUserId: userId,
          eventId,
          ...(cct && { couponCodeTypeId: cct })
        }
      }) 

      // logEvent('line::user_join_event', {
      //   external_user_id: userId,
      //   event_id: eventId,
      // })

      if(data.registerEvent) {
        liff.openWindow({
          url: "https://line.me/R/ti/p/" + process.env.REACT_APP_OA_ID,
          external: false
        });
      }
    } catch(e) {
      alert(e);
    }
  }

  return (
      <>
      <div className="img-header-tab">
        <img src="/images/mangomango_logo2.svg" alt="Pixmory" className="img-header" />
      </div>
      <div style={{ width: '100%', textAlign: 'center', fontSize: '18px' }}>
      {userId && eventId && data && connectOA ? (
        <>
          <div style={{padding: '1em'}}><h3>{textLineLiff[data.event.language]["headTab"]} : {data.event.title}</h3>
            <Button onClick={submitRegisterEvent} type="primary" shape="round" size={'large'} style={{marginTop:'1em'}}>
              {textLineLiff[data.event.language]["buttonSubmit"]}
            </Button>
          </div>
        </>
      ) : (
        <>
          <p>{loadText}</p>
        </>
      )}
      </div>
    </>
  );
})

export default LineEventJoin
