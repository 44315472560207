import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  from,
  defaultDataIdFromObject,
  split,
} from '@apollo/client'
import {
  routerStore,
  userStore,
} from '../contexts'
import { getMainDefinition } from '@apollo/client/utilities'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { WebSocketLink } from '@apollo/client/link/ws'
const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_URI,
})

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_WS_URI,
  options: {
    reconnect: true,
  },
})

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition'
      && definition.operation === 'subscription'
    )
  },
  wsLink,
  httpLink,
)

// const uploadLink = createUploadLink({ uri: process.env.REACT_APP_API_URI })
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token')
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const errorLink = onError(({ networkError = {} }) => {
  if (networkError.statusCode === 401) {
    userStore.logout()
    routerStore.push('/login')
  }
})

export const client = new ApolloClient({
  link: from([authLink, errorLink, splitLink]),
  cache: new InMemoryCache({
    dataIdFromObject: (object) => {
      if (['PhotoSizes', 'EventPhotoSizes'].includes(object.__typename)) {
        return null
      }
      if (['Photo', 'FacePhoto'].includes(object.__typename)) {
        return object._id
      }
      return defaultDataIdFromObject(object)
    },
  }),
})
