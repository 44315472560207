import React from 'react'
import { observer } from 'mobx-react-lite'
import {
  Button,
  Col,
  Icon,
  Result,
  Row,
} from 'antd'

import * as S from '~/styles'
import { useStores } from '~/contexts'
import { FormattedMessage } from 'react-intl'
import paymentQuery from '~/graphql/queries/payment.gql'
import { useQuery } from '@apollo/client'

// const statusIconMap = {
//   PENDING: 'pending',
//   COMPLETED: 'completed',
//   CANCELLED: 'cancelled',
//   FAILED: 'failed',
//   REFUNDED: 'refunded'
// }

const PaymentCompleted = observer(({ match }) => {
  const { routerStore } = useStores()
  const { loading, error, data, refetch } = useQuery(paymentQuery, { variables: { _id: match.params?.paymentId }})

  if (loading) return 'Loading...'
  if (error) return 'Error...'
  const { _id, code, status, creditOrder, error: paymentError } = data?.payment
  
  const handleClick = () => {
    if (creditOrder) {
      routerStore.push(`/events/${creditOrder?.eventId}/manage`)
    }
    // routerStore.push(`/events`)
  }
  return (

    <Row type="flex" gutter={[0, 30]}>
      <Col span={24}>
        <S.PageHeader>
          <h1><FormattedMessage id="app.payment" defaultMessage='payment'/></h1>
        </S.PageHeader>
      </Col>
      <Col span={24}>
        <S.EventCard title={<FormattedMessage id="app.paymentStatus" defaultMessage='paymentStatus'/>}>
          {status === 'pending' && <Result key={_id}
            // status="error"
            icon={<Icon type="hourglass" />}
            title={<FormattedMessage id="app.paymentProcessing" defaultMessage='Processing Payment...'/>}
            extra={[<Button onClick={() => refetch({ _id: match.params?.paymentId })}><FormattedMessage id="app.recheckPayment" defaultMessage='Recheck Payment Status'/></Button>]}
          />}
          {status === 'failed' && <Result key={_id}
            status="error"
            title={<FormattedMessage id="app.paymentFailed" defaultMessage='Payment Failed'/>}
            subTitle={<>
              <FormattedMessage id={`api.errors.payment.${paymentError?.code}`} defaultMessage='Unknown Error'/>
              {' '}
              (Payment Code {code})
            </>}
            extra={[<Button onClick={handleClick}><FormattedMessage id="app.backToEventPage" defaultMessage='Backt to Event Page'/></Button>]}
          />}
          {status === 'completed' && <Result
            status="success"
            title={<FormattedMessage id="app.orderComplete" defaultMessage='orderComplete'/>}
            extra={[<Button onClick={handleClick}><FormattedMessage id="app.backToEventPage" defaultMessage='Backt to Event Page'/></Button>]}
            // subTitle={(
            //   <>
            //     <p style={{ display: 'inline-block', marginRight: 5 }}><FormattedMessage id="app.allEvents" defaultMessage='All Events'/></p>
            //     <p
            //       style={{
            //         display: 'inline-block',
            //         textDecoration: 'underline',
            //         cursor: 'pointer',
            //       }}
            //       aria-hidden="true"
            //       onClick={handleClick}
            //     >
            //       <FormattedMessage id="app.click" defaultMessage='click'/>
            //     </p>
            //   </>
            // )}
          />},
        </S.EventCard>
      </Col>
    </Row>
  )
})

export default PaymentCompleted
