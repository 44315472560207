import React from 'react'
import { Tag, Icon } from 'antd'
import { FormattedMessage } from 'react-intl'
import _get from 'lodash/get'

const statusMap = {
  ORDER_SUBMITTED: { color: 'orange', icon: 'clock-circle' },
  PAYMENT_COMPLETED: { color: 'green', icon: 'check' },
}

const OrderStatus = ({ status }) => (
  <Tag color={_get(statusMap, [status, 'color'])}>
    <Icon type={_get(statusMap, [status, 'icon'])} />
    {' '}
    <FormattedMessage
      id={`app.status.order.${status}`}
      defaultMessage="STATUS"
      tagName="span"
    />
  </Tag>
)

export default OrderStatus
