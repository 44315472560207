import { observable, computed, action } from 'mobx'

export class FaceStore {
  @observable imgUrl = ''

  @computed get hasImage() {
    return this.imgUrl !== null
  }

  @computed get refData() {
    const [, mimeType, base64data] = this.imgUrl.match(/^data:(.+);base64,(.*)$/) || []
    if (!base64data) {
      return null
    }
    return {
      mimeType,
      base64data,
    }
  }
  @action setImgUrl(imgUrl) {
    if (this.imgUrl) {
      this.unsetImgUrl()
    }
    this.imgUrl = imgUrl
  }
  @action unsetImgUrl() {
    // console.log('unset')
    this.imgUrl = ''
  }
}
