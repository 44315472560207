import { observable, action } from 'mobx'
import jwtDecode from 'jwt-decode'

export class UserStore {
  @observable _id = null
  @observable username = null
  @observable role = null
  onLogout = null

  @action
  initUser() {
    const token = localStorage.getItem('token')
    if (!token) {
      return false
    }
    try {
      const { _id, username, role } = jwtDecode(token)
      this._id = _id
      this.username = username
      this.role = role
    } catch (e) {
      console.error(e.message)
      this.logout()
    }
    return true
  }

  @action
  login({ token }) {
    localStorage.setItem('token', token)
    this.initUser()
  }

  @action
  logout() {
    localStorage.clear()
    this._id = null
    this.username = null
    this.role = null
    if (this.onLogout && typeof this.onLogout === 'function') {
      this.onLogout()
    }
  }
}

