import React from "react";
import { compose } from "recompose";
import { observer } from "mobx-react-lite";
// import { useMutation } from "@apollo/client";
import { Link } from "react-router-dom";
// import { useIntl } from "react-intl";
import {
  Form,
  // Input,
  Button,
  Col,
  Row,
} from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
// import { ArrowRightOutlined, MailOutlined } from "@ant-design/icons";
// import { FormattedMessage } from "react-intl";
import { useStores } from "~/contexts";
import * as S from "~/styles";
import { useScreenView } from "../hooks/useFirebaseAnalytics";
// import authAdminMutation from "~/graphql/mutations/authAdminUser.gql";

const Checkemail = () => {
  useScreenView('email-check');
  return (
    <S.Container fluid>
      <S.LoginWrapper>
        <div className="pixmoryBanner">
          <div style={{ paddingBottom: "20px" }}>
            <Link to="/">
              <img
                style={{ width: "300px", textAlign: "center" }}
                src="/images/mangomango_logo2.svg"
                alt="MangMango"
              />
            </Link>
          </div>
          <div style={{ paddingBottom: "50px", display: "inline-flex" }}>
            <div
              style={{
                borderRadius: "25px",
                background: "#FFED8D",
              }}
            >
              <Col
                style={{
                  // width: "350px",
                  borderRadius: "25px 25px 0 0",
                  paddingTop: 32,
                  paddingBottom: 26,
                  paddingLeft: 32,
                  paddingRight: 32,
                  background: "#fff",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  gap: 12,
                  display: "flex",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 12,
                    display: "inline-flex",
                  }}
                >
                  <Link
                    to="/login"
                    style={{
                      width: "auto",
                      padding: 8,
                      background: "#FDBE1A",
                      borderRadius: 1000,
                      overflow: "hidden",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 12,
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        color: "#111111",
                        fontSize: 14,
                        fontWeight: "400",
                        lineHeight: 1,
                        wordWrap: "break-word",
                      }}
                    >
                      <ArrowLeftOutlined /> ย้อนกลับ
                    </div>
                  </Link>
                </div>
                <p
                  style={{
                    width: "100%",
                    textAlign: "left",
                    color: "#111111",
                    fontSize: 32,
                    fontWeight: "700",
                    lineHeight: 1,
                    wordWrap: "break-word",
                  }}
                >
                  ลืมรหัสผ่าน
                </p>
                <p
                  style={{
                    width: "100%",
                    textAlign: "left",
                    color: "rgba(17, 17, 17, 0.60)",
                    fontSize: 14,
                    fontWeight: "200",
                    lineHeight: 1,
                    wordWrap: "break-word",
                  }}
                >
                  กรุณากรอกอีเมลที่เคยสมัครแมงโก้ แมงโก้
                </p>
                <div
                  style={{
                    alignSelf: "stretch",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: 16,
                    display: "flex",
                  }}
                ></div>
                <div
                  style={{
                    alignSelf: "stretch",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 12,
                    // display: "inline-flex",
                  }}
                >
                  <Row
                    style={{
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: 12,
                      // display: "flex",
                    }}
                  >
                    <Col>
                      <img style={{paddingBottom: 20}} src="/images/art-1.svg" />
                    </Col>
                    <Col>
                      <p
                        style={{
                          color: "rgba(17, 17, 17, 0.60)",
                          fontSize: 14,
                          fontWeight: "200",
                          paddingBottom: 20
                        }}
                      >
                        เช็คสแปมแล้วก็ยังไม่เจอ?
                      </p>
                    </Col>
                    <Col>
                      <Button type="primary" shape="round">
                        ส่งอีเมลอีกครั้ง
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col className="desktopOnly">
                <Col
                  style={{
                    borderRadius: "25px",
                    background: "#FFED8D",
                    width: "450px",
                    height: "100%",
                    paddingTop: 16,
                  }}
                >
                  <div
                    style={{
                      alignSelf: "stretch",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: 12,
                      display: "inline-flex",
                    }}
                  >
                    <p
                      style={{
                        width: "100%",
                        color: "rgba(17, 17, 17, 0.60)",
                        fontSize: 16,
                        fontWeight: "200",
                        lineHeight: 3,
                        wordWrap: "break-word",
                        paddingBottom: 16,
                      }}
                    >
                      หากมีปัญหาการใช้งาน ติดต่อเราได้ทางช่องทางด้านล่าง
                    </p>
                  </div>
                  <Row
                    style={{
                      // alignSelf: "stretch",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "inline-flex",
                    }}
                  >
                    <Col span={8} style={{ marginLeft: 10 }}>
                      <img
                        style={{ width: "100px", borderRadius: 5 }}
                        src="/images/QRcode_facebook_mangomango.png"
                      />
                    </Col>
                    <Col
                      span={12}
                      style={{
                        textAlign: "left",
                        paddingBottom: 10,
                        fontSize: 16,
                        fontWeight: "400",
                      }}
                    >
                      <div style={{ paddingBottom: 15 }}>
                        <img
                          style={{ width: "27px" }}
                          src="/images/email.svg"
                        />
                        hello@mangomango.app
                      </div>
                      <div>
                      <img style={{ width: '30px' }} src='/images/facebook.svg' /> MangoMango Thailand
                      </div>
                    </Col>
                    <div>
                      <img
                        style={{
                          width: "100px",
                          height: "130px",
                        }}
                        src="/images/Component2.svg"
                      />
                    </div>
                  </Row>
                </Col>
              </Col>
              <Col className="mobileOnly">
                <Col
                  style={{
                    borderRadius: "25px",
                    background: "#FFED8D",
                    width: "350px",
                    height: "100%",
                    paddingTop: 16,
                  }}
                >
                  <div
                    style={{
                      alignSelf: "stretch",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: 12,
                      display: "inline-flex",
                    }}
                  >
                    <p
                      style={{
                        width: "100%",
                        textAlign: "center",
                        paddingBottom: 16,
                        color: "rgba(17, 17, 17, 0.60)",
                        fontSize: 14,
                        fontWeight: "400",
                        wordWrap: "break-word",
                      }}
                    >
                      หากมีปัญหาการใช้งาน
                      <br />
                      ติดต่อเราได้ทางช่องทางด้านล่าง
                    </p>
                  </div>
                  <div className="pixmoryBanner">
                    <img
                      style={{
                        width: "100px",
                        borderRadius: 10,
                        paddingBottom: 16,
                      }}
                      src="/images/QRcode_facebook_mangomango.png"
                    />
                    <div style={{ textAlign: "left", paddingLeft: 90 }}>
                      <div style={{ paddingBottom: 20 }}>
                        <img
                          style={{ width: "30px" }}
                          src="/images/email.svg"
                        />{" "}
                        hello@mangomango.app
                      </div>
                      <div>
                      <img style={{ width: '30px' }} src='/images/facebook.svg' /> MangoMango Thailand
                      </div>
                    </div>
                    <div>
                      <img
                        style={{
                          borderRadius: "25px",
                          width: "100%",
                          height: "auto",
                        }}
                        src="/images/Frame507.svg"
                      />
                    </div>
                  </div>
                </Col>
              </Col>
            </div>
          </div>
        </div>
      </S.LoginWrapper>
    </S.Container>
  );
};

export default compose(Form.create(), observer)(Checkemail);
