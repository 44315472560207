/* eslint-disable no-unused-vars */
/* eslint-disable object-curly-newline */
import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { FormattedMessage, FormattedDate } from 'react-intl'
import { useQuery } from '@apollo/client'
import moment from 'moment'
import _get from 'lodash/get'
import _pickBy from 'lodash/pickBy'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Carousel,
  Col,
  Row,
} from 'antd'

import * as S from '~styles'
import { useStores } from '~/contexts'
import eventBySlugQuery from '~/graphql/queries/eventBySlug.gql'
import adminUserByUsernameQuery from '~/graphql/queries/adminUserByUsername.gql'
import FaceSearchResults from '~/components/FaceSearchResults'
import SearchResults from '~/components/SearchResults'
import FeaturedResults from '~/components/FeaturedResults'
// import UnidentifiedSearch from '~/components/UnidentifiedSearch'
// import FaceSearch from '~/components/FaceSearch'

const Photos = observer(({ match }) => {
  const { routerStore: { location } } = useStores()
  const { query: { s, i, p, u } } = location
  const _u = parseInt(u, 10) === 0
  const [bib, setBib] = useState(s)
  const [imageId, setImageId] = useState(i)
  const [page, setPage] = useState(p)
  const [unidentified, setUnindentified] = useState(_u)
  const { loading: eventLoading, error: eventError, data: eventData } = useQuery(eventBySlugQuery, {
    variables: {
      slug: match.params.eventSlug,
    },
  })
  const { loading: userLoading, error: userError, data: userData } = useQuery(adminUserByUsernameQuery, {
    skip: !match.params.photographerSlug,
    variables: {
      username: match.params.photographerSlug,
    },
  })

  useEffect(() => {
    if (s !== bib || i !== imageId || p !== page || _u !== unidentified) {
      location.query = _pickBy({
        ...location.query,
        s: bib,
        i: imageId,
        p: page,
        u: unidentified ? '0' : undefined,
      })
    }
  }, [bib, imageId, unidentified]) // eslint-disable-line react-hooks/exhaustive-deps

  // const handleFaceInput = (imageId) => {
  //   setImageId(imageId)
  //   setBib(null)
  //   setPage()
  //   setUnindentified(false)
  // }
  // const handleClearFace = () => {
  //   setImageId()
  //   setPage()
  //   setUnindentified(false)
  // }
  // const handleUnidentifiedToggle = () => {
  //   if (unidentified) {
  //     handleClearFace()
  //   }
  //   setUnindentified(!unidentified)
  // }
  if (eventLoading || userLoading) return 'Loading...'
  if (eventError || userError) return 'Error...'
  if (eventData && !eventData.eventBySlug) return 'Event Not Found'
  const { eventBySlug: event } = eventData
  const {
    // photoTagFaces,
    coverUrl,
    showDefaultPhotos,
    searchable,
    searchableAt,
    // photosDeleted,
  } = event
  const photographer = _get(userData, 'adminUserByUsername')
  return (
    <S.Container>
      <Carousel>
        <div>
          <img src={coverUrl} alt="" style={{ width: '100%', maxHeight: 450, objectFit: 'cover' }} />
          <S.EventBanner>
            <div className="eventBanner__title">{event.title}</div>
            <div className="eventBanner__content">
              <span style={{ marginRight: 15 }}>
                <FontAwesomeIcon icon={['fas', 'calendar-alt']} />&ensp;
                <FormattedDate value={event.startDate} day="numeric" month="long" year="numeric" />
              </span>
              <span>
                <FontAwesomeIcon icon={['fas', 'map-marked-alt']} />&ensp;
                {event.location}
              </span>
            </div>
          </S.EventBanner>
        </div>
      </Carousel>
      {/* <S.SearchBar>
        <Row type="flex" justify="center" gutter={8} align="middle">
          {photoTagFaces && !photosDeleted && !event.photoFileDeleted && (
            <Col xs={20} md={5}>
              <FaceSearch onInput={handleFaceInput} onClear={handleClearFace} imageId={imageId} disabled={!photoTagFaces || !searchable || photosDeleted} awsAccount={event.awsAccount} />
            </Col>
          )}
          <Col xs={20} md={5}>
            <UnidentifiedSearch onToggle={handleUnidentifiedToggle} unidentified={unidentified} />
          </Col>
        </Row>
      </S.SearchBar> */}
      {!imageId && <FeaturedResults eventId={event._id} />}
      {searchable
        ? imageId
          ? <FaceSearchResults eventId={event._id} photographerId={photographer && photographer._id} imageId={imageId} />
          : unidentified
            ? <SearchResults eventId={event._id} photographerId={photographer && photographer._id} showDefaultPhotos={showDefaultPhotos} />
            : (
              <Row type="flex" style={{ paddingTop: 15 }}>
                <Col span={24} style={{ textAlign: 'center', color: '#1F81C5', fontSize: '1rem' }}>
                  {(event.photosDeleted || event.photoFileDeleted) && (
                  <FormattedMessage id="app.noLongerSearchable" />
                  )}
                  {(!event.photosDeleted && !event.photoFileDeleted) && (
                    <span>
                      (เนื่องด้วยนโยบายความเป็นส่วนตัว ระบบจะแสดงภาพพรีวิวหลังผลการค้นหา)
                    </span>
                  )}
                </Col>
              </Row>
            )
        : (
          <div style={{
            color: '#1F81C5',
            textAlign: 'center',
            padding: 12,
            fontSize: '1.2rem',
          }}
          >
            <FormattedMessage id="app.searchableAt" defaultMessage="Come back later..." values={{ time: moment(searchableAt).format('LLL') }} />
          </div>
        )}
    </S.Container>
  )
})

export default Photos
