import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { observer } from 'mobx-react-lite'
import { useMutation, useQuery } from '@apollo/client'
import { Button, Checkbox, Col, Icon, Modal, Row, Spin, message } from 'antd'
import _get from 'lodash/get'
import _pickBy from 'lodash/pickBy'
import { useStores, useUser } from '../contexts'
import eventQuery from '~/graphql/queries/event.gql'
import photoItemsQuery from '~/graphql/queries/photoItems.gql'
// import listPhotosQuery from '~/graphql/queries/listPhotos.gql'
import { FormattedMessage } from 'react-intl'
import deletePhotosMutation from '~/graphql/mutations/deletePhotos.gql'
import PhotoList from './PhotoList'
import PhotoPagination from '~/components/PhotoPagination'
const limit = 160

const DeleteConfirmModal = ({ handleDeletePhotos, selectedPhotos, onClose, visible }) => {
  const intl = useIntl()
  const handleClose = () => {
    onClose(false)
  }
  return (
    <Modal
      bodyStyle={{ padding: '24px' }}
      closable
      footer={null}
      onCancel={handleClose}
      visible={visible}
      width={360}
      style={{ textAlign: 'center' }}
    >
      <div>
        <Icon type='exclamation-circle' theme='filled' style={{ color: '#F94005', fontSize: '56px' }} />
      </div>
      <h1 style={{ lineHeight: '32px', marginTop: '24px' }}>{`${
        selectedPhotos.length > 1
          ? intl.formatMessage(
              {
                id: 'app.deleteSelectedConfirmHeader',
                defaultMessage: `Are you sure you want to delete the selected {selectedPhotos} {selectedPhotos, plural, one {photo} other {photos} }?`,
              },
              { selectedPhotos: selectedPhotos.length }
            )
          : intl.formatMessage({ id: 'app.deleteConfirmHeader', defaultMessage: 'Are you sure you want to delete this photo?' })
      }`}</h1>
      <p style={{ color: 'rgba(17, 17, 17, 0.40)', marginBottom: '10px' }}>
        {intl.formatMessage({
          id: 'app.deleteConfirmDescription',
          defaultMessage: `If you proceed, the photo(s) will be permanently deleted, and event attendees won't be able to search for it again.`,
        })}
      </p>
      <Row gutter={[16, 0]} type='flex' style={{ marginTop: '60px' }}>
        <Col span={12}>
          <Button
            onClick={handleDeletePhotos}
            style={{
              backgroundColor: '#F94005',
              border: 'none',
              color: 'white',
              fontSize: '16px',
              height: '40px',
              width: '100%',
            }}
          >
            {intl.formatMessage({ id: 'app.delete', defaultMessage: 'Delete' })}
          </Button>
        </Col>
        <Col span={12}>
          <Button
            onClick={handleClose}
            style={{
              backgroundColor: 'black',
              border: 'none',
              color: 'white',
              fontSize: '16px',
              height: '40px',
              width: '100%',
            }}
          >
            {intl.formatMessage({ id: 'app.cancel', defaultMessage: 'Cancel' })}
          </Button>
        </Col>
      </Row>
    </Modal>
  )
}

const EventPhotos = observer(({ event, handleInvitationPhotographerModal, handleUploadPhotoModal,isOwner,dataCurrentAdmin }) => {
  const intl = useIntl()
  const {
    routerStore: { location },
  } = useStores()
  const { user } = useUser()
  const {
    query: { p: page },
  } = location
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false)
  const [selectedPhotos, setSelectedPhotos] = useState([])
  const userId = event.isMine || event.isEditable ? [] : user._id
  const { error, loading, data } = useQuery(photoItemsQuery, {
    variables: {
      deleted: false,
      eventId: event._id,
      // limit,
      // skip: (page - 1) * limit,
      page: parseInt(page, 10) || 1,
      ...(dataCurrentAdmin.currentAdminUser._id.length > 0 && event.createdByUserId === user._id ? {} : { photographerId: user._id }) && user.role !== 'admin',
    },
    fetchPolicy: 'network-only',
  })

  const [deletePhotos] = useMutation(deletePhotosMutation, {
    refetchQueries: [
      {
        query: photoItemsQuery,
        variables: {
          deleted: false,
          eventId: event._id,
          // limit,
          // skip: (page - 1) * limit,
          page: parseInt(page, 10) || 1,
          ...(event.createdByUserId === user._id ? {} : { photographerId: user._id }),
        },
      },
      {
        query: eventQuery,
        variables: { _id: event._id },
      },
    ],
  })

  if (loading) return <Spin style={{ display: 'block' }} />
  if (error) return 'Error...'

  const photos = _get(data, 'photoItems.items', []).filter((e) => !e.deleted)
  const photoCount = data.photoItems.count


  const {
    currentPage,
    perPage,
    pageCount,
    itemCount,
  } = _get(data, 'photoItems.pageInfo', {})

  const  pageSize = perPage ?? limit

  const filteredPhotos = isOwner ? photos : photos.filter(photo => photo.photographer.username === user.username)

  // const photoReported = photos.filter((e) => e.reported)
  // const photoReportedCount = photoReported.length
  // const photoSold = photos.filter((e) => e.archivedAt)
  // const photoSoldCount = photoSold.length
  // const photoDeleted = _get(data, 'photos', []).filter((e) => e.deleted)
  // const photoDeletedCount = photoDeleted.length
  const onSelectPhoto = (photo) => {
    if (selectedPhotos.includes(photo)) {
      setSelectedPhotos((e) => e.filter((e) => e !== photo))
    } else {
      setSelectedPhotos((e) => [...e, photo])
    }
  }
  const handleSelectAll = (e) => {
    const v = e.target.checked
    setSelectedPhotos(v ? filteredPhotos.map((photo) => photo._id) : [])
  }
  const handleDeleteConfirmModal = (v) => {
    setDeleteConfirmModal(v)
  }
  const handleDeletePhotos = async () => {
    try {
      await deletePhotos({
        variables: { _ids: selectedPhotos },
      })
      setDeleteConfirmModal(false)
      setSelectedPhotos([])
    } catch (error) {
      message.error(error.message, 5)
    }
  }

  const handlePageChange = (page) => {
    location.query = _pickBy({
      ...location.query,
      p: page,
    })
  }

  return (
    <>
      <Row align='middle' justify='end' type='flex' style={{ marginTop: '3em' }}>
        <Col xs={24} sm={22}>
          <Checkbox onChange={handleSelectAll} style={{ fontSize: '16px', lineHeight: '20px' }}>
            <strong>{intl.formatMessage({ id: 'app.selectAll', defaultMessage: 'Select All' })}</strong>
            {` ${intl.formatMessage(
              {
                id: 'app.selectOnPage',
                defaultMessage: '{selectedPhotos}/{photoCount} {selectedPhotos, plural, one {photo} other {photos} } on this page are selected',
              },
              { selectedPhotos: selectedPhotos.length, photoCount: photoCount >= limit ? limit : filteredPhotos.length }
            )}`}
          </Checkbox>
        </Col>
        <Col xs={24} sm={2} style={{ textAlign: 'right' }}>
          <Button
            className='grey-button'
            disabled={selectedPhotos.length === 0}
            onClick={() => handleDeleteConfirmModal(true)}
            style={{ fontSize: '1em', height: '2.5em' }}
          >
            <Icon type='delete' />
            &nbsp;
            <FormattedMessage id='app.del' />
          </Button>
        </Col>
        {/* <Col sm={2}>
          <Button disabled={selectedPhotos.length === 0} style={{ border: 'none', fontSize: '16px', lineHeight: '20px' }}>
            <Icon type='download' />
            ดาวน์โหลด
          </Button>
        </Col> */}
      </Row>
      <Row style={{ marginTop: '10px' }}>
        <PhotoList
          selectable
          event={event}
          handleInvitationPhotographerModal={handleInvitationPhotographerModal}
          handleUploadPhotoModal={handleUploadPhotoModal}
          onSelectPhoto={onSelectPhoto}
          photoCount={photoCount}
          photos={photos}
          selectedPhotos={selectedPhotos}
          isOwner={isOwner}
        />
      </Row>
      <PhotoPagination
        pageSize={pageSize}
        current={currentPage}
        total={itemCount}
        onPageChange={handlePageChange}
        page={currentPage || page}
        pageCount={pageCount}
        itemCount={itemCount}
        /> 
      <DeleteConfirmModal
        handleDeletePhotos={handleDeletePhotos}
        onClose={handleDeleteConfirmModal}
        selectedPhotos={selectedPhotos}
        visible={deleteConfirmModal}
      />
    </>
  )
})

export default EventPhotos
