import React, { Component } from 'react'
import Dropzone from 'react-fine-uploader/dropzone'
import FileInput from 'react-fine-uploader/file-input'
import Thumbnail from 'react-fine-uploader/thumbnail'
import Filename from 'react-fine-uploader/filename'

import {
  Tabs,
  Badge,
  Button,
  Popover,
  Row,
  Col,
  // message,
  Modal,
  Icon,
  // Table,
} from 'antd'

import filesize from 'filesize'

import update from 'immutability-helper'
import _values from 'lodash/values'

import * as S from '~/styles'
import { FormattedMessage } from 'react-intl'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class ThairunUploader extends Component {
  constructor() {
    super()
    this.state = {
      statuses: [],
      files: {},
      total: {},
    }
  }
  componentDidMount() {
    const { uploader } = this.props
    const {
      QUEUED, UPLOADING, PAUSED,
      UPLOAD_SUCCESSFUL, UPLOAD_FAILED,
      // UPLOAD_RETRYING
    } = uploader.qq.status
    this.setState({
      statuses: [
        QUEUED, UPLOADING, PAUSED,
        UPLOAD_SUCCESSFUL, UPLOAD_FAILED,
        // UPLOAD_RETRYING
      ],
    })
    uploader.on('submitted', (id, name) => {
      const files = update(this.state.files, {
        [id]: { $merge: { id, name } },
      })
      this.setState({ files })
    })
    uploader.on('statusChange', (id, oldStatus, newStatus) => {
      const files = update(this.state.files, {
        $merge: {
          [id]: { id, status: newStatus },
        },
      })
      this.setState({ files })
    })
    uploader.on('progress', (id, name, bytesUploaded, bytesTotal) => {
      const files = update(this.state.files, {
        [id]: {
          $merge: { bytesUploaded, bytesTotal },
        },
      })
      this.setState({ files })
    })
    uploader.on('totalProgress', (bytesUploaded, bytesTotal) => {
      this.setState({ total: { bytesUploaded, bytesTotal } })
    })
    uploader.on('error', (id, name, errorReason, xhrOrXdr) => {
      // message.error(errorReason, 10)
      Modal.error({
        title: 'เกิดข้อผิดพลาด',
        content: errorReason,
      })
    })
  }
  continueAll = (files) => {
    // const { continueUpload } = this.props.uploader.methods
    files.forEach((file) => this.props.uploader.methods.continueUpload(file.id))
  }
  pauseAll = (files) => {
    // const { pauseUpload } = this.props.uploader.methods
    files.forEach((file) => this.props.uploader.methods.pauseUpload(file.id))
  }
  retryAll = (files) => {
    // const { retry } = this.props.uploader.methods
    files.forEach((file) => this.props.uploader.methods.retry(file.id))
  }
  render() {
    const { uploader, minSize } = this.props
    // console.log('u', uploader.methods.getUploads)
    const {
      QUEUED, UPLOADING, PAUSED, UPLOAD_SUCCESSFUL, UPLOAD_FAILED,
    } = uploader.qq.status

    const statusMap = {
      [UPLOADING]: 'uploading',
      [UPLOAD_FAILED]: 'failed',
      [PAUSED]: 'paused',
      [UPLOAD_SUCCESSFUL]: 'completed',
      [QUEUED]: 'queued',
    }
    // const { bytesUploaded, bytesTotal } = this.state.total
    // const { pauseUpload, continueUpload, retry, cancel } = uploader.methods
    const allFiles = _values(this.state.files).filter((f) => this.state.statuses.includes(f.status))
    return (
      <div style={{ marginTop: '1em', marginBottom: '20px', textAlign: 'center' }}>
        <Dropzone
          style={{
            width: '100%',
            height: '238.57px',
            padding: '15px',
            borderRadius: '12px',
            border: '1px dashed rgb(253, 190, 26)',
            backgroundColor:'rgba(253, 190, 26, 0.1)',
            color:'rgba(17, 17, 17, 0.6)',
            textAlign:'center',
            paddingTop: 'calc(238.57px / 3)'
          }}
          multiple
          uploader={uploader}
        >
          <FileInput multiple accept="image/*" uploader={uploader}>
            <Row align='middle' justify='center' type='flex'>
              <Row style={{width:'100%'}}>
                <Icon type='upload' style={{ backgroundColor: '#FDBE1A', borderRadius: '99px', padding: '8px' }} />
              </Row>
              <Row align='middle' justify='center' type='flex' style={{ color: 'rgba(17, 17, 17, 0.60)'}}>
                <Col style={{width:'100%'}}>
                  <p><strong style={{ color: '#FDBE1A' }}><FormattedMessage id='app.uploadCover' defaultMessage='Click to upload' /></strong>&nbsp;
                  <FormattedMessage id='app.orDragAndDrop' defaultMessage='or drag and drop files here' /></p>
                </Col>
                <Col style={{width:'100%'}}>
                  <p><FormattedMessage id='app.JPGonly' defaultMessage='JPG files only' /></p>
                </Col>
              </Row>
            </Row>
            {/* <span className="icon ion-upload"><FormattedMessage id="app.filesUpload"/> {minSize && <>(ภาพต้องมีขนาด กว้าง × สูง ไม่น้อยกว่า {minSize.width}px × {minSize.height}px)</>}</span> */}
          </FileInput>
        </Dropzone>
        {/* <p>ตอนนี้ระบบอัปโหลดภาพขัดข้องชั่วคราว ทีมงานกำลังเร่งแก้ไข</p> */}
        {allFiles.length > 0 && <p style={{  }}>{`พร้อมเผยแพร่ภาพ ${allFiles.length} ภาพ`}</p>}
        {allFiles.length > 0 && (
          <Tabs defaultActiveKey={UPLOADING}>
            {this.state.statuses.map((status) => {
              const files = uploader.methods.getUploads({ status })
              // const files = allFiles.filter(file => file.status === status)
              const actionButtonMap = {
                [UPLOADING]: <Button icon="pause-circle" onClick={() => this.pauseAll(files)}>pause all</Button>,
                [PAUSED]: <Button icon="play-circle" onClick={() => this.continueAll(files)}>resume all</Button>,
                [UPLOAD_FAILED]: <Button icon="reload" onClick={() => this.retryAll(files)}>retry all</Button>,
              }
              return (
                <Tabs.TabPane key={status} disabled={files.length === 0} tab={<span>{statusMap[status]} <Badge count={files.length} overflowCount={9999} style={{ background: '#fdbe1a' }} /></span>}>
                  {files.length > 0 && (
                  <Row>
                    <Col span={24} style={{ textAlign: 'center' }}>{actionButtonMap[status]}</Col>
                  </Row>
                  )}
                  {files.map((file) => {
                    const { bytesUploaded, bytesTotal } = this.state.files[file.id]
                    // const { bytesUploaded, bytesTotal } = file
                    const uploadedSize = filesize(bytesUploaded || 0)
                    const totalSize = filesize(bytesTotal || 0)
                    const percent = bytesUploaded / bytesTotal * 100 || (status === UPLOAD_SUCCESSFUL ? 100 : 0)
                    return (
                      <Row key={file.id}>
                        <Col xs={24} sm={24}>
                          <Popover content={<Thumbnail uploader={uploader} id={file.id} />}>
                            <Button style={{ width: '100%' }}><Filename uploader={uploader} id={file.id} /></Button>
                          </Popover>
                        </Col>
                        <Col xs={20} sm={22}>
                          <S.PixmoryProgress
                            type="line"
                            percent={percent}
                            status={status === UPLOADING ? 'active' : 'normal'}
                            format={(p) => `${p.toFixed(0)}%`}
                            showInfo
                            strokeWidth={15}
                            strokeColor="#4AA3B4"
                          />
                          {status !== UPLOAD_SUCCESSFUL && <span>{uploadedSize} / {totalSize}</span>}
                        </Col>
                        <Col xs={4} sm={2} style={{ textAlign: 'right' }}>
                          {status === UPLOADING
                      && (
                      <Button
                        shape="circle"
                        icon="pause-circle"
                        onClick={() => uploader.methods.pauseUpload(file.id)}
                        style={{
                          fontSize: '15px', border: 'none', boxShadow: 'none', width: '25px', height: '25px', minWidth: '0px',
                        }}
                      />
                      )}
                          {status === PAUSED
                      && (
                      <Button
                        shape="circle"
                        icon="play-circle"
                        onClick={() => uploader.methods.continueUpload(file.id)}
                        style={{
                          fontSize: '15px', border: 'none', boxShadow: 'none', width: '25px', height: '25px', minWidth: '0px',
                        }}
                      />
                      )}
                          {(status === UPLOADING || status === PAUSED)
                      && (
                      <Button
                        shape="circle"
                        icon="close-circle"
                        onClick={() => uploader.methods.cancel(file.id)}
                        style={{
                          fontSize: '15px', border: 'none', boxShadow: 'none', color: 'red', width: '25px', height: '25px', minWidth: '0px',
                        }}
                      />
                      )}
                          {status === UPLOAD_FAILED
                      && (
                      <Button
                        shape="circle"
                        icon="reload"
                        onClick={() => uploader.methods.retry(file.id)}
                        style={{
                          fontSize: '15px', border: 'none', boxShadow: 'none', width: '25px', height: '25px', minWidth: '0px',
                        }}
                      />
                      )}
                        </Col>
                      </Row>
                    )
                  })}
                </Tabs.TabPane>
              )
            })}
          </Tabs>
        )}
      </div>
    )
  }
}

export default ThairunUploader
