import React, { createContext, useContext, useEffect, useState } from 'react'
import { useLocalStore } from 'mobx-react-lite'
import { ApolloClient, ApolloProvider, defaultDataIdFromObject, from, HttpLink, InMemoryCache, split } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { onAuthStateChanged } from 'firebase/auth'
import { CartStore, FaceStore, I18NStore, RouterStore, UserStore } from '../stores'
import { auth } from '../services/firebase'

const UserContext = createContext()
const storesContext = createContext()

export const routerStore = new RouterStore()
export const userStore = new UserStore()
export const cartStore = new CartStore()
export const faceStore = new FaceStore()
export const i18nStore = new I18NStore()

const createStore = () => ({
  routerStore,
  userStore,
  cartStore,
  faceStore,
  i18nStore,
})
const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_URI,
})
const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_WS_URI,
  options: {
    reconnect: true,
  },
})
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
  },
  wsLink,
  httpLink
)
const cache = new InMemoryCache({
  dataIdFromObject: (object) => {
    if (['PhotoSizes', 'EventPhotoSizes'].includes(object.__typename)) {
      return null
    }
    if (['Photo', 'FacePhoto'].includes(object.__typename)) {
      return object._id
    }
    return defaultDataIdFromObject(object)
  },
})
const errorLink = onError(({ networkError = {} }) => {
  if (networkError.statusCode === 401) {
    routerStore.push('/login')
  }
})

export const client = new ApolloClient({
  link: from([errorLink, splitLink]),
  cache,
})

export const StoresProvider = ({ children }) => {
  const store = useLocalStore(createStore)
  const [userLoading, setUserLoading] = useState(true)
  const [user, setUser] = useState()
  const [token, setToken] = useState()
  const handleUpdateUser = (data) => {
    if (!data) {
      return setUser()
    }
    setUser(data)
  }
  const [apolloClient, setApolloClient] = useState(client)
  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const { accessToken } = user
        const authLink = setContext(async (_, { headers }) => ({
          headers: {
            ...headers,
            Authorization: `Bearer ${accessToken}`,
          },
        }))
        const _apolloClient = new ApolloClient({
          link: from([authLink, errorLink, splitLink]),
          cache,
        })
        setApolloClient(_apolloClient)
        setToken(accessToken)
      } else {
        setApolloClient(client)
      }
      setUserLoading(false)
    })
  }, []) //eslint-disable-line
  return (
    <storesContext.Provider value={store}>
      <UserContext.Provider value={{ token, userLoading, updateUser: handleUpdateUser, user }}>
        <ApolloProvider client={apolloClient}>
          {children}
        </ApolloProvider>
      </UserContext.Provider>
    </storesContext.Provider>
  )
}

export const useStores = () => {
  const store = useContext(storesContext)
  if (!store) {
    // this is especially useful in TypeScript so you don't need to be checking for null all the time
    throw new Error('useStore must be used within a StoreProvider.')
  }
  return store
}

export const useUser = () => {
  const context = useContext(UserContext)
  if (!context) {
    throw new Error('UserContext was used outside of the UserProvider.')
  }
  return context
}
