import React, { useState,useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useMutation } from '@apollo/client'
import { Link } from 'react-router-dom'
import { compose } from 'recompose'
import { Form, Input, Button, message, Modal,Radio } from 'antd'
// import _get from 'lodash/get'
import { FormattedMessage } from 'react-intl'
import { useStores } from '~/contexts'
import addEventMutation from '~/graphql/mutations/createEvent.gql'
import startUploadMutation from '~/graphql/mutations/startUpload.gql'
// import { lang } from 'moment'
import ThSVGIcon from '../assets/svg_icons/th_icon.svg'
import EnSVGIcon from '../assets/svg_icons/en_icon.svg'

const EventCreateModal = ({ form: { getFieldDecorator, validateFields, resetFields }, onClose, visible }) => {
  const intl = useIntl()
  const { routerStore: { history } } = useStores()
  const [submitLoading, setSubmitLoading] = useState(false)

  const [languageEvent, SetLanguageEvent] = useState('');
  const cssLanguageBtn = {
    "used": {opacity: '1', color:'rgba(253, 190, 26, 1)', backgroundColor: 'rgba(253, 190, 26, 0.1)', borderColor:'rgba(253, 190, 26, 1)'},
    "un_used": {opacity: '0.4', color: 'rgba(17, 17, 17, 1)', backgroundColor: 'rgba(255, 255, 255, 1)', borderColor: 'rgba(17, 17, 17, 0.1)'}
  }
  const languageEventChange = (e) => {
    SetLanguageEvent(e.target.value);
  };
  const [addEvent] = useMutation(addEventMutation)
  const [startUpload] = useMutation(startUploadMutation)
  const handleFormSubmit = (e) => {
    e.preventDefault()
    validateFields(async (err, values) => {

      if (!err) {
        setSubmitLoading(true)
        const { title } = values
        try {
          const {
            data: {
              newEvent: {
                record: { _id, title: eventTitle, createdByUserId },
              },
            },
          } = await addEvent({
            variables: {
              record: {
                title,
                language: languageEvent,
              },
            },
          })
          await startUpload({
            variables: {
              eventId: _id,
              photographerId: createdByUserId,
              approved: true
            },
          })
          message.info(`Event "${eventTitle}" created`, 5)
          resetFields()
          onClose(false)
          setSubmitLoading(false)
          history.push(`/events/${_id}/manage`)
        } catch (error) {
          setSubmitLoading(false)
          message.error(error.message)
        }
      }
    })
  }
  return (
    <Modal
      bodyStyle={{ padding: '24px 24px 5px 24px' }}
      closable
      footer={null}
      onCancel={onClose}
      visible={visible}
      width={360}
    >
      <h1><FormattedMessage id='app.createEvent'/></h1>
      <p style={{ color: 'rgba(17, 17, 17, 0.40)', marginBottom: '20px' }}><FormattedMessage id='app.mango.text5'/> <Link to={'/credits/buy/step1'}  onClick={onClose} style={{color: '#FDBE1A'}}><FormattedMessage id='app.BuyPackage'/></Link></p>
      <Form onSubmit={handleFormSubmit}>
        <Form.Item>
          {getFieldDecorator('title', {
            rules: [
              {
                required: true,
                message: intl.formatMessage({ id: 'app.pleaseFillEventTitle'}),
              },
            ],
          })(
            <Input
              placeholder={intl.formatMessage({ id: 'app.eventTitle', defaultMessage: 'Event Title' })}
              style={{ backgroundColor: 'rgba(17, 17, 17, 0.05)', border: 'none', borderRadius: '25px', height: '40px' }}
            />
          )}
        </Form.Item>

        <div style={{marginTop:'3px',marginBottom:'4px'}}> <FormattedMessage id="app.eventLanguage" tagName="h4" /> </div>
       
        <Form.Item>
          {getFieldDecorator('language', {
            initialValue: languageEvent,
            rules: [{ required: true, message: intl.formatMessage({ id: 'app.pickLanguage', defaultMessage: 'Please pick an item' }) }]
          })(
          <Radio.Group setFieldsValue={languageEvent} onChange={languageEventChange} style={{width:'100%'}}>
            <Radio.Button value="th" style={{width:'50%', ...(languageEvent === "th" ? cssLanguageBtn["used"] : cssLanguageBtn["un_used"]) }}>
              <img src={ThSVGIcon} style={{paddingRight:'4px'}}/>
              {intl.formatMessage({ id: 'app.thLang' })}
            </Radio.Button>
            <Radio.Button value="en" style={{width:'50%', ...(languageEvent === "en" ? cssLanguageBtn["used"] : cssLanguageBtn["un_used"]) }}>
              <img src={EnSVGIcon} style={{paddingRight:'4px'}}/>
              {intl.formatMessage({ id: 'app.enLang' })}
            </Radio.Button>
          </Radio.Group>
          )}
          </Form.Item>
       
        <Form.Item>
          <Button
            htmlType='submit'
            loading={submitLoading}
            type='primary'
            style={{
              backgroundColor: 'black',
              border: 'none',
              borderRadius: '25px',
              color: 'white',
              fontSize: '16px',
              height: '40px',
              width: '100%',
            }}
          >
            {intl.formatMessage({ id: 'app.createEvent', defaultMessage: 'Create Event' })}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default compose(Form.create())(EventCreateModal)
