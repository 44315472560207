/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import { Pagination } from 'antd'

import { PhotoPagination as $PhotoPagination } from '~/styles'

const PhotoPagination = ({
  pageSize, page, onPageChange, itemCount,
}) => (
  <$PhotoPagination>
    {/* <span className="slider">
      <Slider
        included={false}
        min={1}
        max={pageCount}
        defaultValue={page}
        onAfterChange={onPageChange}
      />
    </span> */}
    <span className="paging">
      <Pagination
        // showQuickJumper
        defaultCurrent={1}
        defaultPageSize={pageSize}
        current={page}
        total={itemCount}
        onChange={onPageChange}
      />
    </span>
  </$PhotoPagination>
)

export default PhotoPagination
