import React from 'react'
import compose from 'recompose/compose'
import { inject, observer } from 'mobx-react'
import { BlueBorderSelect } from '~/styles'

const LocaleSwitcherMobile = compose(
  inject('i18n'),
  observer
)(({ i18n }) => (

  <BlueBorderSelect
    defaultValue={i18n.locale}
    bordered={false}
    onChange={(locale) => i18n.setLocale(locale)}
    dropdownMatchSelectWidth={false}
  // suffixIcon={<FontAwesomeIcon icon={['fas', 'chevron-down']} />}
  >
    {['en', 'th'].map((l, i) => (
      <BlueBorderSelect.Option style={{borderRadius: "20px", transform: 'translateY(-1px)', height: '30px'}} key={l} value={l} onClick={() => i18n.setLocale(l)}>
        {/* {(l === 'en' ? countryToFlag('us') : countryToFlag(l))+l.toUpperCase()} */}
        {/* <img src={'/images/'+l+'.svg'} alt={l} style={{ transform: 'translateY(-1px)', height: '20px' }} /> */}
        <span>{l.toUpperCase()}</span>
      </BlueBorderSelect.Option>
    ))}
  </BlueBorderSelect>
))

export default LocaleSwitcherMobile
