import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useQuery, useMutation } from '@apollo/client'
import { Redirect } from 'react-router'
import _get from 'lodash/get'
import moment from 'moment'
import {
  Row,
  Col,
  Button,
  message,
  Alert,
} from 'antd'
import { CheckOutlined } from '@ant-design/icons'
import { FormattedMessage } from 'react-intl'

import { useStores } from '~/contexts'
import EventPhotos from '~/components/EventPhotos'
import PhotoUploadTools from '~/components/PhotoUploadTools'
import eventQuery from '~/graphql/queries/event.gql'
import currentAdminUserCreditsQuery from '~/graphql/queries/currentAdminUserCredits.gql'
import replyUploadMutation from '~/graphql/mutations/replyUpload.gql'
import startUploadMutation from '~/graphql/mutations/startUpload.gql'
import * as S from '~/styles'
// import eventTypeOption from '~/assets/jsons/eventType.json'
// import PageNotAuthorized from '~/pages/PageNotAuthorized'

const EventUpload = observer(({ match }) => {
  const { userStore: user } = useStores()
  const { showPhotos, setShowPhotos } = useState(false)
  // const userAdmin = user.role === 'admin'
  const { error, loading, data } = useQuery(eventQuery, {
    variables: {
      _id: match.params.eventId,
    },
    fetchPolicy: 'network-only',
  })
  const { error: photographerError, loading: photographerLoading } = useQuery(currentAdminUserCreditsQuery, {
    skip: true,
  })
  const [joinUpload] = useMutation(replyUploadMutation, {
    refetchQueries: [{
      query: eventQuery,
      variables: { _id: match.params.eventId },
    }],
  })
  const [startUpload] = useMutation(startUploadMutation, {
    refetchQueries: [{
      query: eventQuery,
      variables: { _id: match.params.eventId },
    }],
  })
  if (loading || photographerLoading) return 'Loading...'
  if (error || photographerError) return 'Error...'
  const { event } = data ?? {}
  const { userUpload } = event ?? {}
  // const [eventType] = eventTypeOption.filter((e) => e.value === event.eventType)
  if (event.isMine || event.isEditable) return <Redirect to={`/events/${match.params.eventId}/manage`} />
  // if (!userUpload) return <PageNotAuthorized />
  if (userUpload && (userUpload.joined === 'rejected' || userUpload.joined === 'canceled')) return <Redirect to="/dashboard" />

  // const creditTargets = _get(event, 'creditTargets')
  const eventCredit = _get(event, 'createdByUser.creditBalance.total', 0)
  // const photographerCredit = _get(photographerData, 'currentAdminUser.creditBalance.total', 0)
  // const creditsAvailable = creditTargets === 'event' ? eventCredit : photographerCredit
  const photoDownloadMode = _get(event, 'photoDownloadMode')
  const creditsAvailable = photoDownloadMode === 'priced' ? undefined : eventCredit
  // const creditsAvailable = photoDownloadMode === 'priced' ? eventCredit : undefined // temporary

  const handleJoinEvent = async (e, reply) => {
    e.preventDefault()
    try {
      await joinUpload({
        variables: {
          _id: userUpload._id,
          record: { joined: reply },
        },
      })
    } catch (error) {
      message.error(error.message, 5)
    }
  }
  const handleStartUpload = async () => {
    try {
      await startUpload({
        variables:{
          eventId: match.params.eventId,
          photographerId: user._id,
          photographerName: user.username,
          approved: true,
          joined: 'joined',
          photoLimit: 0,
        },
      })
    } catch (error) {
      message.error(error.message, 5)
    }
  }
  return (
    <S.Container fluid>
      <Row type="flex" gutter={[15, 30]} justify="space-between">
        {userUpload?.joined === 'waiting' && (
        <Col span={24}>
          <Alert
            message="คุณได้รับคำเชิญจากผู้จัดงานให้เข้าร่วมการถ่ายภาพ และอัปโหลดภาพเข้าสู่อัลบัมกิจกรรม โปรดศึกษารายละเอียดด้านล่าง"
            type="info"
            showIcon
            style={{ backgroundColor: '#ebf5f7', borderColor: '#7cbcc8' }}
          />
        </Col>
        )}
        <Col span={24}>
          <S.PageHeader>
            <h1>{event.title}</h1>
          </S.PageHeader>
        </Col>
        <Col span={24}>
          <S.EventCard title="รายละเอียดกิจกรรม">
            <Row type="flex" style={{ padding: '0px 15px' }} gutter={[0, 8]}>
              <Col span={24} style={{ display: 'flex' }}>
                <p style={{ fontWeight: 'bold', width: 140 }}>ชื่อกิจกรรม</p>
                {event.title}
              </Col>
              <Col span={24} style={{ display: 'flex' }}>
                <p style={{ fontWeight: 'bold', width: 140 }}>วันที่จัดกิจกรรม</p>
                {moment(event.startDate).format('Do MMMM YYYY')}
              </Col>
              <Col span={24} style={{ display: 'flex' }}>
                <p style={{ fontWeight: 'bold', width: 140 }}>สถานที่จัด</p>
                {event.location}
              </Col>
              <Col span={24} style={{ display: 'flex' }}>
                <p style={{ fontWeight: 'bold', width: 140 }}>ประเภทกิจกรรม</p>
                {event.eventType}
              </Col>
              <Col span={24} style={{ display: 'flex' }}>
                <p style={{ fontWeight: 'bold', width: 140 }}>รูปแบบการรับภาพ</p>
                <FormattedMessage id={`app.event.${event.photoDownloadMode}`} />
              </Col>
              <Col span={24} style={{ display: 'flex' }}>
                <p style={{ fontWeight: 'bold', width: 140 }}>เครดิตคงเหลือ</p>
                {/* {creditsAvailable || 'ไม่จำกัด'} */}
                {!creditsAvailable ? 'ไม่จำกัด' : creditsAvailable}
              </Col>
            </Row>
          </S.EventCard>
        </Col>
        {event?.uploadNotice && (
        <Col span={24}>
          <S.EventCard title="ข้อกำหนด และเงื่อนไข การเข้าร่วมกิจกรรม">
            <Row type="flex" style={{ padding: '0px 15px' }} gutter={[0, 8]}>
              <Col>
                <p>{event.uploadNotice}</p>
              </Col>
            </Row>
          </S.EventCard>
        </Col>
        )}
        {userUpload?.joined === 'waiting' && (
        <Col span={24}>
          <div style={{
            border: '1px solid #4CA3B4', background: '#EBF5F7', textAlign: 'center', marginTop: 10, padding: 15,
          }}
          >
            <span style={{ color: '#4CA3B4', fontSize: '1.1rem', fontWeight: 'bold' }}>คําเชิญจากผู้จัดงาน ถึงคุณ</span>
            <Row type="flex">
              <Col span={1}>
                <CheckOutlined style={{ color: '#4CA3B4' }} />
              </Col>
              <Col span={23} style={{ textAlign: 'left' }}>
                <span style={{ color: '#4CA3B4' }}>เมื่อตกลงร่วมงานแล้วคุณสามารถ </span>
                <span style={{ color: '#4CA3B4', fontWeight: 'bold' }}> อัปโหลดภาพถ่าย </span>
                <span style={{ color: '#4CA3B4' }}>เข้าสู่กิจกรรมได้</span>
              </Col>
            </Row>
            <Row type="flex">
              <Col span={1}>
                <CheckOutlined style={{ color: '#4CA3B4' }} />
              </Col>
              <Col span={23} style={{ textAlign: 'left' }}>
                <span style={{ color: '#4CA3B4' }}>กรณีที่คุณ ไม่ต้องการเข้าร่วมงาน หรือ ต้องการเปลี่ยน อีเมลรับลิงก์ โปรดติดต่อผู้จัดงานโดยตรง</span>
              </Col>
            </Row>
            <Button type="danger" onClick={(e) => handleJoinEvent(e, 'rejected')} style={{ marginRight: 10 }}>
              <span style={{ fontSize: '1rem' }}>ปฎิเสธการเข้าร่วมงาน</span>
            </Button>
            <Button type="primary" onClick={(e) => handleJoinEvent(e, 'joined')}>
              <span style={{ fontSize: '1rem' }}>ตกลงร่วมงาน</span>
            </Button>
          </div>
        </Col>
        )}
        <Col span={24}>
          <S.EventCard title='อัปโหลดภาพถ่าย'>
            {userUpload?.joined === 'joined' ? (
              <PhotoUploadTools event={event} user={user} />
            ) : (
              <div style={{ textAlign: 'center' }}>
                <Button type='primary' onClick={handleStartUpload}>
                  <span style={{ fontSize: '1rem' }}>เริ่มอัปโหลดภาพ</span>
                </Button>
              </div>
            )}
          </S.EventCard>
        </Col>
        {userUpload?.joined === 'joined' && (
          <>
            <Button onClick={() => setShowPhotos((s) => !s)}>{showPhotos ? 'ซ่อนรูป' : 'แสดงรูป'}</Button>
            {showPhotos && (
              <Col span={24}>
                <EventPhotos event={event} id={event._id} />
              </Col>
            )}
          </>
        )}
      </Row>
    </S.Container>
  )
})

export default EventUpload
