import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useHeader } from '../services/header'
import { Menu, message } from 'antd'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { observer } from 'mobx-react-lite'
import { FormattedMessage } from 'react-intl'

import { useStores } from '~/contexts'
import { signOut } from 'firebase/auth'
import { useUser } from '../contexts'
import { auth } from '../services/firebase'

const { Item } = Menu

const Navbar = observer((onClose) => {
  const {
    routerStore: {
      location: { pathname },
    },
  } = useStores()
  const [currentPath, setCurrentPath] = useState('')
  const { createEvent } = useHeader()
  const history = useHistory()
  const { updateUser } = useUser()

  const onClickLogout = async () => {
    try {
      await signOut(auth)
      window.recaptchaVerifier = undefined
      updateUser()
      history.push('/')
    } catch (error) {
      return message.error(error.message)
    }
  }

  useEffect(() => {
    setCurrentPath(pathname)
  }, [pathname])

  return (
    <Menu mode='inline' selectedKeys={[currentPath]} defaultOpenKeys={['event', 'manual']} style={{ border: 'none' }}>
      <Item className='mobileOnly'>
        <Link to='/events'>
          <FormattedMessage id='app.home' defaultMessage='Home' />
        </Link>
      </Item>
      {/* <Item key="/dashboard">
        <Link to="/dashboard">
          <FormattedMessage id="app.dashboard" defaultMessage="Dashboard" />
        </Link>
      </Item> */}
      {/* <Item>
        <Link to="/credits/buy">
          <FormattedMessage id="app.BuyPackage" defaultMessage="BuyPackage" />
        </Link>
      </Item> */}
      <Item>
        <Link onClick={() => createEvent.open()}>
          <FormattedMessage id='app.starFreeTrial' defaultMessage='starFreeTrial' />
        </Link>
      </Item>
      <Item>
        <Link to='/mycredit'>
          <FormattedMessage id='app.myCredit' defaultMessage='my credit' />
        </Link>
      </Item>
      <Item>
        <Link to='/' onClick={onClickLogout} style={{ color: 'red' }}>
          {/* <FontAwesomeIcon icon={['fas', 'sign-out-alt']} style={{ marginRight: 7 }} /> */}
          <FormattedMessage id='app.logout' defaultMessage='Logout' />
        </Link>
      </Item>
      {/* <SubMenu key="event" title={<FormattedMessage id="app.events" defaultMessage="Events" />}>
        <Item key="/events">
          <Link to="/events"><FormattedMessage id="app.allEvents" defaultMessage="All Events" /></Link>
        </Item>
        {(userAdmin || userOrganizer) && (
          <Item key="/events/create">
            <Link to="/events/create"><FormattedMessage id="app.createEvent" defaultMessage="Create Event" /></Link>
          </Item>
        )}
      </SubMenu>
      <SubMenu key="manual" title={<FormattedMessage id="app.support" defaultMessage="Support" />}>
        <Item key="/manual/event">
          <Link to="/manual/event"><FormattedMessage id="app.instructionManual" defaultMessage="Instruction Manual" /></Link>
        </Item>
        <Item key="/manual/line">
          <Link to="/manual/line"><FormattedMessage id="app.receivingImagesFromLine" defaultMessage="Receiving images from line" /></Link>
        </Item>
      </SubMenu> */}
      {/* {(userAdmin || userOrganizer) && (
      <Item key="/photo-downloads">
        <Link to="/photo-downloads"><FormattedMessage id="app.downloadLists" defaultMessage="Download Lists" /></Link>
      </Item>
      )} */}
    </Menu>
  )
})

export default Navbar
