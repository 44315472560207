import React from 'react'
import { observer } from 'mobx-react-lite'
import { Col, Row } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage } from 'react-intl'

import { useStores } from '~/contexts'
import OmiseCard from '~/components/Payments/OmiseCard'
import ScbQr from '~/components/Payments/ScbQr'
import Linepay from '~/components/Payments/Linepay'
import OmiseTruemoney from '~/components/Payments/OmiseTruemoney'
import * as S from '~/styles'

const Payments = observer(({
  orderTarget,
  payment,
  price,
  eventId,
  orderId,
  packageId,
  returnUri,
  refetchQueries,
}) => {
  const { routerStore: { location } } = useStores()

  const handleSelect = (e) => {
    location.query = {
      ...location.query,
      payment: e,
    }
  }

  return (
    <S.PaymentsWrapper>
      <Row type="flex" gutter={[0, 30]}>
        <Col span={24}>
          <p className="paymentTitle"><FormattedMessage id="app.paymentMethod" /></p>
        </Col>
        <Col span={24} className="paymentSelection">
          <Col
            className="paymentButton"
            style={{ backgroundColor: payment === 'credit' && '#ebf5f7' }}
            onClick={() => handleSelect('credit')}
          >
            <FontAwesomeIcon icon={['far', 'credit-card']} />
            <p className="desktopOnly flexCenter"><FormattedMessage id="app.credit/debit" defaultMessage='Credit/Debit'/></p>
          </Col>
          <Col
            className="paymentButton"
            style={{ backgroundColor: payment === 'qrcode' && '#ebf5f7' }}
            onClick={() => handleSelect('qrcode')}
          >
            <FontAwesomeIcon icon={['fas', 'qrcode']} />
            <p className="desktopOnly flexCenter"><FormattedMessage id="app.scanQrcode" defaultMessage='scanQrcode'/></p>
          </Col>
          <Col
            className="paymentButton"
            style={{ backgroundColor: payment === 'truemoney' && '#ebf5f7' }}
            onClick={() => handleSelect('truemoney')}
          >
            <FontAwesomeIcon icon={['fas', 'wallet']} />
            <p className="desktopOnly flexCenter"><FormattedMessage id="app.truemoneywallet" defaultMessage='truemoneywallet'/></p>
          </Col>
          <Col
            className="paymentButton"
            style={{ backgroundColor: payment === 'line' && '#ebf5f7' }}
            onClick={() => handleSelect('line')}
          >
            <FontAwesomeIcon icon={['fab', 'line']} />
            <p className="desktopOnly flexCenter"><FormattedMessage id="app.rabbitlinepay" defaultMessage='rabbitlinepay'/></p>
          </Col>
        </Col>
        <Col span={24}>
          {payment === 'credit' && (
            <OmiseCard
              orderTarget={orderTarget}
              price={price}
              eventId={eventId}
              orderId={orderId}
              packageId={packageId}
              returnUri={returnUri}
              refetchQueries={refetchQueries}
            />
          )}
          {payment === 'qrcode' && (
            <ScbQr
              orderTarget={orderTarget}
              price={price}
              eventId={eventId}
              orderId={orderId}
              packageId={packageId}
            />
          )}
          {payment === 'truemoney' && (
            <OmiseTruemoney
              orderTarget={orderTarget}
              price={price}
              eventId={eventId}
              packageId={packageId}
              orderId={orderId}
              returnUri={returnUri}
            />
          )}
          {payment === 'line' && (
            <Linepay
              orderTarget={orderTarget}
              price={price}
              eventId={eventId}
              orderId={orderId}
              packageId={packageId}
            />
          )}
        </Col>
      </Row>
    </S.PaymentsWrapper>
  )
})

export default Payments
