import React from 'react'
import Gallery from 'react-photo-gallery'
import _get from 'lodash/get'

import Thumbnail from '~/components/Thumbnail'
import { useStores } from '~/contexts'

const ResponsiveGallery = ({
  onClick, photos, ...props
}) => {
  const { cartStore: cart } = useStores()

  const handleClick = (e, { photo }) => {

    const photoId = _get(photo, 'photo._id')
    const photoInCart = cart.photoInCart(photoId)
    if (e === 'select') {
      if (photoInCart) cart.removeProduct(photoId)
      if (!photoInCart) cart.addProduct({ ...photo, sizeLabel: 'small' })
    }
    if (e === 'preview') {
      onClick(photo)
    }
  }

  const columns = () => {
    if (window.screen.width < 375) return 1
    if (window.screen.width < 576) return 2
    if (window.screen.width < 1200) return 3
    return 5
  }

  const galleryItems = photos.map((photo) => ({
    photo,
    src: _get(photo, 'view.thumbnail.url'),
    width: _get(photo, 'view.thumbnail.size.width'),
    height: _get(photo, 'view.thumbnail.size.height'),
  }))

  return (
    <Gallery
      {...props}
      photos={galleryItems}
      renderImage={(props) => <Thumbnail {...props} />}
      onClick={handleClick}
      direction="column"
      columns={columns}
    />
  )
}

export default ResponsiveGallery
