// import React from 'react'
import { observer } from 'mobx-react-lite'
// import { Redirect } from 'react-router-dom';
import _get from 'lodash/get'

// this page for testing redirect please don't edit //

const LineRedirect = observer(({ match }) => {

    const eventId = _get(match, 'params.eventId')
    const liffUrl = `line://app//${process.env.REACT_APP_LIFF_ID}?eventId=${eventId}`; 

    return (
        window.location.href = liffUrl
    )
});

export default LineRedirect;