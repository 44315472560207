import React from 'react'
import compose from 'recompose/compose'
import { inject, observer } from 'mobx-react'
// import { Radio } from 'antd'
import { BlueBorderSelect } from '~/styles'

// const RadioButton = Radio.Button
// const RadioGroup = Radio.Group

const LocaleSwitcher = compose(
  inject('i18n'),
  observer
)(({ i18n }) => (
  // <RadioGroup defaultValue={i18n.locale}>
  //   {['en', 'th'].map((l, i) => (
  //     <RadioButton value={l} onClick={() => i18n.setLocale(l)} key={l}>
  //       {l.toUpperCase()}
  //     </RadioButton>
  //   ))}
  // </RadioGroup>
  <BlueBorderSelect
    defaultValue={i18n.locale}
    bordered={false}
    onChange={(locale) => i18n.setLocale(locale)}
    dropdownMatchSelectWidth={false}
    // filterOption={true}
  // suffixIcon={<FontAwesomeIcon icon={['fas', 'chevron-down']} />}
  >
    {['en', 'th'].map((l, i) => (
      <BlueBorderSelect.Option style={{borderRadius: "20px", transform: 'translateY(-1px)', height: '30px'}} key={l} value={l} onClick={() => i18n.setLocale(l)}>
        {/* {(l === 'en' ? countryToFlag('us') : countryToFlag(l))+l.toUpperCase()} */}
        {/* <img src={'/images/'+l+'.svg'} alt={l} style={{ transform: 'translateY(-1px)', height: '30px' }} /> */}
        <span>{l.toUpperCase()}</span>
      </BlueBorderSelect.Option>
    ))}
  </BlueBorderSelect>
))

export default LocaleSwitcher
