import React from 'react'
import _get from 'lodash/get'
import { observer } from 'mobx-react-lite'
import { Button } from 'antd'

import { useStores } from '~/contexts'

const AddToCart = observer(({ photo }) => {
  const { cartStore: cart } = useStores()
  const photoId = _get(photo, '_id')
  const photoInCart = cart.photoInCart(photoId)
  const handleClick = () => {
    if (photoInCart) cart.removeProduct(photoId)
    if (!photoInCart) cart.addProduct({ photo, sizeLabel: 'small' })
  }
  return (
    <Button
      type={photoInCart ? 'danger' : 'primary'}
      style={{ width: '100%' }}
      icon="shopping-cart"
      onClick={handleClick}
    >
      {photoInCart ? 'ลบออกจากรถเข็น' : 'เพิ่มลงรถเข็น'}
    </Button>
  )
})

export default AddToCart
