import React from 'react'
import { observer } from 'mobx-react-lite'
import { FormattedMessage } from 'react-intl'
import { useQuery } from '@apollo/client'
import _get from 'lodash/get'
import {
  Button,
  Col,
  Row,
} from 'antd'

import { useStores } from '~/contexts'
import * as S from '~styles'
import Payments from '~/components/Payments'
import orderWithConfirmKeyQuery from '~/graphql/queries/orderWithConfirmKey.gql'
import OrderStatus from '~/components/OrderStatus'

const BuyPhotos = observer(({ match }) => {
  const { routerStore: { location } } = useStores()
  const { query: { confirmKey, payment = 'credit' } } = location
  const orderId = _get(match, 'params.orderId')
  const { loading, error, data } = useQuery(orderWithConfirmKeyQuery, {
    variables: {
      orderId,
      confirmKey,
    },
  })

  if (loading) return 'Loading...'
  if (error) return 'Error...'

  const orderUrl = `${window.location.origin}/orders/${orderId}?confirmKey=${confirmKey}&payment=${payment}`
  const {
    // amount,
    payAmount,
    status,
    products,
    photos,
  } = _get(data, 'orderWithConfirm')
  const isCompleted = status === 'PAYMENT_COMPLETED'
  const orderItems = []

  products.forEach((product) => {
    const item = {
      ...product,
      ...photos.find((p) => p._id === product._id),
    }
    orderItems.push(item)
  })

  return (
    <S.Container>
      <Row type="flex" justify="center" gutter={[30, 30]}>
        {!isCompleted && (
          <Col span={24}>
            <S.PageHeader>
              <h1><FormattedMessage id="app.payment" /></h1>
            </S.PageHeader>
          </Col>
        )}
        <Col xs={{ order: 2, span: 24 }} md={{ order: 1, span: 12 }}>
          <S.EventCard
            title={<FormattedMessage id="app.orderListSummary" />}
            extra={<OrderStatus status={status} />}
            actions={[(
              <div
                className="flex spaceBetween"
                style={{ padding: '0 24px', fontSize: '1rem', fontWeight: 'bold' }}
              >
                <p><FormattedMessage id="app.total" /> {orderItems.length} <FormattedMessage id="app.images" /></p>
                <p>{payAmount} <FormattedMessage id="app.baht" /></p>
              </div>
            )]}
          >
            <S.EventPayment>
              <div style={{ overflowY: 'auto', maxHeight: 642 }}>
                {orderItems.map((item, index) => (
                  <Row type="flex" key={_get(item, '_id')}>
                    <Col span={12}>
                      <img src={_get(item, 'view.thumbnail.url')} alt={index} style={{ width: '100%' }} />
                    </Col>
                    <Col span={12}>
                      <div className="flexCenter">
                        <Button
                          disabled={!isCompleted}
                          icon="download"
                          href={item.extra ? `${_get(item, 'downloadUrl')}&extra=${item.extra}` : _get(item, 'downloadUrl')}
                          style={{ fontSize: '0.9rem' }}
                        >
                          <FormattedMessage id="app.download" defaultMessage="Download photo" tagName="span" />
                        </Button>
                      </div>
                    </Col>
                    <div style={{ height: 15, width: '100%' }} />
                  </Row>
                ))}
              </div>
            </S.EventPayment>
          </S.EventCard>
        </Col>
        {!isCompleted && (
          <Col xs={{ order: 1, span: 24 }} md={{ order: 2, span: 12 }}>
            <Payments
              orderTarget="photo"
              payment={payment}
              price={payAmount}
              orderId={orderId}
              returnUri={orderUrl}
              refetchQueries={[{ query: orderWithConfirmKeyQuery, variables: { orderId, confirmKey } }]}
            />
          </Col>
        )}
      </Row>
    </S.Container>
  )
})

export default BuyPhotos
