import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import _get from 'lodash/get'
import { useMutation } from '@apollo/client'
import { useIntl } from 'react-intl'
import QRCode from 'qrcode'
import {
  Button,
  Col,
  Row,
  message,
  Typography,
} from 'antd'

import * as S from '~/styles'
import purchaseEventCreditsMutation from '~/graphql/mutations/purchaseEventCredits.gql'
import payOrderMutation from '~/graphql/mutations/payOrder.gql'

const ScbQr = observer(({
  orderTarget,
  eventId,
  orderId,
  price,
  packageId,
}) => {
  const intl = useIntl()
  const [qrCode, setQrCode] = useState(null)
  const [purchaseEventCredits] = useMutation(purchaseEventCreditsMutation)
  const [payOrder] = useMutation(payOrderMutation)

  const handleCreditCheckout = async () => {
    try {
      const { data } = await purchaseEventCredits({
        variables: {
          eventId,
          packageId,
          method: 'scb_qr',
          params: {},
        },
      })
      const source = _get(data, 'purchaseEventCredits.data.scb_qr.source')
      if (source) {
        const qrCode = await QRCode.toDataURL(source, { scale: 8 })
        setQrCode(qrCode)
      }
    } catch (error) {
      message.error(error.message, 5)
    }
  }

  const handlePhotoCheckout = async () => {
    try {
      const { data } = await payOrder({
        variables: {
          orderId,
          method: 'scb_qr',
          params: {},
        },
      })
      const source = _get(data, 'payOrder.data.scb_qr.source')
      if (source) {
        const qrCode = await QRCode.toDataURL(source, { scale: 8 })
        setQrCode(qrCode)
      }
    } catch (error) {
      message.error(error.message, 5)
    }
  }

  const handleReload = () => {
    window.scrollTo(0, 0)
    window.location.reload()
  }

  const button = (
    !qrCode ? (
      <Button
        type="primary"
        onClick={
          orderTarget === 'credit'
            ? handleCreditCheckout
            : orderTarget === 'photo'
              ? handlePhotoCheckout
              : null
        }
      >
        {`ชำระ ${price.toLocaleString()} บาท`}
      </Button>
    ) : (
      <Button
        type="primary"
        onClick={handleReload}
      >
        {intl.formatMessage({
          id: 'app.payment.method.scb_qr.reload',
          defaultMessage: 'Check status',
        })}
      </Button>
    )
  )

  return (
    <S.EventCard title="สแกนคิวอาร์โค้ด">
      <Row type="flex" justify="center" style={{ textAlign: 'center' }}>
        <Col span={24}>
          {!qrCode ? (
            <Typography.Paragraph style={{ whiteSpace: 'pre-line' }}>
              {intl.formatMessage({
                id: 'app.messages.payment.method.scb_qr',
                defaultMessage: 'Scan this QR Code with a mobile application of any Thai bank \n or save this QR Code and open in the application \n using the scan to pay function',
              })}
            </Typography.Paragraph>
          ) : (
            <img src={qrCode} alt="PromptPay QR" style={{ width: 250 }} />
          )}
        </Col>
        <Col span={24}>
          <div className="desktopOnly flexCenter">
            {button}
          </div>
          <div className="mobileOnly column">
            {button}
          </div>
        </Col>
      </Row>
    </S.EventCard>
  )
})

export default ScbQr
