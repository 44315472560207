import React, { useState } from 'react'
import { compose } from 'recompose'
import { useMutation, useQuery } from '@apollo/client'
import { useIntl, FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import _get from 'lodash/get'
import moment from 'moment'
import {
  Row,
  Form,
  Input,
  Col,
  DatePicker,
  // Upload,
  Button,
  Select,
  message,
  Radio,
  Switch,
} from 'antd'
import {
  SaveOutlined,
  LoadingOutlined,
  // UploadOutlined,
} from '@ant-design/icons'

import { useStores } from '~/contexts'
import eventTypeOption from '~/assets/jsons/eventType.json'
import * as S from '~/styles'
import addEventMutation from '~/graphql/mutations/createEvent.gql'
import startUploadMutation from '~/graphql/mutations/startUpload.gql'
import adminUserByUsernameQuery from '~/graphql/queries/adminUserByUsername.gql'
import supportedBankList from '~/assets/jsons/supportedBanks.json'
import CloudImageUploader from '~/components/CloudImageUploader'
import FrameImageInput from '../components/FrameImageInput'

const { Option } = Select

// const acceptCoverExtension = '.jpg,.jpeg,.png'
// const fileData = (url = '') => {
//   const matches = url.match(/^data:(.+);base64,(.*)$/)
//   if (!matches) {
//     return null
//   }
//   const [, mimeType, base64data] = matches
//   return {
//     mimeType,
//     base64data,
//   }
// }
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 7 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 17 },
  },
}

const EventCreate = ({
  form: {
    getFieldDecorator, validateFields, setFieldsValue, getFieldValue, resetFields,
  },
}) => {
  const [submitLoading, setSubmitLoading] = useState(false)
  const {
    userStore: user,
    routerStore: { history },
  } = useStores()
  const { loading, error, data: adminUser } = useQuery(adminUserByUsernameQuery, {
    variables: {
      username: user.username,
    },
    fetchPolicy: 'network-only',
  })
  const [addEvent] = useMutation(addEventMutation)
  const [startUpload] = useMutation(startUploadMutation)
  const intl = useIntl()
  // const fileReader = new FileReader()

  if (loading) return 'Loading...'
  if (error) return 'Error...'

  const currentAdminUserPhone = _get(adminUser, 'adminUserByUsername.profile.phone')
  const eventFrameEnabled = getFieldValue('photoSpecialFrame') ?? false

  // const beforeUploadCover = (file) => {
  //   const isTypeValid = ['image/jpeg', 'image/png'].includes(file.type)
  //   if (!isTypeValid) {
  //     message.error(`Please only upload ${acceptCoverExtension} file`, 5)
  //   } else if (file.size > 500000) {
  //     message.error('(File size must not exceed 500 kB)', 5)
  //   } else {
  //     fileReader.onload = () => {
  //       file.thumbUrl = fileReader.result
  //       setFieldsValue({
  //         coverFile: file,
  //       })
  //     }
  //     fileReader.readAsDataURL(file)
  //   }
  //   return false
  // }

  const handleRangeDateChange = () => {
    // const d = getFieldValue('rangeDate')
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()
    validateFields(async (err, values) => {
      if (!err) {
        try {
          setSubmitLoading(true)
          const {
            title,
            location,
            eventType,
            frame,
            photoDownloadMode,
            photoSpecialFrame,
            uploadNotice,
            // coverFile,
            coverUrl,
            rangeDate,
            creditTargets,
            registrationStartsAt,
            registrationEndsAt,
            bankAccount,
            contactPhone,
          } = values
          // const coverUrl = _get(coverFile, 'thumbUrl')
          const [startDate, endDate] = rangeDate
          const keywordsRaw = _get(values, 'keywords', '').split(',')
          const keywords = keywordsRaw.map((sy) => sy.trim())
          keywords.push(title)
          const { data: { newEvent: { record: { _id, title: eventTitle } } } } = await addEvent({
            variables: {
              media: { ...frame },
              record: {
                title,
                keywords,
                location,
                eventType,
                photoDownloadMode,
                photoSpecialFrame: photoSpecialFrame ? 'event' : 'event_photographer',
                uploadNotice,
                creditTargets,
                contactPhone,
                coverUrl,
                bankAccount: {
                  brand: bankAccount?.brand,
                  number: bankAccount?.number,
                  name: bankAccount?.accountName,
                  passbookImageUrl: bankAccount?.passbookImageUrl,
                },
                startDate: moment(startDate).startOf('day').toISOString(),
                endDate: moment(endDate).endOf('day').toISOString(),
                approved: true,
                photoTagFaces: true,
                photoTagText: true,
                hidden: false,
                registrationStartsAt: registrationStartsAt
                  && registrationStartsAt
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .millisecond(0)
                    .toISOString(),
                registrationEndsAt: registrationEndsAt
                  && registrationEndsAt
                    .hour(23)
                    .minute(59)
                    .second(59)
                    .millisecond(999)
                    .toISOString(),
              },
              // cover: fileData(coverUrl),
            },
          })
          await startUpload({
            variables: {
              eventId: _id,
              photographerId: user._id,
              photographerName: user.username,
              approved: true,
              joined: 'joined',
              photoLimit: 0,
            },
          })
          message.info(`Event "${eventTitle}" created`, 5)
          resetFields()
          setSubmitLoading(false)
          history.push(`/events/${_id}/edit`)
        } catch (error) {
          setSubmitLoading(false)
          message.error(error.message)
          // error.graphQLErrors.forEach((error) => message.error(intl.formatMessage({
          //   id: `api.errors.${error.message}`,
          //   defaultMessage: 'Error',
          // }), 5),
          // )
        }
      }
    })
  }

  // const coverFile = getFieldValue('coverFile')
  // const coverFileProps = {
  //   name: 'coverFile',
  //   action: '',
  //   accept: acceptCoverExtension,
  //   beforeUpload: beforeUploadCover,
  //   fileList: coverFile ? [coverFile] : [],
  //   showUploadList: false,
  // }
  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '40px',
  }
  return (
    <S.Container fluid>
      <Row type="flex" align="middle" gutter={[0, 30]}>
        <Col span={24}>
          <S.PageHeader>
            <h1><FormattedMessage id="app.createEvent" defaultMessage="Create Event" /></h1>
            <Link to="/events">
              <Button type="primary"><FormattedMessage id="app.backToEventPage" defaultMessage="Back to event page" /></Button>
            </Link>
          </S.PageHeader>
        </Col>
        <Col span={24}>
          <S.EventCard
            title={<FormattedMessage id="app.eventDetail" defaultMessage="Event Detail" />}
            extra={
              submitLoading ? (
                <span style={{fontWeight: 400}}><LoadingOutlined style={{marginRight: 2}}/><FormattedMessage id="app.createEvent" defaultMessage="Create Event" /></span>
              ) : (
                <div aria-hidden="true" onClick={handleFormSubmit}>
                  <span style={{fontWeight: 400}}><SaveOutlined style={{marginRight: 2}}/><FormattedMessage id="app.createEvent" defaultMessage="Create Event" /></span>
                </div>
              )
            }
          >
            <S.FormWrapper>
              <Form {...formItemLayout}>
                <Form.Item label={<FormattedMessage id="app.title" defaultMessage="Title" />}>
                  {getFieldDecorator('title', {
                    rules: [{
                      required: true,
                      message: 'This field is required',
                    }],
                  })(
                    <Input placeholder={
                      intl.formatMessage({ id: 'app.title', defaultMessage: 'Title' })
                    } />,
                  )}
                </Form.Item>
                <Form.Item label={<FormattedMessage id="app.location" defaultMessage="Location" />}>
                  {getFieldDecorator('location', {
                    rules: [{
                      required: true,
                      message: 'This field is required',
                    }],
                  })(
                    <Input placeholder={
                      intl.formatMessage({ id: 'app.building', defaultMessage: 'Building Name, Province, Other' })
                    } />,
                  )}
                </Form.Item>
                <Form.Item label={<FormattedMessage id="app.eventDate" defaultMessage="Event Date" />}>
                  {getFieldDecorator('rangeDate', {
                    rules: [{
                      required: true,
                      message: 'This field is required',
                    }],
                  })(
                    <DatePicker.RangePicker
                      format="Do-MMM-YYYY"
                      disabledDate={(current) => moment().add(-30, "days") > current}
                      placeholder={['dd/mm/yyyy', 'dd/mm/yyyy']}
                      style={{ width: '100%' }}
                      onChange={handleRangeDateChange()}
                    />,
                  )}
                </Form.Item>
                <Form.Item label={<FormattedMessage id="app.eventType" defaultMessage="Event Type" />}>
                  {getFieldDecorator('eventType', {
                    rules: [{
                      required: true,
                      message: 'This field is required',
                    }],
                  })(
                    <Select placeholder={
                      intl.formatMessage({ id: 'app.eventTypeOption', defaultMessage: 'Select Event Type' })
                    }>
                      {eventTypeOption.map((eventTypeOptionId) => (
                        eventTypeOptionId !== 'all'
                        && <Select.Option key={eventTypeOptionId} value={eventTypeOptionId}><FormattedMessage id={`app.${eventTypeOptionId}`} /></Select.Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
                {/* <Form.Item label="เครดิตอัปโหลด">
                    {getFieldDecorator('creditTargets', {
                      rules: [{
                        required: true,
                        message: 'This field is required',
                      }],
                    })(
                      <Select placeholder="เลือกเครดิตที่ใช้อัปโหลดภาพ">
                        <Select.Option value="event">กิจกรรม</Select.Option>
                        <Select.Option value="photographer">ช่างภาพ</Select.Option>
                      </Select>,
                    )}
                  </Form.Item> */}
                <Form.Item label={<FormattedMessage id="app.telephone" defaultMessage="Telephone" />}>
                  {getFieldDecorator('contactPhone', {
                    rules: [{ required: true, message: 
                      intl.formatMessage({ id: 'app.enterYourPhone', defaultMessage: 'Enter Your Phone' })
                     }],
                    initialValue: currentAdminUserPhone,
                  })(
                    <Input />
                  )}
                </Form.Item>
                <Form.Item label={<FormattedMessage id="app.details.photographer" defaultMessage="Details inform photographer" />}>
                  {getFieldDecorator('uploadNotice')(
                    <Input.TextArea placeholder={intl.formatMessage({ id: 'app.usernameValidationMessage2', defaultMessage: 'Please input only English or Thai text less than 400 characters' })} maxLength={400} style={{ height: 150 }} />,
                  )}
                </Form.Item>
                <Form.Item label={<FormattedMessage id="app.coverUrl" defaultMessage="coverUrl" />}>
                  {getFieldDecorator('coverUrl', {
                    rules: [{
                      required: true,
                    }],
                  })(
                    <CloudImageUploader path="events/cover" provider="gs" accept="image/*" resize={{ maxWidth: 1200, mode: 'contain' }} />,
                  )}
                </Form.Item>
                <Form.Item label={<FormattedMessage id="app.enableEventFrame" defaultMessage="Enable Event Frame" />}>
                  {getFieldDecorator('photoSpecialFrame', {
                    valuePropName: 'checked',
                  })(
                    <Switch />
                  )}
                </Form.Item>
                {eventFrameEnabled && <Form.Item label={<FormattedMessage id="app.eventFrame" defaultMessage="Event Frame" />}>
                  {getFieldDecorator('frame', {
                    rules: [{
                      required: true,
                      message: 'This field is required',
                    }]
                  })(
                    <FrameImageInput />
                  )}
                </Form.Item>}
                <Form.Item label="รูปแบบการรับภาพ">
                  {getFieldDecorator('photoDownloadMode', {
                    rules: [{
                      required: true,
                      message: 'This field is required',
                    }],
                  })(
                    <Radio.Group>
                      <Radio style={radioStyle} value="priced">
                        <b><FormattedMessage id="app.sales" defaultMessage="Priced" /></b><FormattedMessage id="app.sales.service" />
                      </Radio>
                      <Radio style={radioStyle} value="free">
                        <b><FormattedMessage id="app.free" defaultMessage="Free" /></b><FormattedMessage id="app.free.service" />
                      </Radio>
                    </Radio.Group>,
                  )}
                </Form.Item>
                {getFieldValue('photoDownloadMode') === 'priced' && (
                  <>
                    <Form.Item label={<FormattedMessage id="app.selectBank" defaultMessage="Select Bank" />}>
                      {getFieldDecorator('bankAccount.brand', {
                        initialValue: '',
                        rules: [{
                          required: true,
                          message: intl.formatMessage({ id: 'app.pleaseSelectBank'}),
                        }],
                      })(
                        <Select>
                          <Option key="" value="">
                            <FormattedMessage id="app.selectBank" defaultMessage="Select Bank" />
                          </Option>
                          {supportedBankList.map((bankId) => (
                            <Option key={bankId} value={bankId}>
                              <FormattedMessage id={`app.${bankId}`} />
                            </Option>
                          ))}
                        </Select>,
                      )}
                    </Form.Item>
                    <Form.Item
                      label={intl.formatMessage({ id: 'app.accountNumber', defaultMessage: 'Account Number' })}
                    >
                      {getFieldDecorator('bankAccount.number', {
                        initialValue: '',
                        rules: [{
                          pattern: /[0-9-]/,
                          required: true,
                          len: 10,
                          message: intl.formatMessage({ id: 'app.usernameValidationMessage3'}),
                        }],
                      })(
                        <Input placeholder={intl.formatMessage({ id: 'app.accountNumber', defaultMessage: 'Account Number' })} />,
                      )}
                    </Form.Item>
                    <Form.Item
                      label={intl.formatMessage({ id: 'app.accountName', defaultMessage: 'Account Name' })}
                    >
                      {getFieldDecorator('bankAccount.accountName', {
                        initialValue: '',
                        rules: [{
                          required: true,
                          message: intl.formatMessage({ id: 'app.usernameValidationMessage4'}),
                        }],
                      })(
                        <Input placeholder={intl.formatMessage({ id: 'app.accountName', defaultMessage: 'Account Name' })} />,
                      )}
                    </Form.Item>
                    <Form.Item
                      label={intl.formatMessage({ id: 'app.passbookImageUrl', defaultMessage: 'Bank Account Book' })}
                    >
                      {getFieldDecorator('bankAccount.passbookImageUrl', {
                        rules: [{
                          required: true,
                          message: intl.formatMessage({ id: 'app.PleaseUploadPankbookImageUrl'}),
                        }],
                      })(
                        <CloudImageUploader provider="gs" path="passbooks" />,
                      )}
                    </Form.Item>
                  </>
                )}
                <Form.Item wrapperCol={{
                  xs: { span: 24 },
                  sm: { span: 17, offset: 7 },
                }} >
                  <Button loading={submitLoading} type="primary" icon="blank" onClick={handleFormSubmit}><FormattedMessage id="app.createEvent" defaultMessage="Create Event" /></Button>
                </Form.Item>
              </Form>
            </S.FormWrapper>
          </S.EventCard>
        </Col>
      </Row>
    </S.Container>
  )
}

export default compose(
  Form.create(),
)(EventCreate)
