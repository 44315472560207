import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { PhotoSwipe } from 'react-photoswipe'
import _get from 'lodash/get'
import {
  Row, Col, Button, Icon, Modal, Radio,
} from 'antd'
import { graphql } from '@apollo/client/react/hoc'
import { gql } from '@apollo/client'
import { client } from '~/services/apollo'
import { FormattedMessage } from 'react-intl'

import AddToCart from '~/components/AddToCart'
import PhotoDownload from '~/components/PhotoDownload'
import reportPhotoMutation from '~/graphql/mutations/reportPhoto.gql'
import withI18n from '~/hocs/withI18n'

@graphql(reportPhotoMutation, { name: 'reportPhoto' })
class PhotoView extends Component {
  state = {
    captionEl: null,
  }
  reportSelectRef = React.createRef()
  containerRef = React.createRef()
  handleClose = () => {
    // this.props.onClose()
    this.props.onPhotoChange && this.props.onPhotoChange(null)
  }
  handleAfterChange = (pswp) => {
    // pswp.framework.bind( pswp.scrollWrap, "touchend", function(e) {
    //   e.preventDefault()
    //   const myInterval = setInterval(pswp => {
    //     clearInterval(myInterval);

    //   }, 1000);
    // });

    // $('.pswp__img').on('click touchstart', function() {
    //   alert("Click");
    // })

    this.props.onPhotoChange && this.props.onPhotoChange(pswp.currItem.photo)
  }
  handleReportPhoto = (photo) => {
    // console.log({ photo })
    const modal = Modal.confirm({
      width: 300,
      title: withI18n(<FormattedMessage id="app.reportPhoto" />),
      icon: <Icon type="warning" />,
      content:
        withI18n(<Radio.Group ref={this.reportSelectRef}>
          {['low_quality', 'inappropriate', 'irrelevant'].map((reason) => (
            <Button
              key={reason}
              style={{
                display: 'block', textAlign: 'left', width: '100%', marginTop: 8,
              }}
            >
              <Radio key={reason} value={reason} style={{ display: 'block' }}>
                <FormattedMessage id={`app.photo.reported.${reason}`} />
              </Radio>
            </Button>
          ),
          )}
                 </Radio.Group>),
      onOk: async () => {
        const reason = this.reportSelectRef.current.state.value
        await this.props.reportPhoto({
          variables: {
            _id: photo._id,
            reason,
          },
        })
        this.forceUpdate()
        modal.destroy()
      },
    })
  }
  renderActions = (photo) => {
    if (photo && this.state.captionEl) {
      const extras = []
      const event = client.readFragment({
        id: `Event:${photo?.eventId}`,
        fragment: gql`#graphql
          fragment PhotoSizes on Event {
            photoFrame {
              _id
              mode
            }
          }
        `
      })
      // const photoSize = event?.photoSizes?.find(item => item._id === photo.purchasedSizeLabel)
      if (event?.photoFrame?.mode === 'event') {
        extras.push('frame')
      }
      const downloadMode = _get(photo, 'downloadMode', false)
      const $actions = (
        <Row type="flex" align="middle" style={{ color: 'white' }}>
          <Col xs={24} md={12} style={{ padding: 6 }} className="action">
            {downloadMode === 'free'
              ? <PhotoDownload photo={photo} extras={extras} />
              : <AddToCart photo={photo} afterAdd={this.handleClose} />}
          </Col>
          {/* <Col xs={24} md={12} style={{ textAlign: 'center', padding: 6 }}>
            {photo.reported
              ? (
                <Button disabled style={{ width: '100%' }}>
                  <FormattedMessage id="app.reportedAs" defaultValue="Photo was reported" />
                  <FormattedMessage id={`app.photo.reported.${photo.reported}`} defaultValue="" />
                </Button>
              )
              : <Button style={{ width: '100%' }} onClick={() => this.handleReportPhoto(photo)}><Icon type="warning" /><FormattedMessage id="app.reportPhoto" /></Button>}
          </Col> */}
        </Row>
      )
      return ReactDOM.createPortal($actions, this.state.captionEl)
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    // console.log(this.props.photoId, nextProps.photoId)
    const photoChange = this.props.photoId !== nextProps.photoId
    const captionChange = this.state.captionEl !== nextState.captionEl
    return photoChange || captionChange
  }
  
  render() {
    // console.log('photoview render')
    const { photos, photoId, onClose } = this.props
    const photoIndex = photos.findIndex((p) => p._id === photoId)
    const photo = photos[photoIndex]
    const isOpen = !!photo
    const index = photoIndex >= 0 ? photoIndex : 0
    // console.log({ photo })
    const photoSwipeItems = photos.map((photo) => ({
      photo,
      src: _get(photo, 'view.preview.url'),
      w: _get(photo, 'view.preview.size.width'),
      h: _get(photo, 'view.preview.size.height'),
    }))

    const restest = (e) => {
      console.log(e)
    }

    return (
      <div ref={this.containerRef}>
        <PhotoSwipe
          isOpen={isOpen}
          items={photoSwipeItems}
         gettingData={restest}
        // close={this.closePhotoSwipe}
          options={{
            barsSize: { top: 44, bottom: 44 },
            index,
            history: false,
            captionEl: true,
            closeElClasses: ['item', 'zoom-wrap', 'ui', 'top-bar'],
            tapToToggleControls: false,
            getDoubleTapZoom: (isMouseClick, item) => {
              if(!isMouseClick) {
                window.location.href = item.src;
              }
            },
            isClickableElement: (el) => el.classList && el.classList.contains('action'),
            addCaptionHTMLFn: (item, captionEl, isFake) => {
              item.title = true
              this.captionEl = captionEl
              // console.log('setState', captionEl, this.state.captionEl)
              if (!this.state.captionEl) {
                // console.log('setState')
                this.setState({
                  captionEl,
                })
              }
              return true
            },
            getThumbBoundsFn: (index) => {
              const img = this.props.getImgRef && this.props.getImgRef(index)
              const pageYScroll = window.pageYOffset || document.documentElement.scrollTop
              if (img) {
                const rect = img.getBoundingClientRect()
                return { x: rect.left, y: rect.top + pageYScroll, w: rect.width }
              }
            },
            // indexIndicatorSep: ' * ',
            // shareEl: true,
            // shareButtons: [
            //   { id: 'facebook', label: 'Share on Facebook', url: 'https://www.facebook.com/sharer/sharer.php?u={{url}}' },
            //   { id: 'twitter', label: 'Tweet', url: 'https://twitter.com/intent/tweet?text={{text}}&url={{url}}' },
            //   { id: 'pinterest', label: 'Pin it', url: 'http://www.pinterest.com/pin/create/button/?url={{url}}&media={{image_url}}&description={{text}}' },
            //   { id: 'download', label: 'Download image', url: '{{raw_image_url}}', download: true }
            // ],
            closeEl: true,
            arrowEl: true,
            shareEl: false,
          }}
          onClose={onClose || this.handleClose}
        // imageLoadComplete={this.handleSwipeImage}
          afterChange={this.handleAfterChange}
        />
        {this.renderActions(photo)}
      </div>
    )
  }
}

export default PhotoView

// import React from 'react'
// import { PhotoSwipe } from 'react-photoswipe'
// import _get from 'lodash/get'

// const PhotoView = ({
//   photos, photoId, onClose, onPhotoChange,
// }) => {
//   const photoIndex = photos.findIndex((p) => p._id === photoId)
//   const photo = photos[photoIndex]
//   const isOpen = !!photo
//   const index = photoIndex >= 0 ? photoIndex : 0

//   const handleClose = () => {
//     onPhotoChange(null)
//   }
//   const photoSwipeItems = photos.map((photo) => ({
//     photo,
//     src: _get(photo, 'view.preview.url'),
//     w: _get(photo, 'view.preview.size.width'),
//     h: _get(photo, 'view.preview.size.height'),
//   }))
//   return (
//     <PhotoSwipe
//       isOpen={isOpen}
//       items={photoSwipeItems}
//       options={{ index, shareEl: false }}
//       onClose={onClose || handleClose}
//     />
//   )
// }

// export default PhotoView

