import path from 'path'
import url from 'url'

import React, { useState } from 'react'
import { Spin, Upload, message } from 'antd'
import { useMutation } from '@apollo/client'

import loadImage from 'blueimp-load-image'
import axios from 'axios'
import _get from 'lodash/get'
import { useIntl } from 'react-intl'

import cloudUploadMutation from '~/graphql/mutations/cloudUpload.gql'

const resizeImg = async (file, options = {}, quality = 1) => {
  const { width, height, fixWidth, fixHeight, orientationFrame ,...resizeOptions } = options
  const { image, originalWidth, originalHeight } = await loadImage(file, {
    ...resizeOptions,
    orientation: true,
    canvas: true,
  })
  // if ((fixWidth && fixWidth !== originalWidth) || (fixHeight && fixHeight !== originalHeight)) {
  //   throw new Error('INVALID_PHOTO_DIMENSION')
  // }
  let canvas

  if(orientationFrame === 'vertical'){
    if (image.width >= image.height) {
      throw new Error('INVALID_PHOTO_DIMENSION')
    }
  }

  if(orientationFrame === 'horizontal'){
    if (image.height >= image.width) {
      throw new Error('INVALID_PHOTO_DIMENSION')
    }
  }

  if (width && height) {
    canvas = document.createElement('CANVAS')
    canvas.width = width ?? resizeOptions.maxWidth ?? originalWidth
    canvas.height = height ?? resizeOptions.maxHeight ?? originalHeight
    canvas.getContext('2d').drawImage(image, 0, 0)
  } else {
    canvas = image
    // canvas = document.createElement('CANVAS')
    // canvas.width = 1600
    // canvas.height = 1600
    // canvas.getContext('2d').drawImage(image, 1200, 50,300,300)
  }
  console.log({ canvas })
  return new Promise((resolve) => canvas.toBlob(resolve, file.type, quality))
}

const CloudImageUploader = React.forwardRef(
  (
    {
      children,
      onChange,
      value,
      fileMiddleware,
      fixsize = {},
      resize = {},
      afterResize,
      provider = 'gs',
      path: fullPath,
      fileName,
      accept,
      isTemporary,
      getFileName,
      orientationFrame
    },
    ref
  ) => {
    const intl = useIntl()
    const [loading, setLoading] = useState(false)
    const [upload] = useMutation(cloudUploadMutation)
    const {
      mode, // contain / cover
      ...resizeOptions
    } = resize
    if (mode && !['contain', 'cover'].includes(mode)) {
      throw new Error('RESIZE_MODE_UNSUPPORTED')
    }
    const options = {
      ...resizeOptions,
      contain: mode === 'contain',
      cover: mode === 'cover',
      fixWidth: fixsize.width,
      fixHeight: fixsize.height,
      orientationFrame
    }
    const processFile = async (file) => {
      const _blob = await resizeImg(file, options).catch((error) => {
        return message.error(intl.formatMessage({ id: `api.errors.${error.message}`, defaultMessage: 'Error' }))
      })
      const blob = afterResize ? afterResize(_blob) : _blob
      const response = await upload({
        variables: {
          provider,
          path: fullPath,
          mimeType: file.type,
          fileName,
          isTemporary,
        },
      })
      const { uploadUrl, downloadUrl } = _get(response, 'data.upload', {})
      if (uploadUrl) {
        await axios.put(uploadUrl, blob, {
          headers: {
            'Content-Type': file.type,
            ...(provider === 'gs' && {
              'x-goog-acl': 'public-read',
            }),
          },
        })
        onChange(downloadUrl)
      }
    }

    const customRequest = async ({ file }) => {
      setLoading(true)
      if (fileMiddleware) {
        return fileMiddleware(file, processFile)
      }
      await processFile(file)
      setLoading(false)
    }
    const onRemove = () => {
      onChange()
    }
    const handleFileUpload = (info) => {
      getFileName(info.file.name)
    }
    const fileList = value
      ? [
          {
            uid: value,
            name: path.basename(url.parse(value).pathname),
            status: 'done',
            url: value,
            thumbUrl: value,
          },
        ]
      : []
    return (
      <Upload ref={ref} accept={accept} customRequest={customRequest} fileList={fileList} showUploadList={false} onRemove={onRemove} onChange={handleFileUpload}>
        {loading ? <Spin /> : children}
      </Upload>
    )
  }
)

export default CloudImageUploader
