import React from 'react'
import { Col, Row } from 'antd'
import { FormattedMessage } from 'react-intl'

import * as S from '~/styles'

const ManualEvent = () => (

  <S.Container>
  <Row type="flex" gutter={[30, 30]}>
    <Col span={24}>
      <S.PageHeader>
        <h1><FormattedMessage id="app.instructionManual" defaultMessage="Instruction Manual" /></h1>
      </S.PageHeader>
    </Col>
    <Col xs={24} md={12}>
      <S.EventCard title={<FormattedMessage id="app.organizers.admin"/>}>
        <ul style={{ fontSize: '1rem', listStyleType: 'decimal' }}>
          <li>
          <FormattedMessage id="app.announcements"/>
            <ul style={{ listStyleType: 'decimal' }}>
              <li><FormattedMessage id="app.title"/></li>
              <li><FormattedMessage id="app.eventDate"/></li>
              <li><FormattedMessage id="app.location"/></li>
              <li><FormattedMessage id="app.photographerNumber"/></li>
            </ul>
          </li>
          <li><FormattedMessage id="app.photoSize"/></li>
          <li><FormattedMessage id="app.coverLogo"/></li>
          <li><FormattedMessage id="app.QRCodeFile"/></li>
          <li><FormattedMessage id="app.notify.photographer"/></li>
        </ul>
      </S.EventCard>
    </Col>
    <Col xs={24} md={12}>
      <S.EventCard title={<FormattedMessage id="app.photographer"/>}>
        <div style={{ fontSize: '1rem' }}>
          <p style={{ fontWeight: 'bold' }}><FormattedMessage id="app.stepsToUse"/></p>
          <ul style={{ listStyleType: 'decimal' }}>
            <li><FormattedMessage id="app.photographerLogin"/></li>
            <li><FormattedMessage id="app.selectTitleEvent"/></li>
          </ul>
          <p style={{ fontWeight: 'bold' }}><FormattedMessage id="app.stepsToUpload"/></p>
          <ul style={{ listStyleType: 'decimal' }}>
            <li><FormattedMessage id="app.fileImage"/></li>
            <li><FormattedMessage id="app.sizeImage"/></li>
            <li><FormattedMessage id="app.setCamera"/></li>
          </ul>
        </div>
      </S.EventCard>
    </Col>
  </Row>
</S.Container>
 )

 


export default ManualEvent
