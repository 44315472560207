import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { compose } from 'recompose'
import { Link } from 'react-router-dom'
import { useQuery, useMutation, useApolloClient } from '@apollo/client'
import { useIntl, FormattedMessage } from 'react-intl'
import _get from 'lodash/get'
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  message,
  Modal,
  Table,
  Popover,
  Radio,
} from 'antd'
import {
  LoadingOutlined,
  ExclamationCircleOutlined,
  CopyOutlined,
  MailOutlined,
} from '@ant-design/icons'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import * as S from '~/styles'
import { useStores } from '~/contexts'
import eventQuery from '~/graphql/queries/event.gql'
import uploadsQuery from '~/graphql/queries/uploads.gql'
import adminUserByEmailQuery from '~/graphql/queries/adminUserByEmail.gql'
import startUploadMutation from '~/graphql/mutations/startUpload.gql'
import registerAdminUserMutation from '~/graphql/mutations/registerAdminUser.gql'
import updateUploadsJoinedMutation from '~/graphql/mutations/updateUploadsJoined.gql'
import cancelUploadMutation from '~/graphql/mutations/cancelUpload.gql'
import PageNotAuthorized from '~/pages/PageNotAuthorized'
import orderItemsQuery from '~/graphql/queries/orderItems.gql'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 7 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 17 },
  },
}

const EventPhotographers = compose(
  Form.create(),
  observer,
)(({
  form: { getFieldDecorator, validateFields, resetFields },
  match,
}) => {
  const [submitLoading, setSubmitLoading] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedIds, setSelectedIds] = useState([])
  const { userStore: user } = useStores()
  const { routerStore: { location } } = useStores()
  const { query: { join: joined } } = location
  const intl = useIntl()
  const client = useApolloClient()

  const { error, loading, data } = useQuery(eventQuery, {
    variables: {
      _id: match.params.eventId,
    },
    fetchPolicy: 'network-only',
  })
  const { error: uploadsError, loading: uploadsLoading, data: uploadsData } = useQuery(uploadsQuery, {
    variables: {
      eventId: match.params.eventId,
      joined,
    },
    fetchPolicy: 'network-only',
  })
  const { error: orderItemsError, loading: orderItemsLoading, data: orderItemsData } = useQuery(orderItemsQuery, {
    variables: {
      eventId: match.params.eventId,
      orderStatus: 'PAYMENT_COMPLETED',
    },
    fetchPolicy: 'network-only',
  })
  const [registerAdminUser] = useMutation(registerAdminUserMutation)
  const [startUpload] = useMutation(startUploadMutation, {
    refetchQueries: [
      {
        query: eventQuery,
        variables: { _id: match.params.eventId },
      },
      {
        query: uploadsQuery,
        variables: { eventId: match.params.eventId },
      },
    ],
  })
  const [updateUploadsJoined] = useMutation(updateUploadsJoinedMutation, {
    refetchQueries: [{
      query: uploadsQuery,
      variables: { eventId: match.params.eventId },
    }],
  })
  const [cancelUpload] = useMutation(cancelUploadMutation, {
    refetchQueries: [{
      query: uploadsQuery,
      variables: { eventId: match.params.eventId },
    }],
  })

  if (loading || orderItemsLoading) return 'Loading...'
  if (error || uploadsError || orderItemsError) return 'Error...'
  const { event } = data
  if (!event.isMine && !event.isEditable) return <PageNotAuthorized />
  const uploads = _get(uploadsData, 'uploads', [])
  const eventCredit = _get(event, 'createdByUser.creditBalance.total', 0)
  const photoDownloadMode = _get(event, 'photoDownloadMode')
  const creditsAvailable = photoDownloadMode === 'priced' ? undefined : eventCredit
  // const creditsAvailable = photoDownloadMode === 'priced' ? eventCredit : undefined // temporary
  const orderItems = _get(orderItemsData, 'orderItems.items', [])

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleStartUpload = async (data) => {
    const {
      eventId,
      adminUserId,
      photographerName,
      approvalMessage,
      approved,
      photoLimit,
    } = data
    try {
      await startUpload({
        variables: {
          eventId,
          photographerId: adminUserId,
          photographerName,
          approvalMessage,
          approved,
          photoLimit: parseInt(photoLimit),
          photoSizes: [
            'small',
            'medium',
            'large',
            'xlarge',
            'xxlarge',
          ],
        },
      })
      setSubmitLoading(false)
      resetFields()
      showModal()
    } catch (error) {
      error.graphQLErrors.forEach((error) => message.error(intl.formatMessage({
        id: `api.errors.${error.message}`,
        defaultMessage: 'Error',
      }), 5),
      )
      setSubmitLoading(false)
    }
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()
    validateFields(async (err, values) => {
      if (!err) {
        setSubmitLoading(true)
        const {
          photographerEmail,
          photographerName,
          // defaultPassword,
          role,
        } = values
        const uploadsEmail = uploads.find((data) => data?.photographer?.profile?.email === photographerEmail)
        try {
          if (uploadsEmail) {
            message.error(`อีเมล "${photographerEmail}" มีอยู่ในคำเชิญแล้ว`, 5)
            setSubmitLoading(false)
          } else {
            const { data: { adminUserByEmail } } = await client.query({
              query: adminUserByEmailQuery,
              fetchPolicy: 'network-only',
              variables: {
                email: photographerEmail.trim(),
              },
            })
            if (!adminUserByEmail) {
              const { data: { registerAdminUser: { record: { _id: newAdminUserId } } } } = await registerAdminUser({
                variables: {
                  record: {
                    username: photographerEmail,
                    password: (Math.random() + 1).toString(36).substring(7),
                    displayName: photographerName,
                    role,
                    profile: {
                      email: photographerEmail,
                    },
                  },
                },
              })
              handleStartUpload({ ...values, adminUserId: newAdminUserId })
            }
            if (adminUserByEmail) {
              handleStartUpload({ ...values, adminUserId: adminUserByEmail._id })
            }
          }
        } catch (error) {
          error.graphQLErrors.forEach((error) => message.error(intl.formatMessage({
            id: `api.errors.${error.message}`,
            defaultMessage: 'Error',
          }), 5),
          )
          setSubmitLoading(false)
        }
      }
    })
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleFilter = (status) => {
    const { query } = location
    status === 'all' ? delete (query.join) : query.join = status
    location.query = query
  }

  const handleUpdateUploadsJoined = async (join) => {
    try {
      await updateUploadsJoined({
        variables: {
          _ids: selectedIds,
          joined: join,
        },
      })
      setSelectedIds([])
    } catch (error) {
      message.error(error.message, 5)
      // error.graphQLErrors.forEach((error) => message.error(intl.formatMessage({
      //   id: `api.errors.${error.message}`,
      //   defaultMessage: 'Error',
      // })),
      // )
    }
  }

  const handleCancelUpload = async (photographerId) => {
    try {
      await cancelUpload({
        variables: {
          _id: photographerId,
        },
      })
    } catch (error) {
      message.error(error.message, 5)
    }
  }

  const confirmSetJoined = (join, photographerId) => {
    if (join === 'joined') {
      Modal.confirm({
        title: intl.formatMessage({ id: 'app.confirmSetJoined'}),
        icon: <ExclamationCircleOutlined />,
        content: `${intl.formatMessage({ id: 'app.photographerNumberSelected'})} ${(selectedIds).length} ${intl.formatMessage({ id: 'app.people'})}`,
        onOk() {
          handleUpdateUploadsJoined(join)
        },
      })
    }
    if (join === 'canceled') {
      Modal.confirm({
        title: intl.formatMessage({ id: 'app.confirmSetCanceled'}),
        icon: <ExclamationCircleOutlined />,
        content: `${intl.formatMessage({ id: 'app.photographerNumberSelected'})} ${(selectedIds).length} ${intl.formatMessage({ id: 'app.people'})}`,
        okType: 'danger',
        onOk() {
          handleUpdateUploadsJoined(join)
        },
      })
    }
    if (join === 'rejected') {
      Modal.confirm({
        title: intl.formatMessage({ id: 'app.confirmSetRejected'}),
        icon: <ExclamationCircleOutlined />,
        content: '',
        okType: 'danger',
        onOk() {
          handleCancelUpload(photographerId)
          message.success(intl.formatMessage({ id: 'app.confirmRejectedSuccessful'}), 5)
        },
      })
    }
  }

  getFieldDecorator('eventId', {
    initialValue: match.params.eventId,
  })
  getFieldDecorator('role', {
    initialValue: 'organizer',
  })
  // getFieldDecorator('defaultPassword', {
  //   initialValue: process.env.DEFAULT_PASSWORD,
  // })
  getFieldDecorator('photoLimit', {
    initialValue: '0',
  })
  getFieldDecorator('approved', {
    initialValue: true,
  })

  const content = (
    <div style={{ display: 'flex', alignItems: 'center', padding: 10 }}>
      <p style={{ height: 'auto', margin: '0px 10px 0px 0px', fontWeight: 600 }}><FormattedMessage id="app.linkUpload" /></p>
      <p style={{ height: 'auto', margin: '0px 10px 0px 0px' }}>{`${window.location.origin}/events/${event._id}/upload`}</p>
      <CopyToClipboard text={`${window.location.origin}/events/${event._id}/upload`}>
        <Button type="link" style={{ padding: 0 }} onClick={() => { message.success(intl.formatMessage({ id: 'app.copyLinkSuccessfully'}), 5) }}>
          <CopyOutlined /><FormattedMessage id="app.copyLink" />
        </Button>
      </CopyToClipboard>
    </div>
  )

  const columns = [
    {
      title: intl.formatMessage({ id: 'app.photographers'}),
      dataIndex: 'photographerName',
      key: 'photographerName',
      fixed: 'left',
      // ...(window.screen.width < 576 && { width: 200 }),
      render: (value) => <span>{value}</span>,
      // sorter: (a, b) => a.photographerName.length - b.photographerName.length,
    },
    {
      title: intl.formatMessage({ id: 'app.contactInfo'}),
      key: 'email',
      render: (value, record) => <span>{_get(record, 'photographer.profile.email')}</span>,
    },
    {
      title: intl.formatMessage({ id: 'app.upload.photo'}),
      dataIndex: 'photoCount',
      key: 'photoCount',
      render: (value) => <span>{value}</span>,
    },
    {
      title: intl.formatMessage({ id: 'app.priced'}),
      key: 'orderItems',
      render: (value, record) => {
        const photographerId = _get(record, 'photographer._id')
        const displayName = _get(record, 'photographer.displayName')
        const orderCount = orderItems.filter((e) => e.photographerId === photographerId).length
        return <Link to={`/events/${match.params.eventId}/manage?view=list&photographer=${displayName}`}><u>{orderCount}</u></Link>
      },
    },
    {
      title: intl.formatMessage({ id: 'app.status'}),
      dataIndex: 'joined',
      key: 'joined',
      render: (value, record) => {
        if (value === 'waiting') {
          return (
            <Popover placement="bottomRight" title={null} content={content} trigger="click">
              <S.TextButton>
                <FormattedMessage id={`app.eventUpload.${value}`} />
              </S.TextButton>
            </Popover>
          )
        }
        if (value === 'rejected') {
          return (
            <S.TextButton
              color="secondary"
              onClick={() => { confirmSetJoined('rejected', _get(record, '_id')) }}
            >
              <FormattedMessage id={`app.eventUpload.${value}`} />
            </S.TextButton>
          )
        }
        return (
          <FormattedMessage id={`app.eventUpload.${record.photographer.profile.email === user.username ? 'owner' : value}`} />
        )
      },
    },
  ]

  const rowSelection = {
    onChange: (selectedRows) => {
      setSelectedIds(selectedRows)
      // console.log('selectedRows: ', selectedRows)
    },
    getCheckboxProps: (record) => ({
      disabled: record.joined === 'waiting' || record.joined === 'rejected' || record.photographer.profile.email === user.username, // Column configuration not to be checked
      // name: record.joined,
    }),
    selectedRowKeys: selectedIds,
  }

  return (
    <S.Container fluid>
      <Row type="flex" align="middle" gutter={[0, 30]}>
        <Col span={24}>
          <S.PageHeader>
            <h1>{event.title}</h1>
            <Link to={`/events/${match.params.eventId}/manage`}>
              <Button type="primary"><FormattedMessage id="app.backToEventPage" defaultMessage="Back to event page" /></Button>
            </Link>
          </S.PageHeader>
        </Col>
        <Col span={24}>
          <S.EventCard
            title={<FormattedMessage id="app.sendInvitation" />}
            extra={
              submitLoading ? (
                <span style={{fontWeight: 400}}><LoadingOutlined style={{marginRight: 2}}/><FormattedMessage id="app.send" /></span>
              ) : (
                <div aria-hidden="true" onClick={handleFormSubmit}>
                  <span style={{fontWeight: 400}}><MailOutlined style={{marginRight: 2}}/><FormattedMessage id="app.send" /></span>
                </div>
              )
            }
          >
            <S.FormWrapper>
              <Form {...formItemLayout}>
                <Form.Item label={<FormattedMessage id="app.photographerEmail" />}>
                  {getFieldDecorator('photographerEmail', {
                    rules: [{
                      required: true,
                      message: 'This field is required',
                    }],
                  })(
                    <Input
                      placeholder="example@mail.com"
                    // onChange={(e) => { setPhotographerEmail((e.target.value).trim()) }}
                    />,
                  )}
                </Form.Item>
                <Form.Item label={<FormattedMessage id="app.photographerName" />}>
                  {getFieldDecorator('photographerName', {
                    rules: [{
                      required: true,
                      message: 'This field is required',
                    }],
                  })(
                    <Input placeholder={intl.formatMessage({ id: 'app.photographerNameMessage'})} />,
                  )}
                </Form.Item>
                <Form.Item label={<FormattedMessage id="app.photographerNameMessage1" />}>
                  {getFieldDecorator('approvalMessage', {
                  })(
                    <Input.TextArea placeholder={intl.formatMessage({ id: 'app.photographerNameMessage2'})}  maxLength={300} style={{ height: 100 }} />,
                  )}
                </Form.Item>
                <Form.Item label={<FormattedMessage id="app.totalCreditsOwner" />}>
                  <p>
                    {/* {creditsAvailable || 'ไม่จำกัด'} */}
                    {!creditsAvailable ? 'ไม่จำกัด' : creditsAvailable}
                  </p>
                </Form.Item>
              </Form>
            </S.FormWrapper>
          </S.EventCard>
        </Col>
      </Row>
      <Row type="flex" align="middle" gutter={[0, 10]} style={{ marginTop: 30 }}>
        <Col span={24} style={{ fontSize: '1rem' }}>
          <p style={{ fontWeight: 500, fontSize: '1.1rem' }}>
            <FormattedMessage id="app.allPhotographers" />
          </p>
          <Radio.Group value={joined || 'all'}>
            <Radio.Button value="all" onClick={() => { handleFilter('all') }}>
              <FormattedMessage id="app.showAllPhotographers" />
            </Radio.Button>
            <Radio.Button value="joined" onClick={() => { handleFilter('joined') }}>
              <FormattedMessage id="app.protographer.joined" /> {event.uploads.filter((item) => item.joined === 'joined').length}
            </Radio.Button>
            <Radio.Button value="canceled" onClick={() => { handleFilter('canceled') }}>
              <FormattedMessage id="app.protographer.canceled" /> {event.uploads.filter((item) => item.joined === 'canceled').length}
            </Radio.Button>
            <Radio.Button value="waiting" onClick={() => { handleFilter('waiting') }}>
              <FormattedMessage id="app.protographer.waiting" /> {event.uploads.filter((item) => item.joined === 'waiting').length}
            </Radio.Button>
            <Radio.Button value="rejected" onClick={() => { handleFilter('rejected') }}>
              <FormattedMessage id="app.protographer.rejected" /> {event.uploads.filter((item) => item.joined === 'rejected').length}
            </Radio.Button>
          </Radio.Group>
          {/* <S.TextButton onClick={() => { handleFilter('all') }}>
            แสดงช่างภาพทั้งหมด
          </S.TextButton>
          <span style={{ marginRight: 10 }}>|</span>
          <S.TextButton onClick={() => { handleFilter('joined') }}>
            ได้สิทธิ์ในการอัปโหลดภาพ {event.uploads.filter((item) => item.joined === 'joined').length}
          </S.TextButton>
          <span style={{ marginRight: 10 }}>|</span>
          <S.TextButton onClick={() => { handleFilter('canceled') }}>
            ช่างภาพที่โดนยกเลิกสิทธิ์ {event.uploads.filter((item) => item.joined === 'canceled').length}
          </S.TextButton>
          <span style={{ marginRight: 10 }}>|</span>
          <S.TextButton onClick={() => { handleFilter('waiting') }}>
            ช่างภาพที่ยังไม่รับคำเชิญ {event.uploads.filter((item) => item.joined === 'waiting').length}
          </S.TextButton>
          <span style={{ marginRight: 10 }}>|</span>
          <S.TextButton onClick={() => { handleFilter('rejected') }}>
            ช่างภาพที่ปฎิเสธการเข้าร่วมงาน {event.uploads.filter((item) => item.joined === 'rejected').length}
          </S.TextButton> */}
        </Col>
        <Col span={24} className="desktopOnly">
          <Button
            type="danger"
            onClick={() => { confirmSetJoined('canceled') }}
            disabled={selectedIds.length === 0}
            style={{ marginRight: 15 }}
          ><FormattedMessage id="app.eventUpload.canceled" />
          </Button>
          <Button
            type="primary"
            disabled={selectedIds.length === 0}
            onClick={() => { confirmSetJoined('joined') }}
            style={{ marginRight: 15 }}
          ><FormattedMessage id="app.eventUpload.joined" />
          </Button>
        </Col>
        <Col span={24} className="mobileOnly" style={{ flexWrap: 'wrap', gap: 5 }}>
          <Button
            type="danger"
            onClick={() => { confirmSetJoined('canceled') }}
            disabled={selectedIds.length === 0}
            style={{ width: '100%' }}
          ><FormattedMessage id="app.eventUpload.canceled" />
          </Button>
          <Button
            type="primary"
            disabled={selectedIds.length === 0}
            onClick={() => { confirmSetJoined('joined') }}
            style={{ width: '100%' }}
          ><FormattedMessage id="app.eventUpload.joined" />
          </Button>
        </Col>
        <Modal visible={isModalVisible} onOk={handleOk} onCancel={handleOk} footer={null}>
          <p style={{
            fontSize: '1.2rem', fontWeight: 'bold', textAlign: 'center', marginTop: 50,
          }}
          ><FormattedMessage id="app.sendInvitationSuccessful" />
          </p>
          <Button
            type="primary"
            onClick={handleOk}
            style={{
              fontSize: '1rem', height: 'auto', margin: '30px auto', padding: '5px 15px', display: 'flex',
            }}
          ><FormattedMessage id="app.beInformed" />
          </Button>
        </Modal>
        <Col span={24}>
          <Table
            rowKey={(uploads) => uploads._id}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={uploads}
            loading={uploadsLoading}
            scroll={{ x: 1200 }}
          />
        </Col>
      </Row>
    </S.Container>
  )
})

export default EventPhotographers
