import React from 'react'
import { compose } from 'recompose'
import { useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'
import moment from 'moment'
import _get from 'lodash/get'
import { 
  Col, 
  Row, 
  Table,
  Form,
  Input,
  DatePicker,
  Select,
  Button,
} from 'antd'

import {FormattedMessage } from 'react-intl'
import { useStores } from '~/contexts'
import * as S from '~/styles'
import orderItemsQuery from '~/graphql/queries/orderItems.gql'
// import findOrderItemsQuery from '~/graphql/queries/findOrderItems.gql'

const { Option } = Select
const { RangePicker } = DatePicker

const paymentMethodInput = [
  {_id: 0, method: 'all', name: 'All Payment Methods'},
  {_id: 1, method: 'omise_card', name: 'Credit/Debit Card'},
  {_id: 2, method: 'omise_truemoney', name: 'Truemoney'},
  {_id: 3, method: 'omise_netbank', name: 'Netbank'},
  {_id: 4, method: 'linepay_web', name: 'Linepay'},
  {_id: 5, method: 'scb_qr', name: 'QR Code'}
  // {_id: 6, method:'OMISE', name: 'OMISE', input:'OMISE'},
]

const paymentMethodMap = {
  all: '',
  omise_card: 'เลขบัตร 4 ตัวสุดท้าย',
  omise_truemoney: 'เบอร์โทรศัพท์',
  omise_netbank: '',
  linepay_web: 'transaction id',
  scb_qr: 'reference 2'
}

const Orders = ({
  form: { getFieldDecorator, getFieldsValue, getFieldValue },
  match
}) => {
  const { routerStore: { location } } = useStores()
  const { 
    p: page, 
    // s: search, 
    m: method, 
    sv: searchValue, 
    from: startDate, 
    to: endDate 
  } = location.query
  const dateSearch = [
    ...(startDate ? [{ startDate: moment(startDate).toISOString() }] : []),
    ...(endDate ? [{ endDate: moment(endDate).toISOString() }] : [])
  ]
  const { error: orderItemsError, loading: orderItemsLoading, data: orderItemsData } = useQuery(orderItemsQuery, {
    variables: {
      orderStatus: 'PAYMENT_COMPLETED',
      page: parseInt(page, 10) || 1,
      eventId: match.params.eventId,
      ...(dateSearch.length > 0 && dateSearch[0]),
      ...(dateSearch.length > 0 && dateSearch[1]),
      method,
      searchValue,
      perPage: 100
    },
    fetchPolicy: 'network-only',
  })
  // const { error: findOrderItemsError, loading: findOrderItemsLoading, data: findOrderItemsData } = useQuery(findOrderItemsQuery, {
  //   variables: {
  //     orderStatus: 'PAYMENT_COMPLETED',
  //     // clientEmail,
  //     // clientPhone,
  //     // eventId: match.params.eventId,
  //     // startDate: moment(startDate).toISOString(),
  //     // endDate: moment(endDate).endOf('day').toISOString(),
  //     // method,
  //     // searchValue
  //   },
  //   fetchPolicy: 'network-only',
  // })
  // if (orderItemsLoading) return 'Loading...'
  if (orderItemsError) return 'Error...'
  const orderItems = _get(orderItemsData, 'orderItems.items', [])
  // const findOrderItems = _get(findOrderItemsData, 'findOrderItems', [])

  const handleSearch = () => {
    const { p, s, ph, from, to, e, m, sv, ...query } = location.query
    const {
      date,
      // email,
      method,
      // phone,
      searchValue
    } = getFieldsValue()
    const [startDate, endDate] = date

    location.query = {
      // ...(email && { s: email }),
      // ...(phone && { ph: phone }),
      ...(date && date.length > 0 && {
        from: startDate.format(moment.HTML5_FMT.DATE),
        to: endDate.format(moment.HTML5_FMT.DATE)
      }),
      // ...(event && { e: event }),
      ...(method && method !== 'all' && method !== '' && {
        m: method
      }),
      ...(searchValue && {
        sv: searchValue
      }),
      ...query
    }
  }
  
  const columns = [
    {
      title: <FormattedMessage id="app.orderId" defaultMessage='orderId'/>,
      key: 'orderId',
      dataIndex: 'orderId',
      render: (value, record) => <a href={record.orderLink} target="_blank" rel="noopener noreferrer">{value}</a>
      ,
    },
    {
      title: <FormattedMessage id="app.method" defaultMessage='method'/>,
      key: 'method',
      dataIndex: 'method',
      render: (value) => <span>{value}</span>,
    },
    {
      title: <FormattedMessage id="app.amount" defaultMessage='amount'/>,
      key: 'amount',
      dataIndex: 'amount',
      render: (value) => <span>{value}</span>,
    },
    {
      title: <FormattedMessage id="app.systemCost" defaultMessage='systemCost'/>,
      key: 'cost',
      render: (value, record) => <span>{record.amount - record.organizerShare}</span>,
    },
    {
      title: <FormattedMessage id="app.myBalance" defaultMessage='myBalance'/>,
      key: 'organizerShare',
      dataIndex: 'organizerShare',
      render: (value) => <span>{value}</span>,
    },
    {
      title: <FormattedMessage id="app.orderCompletedAt" defaultMessage='orderCompletedAt'/>,
      key: 'orderCompletedAt',
      dataIndex: 'orderCompletedAt',
      render: (value, record) => <span>{moment(record.orderCompletedAt).format('Do MMM YYYY, HH:mm:ss')}</span>,
    },
    {
      title: <FormattedMessage id="app.photographerEmail" defaultMessage='photographerEmail'/>,
      key: 'photographerEmail',
      render: (value, record) => <span>{_get(record, 'photographer.profile.email')}</span>,
    },
    {
      title: <FormattedMessage id="app.fileName" defaultMessage='fileName'/>,
      key: 'fileName',
      render: (value, record) => <span>{_get(record, 'photo.fileName')}</span>,
    },
  ]

  return (
    <S.Container fluid>
      <Row type="flex" gutter={[0, 30]}>
        <Col span={24}>
          <S.PageHeader>
            <h1><FormattedMessage id="app.orders" defaultMessage='orders'/></h1>
            <Link to={`/events/${match.params.eventId}/manage`}>
              <Button type="primary"><FormattedMessage id="app.backToEventPage"/></Button>
            </Link>
          </S.PageHeader>
        </Col>
        <Col span={24}>
          <div style={{width: '100%', display: 'flex',  justifyContent: 'flex-end'}}>
            <Form>
              <Input.Group compact>
                {/* <Form.Item>
                  {getFieldDecorator('email', {
                    initialValue: search
                  })(
                    <Input
                      placeholder="Email"
                      style={{ width: 240 }}
                    />
                  )}
                </Form.Item> */}
                <Form.Item>
                  {getFieldDecorator('date', {
                    initialValue: startDate ? [moment(startDate), moment(endDate)] : []
                  })(
                    <RangePicker
                    />
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator('method', {
                    initialValue: method
                  })(
                    <Select showSearch placeholder="Payment Method" style={{ width: 180 }} >
                      {paymentMethodInput.map(item => <Option key={item._id} value={item.method}>{item.name}</Option>)}
                    </Select>
                  )}
                </Form.Item>
                {((getFieldValue('method') && getFieldValue('method') !== 'all' && getFieldValue('method') !== 'omise_netbank' && getFieldValue('method') !== '')) && (
                  <Form.Item>
                  {getFieldDecorator('searchValue', {
                    initialValue: searchValue
                  })(
                    <Input
                      placeholder={ _get(paymentMethodMap, getFieldValue('method')) }
                      style={{ width: 190 }}
                    />
                  )}
                  </Form.Item>
                )}
                <Form.Item>
                  <Button type="primary" icon="search" onClick={handleSearch}></Button>
                </Form.Item>
              </Input.Group>
            </Form>
          </div>
          <Table
            rowKey={rec => `${rec.orderId}:${rec.photoId}`}
            dataSource={orderItems}
            columns={columns}
            loading={orderItemsLoading}
            scroll={{ x: 1200 }}
            // pagination={{
            //   defaultPageSize: perPage,
            //   showQuickJumper: true,
            //   defaultCurrent: 1,
            //   current: currentPage,
            //   total: itemCount,
            //   showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            //   onChange: handlePageChange
            // }}
          />
        </Col>
      </Row>
    </S.Container>
  )
}

export default compose(
  Form.create(),
)(Orders)
