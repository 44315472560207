import React from 'react'
// import compose from 'recompose/compose'
import { observer } from 'mobx-react-lite'
import { ConfigProvider } from 'antd'
import en from 'antd/lib/locale-provider/en_US'
import th from 'antd/lib/locale-provider/th_TH'
import moment from 'moment'
import 'moment/locale/th'

import { IntlProvider } from 'react-intl'
// import enLocaleData from 'react-intl/locale-data/en'
// import thLocaleData from 'react-intl/locale-data/th'

import { useStores } from '~/contexts'
import enMessages from '~/i18n/en.json'
import thMessages from '~/i18n/th.json'

const locales = {
  en,
  th,
}

// addLocaleData(enLocaleData)
// addLocaleData(thLocaleData)

const messages = {
  en: enMessages,
  th: thMessages,
}
// console.log({ locales })
const ConnectedLocaleProvider = observer(({ children }) => {
  const { i18nStore: { locale } } = useStores()
  // console.log({ locale })
  moment.locale(locale)
  return (
    <ConfigProvider locale={locales[locale]}>
      <IntlProvider locale={locale} messages={messages[locale]}>
        {children}
      </IntlProvider>
    </ConfigProvider>
  )
})

export default ConnectedLocaleProvider
