import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import _get from 'lodash/get'
import { Button, Collapse } from "antd"
import { useQuery } from '@apollo/client'
import eventBySlugQuery from '~/graphql/queries/eventBySlug.gql'


const GetByPlatform = observer(({ match, history }) => {

  const [agreeConsent, setAgreeConsent]= useState(false);
  const { error, loading, data } = useQuery(eventBySlugQuery, {
    variables: {
      slug: match.params.eventSlug,
    },
    fetchPolicy: 'network-only',
  })
  if (loading) {
    return <div>Loading...</div>
  }

  const { Panel } = Collapse;


  const callback = (key) => {
    console.log(key);
  }

  const eventId = data?.eventBySlug?._id
  const eventSlug = _get(match, 'params.eventSlug')

  // const lineLiffOpen = `/l/${eventSlug}`
  const lineLiffUrl = `https://liff.line.me/${process.env.REACT_APP_LIFF_ID}?eventId=${eventId}`
  const whatsappUrl = `https://wa.me/${process.env.REACT_APP_WHATSAPP_PHONE}?text=Mango_${eventSlug}&app_absent=0`

  return (
    <>
    {!agreeConsent ? 
      <div style={{padding: '1em', display: 'flex', justifyContent: 'center', flexWrap: 'wrap', textAlign: 'center'}}>
        <Collapse defaultActiveKey={['1']} onChange={callback} style={{width: '100%'}}>
            <Panel header="Consent Agreement" key="1" style={{ fontSize: '1.3rem', wordBreak:'break-word'}}>
              <div style={{fontSize: '0.9rem', textAlign: 'left'}}>
              <h4 style={{fontSize: '1rem'}}>Introduction:</h4>
              <p>MangoMango.app is committed to ensuring the privacy and security of your personal information. Before proceeding, please read and understand the terms and conditions outlined below.</p>

              <h4 style={{fontSize: '1rem', marginTop:'10px'}}>Consent Process:</h4>
              <p style={{marginBottom:'5px'}}>
                1.The photographer will capture your photograph using a camera or mobile device.
              </p>
              <p style={{marginBottom:'5px'}}>
                2.The photographer will upload the captured photo to the MangoMango.app platform. QR Code Generation and Distribution:
              </p>
              <p style={{marginBottom:'5px'}}>
                3.MangoMango.app will generate a unique QR code associated with your photo and send it to the designated receiver through the Line application or WhatsApp.
              </p>
              <p style={{marginBottom:'5px'}}>
                4.Upon receiving the QR code, you, as the receiver, will submit a selfie photo through the MangoMango.app platform.
              </p>
              <p style={{marginBottom:'5px'}}>
                5.MangoMango.app will automatically match the submitted selfie with the initially captured photo using face recognition technology.
              </p>
              <p style={{marginBottom:'5px'}}>
                6.MangoMango.app will send the matched photo along with the result of the face recognition process to your Line chat or WhatsApp.
              </p>

              <h4 style={{fontSize: '1rem', marginTop:'10px'}}>Privacy and Security:</h4>
              <p style={{marginBottom:'5px'}}>
              MangoMango.app is committed to protecting the privacy and security of your personal information. Your photos and facial recognition data will be stored securely and will not be shared with any third parties without your explicit consent.
                The facial recognition data will be used solely for the purpose of matching your selfie with the photo capture.
                /
                By proceeding with the mangomango.app, you acknowledge that you have read and understood the terms of this consent form and agree to the use of face recognition technology as described.
              </p>
              </div>

              <Button onClick={() => setAgreeConsent(true)} style={{marginTop:'10px'}} type='primary'>Accept & Agree</Button>
            </Panel>
        </Collapse>
      </div>
    : 
      <div style={{ marginTop: '10rem', padding: '1em', display: 'flex', justifyContent: 'center', flexWrap: 'wrap', textAlign: 'center' }}>
     
        <h1 style={{ marginBottom: '2rem', fontSize: '2rem', width: '100%' }}>Select app to receive photos</h1>
        <Button onClick={() => window.location.href = lineLiffUrl} style={{ background: '#00B900', width: '70%', height: '3rem', display: 'grid', gridTemplateColumns: '20% 80%', gridTemplateRows: '100%' }}>
          <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
            <img style={{ width: "30px" }} src="/images/line-2.svg" />
          </div>
          <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <span style={{ color: '#fff', fontSize: '150%' }}>LINE</span>
          </div>
        </Button>
        <Button onClick={() => window.location.href = whatsappUrl} style={{ marginTop: '1rem', marginBottom: '10rem', background: '#FFFFFF', width: '70%', height: '3rem', display: 'grid', gridTemplateColumns: '20% 80%', gridTemplateRows: '100%' }}>
          <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
            <img style={{ width: "30px" }} src="/images/whatsapp-svgrepo-com.svg" />
          </div>
          <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <span style={{ color: '#25d366', fontSize: '150%' }}>WhatsApp</span>
          </div>
        </Button>
      </div>
    }
    </>
  )
})

export default GetByPlatform