import React, { useRef, useState } from 'react'

const allowedValue = '1234567890'

const OtpInput = ({ onChange, otpLength }) => {
  const [otpValues, setOtpValues] = useState(' '.repeat(otpLength - 1).split(' '))
  const otpFieldsRef = useRef([])
  const handleOnChange = (e, index) => {
    const value = e.target.value
    if (value.length > 1 || !allowedValue.includes(value)) {
      return
    }
    const newOtpValues = [...otpValues]
    newOtpValues[index] = value
    setOtpValues(newOtpValues)
    if (value.length === 1 && index < otpValues.length - 1) {
      otpFieldsRef.current[index + 1].focus()
    }
    onChange(newOtpValues.join(''))
  }
  const handleOnKeyDown = (e, index) => {
    if (e.key === 'Backspace') {
      if (otpValues[index] !== '') {
        const newOtpValues = [...otpValues]
        newOtpValues[index] = ''
        setOtpValues(newOtpValues)
      } else if (index > 0) {
        otpFieldsRef.current[index - 1].focus()
      }
    }
  }
  return (
    <div className='otp'>
      {otpValues.map((value, index) => (
        <input
          className='otpInput'
          pattern="[0-9]*"
          inputmode="numeric"
          key={index}
          onChange={(e) => handleOnChange(e, index)}
          ref={(ref) => {
            otpFieldsRef.current[index] = ref
          }}
          type='text'
          value={value}
          autoComplete='off'
          autoFocus={index === 0 ? true : false}
          maxLength='1'
          onKeyDown={(e) => handleOnKeyDown(e, index)}
          tabIndex={index}
        />
      ))}
      <style>
        {`
          .otp {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 15px;
            justify-content: center;
            margin: 18px 0 9px 0;
          }
          .otpInput {
            width: 50px;
            height: 60px;
            border: none;
            border-radius: 20px;
            background: rgba(17, 17, 17, 0.05);
            font-size: 32px;
            outline-color: #FDBE1A;
            text-align: center;
          }
          input.otpInput::-webkit-outer-spin-button,
          input.otpInput::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          input.otpInput[type=number] {
            -moz-appearance: textfield;
          }
          @media (max-width: 438px) {
            .otp {
              gap: 12px;
            }
            .otpInput {
              width: 45px;
              height: 54px;
              font-size: 29px;
            }
          }
          @media (max-width: 394px) {
            .otp {
              gap: 8px;
            }
            .otpInput {
              width: 40px;
              height: 48px;
              font-size: 26px;
            }
          }
        `}
      </style>
    </div>
  )
}

export default OtpInput
