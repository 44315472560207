import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
// import { useHistory } from 'react-router-dom';
import { Row, Col, Button  } from "antd";

const emailContract = 'mailto:hello@mangomango.app'
const FacebookUrl = 'https://www.facebook.com/mangomangoapp'


const MailtoRedirect = observer(({ match, history }) => {
    // const [agree, setAgree] = useState(false)
    // useEffect(() => {
    //     if(window) {
    //     // Open the mail client in a new tab
    //     const mailtoURL = 'mailto:hello@mangomango.app';
    //     window.location.href = mailtoURL;
    //     // const mailWindow = window.open(mailtoURL, '_blank');
    
    //     // Close the current page after a delay (adjust the delay as needed)
    //     const closeTimeout = setTimeout(() => {
    //       window.close();
    //     }, 1000);
    
    //     // Clean up the timeout to prevent memory leaks
    //     return () => clearTimeout(closeTimeout);
    //     }
    //   }, []);
    
    return (
        <>
            <div style={{marginTop:'10rem', padding:'1em', display:'flex', justifyContent: 'center', flexWrap: 'wrap', textAlign:'center'}}>
              <h1 style={{marginBottom:'2rem', fontSize:'2rem', width:'100%'}}>ช่องทางการติดต่อ</h1>
              <Button onClick={() => window.location.href = emailContract} style={{ background:'#000', width:'70%', height: '3rem', display: 'flex', justifyContent: 'space-around', flexDirection: 'row', alignItems:'center'}}>
                <img
                  style={{ width: "30px"}}
                  src="/images/email-2.svg"
                />
                <span style={{color:'#fff',fontSize:'150%'}}>ติดต่อเราทางอีเมลล์</span>
              </Button>
              
              <Button onClick={() => window.location.href = FacebookUrl} style={{marginTop:'1rem', marginBottom:'10rem', background:'#0866FF', width:'70%', height: '3rem', display: 'flex', justifyContent: 'space-around', flexDirection: 'row', alignItems:'center'}}>
                <img
                  style={{ width: "24px"}}
                  src="/images/fb.png"
                />
                <span style={{color:'#fff',fontSize:'150%'}}>ติดต่อเราทางเฟสบุ๊ค</span>
              </Button>
            </div>
        </>
    )
});

export default MailtoRedirect
  