import { Modal, Button, message } from 'antd'
import QRCode from 'qrcode'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { CopyOutlined } from '@ant-design/icons'
import React, { useEffect, useRef } from 'react'
import { useIntl,FormattedMessage } from 'react-intl'
import withI18n from '~/hocs/withI18n'
import qrFrame from '../assets/images/frame-QRcode-for-use.png'


const Frame = ({ record, lineUrl }) => {
  const imgRef = useRef(null)
  useEffect(() => {
    const drawQRCode = async () => {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      const width = 440
      const height = 500
      const title = `${record.title}`
      const titleWidth = ctx.measureText(title).width
      const footerText1 = 'ผู้ร่วมงานสามารถสแกน QR Code'
      const footerText2 = 'รับภาพได้ผ่านทาง LINE OA PIXMORY'
      const imageList = [qrFrame, await QRCode.toDataURL(`${lineUrl}`, { margin: 0 })]
      const loadImage = (image) =>
        new Promise((resolve, reject) => {
          const img = new Image()
          img.src = image
          img.onload = () => {
            resolve(img)
          }
        })

      setCanvas(canvas, width, height)

      const [qrFrameImage, qrCodeImage] = await Promise.all(imageList.map((image, index) => loadImage(image, index)))
      ctx.imageSmoothingEnabled = false
      ctx.drawImage(qrFrameImage, 0, 0, 440, 500)
      ctx.drawImage(qrCodeImage, (width - 210) / 2, 130, 210, 210)

      ctx.fillStyle = '#29abe2'
      ctx.font = titleWidth < 190 ? '1.8em Kanit' : '1.4em Kanit'
      ctx.textAlign = 'center'
      ctx.fillText(title, width / 2, 105)

      ctx.font = '1.8em Kanit'
      ctx.fillText(footerText1, width / 2, 375)
      ctx.fillText(footerText2, width / 2, 405)
      imgRef.current.src = canvas.toDataURL()
    }
    drawQRCode()
  }, [record._id, record.title, lineUrl])

  const setCanvas = (canvas, width, height) => {
    const ctx = canvas.getContext('2d')
    const scale = window.devicePixelRatio
    canvas.style.width = width + 'px'
    canvas.style.height = height + 'px'
    canvas.width = width * scale
    canvas.height = height * scale
    ctx.scale(scale, scale)
  }

  return <img ref={imgRef} width='440' height='500' alt='QRCode' />
}

const QrModal = (record, qrCodeRef) => {
  const intl = useIntl()
  const lineLiffUrl = `${process.env.REACT_APP_BASE_URI}/l/${record._id}`;

  return Modal.confirm({
    icon: null,
    title: withI18n(<FormattedMessage id='app.QRcodeForParticipant' />),
    content: (
      <div style={{ textAlign: 'center' }} ref={qrCodeRef}>
        <CopyToClipboard text={lineLiffUrl}>
          {/* <Button 
                onClick={() => { message.success('คัดลอกลิงก์เรียบร้อย', 5) }}
                style={{ marginBottom: 5 }}
              >
                คัดลอกลิงก์
              </Button> */}
          <div style={{ display: 'flex', textAlign: 'left', flexDirection: 'column' }}>
            <p>{lineLiffUrl}</p>
            <Button
              type='link'
              style={{ padding: 0, marginBottom: 10 }}
              onClick={() => {
                message.success(intl.formatMessage({ id: 'app.copyLinkSuccessfully'}), 5)
              }}
            >
              <CopyOutlined />
              {withI18n(<FormattedMessage id='app.copyLink' defaultMessage='Copy Link' />)}
            </Button>
          </div>
        </CopyToClipboard>
        <Frame record={record} lineUrl={lineLiffUrl}/>
      </div>
    ),
    okText: withI18n(<FormattedMessage id='app.download' />),
    cancelText: withI18n(<FormattedMessage id='app.close' />),
    onOk: () => {
      const downloadLink = document.createElement('a')
      downloadLink.href = qrCodeRef.current?.querySelector('img')?.src
      downloadLink.download = `${record.slug}.png`
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
      // return false
    },
    width: 500,
    style: { top: '4em' },
  })
}

export default QrModal
