import React, { useState, useRef } from 'react'
import { compose } from 'recompose'
import { observer } from 'mobx-react-lite'
import { useQuery, useMutation } from '@apollo/client'
import { Link } from 'react-router-dom'
import _get from 'lodash/get'
import moment from 'moment'
import { useIntl, FormattedMessage } from 'react-intl'
import {
  // Modal,
  Row,
  Form,
  Input,
  Col,
  DatePicker,
  Button,
  Select,
  message,
  Radio,
  Switch,
} from 'antd'
import {
  SaveOutlined,
  QrcodeOutlined,
  LoadingOutlined,
  CopyOutlined,
} from '@ant-design/icons'
// import QRCode from 'qrcode.react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { useStores } from '~/contexts'
import eventTypeOption from '~/assets/jsons/eventType.json'
import * as S from '~/styles'
import eventQuery from '~/graphql/queries/event.gql'
import updateEventMutation from '~/graphql/mutations/updateEvent.gql'
import CloudImageUploader from '~/components/CloudImageUploader'
import supportedBankList from '~/assets/jsons/supportedBanks.json'
import PageNotAuthorized from '~/pages/PageNotAuthorized'
import QrModal from '~/utils/QrModal'
import FrameImageInput from '../components/FrameImageInput'

const { Option } = Select

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 7 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 17 },
  },
}

const EventEdit = ({
  form: { getFieldDecorator, validateFields, getFieldValue },
  match,
}) => {
  const [submitLoading, setSubmitLoading] = useState(false)
  const { routerStore } = useStores()
  const qrCodeRef = useRef(null)
  const intl = useIntl()
  const { error, loading, data } = useQuery(eventQuery, {
    variables: {
      _id: match.params.eventId,
    },
  })
  const [updateEvent] = useMutation(updateEventMutation)
  if (loading) return 'Loading...'
  if (error) return 'Error...'
  const { event } = data
  const eventFrameEnabled = event?.photoSpecialFrame === 'event'
  const portraitPreviewUrl = event?.photoFrame?.portraitPreviewUrl
  const landscapePreviewUrl = event?.photoFrame?.landscapePreviewUrl
  if (!event.isMine && !event.isEditable) return <PageNotAuthorized />

  const handleRangeDateChange = () => {
    // const d = getFieldValue('rangeDate')
  }

  const showQrModal = (record) => QrModal(record, qrCodeRef)
    // setIsModalVisible(true)
    // setQrCodeSlug(record._id)
    // setQrCodeTitle(record.title)

  const handleFormSubmit = (e) => {
    e.preventDefault()
    validateFields(async (err, values) => {
      if (!err) {
        setSubmitLoading(true)
        const {
          _id,
          title,
          frame,
          record: {
            rangeDate,
            registrationStartsAt,
            registrationEndsAt,
            bankAccount,
            photoSpecialFrame,
            ...record
          },
        } = values
        const [startDate, endDate] = rangeDate
        const keywordsRaw = _get(values, 'keywords', '').split(',')
        const keywords = keywordsRaw.map((sy) => sy.trim())
        keywords.push(title)
        await updateEvent({
          variables: {
            _id,
            record: {
              ...record,
              bankAccount: {
                brand: bankAccount?.brand,
                number: bankAccount?.number,
                name: bankAccount?.accountName,
                passbookImageUrl: bankAccount?.passbookImageUrl,
              },
              photoSpecialFrame: photoSpecialFrame ? 'event' : 'event_photographer',
              startDate: moment(startDate).startOf('day').toISOString(),
              endDate: moment(endDate).endOf('day').toISOString(),
              registrationStartsAt: registrationStartsAt?.startOf('day').toISOString(),
              registrationEndsAt: registrationEndsAt?.endOf('day').toISOString(),
            },
            media: { ...frame },
          },
        }).then(({ data: { updateEvent: { record: { _id, title } } } }) => {
          message.info(`Event "${title}" updated`, 5)
          routerStore.push(`/events/${_id}/manage`)
          setSubmitLoading(false)
          }
        )
      }
    })
  }

  getFieldDecorator('_id', {
    initialValue: event._id,
  })

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '40px',
  }

  return (
    <S.Container fluid>
      <Row type="flex" align="middle" gutter={[0, 30]}>
        <Col span={24}>
          <S.PageHeader>
            <h1>{event.title}</h1>
            <Link to={`/events/${match.params.eventId}/manage`}>
              <Button type="primary"><FormattedMessage id="app.backToEventPage" defaultMessage="Back to event page" /></Button>
            </Link>
          </S.PageHeader>
        </Col>
        <Col span={24}>
          <S.EventCard title={<FormattedMessage id="app.aboutEvent" defaultMessage="aboutEvent" />}>
            <S.FormWrapper>
              <Form {...formItemLayout}>
                <Form.Item label={<FormattedMessage id="app.albumPreview"/>}>
                  <p style={{ display: 'inline', marginRight: 10, wordWrap: 'break-word' }}>{`${window.location.origin}/${event.slug}`}</p>
                  <CopyToClipboard text={`${window.location.origin}/${event.slug}`}>
                    <Button type="link" style={{ padding: 0 }} onClick={() => { message.success(intl.formatMessage({ id: 'app.copyLinkSuccessfully'}), 5) }}>
                      <CopyOutlined /><FormattedMessage id="app.copyLink" />
                    </Button>
                  </CopyToClipboard>
                </Form.Item>
                <Form.Item label={<FormattedMessage id="app.urlPhotographers"/>}>
                  <p style={{ display: 'inline', marginRight: 10, wordWrap: 'break-word' }}>{`${window.location.origin}/events/${event._id}/upload`}</p>
                  <CopyToClipboard text={`${window.location.origin}/events/${event._id}/upload`}>
                    <Button type="link" style={{ padding: 0 }} onClick={() => { message.success(intl.formatMessage({ id: 'app.copyLinkSuccessfully'}), 5) }}>
                      <CopyOutlined /><FormattedMessage id="app.copyLink" />
                    </Button>
                  </CopyToClipboard>
                </Form.Item>
                <Form.Item label={<FormattedMessage id="app.logInLine" />}>
                  <Button onClick={() => showQrModal(event)}><QrcodeOutlined /><FormattedMessage id="app.QRcode" /></Button>
                </Form.Item>
              </Form>
            </S.FormWrapper>
          </S.EventCard>
        </Col>
        <Col span={24}>
          <S.EventCard
            title={<FormattedMessage id="app.eventDetail" defaultMessage="eventDetail" />}
            extra={
              submitLoading ? (
                <span style={{fontWeight: 400}}><LoadingOutlined style={{marginRight: 2}}/><FormattedMessage id="app.editNote" defaultMessage="saveChanges" /></span>
              ) : (
                <div aria-hidden="true" onClick={handleFormSubmit}>
                  <span style={{fontWeight: 400}}><SaveOutlined style={{marginRight: 2}}/><FormattedMessage id="app.editNote" defaultMessage="saveChanges" /></span>
                </div>
              )
            }
          >
            <S.FormWrapper>
              <Form {...formItemLayout}>
                <Form.Item label={<FormattedMessage id="app.title" defaultMessage="Title" />}>
                  {getFieldDecorator('record.title', {
                    initialValue: event.title,
                    rules: [{
                      required: true,
                      message: 'This field is required',
                    }],
                  })(
                    <Input placeholder="example@mail.com" />,
                  )}
                </Form.Item>
                <Form.Item label={<FormattedMessage id="app.location" defaultMessage="Location" />}>
                  {getFieldDecorator('record.location', {
                    initialValue: event.location,
                    rules: [{
                      required: true,
                      message: 'This field is required',
                    }],
                  })(
                    <Input placeholder={
                      intl.formatMessage({ id: 'app.building', defaultMessage: 'Building Name, Province, Other' })
                    } />,
                  )}
                </Form.Item>
                <Form.Item label={<FormattedMessage id="app.eventDate" defaultMessage="Event Date" />}>
                  {getFieldDecorator('record.rangeDate', {
                    initialValue: [moment(event.startDate), moment(event.endDate)],
                    rules: [{
                      required: true,
                      message: 'This field is required',
                    }],
                  })(
                    <DatePicker.RangePicker
                      format="Do-MMM-YYYY"
                      placeholder={['dd/mm/yyyy', 'dd/mm/yyyy']}
                      style={{ width: '100%', marginTop: 5 }}
                      onChange={handleRangeDateChange()}
                    />,
                  )}
                </Form.Item>
                <Form.Item label={<FormattedMessage id="app.eventType" defaultMessage="Event Type" />}>
                  {getFieldDecorator('record.eventType', {
                    initialValue: event.eventType,
                    rules: [{
                      required: true,
                      message: 'This field is required',
                    }],
                  })(
                    <Select placeholder={
                      intl.formatMessage({ id: 'app.eventTypeOption', defaultMessage: 'Select Event Type' })
                    }>
                      {eventTypeOption.map((eventTypeOptionId) => (
                        eventTypeOptionId !== 'all'
                        && <Select.Option key={eventTypeOptionId} value={eventTypeOptionId}><FormattedMessage id={`app.${eventTypeOptionId}`} /></Select.Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
                {/* <Form.Item label="เครดิตอัปโหลด">
                    {getFieldDecorator('record.creditTargets', {
                      initialValue: event.creditTargets,
                      rules: [{
                        required: true,
                        message: 'This field is required',
                      }],
                    })(
                      <Select placeholder="เลือกเครดิตที่ใช้อัปโหลดภาพ">
                        <Select.Option value="event">กิจกรรม</Select.Option>
                        <Select.Option value="photographer">ช่างภาพ</Select.Option>
                      </Select>,
                    )}
                  </Form.Item> */}
                <Form.Item label={<FormattedMessage id="app.telephone" defaultMessage="Telephone" />}>
                  {getFieldDecorator('record.contactPhone', {
                    rules: [{ required: true, message: intl.formatMessage({ id: 'app.enterYourPhone', defaultMessage: 'Enter Your Phone' }) }],
                    initialValue: event.contactPhone,
                  })(
                    <Input />
                  )}
                </Form.Item>
                <Form.Item label={<FormattedMessage id="app.details.photographer" defaultMessage="Details inform photographer" />}>
                  {getFieldDecorator('record.uploadNotice', {
                    initialValue: event.uploadNotice,
                  })(
                    <Input.TextArea placeholder={intl.formatMessage({ id: 'app.usernameValidationMessage2', defaultMessage: 'Please input only English or Thai text less than 400 characters' })} maxLength={400} style={{ height: 100 }} />,
                  )}
                </Form.Item>
                <Form.Item label={<FormattedMessage id="app.coverUrl" defaultMessage="coverUrl" />}>
                  {getFieldDecorator('record.coverUrl', {
                    initialValue: event.coverUrl,
                    rules: [{
                      required: true,
                    }],
                  })(
                    <CloudImageUploader path="events/cover" provider="gs" accept="image/*" resize={{ maxWidth: 1200, mode: 'contain' }} />,
                  )}
                </Form.Item>
                <Form.Item label={<FormattedMessage id="app.enableEventFrame" defaultMessage="Enable Event Frame" />}>
                  {getFieldDecorator('record.photoSpecialFrame', {
                    valuePropName: 'checked',
                    initialValue: eventFrameEnabled,
                  })(
                    <Switch />
                  )}
                </Form.Item>
                {eventFrameEnabled && <Form.Item label={<FormattedMessage id="app.eventFrame" defaultMessage="Event Frame" />}>
                  {getFieldDecorator('frame')(
                    <FrameImageInput portraitPreviewUrl={portraitPreviewUrl} landscapePreviewUrl={landscapePreviewUrl} />
                  )}
                </Form.Item>}
                <Form.Item label={<FormattedMessage id="app.photoDownloadMode" defaultMessage="Photo DownloadMode" />}>
                  {getFieldDecorator('record.photoDownloadMode', {
                    initialValue: event.photoDownloadMode,
                    rules: [{
                      required: true,
                      message: 'This field is required',
                    }],
                  })(
                    <Radio.Group disabled>
                      <Radio style={radioStyle} value="priced"><span style={{ fontWeight: 600 }}><FormattedMessage id="app.event.priced" defaultMessage="Priced" /></span> <FormattedMessage id="app.sales.service" /> </Radio>
                      <Radio style={radioStyle} value="free"><b style={{ fontWeight: 600 }}><FormattedMessage id="app.event.free" defaultMessage="free" /></b> <FormattedMessage id="app.free.service" /> </Radio>
                    </Radio.Group>,
                  )}
                </Form.Item>
                {getFieldValue('record.photoDownloadMode') === 'priced' && (
                  <>
                    <Form.Item label={<FormattedMessage id="app.selectBank" defaultMessage="Select Bank" />}>
                      {getFieldDecorator('record.bankAccount.brand', {
                        initialValue: event.bankAccount.brand,
                        rules: [{
                          required: true,
                          message: 'This field is required',
                        }],
                      })(
                        <Select>
                          <Option key="" value="">
                            <FormattedMessage id="app.selectBank" defaultMessage="Select Bank" />
                          </Option>
                          {supportedBankList.map((bankId) => (
                            <Option key={bankId} value={bankId}>
                              <FormattedMessage id={`app.${bankId}`} />
                            </Option>
                          ))}
                        </Select>,
                      )}
                    </Form.Item>
                    <Form.Item
                      label={intl.formatMessage({ id: 'app.accountNumber', defaultMessage: 'Account Number' })}
                    >
                      {getFieldDecorator('record.bankAccount.number', {
                        initialValue: event.bankAccount.number,
                        rules: [{
                          required: true,
                          len: 10,
                          message: 'This field is required',
                        }],
                      })(
                        <Input placeholder={intl.formatMessage({ id: 'app.accountNumber', defaultMessage: 'Account Number' })} />,
                      )}
                    </Form.Item>
                    <Form.Item
                      label={intl.formatMessage({ id: 'app.accountName', defaultMessage: 'Account Name' })}
                    >
                      {getFieldDecorator('record.bankAccount.name', {
                        initialValue: event.bankAccount.name,
                        rules: [{
                          required: true,
                          message: 'This field is required',
                        }],
                      })(
                        <Input placeholder={intl.formatMessage({ id: 'app.accountName', defaultMessage: 'Account Name' })} />,
                      )}
                    </Form.Item>
                    <Form.Item
                      label={intl.formatMessage({ id: 'app.passbookImageUrl', defaultMessage: 'Bank Account Book' })}
                    >
                      {getFieldDecorator('record.bankAccount.passbookImageUrl', {
                        initialValue: event.bankAccount.passbookImageUrl,
                        rules: [{
                          required: true,
                          message: 'This field is required',
                        }],
                      })(
                        <CloudImageUploader provider="gs" path="passbooks" />,
                      )}
                    </Form.Item>
                  </>
                )}
                <Form.Item wrapperCol={{
                  xs: { span: 24 },
                  sm: { span: 17, offset: 7 },
                }} >
                  <Button loading={submitLoading} type="primary" icon="blank" onClick={handleFormSubmit}><SaveOutlined style={{ marginRight: 2 }} /><FormattedMessage id="app.saveChanges" defaultMessage="saveChanges" /></Button>
                </Form.Item>
              </Form>
            </S.FormWrapper>
          </S.EventCard>
        </Col>
      </Row>
    </S.Container>
  )
}

export default compose(
  Form.create(),
  observer,
)(EventEdit)
