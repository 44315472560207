import { useEffect } from 'react'
import clarity from '../services/clarity'

const useClarity = () => {
  useEffect(() => {
    clarity.start({
      projectId: 'ivvigf447y',
      upload: 'https://e.clarity.ms/collect',
      track: true,
      content: true,
      // cookies: ['user_id', 'user_email', 'user_name']
    })
    // console.log('clarity', clarity)
    return () => clarity.stop()
  })
  return clarity
}

export default useClarity