import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStores } from '~/contexts'
import * as S from '~/styles'

const thContentHtml = `<p style="margin: 0.0px 0.0px 8.0px 0.0px; text-align: center; font: 18.0px Thonburi; color: #000000;"><strong>ข้อตกลงการใช้บริการ</strong><span style="font: 18.0px Helvetica;"><strong>&nbsp;</strong></span><strong>และ</strong><span style="font: 18.0px Helvetica;"><strong>&nbsp;</strong></span><strong>นโยบายความเป็นส่วนตัว</strong></p>

  <p style="margin: 0.0px 0.0px 8.0px 0.0px; font: 11.0px Thonburi; color: #000000;">บริษัท<span style="font: 11.0px Helvetica;">&nbsp;</span>ไทยดอทรัน<span style="font: 11.0px Helvetica;">&nbsp;</span>จำกัด<span style="font: 11.0px Helvetica;">&nbsp;(&ldquo;</span>ผู้ให้บริการ<span style="font: 11.0px Helvetica;">&rdquo;)&nbsp;</span>ตระหนักและใส่ใจถึงนโยบายความเป็นส่วนตัวของผู้ใช้บริการ<span style="font: 11.0px Helvetica;">&nbsp;(&ldquo;</span>ผู้ใช้บริการ<span style="font: 11.0px Helvetica;">&rdquo;)&nbsp;</span>ที่เข้าใช้บริการผ่านช่องทางต่างๆ<span style="font: 11.0px Helvetica;">&nbsp;</span>ของผู้ให้บริการในปัจจุบัน<span style="font: 11.0px Helvetica;">&nbsp;</span>และรวมถึงช่องทางเพิ่มเติมในอนาคต<span style="font: 11.0px Helvetica;">&nbsp;(&ldquo;</span>บริการ<span style="font: 11.0px Helvetica;">&rdquo;)&nbsp;</span>ทางผู้ให้บริการ<span style="font: 11.0px Helvetica;">&nbsp;</span>จึงได้กำหนด<span style="font: 11.0px Helvetica;">&nbsp;</span>การเก็บรวบรวม<span style="font: 11.0px Helvetica;">&nbsp;</span>ใช้<span style="font: 11.0px Helvetica;">&nbsp;</span>และเปิดเผย<span style="font: 11.0px Helvetica;">&nbsp;</span>ข้อมูลส่วนบุคคล<span style="font: 11.0px Helvetica;">&nbsp;</span>โดยผู้ใช้บริการ<span style="font: 11.0px Helvetica;">&nbsp;</span>รับทราบและยินยอมในข้อตกลงและข้อกำหนดในการใช้บริการ</p>
  
  <p style="margin: 0.0px 0.0px 8.0px 0.0px; font: 16.0px Thonburi; color: #000000;"><strong>วัตถุประสงค์ในการเก็บรวบรวมข้อมูลส่วนบุคคล</strong></p>
  
  <p style="margin: 0.0px 0.0px 8.0px 0.0px; font: 11.0px Thonburi; color: #000000;">เพื่อให้การบริการเป็นไปอย่างมีประสิทธิภาพ<span style="font: 11.0px Helvetica;">&nbsp;</span>ถูกต้อง<span style="font: 11.0px Helvetica;">&nbsp;</span>รวดเร็ว<span style="font: 11.0px Helvetica;">&nbsp;</span>และตอบโจทย์ความต้องการของผู้ใช้บริการ<span style="font: 11.0px Helvetica;">&nbsp;</span>ผู้ให้บริการจึงมีการเก็บข้อมูลส่วนบุคคลตามที่จำเป็น<span style="font: 11.0px Helvetica;">&nbsp;</span>เพื่อ</p>
  
  <ul style="list-style-type: disc;">
    <li style="margin: 0.0px 0.0px 8.0px 0.0px; font: 11.0px Thonburi; color: #000000;">ให้สามารถทำตามวัตถุประสงค์ของผู้ใช้บริการ<span style="font: 11.0px Helvetica;">&nbsp;</span>ในการใช้บริการต่างๆ<span style="font: 11.0px Helvetica;">&nbsp;</span></li>
    <li style="margin: 0.0px 0.0px 8.0px 0.0px; font: 11.0px Thonburi; color: #000000;">ใช้ในการประมวลผลเพื่อให้ผู้ให้บริการทราบถึงข้อมูลและสถิติต่างๆ<span style="font: 11.0px Helvetica;">&nbsp;</span>ในการใช้บริการ<span style="font: 11.0px Helvetica;">&nbsp;</span>เพื่อให้สามารถนำข้อมูลมาปรับปรุง<span style="font: 11.0px Helvetica;">&nbsp;</span>และพัฒนาช่องทางบริการต่างๆ<span style="font: 11.0px Helvetica;">&nbsp;</span>เพื่อให้ผู้ใช้บริการได้รับประสบการณ์ที่ดีในการใช้งาน<span style="font: 11.0px Helvetica;">&nbsp;</span>สามารถใช้งานได้สะดวกสบาย<span style="font: 11.0px Helvetica;">&nbsp;</span>และเกิดประโยชน์สูงสุด</li>
    <li style="margin: 0.0px 0.0px 8.0px 0.0px; font: 11.0px Thonburi; color: #000000;">แจ้งข้อมูล<span style="font: 11.0px Helvetica;">&nbsp;</span>ข่าวสาร<span style="font: 11.0px Helvetica;">&nbsp;</span>กิจกรรม<span style="font: 11.0px Helvetica;">&nbsp;</span>ผลิตภัณฑ์<span style="font: 11.0px Helvetica;">&nbsp;</span>และรายการส่งเสริมการขาย<span style="font: 11.0px Helvetica;">&nbsp;</span>ผ่านช่องทางของผู้ให้บริการ<span style="font: 11.0px Helvetica;">&nbsp;</span>และ<span style="font: 11.0px Helvetica;">&nbsp;</span>พันธมิตรที่เกี่ยวข้อง</li>
  </ul>
  
  <p style="margin: 0.0px 0.0px 8.0px 0.0px; font: 16.0px Thonburi; color: #000000;"><strong>การเก็บรวบรวมข้อมูลส่วนบุคคล</strong></p>
  
  <p style="margin: 0.0px 0.0px 8.0px 0.0px; font: 11.0px Thonburi; color: #000000;">ผู้ให้บริการได้รับข้อมูลส่วนบุคคลโดยได้รับผ่านทางการใช้บริการ<span style="font: 11.0px Helvetica;">&nbsp;</span>เมื่อมีความจำเป็นในการดำเนินการตามวัตถุประสงค์<span style="font: 11.0px Helvetica;">&nbsp;</span>โดยข้อมูลเหล่านั้นรวมถึง</p>
  
  <ul style="list-style-type: disc;">
    <li style="margin: 0.0px 0.0px 8.0px 0.0px; font: 11.0px Thonburi; color: #000000;">ชื่อ<span style="font: 11.0px Helvetica;">&nbsp;</span>นามสกุล<span style="font: 11.0px Helvetica;">&nbsp;</span>อายุ<span style="font: 11.0px Helvetica;">&nbsp;</span>วันเดือนปีเกิด<span style="font: 11.0px Helvetica;">&nbsp;</span>เพศ<span style="font: 11.0px Helvetica;">&nbsp;</span>เลขประจำตัวประชาชน<span style="font: 11.0px Helvetica;">&nbsp;/&nbsp;</span>หนังสือเดินทาง</li>
    <li style="margin: 0.0px 0.0px 8.0px 0.0px; font: 11.0px Thonburi; color: #000000;">ช่องทางการติดต่อ<span style="font: 11.0px Helvetica;">&nbsp;</span>เช่น<span style="font: 11.0px Helvetica;">&nbsp;</span>อีเมล์<span style="font: 11.0px Helvetica;">&nbsp;</span>ที่อยู่จัดส่ง<span style="font: 11.0px Helvetica;">&nbsp;</span>ที่อยู่ในการออกใบกำกับภาษี<span style="font: 11.0px Helvetica;">&nbsp;</span>หมายเลขโทรศัพท์<span style="font: 11.0px Helvetica;">&nbsp;</span></li>
    <li style="margin: 0.0px 0.0px 8.0px 0.0px; font: 11.0px Thonburi; color: #000000;">ข้อมูลการสั่งซื้อสินค้า<span style="font: 11.0px Helvetica;">&nbsp;</span>และ<span style="font: 11.0px Helvetica;">/</span>หรือ<span style="font: 11.0px Helvetica;">&nbsp;</span>บริการ</li>
    <li style="margin: 0.0px 0.0px 8.0px 0.0px; font: 11.0px Helvetica; color: #000000;"><span style="font: 11.0px Thonburi;">ข้อมูลบัญชีผู้ใช้งาน</span> user name , password, recovery email <span style="font: 11.0px Thonburi;">รูปภาพ</span>&nbsp;</li>
    <li style="margin: 0.0px 0.0px 8.0px 0.0px; font: 11.0px Thonburi; color: #000000;">ข้อมูลการทำธุรกรรมและการเงิน<span style="font: 11.0px Helvetica;">&nbsp;</span>รายละเอียดบัตรเครคิด<span style="font: 11.0px Helvetica;">&nbsp;</span>บัญชีธนาคาร<span style="font: 11.0px Helvetica;">&nbsp;</span>ประวัติการสั่งซื้อ<span style="font: 11.0px Helvetica;">&nbsp;</span></li>
    <li style="margin: 0.0px 0.0px 8.0px 0.0px; font: 11.0px Thonburi; color: #000000;">ข้อมูลอื่นๆ<span style="font: 11.0px Helvetica;">&nbsp;</span>ตามที่จำเป็น<span style="font: 11.0px Helvetica;">&nbsp;</span>เช่นข้อมูลการรีวิวสินค้าและบริการ<span style="font: 11.0px Helvetica;">&nbsp;</span>ข้อเสนอแนะ<span style="font: 11.0px Helvetica;">&nbsp;</span></li>
    <li style="margin: 0.0px 0.0px 8.0px 0.0px; font: 11.0px Thonburi; color: #000000;">ข้อมูลโดยใช้<span style="font: 11.0px Helvetica;">&nbsp;Cookies&nbsp;</span>เพื่อเพิ่มประสบการณ์การใช้งานของผู้ใช้บริการให้มีประสิทธิภาพมากขึ้น</li>
  </ul>
  
  <p style="margin: 0.0px 0.0px 8.0px 0.0px; font: 11.0px Thonburi; color: #000000;">ข้อมูลส่วนบุคคลของผู้ใช้บริการอาจถูกเปิดเผยต่อบุคคลหรือนิติบุคคลที่เกี่ยวข้องเช่น</p>
  
  <ul style="list-style-type: disc;">
    <li style="margin: 0.0px 0.0px 8.0px 0.0px; font: 11.0px Thonburi; color: #000000;">บุคคลและนิติบุคคลที่เกี่ยวข้องกับการให้บริการ<span style="font: 11.0px Helvetica;">&nbsp;</span>และกิจกรรม<span style="font: 11.0px Helvetica;">&nbsp;</span>ตามจำเป็น<span style="font: 11.0px Helvetica;">&nbsp;</span>เช่น<span style="font: 11.0px Helvetica;">&nbsp;</span>ผู้จัดงาน<span style="font: 11.0px Helvetica;">&nbsp;</span>ช่างภาพ<span style="font: 11.0px Helvetica;">&nbsp;</span>ผู้ให้บริการจัดส่งและขนส่งสินค้าและผลิตภัณฑ์<span style="font: 11.0px Helvetica;">&nbsp;</span></li>
    <li style="margin: 0.0px 0.0px 8.0px 0.0px; font: 11.0px Thonburi; color: #000000;">ผู้ให้บริการประมวลข้อมูลเพื่อให้ผู้ใช้บริการได้รับข่าวสาร<span style="font: 11.0px Helvetica;">&nbsp;</span>กิจกรรม<span style="font: 11.0px Helvetica;">&nbsp;</span>ผลิตภัณฑ์<span style="font: 11.0px Helvetica;">&nbsp;</span>การส่งเสริมการขายและการบริการ<span style="font: 11.0px Helvetica;">&nbsp;</span>เพื่อประโยชน์สูงสุดของผู้ใช้บริการ<span style="font: 11.0px Helvetica;">&nbsp;</span>เช่น<span style="font: 11.0px Helvetica;">&nbsp;search engine, social media, E-commerce&nbsp;</span>ต่างๆ<span style="font: 11.0px Helvetica;">&nbsp;&nbsp;</span></li>
  </ul>
  
  <p style="margin: 0.0px 0.0px 8.0px 0.0px; font: 11.0px Thonburi; color: #000000;">ระยะเวลาในการจัดเก็บข้อมูล</p>
  
  <p style="margin: 0.0px 0.0px 8.0px 0.0px; font: 11.0px Thonburi; color: #000000;">ผู้ให้บริการจะทำการเก็บรักษาข้อมูลที่ได้รับจากผู้ใช้บริการเป็นอย่างดี<span style="font: 11.0px Helvetica;">&nbsp;</span>ด้วยระบบรักษาความปลอดภัยที่ได้มาตรฐาน<span style="font: 11.0px Helvetica;">&nbsp;</span>จนกว่าจะมีการแก้ไข<span style="font: 11.0px Helvetica;">&nbsp;</span>เปลี่ยนแปลง<span style="font: 11.0px Helvetica;">&nbsp;</span>หรือยกเลิกโดยผู้ใช้บริการ</p>
  
  <p style="margin: 0.0px 0.0px 8.0px 0.0px; font: 16.0px Thonburi; color: #000000;"><strong>ติดต่อเรา</strong></p>
  
  <p style="margin: 0.0px 0.0px 8.0px 0.0px; font: 11.0px Thonburi; color: #000000;">การติดต่อเกี่ยวกับข้อมูลส่วนบุคคลของผู้ใช้บริการสามารถติดต่อได้ที่<span style="font: 11.0px Helvetica;">&nbsp;</span>บริษัท<span style="font: 11.0px Helvetica;">&nbsp;</span>ไทยดอทรัน<span style="font: 11.0px Helvetica;">&nbsp;</span>จำกัด<span style="font: 11.0px Helvetica;">&nbsp;</span>ที่อยู่เลขที่<span style="font: 11.0px Helvetica;">&nbsp;809&nbsp;&nbsp;</span>โครงการ<span style="font: 11.0px Helvetica;">&nbsp;Stadium one&nbsp;</span>ถนนพระราม<span style="font: 11.0px Helvetica;">&nbsp;6&nbsp;</span>แขวงวังใหม่<span style="font: 11.0px Helvetica;">&nbsp;</span>เขตปทุมวัน<span style="font: 11.0px Helvetica;">&nbsp;</span>กรุงเทพมหานคร<span style="font: 11.0px Helvetica;">&nbsp;10330&nbsp;</span>หรือทางอีเมล์<span style="font: 11.0px Helvetica;">&nbsp;admin@thai.run</span></p>
`
const enContentHtml = `<p style="margin: 0.0px 0.0px 8.0px 0.0px; text-align: center; font: 18.0px Thonburi; color: #000000;"><strong>Privacy Policy</strong></p>

  <p style="margin: 0.0px 0.0px 8.0px 0.0px; font: 11.0px Thonburi; color: #000000;">This Privacy Policy governs the manner in which Thaidotrun Co., Ltd. collects, uses, maintains and discloses information collected from users (each, a "User") of the http://www.photo.thai.run website ("Site"), as well as information we may collect from you when you use our services (the "Services") (i.e., when you when you purchase photos and/or photo-related products from us). This privacy policy applies to the Site and all products and services offered by Thaidotrun Co., Ltd.</p>
  
  <p style="margin: 0.0px 0.0px 8.0px 0.0px; font: 16.0px Thonburi; color: #000000;"><strong>How Does ThaidotRun Co., Ltd. use your Personal Information it Collects?</strong></p>

  <ul style="list-style-type: disc;">
    <li style="margin: 0.0px 0.0px 8.0px 0.0px; font: 11.0px Thonburi; color: #000000;">To Provide our services. We use your personal information to deliver our services, such as processing the sample of photo you search for in order to return results.</li>
    <li style="margin: 0.0px 0.0px 8.0px 0.0px; font: 11.0px Thonburi; color: #000000;">To improve our Site. We use your personal information to understand how our services are used, such as data analysis, audits, enhancing the Sites, identifying usage trends and determining the effectiveness of our service and any marketing champaign.</li>
    <li style="margin: 0.0px 0.0px 8.0px 0.0px; font: 11.0px Thonburi; color: #000000;">To Communicate with you. We use information we collect, like your email address, to interact with you directly. For example, sending you a photo download link after you complete your purchasing. Moreover, we may send you notices to inform you of information relevant to our Services and to offer you products, programs, or services that we believe may be of interest to you.</li>
  </ul>
  
  <p style="margin: 0.0px 0.0px 8.0px 0.0px; font: 16.0px Thonburi; color: #000000;"><strong>Information we collect as you use our services</strong></p>
  
  <ul style="list-style-type: disc;">
    <li style="margin: 0.0px 0.0px 8.0px 0.0px; font: 11.0px Thonburi; color: #000000;">Your Name Surname, Date of Birth, Personal ID Number / Passport Number</li>
    <li style="margin: 0.0px 0.0px 8.0px 0.0px; font: 11.0px Thonburi; color: #000000;">Your contact such as email address, or postal address</li>
    <li style="margin: 0.0px 0.0px 8.0px 0.0px; font: 11.0px Thonburi; color: #000000;">Your activity in our services</li>
    <li style="margin: 0.0px 0.0px 8.0px 0.0px; font: 11.0px Helvetica; color: #000000;">Your Information to be registered for accessing our service such as username, password, recovery email, display photo</li>
    <li style="margin: 0.0px 0.0px 8.0px 0.0px; font: 11.0px Thonburi; color: #000000;">Your history of transactions and payment channels such as credit card, bank account</li>
    <li style="margin: 0.0px 0.0px 8.0px 0.0px; font: 11.0px Thonburi; color: #000000;">Your feedback and concerns</li>
    <li style="margin: 0.0px 0.0px 8.0px 0.0px; font: 11.0px Thonburi; color: #000000;">A "cookie" is a small text file that a website, when visited by a user, sends to that user's browser which, in turn, may store some data on the user's hard drive or memory. Thaidotrun Co., Ltd. uses cookies to identify your visit session for customizing your user experience</li>
  </ul>
  
  <p style="margin: 0.0px 0.0px 8.0px 0.0px; font: 11.0px Thonburi; color: #000000;">With Whom Does Thaidotrun Co., Ltd. share your Personal Information it Collects?</p>
  
  <ul style="list-style-type: disc;">
    <li style="margin: 0.0px 0.0px 8.0px 0.0px; font: 11.0px Thonburi; color: #000000;">Third-Party Service Providers. Such as hospital or grounded medical staffs, race directors, photographer, fulfillment and delivery partners.</li>
  </ul>
  
  <p style="margin: 0.0px 0.0px 8.0px 0.0px; font: 11.0px Thonburi; color: #000000;">How Does Thaidotrun co., Ltd. protect your personal information collected through our Sites?</p>
  
  <p style="margin: 0.0px 0.0px 8.0px 0.0px; font: 11.0px Thonburi; color: #000000;">We will secure your personal data until receiving you request of edit or delete your personal information.</p>
  
  <p style="margin: 0.0px 0.0px 8.0px 0.0px; font: 16.0px Thonburi; color: #000000;"><strong>Contact US</strong></p>
  
  <p style="margin: 0.0px 0.0px 8.0px 0.0px; font: 11.0px Thonburi; color: #000000;">Thaidotrun Co., Ltd. 803, 805, 807, 809 Stadium One, Rama VI rd., Wangmai, Patumwan, Bangkok, 10330, Thailand Email: admin@thai.run</p>
  `


const PrivacyPolicy = observer(() => {
    // const [agree, setAgree] = useState(false)
    const { i18nStore: i18n } = useStores()
  
    return (<S.Container fluid>
     
      <div dangerouslySetInnerHTML={{ __html: i18n.locale === 'en' ? enContentHtml : thContentHtml }} style={{ padding: '2em' }} />
     
    </S.Container>)
  })

  export default PrivacyPolicy
  