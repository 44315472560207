import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { observer } from 'mobx-react-lite'
import { useLazyQuery, useQuery } from '@apollo/client'
import { Button, Col, Form, Icon, Input, Radio, Row, Spin, message } from 'antd'
import compose from 'recompose/compose'
import creditOrderQuery from '~/graphql/queries/creditOrder.gql'
import paymentQuery from '~/graphql/queries/payment.gql'
import { useStores } from '~/contexts'
import useScript from '../hooks/useScript'
import useOmiseOffsiteCredit from '../hooks/useOmiseOffsiteCredit'
import * as S from '~/styles'

const paymentList = {
  promptpay: <Icon type='qrcode' />,
  card: <Icon type='credit-card' />,
}
const publicKey = process.env.REACT_APP_OMISE_PUBLIC_KEY

const BuyCreditStep2 = compose(
  Form.create(),
  observer
)(({ form, match }) => {
  const intl = useIntl()
  const {
    routerStore: { history },
  } = useStores()
  const { getFieldDecorator, validateFields } = form
  const [sourceType, setSourceType] = useState()
  const [loading, setLoading] = useState(false)
  const [qr, setQr] = useState(null)
  const [loaded] = useScript('https://cdn.omise.co/omise.js.gz')
  const { creditOrderId } = match.params
  const { data: creditOrderData } = useQuery(creditOrderQuery, {
    variables: {
      _id: creditOrderId,
      ...(sessionStorage.getItem('flag_hidden_packages') && { hidden: true }),
    },
  })
  const [payment, { data: paymentData, loading: paymentLoading, refetch, startPolling, stopPolling }] = useLazyQuery(paymentQuery)

  const handleError = (err) => {
    const { graphQLErrors } = err
    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        message.error(intl.formatMessage({ id: `api.${error.message}` }))
      })
    } else if (err.message) {
      message.error(err.message)
    } else {
      message.error(intl.formatMessage({ id: `api.${err}` }))
    }
  }
  const [promptpayLoaded, promptpayLoading, promptpayPay] = useOmiseOffsiteCredit('omise_promptpay', handleError)
  const [cardLoaded, cardLoading, cardPay] = useOmiseOffsiteCredit('omise_card', handleError)
  const { couponCodes, package: packageData, status: creditOrderStatus } = creditOrderData?.creditOrder ?? {}
  const [couponCode] = couponCodes ?? []
  const { code, couponCodeType } = couponCode ?? {}
  const { discount: couponCodeTypeDiscount, discountType: couponCodeTypeDiscountType } = couponCodeType ?? {}
  const { currency = 'THB', name: packageName, price: amount } = packageData ?? {}
  const couponDiscount = couponCodeTypeDiscountType === 'percentage' ? (couponCodeTypeDiscount * amount) / 100 : couponCodeTypeDiscount ?? 0
  const vat = Number(((amount - couponDiscount) * 0.07).toFixed(2))
  const totalPrice = amount - couponDiscount + vat
  const { _id: paymentId, status: paymentStatus } = paymentData?.payment ?? {}
  const numberOption = {
    currency,
    currencyDisplay: 'code',
    maximumFractionDigits: 2,
    style: 'currency',
  }
  useEffect(() => {
    if (paymentStatus === 'pending' && qr) {
      startPolling(5000)
    } else if (['completed', 'failed'].includes(paymentStatus)) {
      stopPolling()
      history.replace(`/credits/${paymentId}/finished`)
    }
  }, [history, creditOrderId, creditOrderStatus, paymentId, qr, startPolling, paymentStatus, stopPolling])

  const handleStep = () => {
    history.push(`/credits/buy/step1`)
  }
  const handleCheckout = async ({ method, source, tokenId }) => {
    if (method === 'promptpay') {
      const { paymentId, uri } = await promptpayPay(
        method,
        {
          creditOrderId,
        },
        source
      )
      setQr(uri)
      payment({ variables: { _id: paymentId } })
    }
    if (method === 'card') {
      const { paymentId } = await cardPay(
        method,
        {
          creditOrderId,
          tokenId,
        },
        source
      )
      history.replace(`/credits/${paymentId}/finished`)
    }
    setLoading(false)
  }
  const handleMethod = (e) => {
    const v = e.target.value
    if (v === 'promptpay') {
      setLoading(true)
      const source = {
        amount: parseInt(totalPrice * 100, 10),
        currency,
      }
      handleCheckout({ method: 'promptpay', source })
    }
    setSourceType(v)
  }
  const handleCreditCardSubmit = (e) => {
    e.preventDefault()
    validateFields(async (err, values) => {
      if (!err) {
        setLoading(true)
        // eslint-disable-next-line no-undef
        Omise.setPublicKey(publicKey)
        const { number, name, expiry, cvv } = values
        const [mm = '', yy = ''] = expiry.replace(/\s/g, '').split('/')
        const expirationMonth = mm
        const expirationYear = `20${yy.substr(-2)}`
        const cardData = {
          name: name.trim(),
          number: number.replace(/\s/g, ''),
          expiration_year: expirationYear,
          expiration_month: expirationMonth,
          security_code: cvv.replace(/\s/g, ''),
        }
        const token = await new Promise((resolve, reject) => {
          // eslint-disable-next-line no-undef
          Omise.createToken('card', cardData, (statusCode, response) => {
            if (statusCode !== 200) {
              message.error(response.message, 5)
              setLoading(false)
              return reject(response.message)
            }
            return resolve(response)
          })
        })
        handleCheckout({ method: 'card', tokenId: token.id })
      }
    })
  }
  const handleConfirmPayment = () => {
    refetch()
  }
  const modifyNumber = (e) => {
    const v = e.target.value
    e.target.value = v
      .replace(/ /g, '')
      .match(/.{1,4}/g)
      .join(' ')
  }
  const modifyExpiry = (e) => {
    const v = e.target.value
    if (v.length === 2 && !v.includes('/')) {
      e.target.value = v + '/'
    }
  }
  const downloadQR = () => {
    setLoading(true)
    const downloadLink = document.createElement('a')
    downloadLink.href = qr
    downloadLink.download = `promptpay_qr.png`
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
    setLoading(false)
  }
  return (
    <S.Package>
      <Row className='description'>{`${intl.formatMessage({ id: 'app.step', defaultMessage: 'Step' })} 2/2`}</Row>
      <Row className='header'>{intl.formatMessage({ id: 'app.payment', defaultMessage: 'Payment' })}</Row>
      <div className='box'>
        <Row style={{ width: '100%' }}>คุณต้องการชำระเงินอย่างไร</Row>
        <Row align='middle' justify='center' type='flex' style={{ width: '100%' }}>
          <Radio.Group buttonStyle='solid' defaultValue={sourceType} onChange={handleMethod} style={{ width: '100%' }}>
            {Object.keys(paymentList).map((p) => (
              <Col span={12} key={p} style={{ padding: '0 0.5em' }}>
                <Radio.Button
                  className='payment-method'
                  disabled={currency === 'USD' && p === 'promptpay'}
                  value={p}
                  style={{ backgroundColor: sourceType === p ? 'rgba(253, 190, 26, 0.10)' : 'white', borderRadius: '0.75em' }}
                >
                  {sourceType === p ? (
                    <p style={{ color: 'black' }}>
                      {paymentList[p]}
                      {`\t${intl.formatMessage({ id: `app.${p}`, defaultMessage: p })}`}
                    </p>
                  ) : (
                    <p style={{ color: 'rgba(17, 17, 17, 0.60)' }}>
                      {paymentList[p]}
                      {`\t${intl.formatMessage({ id: `app.${p}`, defaultMessage: p })}`}
                    </p>
                  )}
                </Radio.Button>
              </Col>
            ))}
          </Radio.Group>
        </Row>
        {sourceType === 'promptpay' && (
          <>
            <Row style={{ width: '100%' }}>
              <p>สแกน QR code ด้านล่างด้วยแอพธนาคาร</p>
              <p className='description' style={{ fontSize: '0.85em' }}>
                หรือบันทึก QR code นี้ไปเปิดในแอพธนาคาร
              </p>
            </Row>
            <Row style={{ width: '100%', margin: '1em 0 0 0', textAlign: 'center' }}>
              {loading || paymentLoading ? (
                <Spin style={{ display: 'block', lineHeight: '20vh' }} />
              ) : qr ? (
                // <img ref={qrCodeRef} src='/images/credit-cards.png' alt='qr-code' style={{ height: '300px' }} />
                <img src={qr} alt='qr-code' style={{ height: '300px' }} />
              ) : (
                <></>
              )}
            </Row>
            <Row style={{ margin: '0 auto' }}>
              <Button
                disabled={!loaded || loading}
                onClick={downloadQR}
                style={{
                  backgroundColor: 'black',
                  border: 'none',
                  color: 'white',
                  fontSize: '1em',
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                {`${intl.formatMessage({ id: 'app.saveQRCode', defaultMessage: 'Save QR Code' })}`} <Icon type='download' />
              </Button>
            </Row>
          </>
        )}
        <Form onSubmit={handleCreditCardSubmit} style={{ width: '100%', margin: '0' }}>
          {sourceType === 'card' && (
            <>
              <Row style={{ width: '100%' }}>กรุณากรอกข้อมูลบัตรเครดิตของคุณ</Row>
              <Row style={{ width: '100%' }}>
                <img src='/images/credit-cards.png' alt='credit-card-logos' style={{ height: '28px' }} />
              </Row>
              <Form.Item style={{ marginTop: '1em' }}>
                {getFieldDecorator('number')(
                  <Input
                    className='grey-input'
                    type='tel'
                    inputMode='numeric'
                    maxLength={19}
                    onKeyUp={modifyNumber}
                    pattern='[0-9\s]{13,19}'
                    placeholder={'เลขบัตร'}
                  />
                )}
              </Form.Item>
              <Form.Item>{getFieldDecorator('name')(<Input className='grey-input' placeholder={'ชื่อบนบัตร'} />)}</Form.Item>
              <Form.Item style={{ width: '50%', display: 'inline-block', padding: '0 0.5em 0 0', margin: 0 }}>
                {getFieldDecorator('expiry')(
                  <Input className='grey-input' maxLength={5} onKeyUp={modifyExpiry} pattern='(?:0[1-9]|1[0-2])/[0-9]{2}' placeholder={'MM/YY'} />
                )}
              </Form.Item>
              <Form.Item style={{ width: '50%', display: 'inline-block', padding: '0 0 0 0.5em', margin: 0 }}>
                {getFieldDecorator('cvv')(<Input className='grey-input' pattern='\d*' placeholder={'CVV'} type='number' />)}
              </Form.Item>
            </>
          )}
          {/* <Row className='summary' style={{ marginTop: '1em' }}>
            <Col span={14}>{intl.formatMessage({ id: 'app.packagePrice', defaultMessage: 'Package Price' })}</Col>
            <Col span={10} style={{ textAlign: 'end' }}>
              {intl.formatNumber(price, { ...numberOption, currency })}
            </Col>
            <Col span={14}>{intl.formatMessage({ id: 'app.vat', defaultMessage: 'VAT {amount} %' }, { amount: 7 })}</Col>
            <Col span={10} style={{ textAlign: 'end' }}>
              {intl.formatNumber(price * (VAT - 1), { ...numberOption, currency })}
            </Col>
            <Col span={14}>{intl.formatMessage({ id: 'app.totalAmountDue', defaultMessage: 'Total amount due' })}</Col>
            <Col span={10} style={{ textAlign: 'end' }}>
              {intl.formatNumber(price * VAT, { ...numberOption, currency })}
            </Col>
          </Row> */}
          <Row className='summary' style={{ color: 'rgba(17, 17, 17, 0.60)' }}>
            <Col span={14}>{packageName}</Col>
            <Col span={10} style={{ textAlign: 'end' }}>
              {intl.formatNumber(amount, { ...numberOption })}
            </Col>
            <Col span={14}>{`VAT 7%`}</Col>
            <Col span={10} style={{ textAlign: 'end' }}>
              {intl.formatNumber(vat, { ...numberOption })}
            </Col>
            {code && (
              <>
                <Col span={6}>{intl.formatMessage({ id: 'app.discount', defaultMessage: 'Discount' })}</Col>
                <Col span={18} style={{ color: 'red', textAlign: 'end' }}>
                  {`${
                    couponCodeTypeDiscountType === 'percentage'
                      ? `[${intl.formatMessage(
                          { id: 'app.percentDiscount', defaultMessage: '{discount}% discount' },
                          { discount: couponCodeTypeDiscount }
                        )}] `
                      : ''
                  }-${intl.formatNumber(couponDiscount, numberOption)}`}
                </Col>
              </>
            )}
            <Col span={14} style={{ color: 'rgba(17, 17, 17, 0.60)' }}>
              {intl.formatMessage({ id: 'app.totalAmountDue', defaultMessage: 'Total amount due' })}
            </Col>
            <Col span={10} style={{ textAlign: 'end' }}>
              {intl.formatNumber(totalPrice, { ...numberOption })}
            </Col>
          </Row>
          {sourceType === 'promptpay' && (
            <Row style={{ width: '100%', marginTop: '2em' }}>
              <Col span={12} style={{ padding: '0 0.5em 0 0' }}>
                <Button
                  onClick={handleStep}
                  style={{
                    width: '100%',
                    height: '3em',
                    fontSize: '1em',
                    backgroundColor: 'rgba(17, 17, 17, 0.05)',
                    border: 'none',
                    color: 'black',
                  }}
                >
                  {intl.formatMessage({ id: 'app.back', defaultMessage: 'Back' })}
                </Button>
              </Col>
              <Col span={12} style={{ padding: '0 0 0 0.5em' }}>
                <Button
                  disabled={!qr}
                  onClick={handleConfirmPayment}
                  style={{
                    width: '100%',
                    height: '3em',
                    fontSize: '1em',
                    backgroundColor: '#FDBE1A',
                    border: 'none',
                    color: 'black',
                  }}
                >
                  {intl.formatMessage({ id: 'app.confirmPayment', defaultMessage: 'Confirm payment' })}
                </Button>
              </Col>
            </Row>
          )}
          {sourceType === 'card' && (
            <Row style={{ width: '100%', marginTop: '2em' }}>
              <Col span={12} style={{ padding: '0 0.5em 0 0' }}>
                <Button
                  onClick={handleStep}
                  style={{
                    width: '100%',
                    height: '3em',
                    fontSize: '1em',
                    backgroundColor: 'rgba(17, 17, 17, 0.05)',
                    border: 'none',
                    color: 'black',
                  }}
                >
                  {intl.formatMessage({ id: 'app.back', defaultMessage: 'Back' })}
                </Button>
              </Col>
              <Col span={12} style={{ padding: '0 0 0 0.5em' }}>
                <Form.Item style={{ width: '100%', margin: 0 }}>
                  <Button
                    htmlType='submit'
                    loading={!loaded || loading}
                    type='primary'
                    style={{
                      width: '100%',
                      height: '3em',
                      fontSize: '1em',
                      backgroundColor: '#FDBE1A',
                      border: 'none',
                      color: 'black',
                    }}
                  >
                    {intl.formatMessage({ id: 'app.confirmPayment', defaultMessage: 'Confirm payment' })}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          )}
        </Form>
      </div>
    </S.Package>
  )
})

export default BuyCreditStep2
