import React from 'react'
import { Switch } from 'react-router-dom'

import PublicRoute from '~/components/PublicRoute'
import PrivateRoute from '~/components/PrivateRoute'
import HeadlessRoute from '~/components/HeadlessRoute'
import MainRoute from '~/components/MainRoute'
import MainPrivateRoute from '~/components/MainPrivateRoute'
import PageNotFound from '~/pages/PageNotFound'
import Home from '~/pages/Home'
import PhoneLogin from '~/pages/PhoneLogin'
import PhoneRegister from '~/pages/PhoneRegister'
import PhoneVerification from '~/pages/PhoneVerification'
import ForgotPassword from '~/pages/ForgotPassword'
import Checkemail from './pages/Checkemail'
import PasswordReset from '~/pages/PasswordReset'
import PasswordCreate from '~/pages/PasswordCreate'
import Dashboard from '~/pages/Dashboard'
import Events from '~/pages/Events'
import PhotoDownloads from '~/pages/PhotoDownloads'
import ManualEvent from '~/pages/ManualEvent'
import ManualLine from '~/pages/ManualLine'
import Photos from '~/pages/Photos'
import BuyPhotos from '~/pages/BuyPhotos'
import EventCreate from '~/pages/EventCreate'
import EventManage from '~/pages/EventManage.js'
import EventEdit from '~/pages/EventEdit'
import EventView from '~/pages/EventView'
import EventUpload from '~/pages/EventUpload'
import EventPhotographers from '~/pages/EventPhotographers'
import Credits from '~/pages/Credits'
import BuyCreditStep1 from '~/pages/BuyCreditStep1'
import BuyCreditStep2 from '~/pages/BuyCreditStep2'
import BuyCreditStep3 from '~/pages/BuyCreditStep3'
import PaymentCompleted from '~/components/Payments/PaymentCompleted'
import Orders from '~/pages/Orders'
import PrivacyPolicy from './pages/PrivacyPolicy'
import LineEventJoin from '~pages/LineEventJoin'
import LineRedirect from '~pages/LineRedirect'
import LineEventPhotos from '~pages/LineEventPhotos'
import { HeaderProvider } from './services/header'

import MailtoRedirect from '~/pages/MailtoRedirect'
import GetByPlatform from '~/pages/GetByPlatform'
import MyCredit from '~/pages/MyCredit'

const App = () => (
  <HeaderProvider>
    <Switch>
      <MainRoute exact path="/" component={Home} />
      <MainRoute exact path="/login" component={PhoneLogin} />
      <MainRoute exact path="/register" component={PhoneRegister} />
      <MainRoute exact path="/verify/phone" component={PhoneVerification} />
      <MainRoute exact path="/forgotpassword" component={ForgotPassword} />
      <MainRoute exact path="/checkemail" component={Checkemail} />
      <MainRoute exact path="/passwordreset" component={PasswordReset} />
      <MainPrivateRoute exact path="/events" component={Events} />
      <MainPrivateRoute exact path="/events/:eventId/manage" component={EventManage} />
      <MainPrivateRoute exact path="/mycredit" component={MyCredit} />
      <MainPrivateRoute exact path="/credits/buy/step1" component={BuyCreditStep1} />
      <MainPrivateRoute exact path="/credits/buy/step2/:creditOrderId" component={BuyCreditStep2} />
      <MainPrivateRoute exact path="/credits/:paymentId/finished" component={BuyCreditStep3} />
      <HeadlessRoute exact path="/liff" component={LineEventJoin} />
      <HeadlessRoute exact path="/l/:eventId" component={LineRedirect} />
      <HeadlessRoute exact path="/liff/:eventSlug" component={LineEventPhotos} />
      <PublicRoute exact path="/get/:eventSlug" component={GetByPlatform} />
      <PublicRoute exact path="/join/:eventSlug" component={GetByPlatform} />
      <PublicRoute exact path="/email" component={MailtoRedirect}/>
      <PublicRoute exact path="/passwordcreate" component={PasswordCreate} />
      <PublicRoute exact path="/privacy-policy" component={PrivacyPolicy} />
      <PrivateRoute exact path="/dashboard" component={Dashboard} />
      <PrivateRoute exact path="/photo-downloads" component={PhotoDownloads} />
      <PublicRoute exact path="/manual-event" component={ManualEvent} />
      <PublicRoute exact path="/manual-line" component={ManualLine} />
      {/* <PublicRoute exact path="/:eventSlug" component={Photos} /> */}
      <PublicRoute exact path="/orders/:orderId" component={BuyPhotos} />
      <PrivateRoute exact path="/manual/event" component={ManualEvent} />
      <PrivateRoute exact path="/manual/line" component={ManualLine} />
      <PrivateRoute exact path="/events/create" component={EventCreate} />
      <PrivateRoute exact path="/events/:eventId/edit" component={EventEdit} />
      {/* <PublicRoute exact path="/events/:eventId/view" component={EventView} /> */}
      <PrivateRoute exact path="/events/:eventId/upload" component={EventUpload} />
      <PrivateRoute exact path="/events/:eventId/photographers" component={EventPhotographers} />
      <PrivateRoute exact path="/events/:eventId/credits" component={Credits} />
      {/* <PrivateRoute exact path="/events/:eventId/credits/buy" component={BuyCredits} /> */}
      <PrivateRoute exact path="/events/:eventId/orders" component={Orders} />
      <PrivateRoute exact path="/payments/:paymentId/completed" component={PaymentCompleted} />
      {/* <PrivateRoute exact path="/events/:eventId/paymentcompleted" component={PaymentCompleted} /> */}
      <MainRoute exact path="/:eventSlug" component={EventView} />{/* keep as last element */}

      <MainRoute path="*" component={PageNotFound} />
    </Switch>
  </HeaderProvider>
)

export default App
