import React, { Fragment, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Form, Input } from 'antd'
import CardReactFormContainer from 'card-react'

const formItemMargin = {
  style: {
    margin: 2,
  },
}

const CardInput = React.forwardRef(({ onChange }, ref) => {
  const intl = useIntl()
  const [cardNumber, setCardNumber] = useState('')
  const [cardName, setCardName] = useState('')
  const [cardExpiry, setCardExpiry] = useState('')
  const [cardCvc, setCardCvc] = useState('')

  const handleCardChange = (e) => {
    e.preventDefault()
    const { id, value } = e.target
    let _value = value
    if (id === 'number') {
      _value = _value.replace(/\D/g, '')
      _value = _value.slice(0, 16)
      if (_value.length > 0) {
        _value = _value.match(/.{1,4}/g).join(' ')
      }
      setCardNumber(_value)
    }
    if (id === 'name') {
      setCardName(_value)
    }
    if (id === 'expiry') {
      _value = _value.replace(/\D/g, '')
      _value = _value.slice(0, 6)
      if (_value.length >= 2) {
        _value = `${_value.slice(0, 2)} / ${_value.slice(2)}`
      }
      setCardExpiry(_value)
    }
    if (id === 'cvc') {
      setCardCvc(_value)
    }
  }

  useEffect(() => {
    onChange({
      number: cardNumber,
      name: cardName,
      expiry: cardExpiry,
      cvc: cardCvc,
    })
  }, [cardNumber, cardName, cardExpiry, cardCvc]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <div id="card-wrapper" ref={ref} style={{ marginBottom: 16 }} />
      <CardReactFormContainer
        // the id of the container element where you want to render the card element.
        // the card component can be rendered anywhere (doesn't have to be in ReactCardFormContainer).
        container="card-wrapper" // required
        // an object contain the form inputs names.
        // every input must have a unique name prop.
        formInputsNames={
          {
            number: 'cc_number', // optional — default "number"
            name: 'cc_name', // optional - default "name"
            expiry: 'cc_expiry', // optional — default "expiry"
            cvc: 'cc_cvc', // optional — default "cvc"
          }
        }
        // initial values to render in the card element
        initialValues={
          {
            number: '', // optional — default •••• •••• •••• ••••
            name: '', // optional — default FULL NAME
            expiry: '', // optional — default ••/••
            cvc: '', // optional — default •••
          }
        }
        // the class name attribute to add to the input field and the corresponding part of the card element,
        // when the input is valid/invalid.
        classes={
          {
            valid: 'valid-input', // optional — default 'jp-card-valid'
            invalid: 'invalid-input', // optional — default 'jp-card-invalid'
          }
        }
        // specify whether you want to format the form inputs or not
        formatting={false}
      >
        <div className="block">
          <Form.Item
            label={(
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <p style={{ flexGrow: 1 }}>{intl.formatMessage({ id: 'app.cardNumber' })}</p>
                <img src="/images/credit-cards.png" alt="Credit Cards" style={{ height: 25 }} />
              </div>
            )}
            {...formItemMargin}
          >
            <Input
              placeholder="1234 5678 9012 3456"
              name="cc_number"
              id="number"
              value={cardNumber}
              onChange={handleCardChange}
              required
            />
          </Form.Item>
          <Form.Item label={intl.formatMessage({ id: 'app.nameOnCard' })} {...formItemMargin}>
            <Input
              placeholder="Full Name"
              name="cc_name"
              id="name"
              value={cardName}
              onChange={handleCardChange}
              required
            />
          </Form.Item>
          <Form.Item label={intl.formatMessage({ id: 'app.expirationDate' })} {...formItemMargin} style={{ display: 'inline-block', width: 'calc(55% - 20px)' }}>
            <Input
              placeholder="01/19"
              name="cc_expiry"
              id="expiry"
              value={cardExpiry}
              onChange={handleCardChange}
              required
            />
          </Form.Item>
          <span style={{ display: 'inline-block', width: 40 }} />
          <Form.Item label={intl.formatMessage({ id: 'app.securityCode' })} {...formItemMargin} style={{ display: 'inline-block', width: 'calc(45% - 20px)' }}>
            <Input
              placeholder="***"
              name="cc_cvc"
              id="cvc"
              value={cardCvc}
              onChange={handleCardChange}
              required
              type="number"
              pattern="\d*"
            />
          </Form.Item>
        </div>
      </CardReactFormContainer>
    </Fragment>
  )
})

export default CardInput
