import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'mobx-react'
import { Router } from 'react-router-mobx'
import { AsyncTrunk } from 'mobx-sync'

import I18nProvider from './providers/I18nProvider'
import {
  StoresProvider,
  routerStore,
  userStore,
  i18nStore,
  cartStore,
} from './contexts'

import Tracker from './providers/Tracker'
import App from './App'
import './index.css'
import './fontawesome'

const stores = {
  i18n: i18nStore,
  user: userStore,
  router: routerStore,
  cart: cartStore,
}

userStore.onLogout = () => {
  cartStore.clear()
  routerStore.push('/')
}

const trunk = new AsyncTrunk(cartStore, { storage: localStorage })
window.cartStore = cartStore
trunk.init().then(() => {
  ReactDOM.render(
    <StoresProvider>
      <Provider {...stores}>
        <I18nProvider>
          <Router component={BrowserRouter} routerStore={routerStore}>
            <Tracker>
              <App />
            </Tracker>
          </Router>
        </I18nProvider>
      </Provider>
    </StoresProvider>,
    document.getElementById('root'),
  )
})

