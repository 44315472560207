import React, { useEffect, useState, useRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { observer } from 'mobx-react-lite'
import { useMutation, useQuery } from '@apollo/client'
import { Alert, Button, Col, Divider, Form, Icon, Input, Modal, Radio, Row, Spin, Switch, message, Popover, Select} from 'antd'
import { ExclamationCircleOutlined } from "@ant-design/icons";
import QRCode from 'qrcode'
import { compose } from 'recompose'
import eventQuery from '~/graphql/queries/event.gql'
import updateEventMutation from '~/graphql/mutations/updateEvent.gql'
import CloudImageUploader from '../components/CloudImageUploader.js'
import EventPhotos from '../components/EventPhotos.js'
import PhotoUploadTools from '../components/PhotoUploadTools.jsx'
// import replyUploadMutation from '~/graphql/mutations/replyUpload.gql'
import startUploadMutation from '~/graphql/mutations/startUpload.gql'
import qrFrame from '../assets/images/frameQRcode.svg'
import photoExampleHorizontal from '../assets/images/frame-example-horizontal-5.jpeg'
import photoExampleVertical from '../assets/images/frame-example-vertical-5.jpeg'
import { useStores, useUser } from '~/contexts'
import * as S from '~/styles'
import { useScreenView } from '../hooks/useFirebaseAnalytics.js'
import ThSVGIcon from '../assets/svg_icons/th_icon.svg'
import EnSVGIcon from '../assets/svg_icons/en_icon.svg'
import LineLogo from '../assets/images/LINE_Logo.svg'
import WhatsAppLogo from '../assets/images/WhatsApp_Logo.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import currentAdminUserQuery from '../graphql/queries/currentAdminUser.gql'

const EventCoverModal = ({ event, onClose, visible }) => {
  const intl = useIntl()
  const [coverUrl, setCoverUrl] = useState(event?.coverUrl)
  const [loading, setLoading] = useState(false)
  const [updateEvent] = useMutation(updateEventMutation)
  const handleClose = () => {
    onClose(false)
    setCoverUrl(event?.coverUrl)
  }
  const handleSave = async () => {
    if (coverUrl) {
      setLoading(true)
      try {
        const {
          data: {
            updateEvent: {
              record: { title: newTitle },
            },
          },
        } = await updateEvent({
          variables: {
            _id: event._id,
            record: {
              coverUrl,
            },
          },
        })
        message.info(`Event "${newTitle}" updated`, 5)
        setLoading(false)
        onClose(false)
      } catch (err) {
        setLoading(false)
        message.error(err.message)
      }
    }
  }
  const handlePhotoUrl = (url) => {
    setCoverUrl(url)
  }
  return (
    <Modal bodyStyle={{ padding: '24px' }} closable footer={null} onCancel={handleClose} visible={visible} width={360}>
      <p className='header'><FormattedMessage id='app.setCover'/></p>
      <p style={{ color: 'rgba(17, 17, 17, 0.40)', marginBottom: '10px' }}><FormattedMessage id='app.setCover2'/></p>
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          margin: '28px 0',
          ...(!coverUrl && {
            height: '240px',
            background: 'rgba(253, 190, 26, 0.10)',
            border: '0.50px #FDBE1A dashed',
            borderRadius: '12px',
          }),
        }}
      >
        {coverUrl && (
          <div style={{ alignSelf: 'end', margin: '0 6px -36px auto', zIndex: '9999' }}>
            {/* <Icon type='upload' style={{ backgroundColor: '#FDBE1A', borderRadius: '99px', padding: '8px' }} /> */}
            <Icon type='close-circle' onClick={() => handlePhotoUrl('')} theme='filled' style={{ fontSize: '28px', background: 'white', borderRadius: '9999px', color: 'red' }} />
          </div>
        )}
        <CloudImageUploader
          accept='image/*'
          loading={loading}
          onChange={handlePhotoUrl}
          path='events/cover'
          resize={{ maxWidth: 1240, mode: 'contain' }}
          value={coverUrl}
        >
          {coverUrl ? (
            <div style={{ display: 'flex', width: '100%' }}>
              <img src={coverUrl} alt='event-cover' style={{ borderRadius: '8px', width: '100%' }} />
            </div>
          ) : (
            <Row align='middle' justify='center' type='flex' style={{ textAlign: 'center' }}>
              <Col span={24}>
                <Icon type='upload' style={{ backgroundColor: '#FDBE1A', borderRadius: '99px', padding: '8px' }} />
              </Col>
              <Col align='middle' justify='center' type='flex' style={{ color: 'rgba(17, 17, 17, 0.60)', marginTop: '8px' }}>
                <p>
                  <strong style={{ color: '#FDBE1A' }}>
                    <FormattedMessage id='app.uploadCover' defaultMessage='Click to upload' />
                  </strong>
                  &nbsp;
                  <FormattedMessage id='app.orDragAndDrop' defaultMessage='or drag and drop files here' />
                </p>
                <p>
                  <FormattedMessage id='app.JPGonly' defaultMessage='JPG files only' />
                </p>
              </Col>
            </Row>
          )}
        </CloudImageUploader>
      </div>
      <Row gutter={[16, 0]} type='flex'>
        <Col span={12}>
          <Button
            onClick={handleClose}
            style={{
              backgroundColor: 'rgba(17, 17, 17, 0.05)',
              border: 'none',
              color: 'black',
              fontSize: '16px',
              height: '40px',
              width: '100%',
            }}
          >
            {intl.formatMessage({ id: 'app.cancel', defaultMessage: 'Cancel' })}
          </Button>
        </Col>
        <Col span={12}>
          <Button
            loading={loading}
            onClick={handleSave}
            style={{
              backgroundColor: 'black',
              border: 'none',
              color: 'white',
              fontSize: '16px',
              height: '40px',
              width: '100%',
            }}
          >
            {intl.formatMessage({ id: 'app.save', defaultMessage: 'Save' })}
          </Button>
        </Col>
      </Row>
    </Modal>
  )
}

const EditEventModal = compose(
  Form.create(),
  observer
)(({ eventId, eventTitle, eventWelcomeMessage, eventLang, form: { getFieldDecorator, getFieldsValue, validateFields, resetFields }, onClose, visible }) => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [focus, setFocus] = useState(!!eventTitle)
  const [updateEvent] = useMutation(updateEventMutation)
  const [languageEvent, SetLanguageEvent] = useState(eventLang);
  const cssLanguageBtn = {
    "used": {opacity: '1', color:'rgba(253, 190, 26, 1)', backgroundColor: 'rgba(253, 190, 26, 0.1)', borderColor:'rgba(253, 190, 26, 1)'},
    "un_used": {opacity: '0.4', color: 'rgba(17, 17, 17, 1)', backgroundColor: 'rgba(255, 255, 255, 1)', borderColor: 'rgba(17, 17, 17, 0.1)'}
  }

  const languageEventChange = (e) => {
    SetLanguageEvent(e.target.value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault()
    validateFields(async (err, values) => {
      if (!err) {
        setLoading(true)
        const { title, language ,welcomeMessage } = values
        try {
          const {
            data: {
              updateEvent: {
                record: { title: newTitle },
              },
            },
          } = await updateEvent({
            variables: {
              _id: eventId,
              record: {
                title,
                language,
                welcomeMessage
              },
            },
          })
          message.info(`Event "${newTitle}" updated`, 5)
          resetFields()
          setLoading(false)
          onClose(false)
        } catch (err) {
          setLoading(false)
          message.error(err.message)
        }
      }
    })
  }
  const handleClose = () => {
    onClose(false)
  }
  const handleFocus = (v) => {
    const { title } = getFieldsValue()
    setFocus(title?.length ? true : v)
  }

  return (
    <Modal bodyStyle={{ padding: '24px 24px 5px 24px' }} closable footer={null} onCancel={handleClose} visible={visible} width={360}>
      <p className='header'><FormattedMessage id='app.editEvent'/></p>
      <p style={{ color: 'rgba(17, 17, 17, 0.40)', marginBottom: '20px' }}><FormattedMessage id='app.event.text1'/></p>
      <Form onSubmit={handleFormSubmit}>
        {!focus && (
          <Row align='middle' justify='start' type='flex' style={{ height: '40px', padding: '8px 20px', position: 'absolute' }}>
            <Col span={24} style={{ color: 'rgba(17, 17, 17, 0.60)', fontSize: '9px', lineHeight: '4px' }}>
              {intl.formatMessage({ id: 'app.eventTitle', defaultMessage: 'Event Title' })}
            </Col>
            <Col span={24} style={{ fontSize: '12px', lineHeight: '4px' }}>
              {eventTitle}
            </Col>
          </Row>
        )}
        <Form.Item>
          {getFieldDecorator('title', {
            initialValue: eventTitle,
            rules: [
              {
                required: true,
                message: 'This field is required',
              },
            ],
          })(
            <Input
              onBlur={() => handleFocus(false)}
              onFocus={() => handleFocus(true)}
              style={{ backgroundColor: 'rgba(17, 17, 17, 0.05)', border: 'none', borderRadius: '25px', height: '40px' }}
            />
          )}
        </Form.Item>

        <Form.Item>
          {getFieldDecorator('welcomeMessage', {
            initialValue: eventWelcomeMessage,
          })(
            <Input.TextArea placeholder={'welcome message'}  maxLength={300} style={{ height: 100 }} />,
          )}
        </Form.Item>
        <div style={{marginTop:'3px',marginBottom:'4px'}}> <FormattedMessage id="app.eventLanguage" tagName="h4" /> </div>
       
        <Form.Item>
          {getFieldDecorator('language', {
            initialValue: languageEvent,
            rules: [{ required: true, message: 'Please pick an item!' }]
          })(
          <Radio.Group setFieldsValue={languageEvent} onChange={languageEventChange} style={{width:'100%'}}>
            <Radio.Button value="th" style={{width:'50%', ...(languageEvent === "th" ? cssLanguageBtn["used"] : cssLanguageBtn["un_used"]) }}>
              <img src={ThSVGIcon} style={{paddingRight:'4px'}}/>
              {intl.formatMessage({ id: 'app.thLang' })}
            </Radio.Button>
            <Radio.Button value="en" style={{width:'50%', ...(languageEvent === "en" ? cssLanguageBtn["used"] : cssLanguageBtn["un_used"]) }}>
              <img src={EnSVGIcon} style={{paddingRight:'4px'}}/>
              {intl.formatMessage({ id: 'app.enLang' })}
            </Radio.Button>
          </Radio.Group>
          )}
        </Form.Item>
        <Form.Item>
          <Row gutter={[16, 0]} type='flex'>
            <Col span={12}>
              <Button
                loading={loading}
                onClick={() => handleClose()}
                style={{
                  backgroundColor: '#FDBE1A',
                  color: 'black',
                  fontSize: '16px',
                  height: '40px',
                  width: '100%',
                }}
              >
                {intl.formatMessage({ id: 'app.cancel', defaultMessage: 'Cancel' })}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                loading={loading}
                onClick={(e) => handleFormSubmit(e)}
                style={{
                  backgroundColor: 'black',
                  borderColor: 'black',
                  color: 'white',
                  fontSize: '16px',
                  height: '40px',
                  width: '100%',
                }}
              >
                {intl.formatMessage({ id: 'app.save', defaultMessage: 'Save' })}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  )
})

const InvitationPhotographerModal = ({ eventId, onClose, visible }) => {
  const intl = useIntl()
  // const [qrCode, setQrCode] = useState(null)
  // const [loading, setLoading] = useState(false)
  const invitationUrl = `${process.env.REACT_APP_BASE_URI}/events/${eventId}/manage`
  // useEffect(() => {
  //   const createQrCode = async () => {
  //     setLoading(true)
  //     const qrCode = await QRCode.toDataURL(invitationUrl, { scale: 8 })
  //     setQrCode(qrCode)
  //     setLoading(false)
  //   }
  //   createQrCode()
  // }, [invitationUrl])
  const handleCopyUrl = () => {
    message.success(intl.formatMessage({ id: 'app.copyLinkSuccessfully'}), 5)
  }
  const handleClose = () => {
    onClose(false)
  }
  return (
    <Modal bodyStyle={{ padding: '24px' }} closable footer={null} onCancel={handleClose} visible={visible} width={360}>
      <p className='header'><FormattedMessage id='app.invitephotographers'/></p>
      <p style={{ color: 'rgba(17, 17, 17, 0.40)', marginBottom: '10px' }}><FormattedMessage id='app.invitephotographers1'/></p>
      <Row
        align='middle'
        justify='start'
        type='flex'
        style={{ background: 'rgba(253, 190, 26, 0.10)', border: '1px', borderRadius: '25px', padding: '20px 20px 10px 20px', marginTop: '20px' }}
      >
        <Col span={24} style={{ color: 'rgba(17, 17, 17, 0.60)', fontSize: '12px', marginTop: '-10px' }}>
          <FormattedMessage id='app.linkUpload'/>
        </Col>
        <Col span={24} style={{ fontSize: '14px', fontWeight: 600 }}>
          {invitationUrl}
        </Col>
      </Row>
      <Row gutter={[16, 0]} type='flex' style={{ marginTop: '20px' }}>
        <Col span={12}>
          <Button
            onClick={() => handleClose()}
            style={{
              backgroundColor: 'rgba(17, 17, 17, 0.05)',
              border: 'none',
              color: 'black',
              fontSize: '16px',
              height: '40px',
              width: '100%',
            }}
          >
            {intl.formatMessage({ id: 'app.cancel', defaultMessage: 'Cancel' })}
          </Button>
        </Col>
        <Col span={12}>
          <CopyToClipboard text={invitationUrl}>
            <Button
              onClick={handleCopyUrl}
              style={{
                backgroundColor: '#FDBE1A',
                border: '#FDBE1A',
                color: 'black',
                fontSize: '16px',
                height: '40px',
                width: '100%',
              }}
            >
              {intl.formatMessage({ id: 'app.copyLink', defaultMessage: 'Copy Link' })}
            </Button>
          </CopyToClipboard>
        </Col>
      </Row>
    </Modal>
    // <Modal bodyStyle={{ padding: '24px' }} closable footer={null} onCancel={handleClose} visible={visible} width={360}>
    //   <p classname='header'>ชวนช่างภาพ</p>
    //   <p style={{ color: 'rgba(17, 17, 17, 0.40)', marginBottom: '10px' }}>ให้ช่างภาพคนอื่นอัพโหลดภาพในงานนี้ด้วยลิ้งก์ด้านล่าง</p>
    //   {loading ? (
    //     <Spin style={{ display: 'block' }} />
    //   ) : (
    //     <div style={{ textAlign: 'center' }}>
    //       <img src={qrCode} alt='invitation-qrcode' style={{ width: 200 }} />
    //     </div>
    //   )}
    //   <Row
    //     align='middle'
    //     justify='end'
    //     type='flex'
    //     style={{ background: 'rgba(253, 190, 26, 0.10)', border: '1px', borderRadius: '25px', padding: '20px 20px 10px 20px', marginTop: '20px' }}
    //   >
    //     <Col sm={12}>
    //       <Row style={{ color: 'rgba(17, 17, 17, 0.60)', fontSize: '12px', marginTop: '-10px' }}>ลิ้งอัพโหลดรูป</Row>
    //       <Row style={{ fontSize: '14px', fontWeight: 600 }}>{invitationUrl}</Row>
    //     </Col>
    //     <Col sm={12} style={{ textAlign: 'right' }}>
    //       <CopyToClipboard text={invitationUrl}>
    //         <Button
    //           onClick={handleCopyUrl}
    //           type='primary'
    //           style={{ backgroundColor: '#FDBE1A', border: '#FDBE1A', color: 'black', fontSize: '16px', height: '40px' }}
    //         >
    //           {intl.formatMessage({ id: 'app.copyLink', defaultMessage: 'Copy Link' })}
    //         </Button>
    //       </CopyToClipboard>
    //     </Col>
    //   </Row>
    //   <Row gutter={[16, 0]} type='flex' style={{ marginTop: '20px' }}>
    //     <Col span={12}>
    //       <Button
    //         onClick={() => handleClose()}
    //         style={{
    //           backgroundColor: 'rgba(17, 17, 17, 0.05)',
    //           border: 'none',
    //           color: 'black',
    //           fontSize: '16px',
    //           height: '40px',
    //           width: '100%',
    //         }}
    //       >
    //         {intl.formatMessage({ id: 'app.cancel', defaultMessage: 'Cancel' })}
    //       </Button>
    //     </Col>
    //     <Col span={12}>
    //       <Button
    //         loading={loading}
    //         // onClick={(e) => handleFormSubmit(e)}
    //         style={{
    //           backgroundColor: 'black',
    //           border: 'none',
    //           color: 'white',
    //           fontSize: '16px',
    //           height: '40px',
    //           width: '100%',
    //         }}
    //       >
    //         บันทึก QR <Icon type='download' />
    //       </Button>
    //     </Col>
    //   </Row>
    // </Modal>
  )
}

const Frame = ({ record , selectQRcode, eventId}) => {
  const imgRef = useRef(null)
  useEffect(() => {
    const drawQRCode = async () => {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      const width = 330
      const height = 558
      const lineLiffUrl = `https://liff.line.me/${process.env.REACT_APP_LIFF_ID}?eventId=${eventId}`
      const whatsappUrl = `https://wa.me/${process.env.REACT_APP_WHATSAPP_PHONE}?text=Mango_${record.slug}&app_absent=0`
      const webSearchUrl = `${process.env.REACT_APP_BASE_URI}/${record.slug}`
      const images = [qrFrame, await QRCode.toDataURL(selectQRcode === 'Line' ? lineLiffUrl :  selectQRcode === 'WhatsApp' ? whatsappUrl : webSearchUrl, { margin: 0 })
      , selectQRcode === 'Line' ? LineLogo : selectQRcode === 'WhatsApp' ?  WhatsAppLogo : LineLogo
     ]
      const loadImage = (image) =>
        new Promise((resolve, reject) => {
          const img = new Image()
          img.src = image
          img.onload = () => {
            resolve(img)
          }
        })

        const wrapText = function(ctx, text, x, y, maxWidth, lineHeight) {
          // First, start by splitting all of our text into words, but splitting it into an array split by spaces
          let words = text.split(' ');
          let line = ''; // This will store the text of the current line
          let testLine = ''; // This will store the text when we add a word, to test if it's too long
          let lineArray = []; // This is an array of lines, which the function will return
      
          // Lets iterate over each word
          for(var n = 0; n < words.length; n++) {
              // Create a test line, and measure it..
              testLine += `${words[n]} `;
              let metrics = ctx.measureText(testLine);
              let testWidth = metrics.width;
              // If the width of this test line is more than the max width
              if (testWidth > maxWidth && n > 0) {
                  // Then the line is finished, push the current line into "lineArray"
                  lineArray.push([line, x, y]);
                  // Increase the line height, so a new line is started
                  y += lineHeight;
                  // Update line and test line to use this word as the first word on the next line
                  line = `${words[n]} `;
                  testLine = `${words[n]} `;
              }
              else {
                  // If the test line is still less than the max width, then add the word to the current line
                  line += `${words[n]} `;
              }
              // If we never reach the full max width, then there is only one line.. so push it into the lineArray so we return something
              if(n === words.length - 1) {
                  lineArray.push([line, x, y]);
              }
          }
          // Return the line array
          return lineArray;
      }
        

      const scale = window.devicePixelRatio
      canvas.style.width = width + 'px'
      canvas.style.height = height + 'px'
      canvas.width = width * scale
      canvas.height = height * scale
      ctx.scale(scale, scale)

      const [qrFrameImage, qrCodeImage, qrLogoImage] = await Promise.all(images.map((image, index) => loadImage(image, index)))
      ctx.imageSmoothingEnabled = false
      ctx.drawImage(qrFrameImage, 0, 0, 330, 558)
      ctx.font = "16px kanit";
      ctx.textAlign = 'center'
      const centerText = canvas.width / 4;
      const titleLength = record.title.length
      let wrappedText = wrapText(ctx, record.title, centerText, titleLength > 56? 90 : titleLength > 34? 100 : 110 , 250, 20);
      wrappedText.forEach(function(item) {
          ctx.fillText(item[0], item[1], item[2]); 
      })
      ctx.drawImage(qrCodeImage, 46, 160, 240, 240)
      if(selectQRcode !== 'Web Search'){
        ctx.drawImage(qrLogoImage, 140, 250,50,50)
      }

      imgRef.current.src = canvas.toDataURL()
    }
    drawQRCode()
  }, [record._id, record.title, record.slug , selectQRcode, eventId])

  return <img ref={imgRef} alt='QRCode' style={{ borderRadius: '12px', width: '100%' }} />
}

const PhotoSharingModal = compose(
  Form.create(),
  observer
)(({ event, form: { getFieldDecorator, getFieldValue }, onClose, visible ,eventId}) => {
  const intl = useIntl()
  const qrCodeRef = useRef(null)
  const [loading, setLoading] = useState(false)
  // const [focus, setFocus] = useState(false)
  const [updateEvent] = useMutation(updateEventMutation)
  const publicUrl = `${process.env.REACT_APP_BASE_URI}/${event.slug}`
  const searchingUrl = `${process.env.REACT_APP_BASE_URI}/${event.slug}`
  const getReceiveByPlatform = `${process.env.REACT_APP_BASE_URI}/join/${event.slug}`
  const [selectQRcode, setSelectQRcode] = useState('Line');

  const handleCopyUrl = () => {
    message.success(intl.formatMessage({ id: 'app.copyLinkSuccessfully'}), 5)
  }
  const handleClose = () => {
    onClose(false)
  }
  const handleOnChange = async (v) => {
    try {
      await updateEvent({
        variables: {
          _id: event._id,
          record: {
            ...v,
          },
        },
      })
    } catch (err) {
      message.error(err.message)
    }
  }
  const downloadQR = () => {
    setLoading(true)
    const downloadLink = document.createElement('a')
    downloadLink.href = qrCodeRef.current?.querySelector('img')?.src
    downloadLink.download = `${event.slug}.png`
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
    setLoading(false)
  }
  // const handleFocus = (v) => {
  //   const { text } = getFieldsValue()
  //   setFocus(text?.length ? true : v)
  // }
  // const handleTextEnableChange = () => {
  //   setFieldsValue({ text: '' })
  //   setFocus(false)
  // }
  const faceSearchEnabled = getFieldValue('faceSearchEnabled')
  useEffect(()=>{
    if(selectQRcode === 'Web Search' && !faceSearchEnabled){
      setSelectQRcode('Line')
    }
  },[faceSearchEnabled, selectQRcode])
  return (
    <Modal bodyStyle={{ padding: '24px' }} closable footer={null} onCancel={handleClose} visible={visible} width={360} style={{ top: '3em' }}>
      <p className='header'><FormattedMessage id='app.sharePhotos'/></p>
      <p style={{ color: 'rgba(17, 17, 17, 0.40)', marginBottom: '10px' }}><FormattedMessage id='app.sharePhotos1'/></p>
      <div style={{display:'flex',justifyContent:'center',width:'100%'}}>
      <Radio.Group value={selectQRcode} style={{marginBottom: '10px', width:'100%', textAlign:'center'}} onChange={(e)=> setSelectQRcode( e.target.value) } defaultValue={'Line'} >
        <Radio.Button  value={'Line'} style={{width:getFieldValue('faceSearchEnabled') ? '33.3%': '50%'}}>Line</Radio.Button>
        <Radio.Button value={'WhatsApp'} style={{width:getFieldValue('faceSearchEnabled') ? '33.3%': '50%'}}>WhatsApp</Radio.Button>
        {getFieldValue('faceSearchEnabled') ? <Radio.Button value={'Web Search'} style={{width:'33.3%'}}>Web Search</Radio.Button> : <></>}
      </Radio.Group>   
      </div>  
      <div ref={qrCodeRef} style={{ borderRadius: '8px', textAlign: 'center' }}>
        <Frame record={event} selectQRcode={selectQRcode} eventId={eventId} />
      </div>
      <div style={{ marginTop: '20px', textAlign: 'center' }}>
        <Button
          loading={loading}
          onClick={(e) => downloadQR(e)}
          style={{
            backgroundColor: 'black',
            border: 'none',
            color: 'white',
            fontSize: '16px',
            width: '100%',
            textAlign: 'center',
          }}
        >
          {intl.formatMessage({ id: 'app.saveQRCode', defaultMessage: 'Save QR Code' })} <Icon type='download' />
        </Button>
      </div>
      <Form>
      <Row
        align='middle'
        justify='end'
        type='flex'
        style={{ background: 'rgba(253, 190, 26, 0.10)', border: '1px', borderRadius: '25px', padding: '20px 20px 10px 20px', marginTop:'10px' }}
      >
        <Col xs={24} sm={12}>
          <Row style={{ color: 'rgba(17, 17, 17, 0.60)', fontSize: '12px', marginTop: '-10px' }}><FormattedMessage id='app.joinUrl'/></Row>
          <Row style={{ fontSize: '14px', fontWeight: 500 }}>{getReceiveByPlatform}</Row>
        </Col>
        <Col xs={24} sm={12} style={{ textAlign: 'right' }}>
          <CopyToClipboard text={getReceiveByPlatform}>
            <Button
              onClick={handleCopyUrl}
              type='primary'
              style={{ backgroundColor: '#FDBE1A', border: '#FDBE1A', color: 'black', fontSize: '16px', height: '40px' }}
            >
              {intl.formatMessage({ id: 'app.copyLink', defaultMessage: 'Copy Link' })}
            </Button>
          </CopyToClipboard>
        </Col>
      </Row>
        {/* <Row
          align='middle'
          justify='end'
          type='flex'
          style={{ marginTop: '10px' }}
        >
          <Col sm={20}>
            <Row style={{ fontSize: '16px', fontWeight: 500, marginTop: '-10px' }}>ตั้งข้อความต้อนรับใน Line</Row>
            <Row style={{ color: 'rgba(17, 17, 17, 0.60)', fontSize: '14px', fontWeight: 400 }}><span>ข้อความนี้จะต่อจาก<strong>{`“สวัสดีค่ะ คุณ MangoMango ยินดีต้อนรับเข้าสู้งาน${event.title}”`}</strong></span></Row>
          </Col>
          <Col sm={4} style={{ textAlign: 'right' }}>
            <Form.Item>
              {getFieldDecorator('textEnable', {
                valuePropName: 'checked',
              })(
                <Switch onChange={handleTextEnableChange} />
              )}
            </Form.Item>
          </Col>
        </Row>
        {getFieldValue('textEnable') && (
          <>
            {!focus && (
              <Row align='middle' justify='start' type='flex' style={{ height: '40px', marginBottom: '0px', padding: '8px 20px', position: 'absolute' }}>
                <Col style={{ color: 'rgba(17, 17, 17, 0.60)', fontSize: '12px', marginTop: '4px', paddingTop: '8px' }}>
                  {intl.formatMessage({ id: 'app.eventTitle', defaultMessage: 'Event Title' })}
                </Col>
              </Row>
            )}
            <Form.Item style={{ margin: '0px' }}>
              {getFieldDecorator('text')(
                <Input onBlur={() => handleFocus(false)} onFocus={() => handleFocus(true)} style={{ backgroundColor: 'rgba(17, 17, 17, 0.05)', border: 'none', borderRadius: '25px', height: '5em', marginTop: '14px' }} />
              )}
            </Form.Item>
          </>
        )}
        <Divider style={{ margin: '12px 0px 6px 0px' }} /> */}
        <Row align='middle' justify='space-between' type='flex' style={{ marginTop: '15px' }}>
          <Col xs={24} sm={20}>
            <Row style={{ fontSize: '16px', fontWeight: 500 }}><FormattedMessage id='app.makeEvent'/></Row>
            <Row style={{ color: 'rgba(17, 17, 17, 0.60)', fontSize: '14px', fontWeight: 400 }}><FormattedMessage id='app.makeEvent1'/></Row>
          </Col>
          <Col xs={24} sm={4} style={{ textAlign: 'right' }}>
            <Form.Item style={{ marginBottom: '0px' }}>
              {getFieldDecorator('showDefaultPhotos', {
                valuePropName: 'checked',
                initialValue: event?.showDefaultPhotos,
              })(<Switch onChange={(v) => handleOnChange({ showDefaultPhotos: v })} />)}
            </Form.Item>
          </Col>
        </Row>
        <Divider style={{ margin: '12px 0px' }} />
        <Row align='middle' justify='space-between' type='flex'>
          <Col xs={24} sm={20}>
            <Row style={{ fontSize: '16px', fontWeight: 500 }}><FormattedMessage id='app.openWeb'/></Row>
            <Row style={{ color: 'rgba(17, 17, 17, 0.60)', fontSize: '14px', fontWeight: 400 }}><FormattedMessage id='app.openWeb1'/></Row>
          </Col>
          <Col xs={24} sm={4} style={{ textAlign: 'right' }}>
            <Form.Item style={{ marginBottom: '0px' }}>
              {getFieldDecorator('faceSearchEnabled', {
                valuePropName: 'checked',
                initialValue: event?.faceSearchEnabled,
              })(<Switch onChange={(v) => handleOnChange({ faceSearchEnabled: v })} />)}
            </Form.Item>
          </Col>
        </Row>
        {(getFieldValue('faceSearchEnabled') || getFieldValue('showDefaultPhotos')) && (
          <>
            <Divider style={{ margin: '12px 0px' }} />
            <Row
              align='middle'
              justify='end'
              type='flex'
              style={{ background: 'rgba(253, 190, 26, 0.10)', border: '1px', borderRadius: '25px', padding: '20px 20px 10px 20px' }}
            >
              <Col xs={24} sm={12}>
                <Row style={{ color: 'rgba(17, 17, 17, 0.60)', fontSize: '12px', marginTop: '-10px' }}><FormattedMessage id='app.linksearch'/></Row>
                <Row style={{ fontSize: '14px', fontWeight: 500 }}>{searchingUrl}</Row>
              </Col>
              <Col xs={24} sm={12} style={{ textAlign: 'right' }}>
                <CopyToClipboard text={searchingUrl}>
                  <Button
                    onClick={handleCopyUrl}
                    type='primary'
                    style={{ backgroundColor: '#FDBE1A', border: '#FDBE1A', color: 'black', fontSize: '16px', height: '40px' }}
                  >
                    {intl.formatMessage({ id: 'app.copyLink', defaultMessage: 'Copy Link' })}
                  </Button>
                </CopyToClipboard>
              </Col>
            </Row>
          </>
        )}
      </Form>
    </Modal>
  )
})

const EditFrameModal = ({ event, onClose, visible }) => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [save, setSave] = useState(false)
  const [frameAlign, setFrameAlign] = useState('vertical')
  const [next, setNext] = useState(false)
  const [verticalPhoto, setVerticalPhoto] = useState(event?.photoFrame?.portraitPreviewUrl)
  const [logoUrl,setLogoUrl] = useState(event?.logoUrl)
  const [horizontalPhoto, setHorizontalPhoto] = useState(event?.photoFrame?.landscapePreviewUrl)
  const [verticalLogoPhoto, setVerticalLogoPhoto] = useState()
  const [verticalFramePhoto, setVerticalFramePhoto] = useState()
  const [horizontalLogoPhoto, setHorizontalLogoPhoto] = useState()
  const [horizontalFramePhoto, setHorizontalFramePhoto] = useState()
  const [updateEvent] = useMutation(updateEventMutation)
  const [selectedLogo,setSelectedLogo] = useState('')
  const [logoFileName,setLogoFileName] = useState('')
  const [frameFileName,setFrameFileName] = useState('')
  const [frameFileNameHorizontal,setFrameFileNameHorizontal] = useState('')


  const handleClose = () => {
    setNext(false)
    onClose(false)
  }
  const handleSave = async () => {
    // if (verticalPhoto && horizontalPhoto) {
      setSave(true)
      try {
        const {
          data: {
            updateEvent: {
              record: { title: newTitle },
            },
          },
        } = await updateEvent({
          variables: {
            _id: event._id,
            record: {
              watermarkMode: selectedLogo,
              logoUrl: logoUrl,
              photoFrame: {
                portraitPreviewUrl: verticalPhoto, //
                landscapePreviewUrl: horizontalPhoto,
              },
            },
          },
        })
        message.info(`Event "${newTitle}" updated`, 5)
        onClose(false)
        // setNext(false)
        setSave(false)
        // Modal.success({
        //   centered: true,
        //   icon: <Icon type='question-circle' style={{ fontSize: '2em' }} />,
        //   title: <p style={{ color: '#111111', fontSize: '24px', fontWeight: '700px' }}>ตั้งค่าลายน้ำเรียบร้อย!</p>,
        //   content: <p style={{ color: 'rgba(17, 17, 17, 0.60)', fontSize: '14px', fontWeight: '400px' }}>แขกในงานจะได้รับภาพที่มีลายน้ำทุกภาพ</p>,
        //   onOk: onClose(false),
        // })
      } catch (err) {
        setSave(false)
        message.error(err.message)
      }
    // } else {
    //   message.error('กรุณาอัปโหลดลายน้ำสำหรับภาพทั้งแนวตั้งและแนวนอน')
    // }
  }
  // const handleNext = async () => {
  //   setSave(true)
  //   if (verticalPhoto) {
  //     setVerticalLogoPhoto(await drawImage({ w: 1200, h: 1800, src: verticalPhoto }))
  //   }
  //   if (horizontalPhoto) {
  //     setHorizontalLogoPhoto(await drawImage({ w: 1800, h: 1200, src: horizontalPhoto }))
  //   }
  //   setNext(true)
  //   setSave(false)
  // }
  // const handleFrameAlign = (e) => {
  //   const v = e.target.value
  //   setFrameAlign(v)
  // }
  const drawImage = async (record) => {
    const { w: width, h: height, src: frameUrl } = record ?? {}
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    const imageList = [width > height ? photoExampleHorizontal : photoExampleVertical, frameUrl]

    const loadImage = (image) =>
      new Promise((resolve, reject) => {
        const img = new Image()
        img.crossOrigin = 'anonymous'
        img.src = image
        img.onload = () => {
          resolve(img)
        }
      })

    const scale = window.devicePixelRatio
    canvas.style.width = width + 'px'
    canvas.style.height = height + 'px'
    canvas.width = width * scale
    canvas.height = height * scale
    ctx.scale(scale, scale)

    const [image, frame] = await Promise.all(imageList.map((image, index) => loadImage(image, index)))
    ctx.imageSmoothingEnabled = false
    ctx.drawImage(image, 0, 0, width, height) ///วาดภาพปกติ
    if(selectedLogo === 'logo'){
      ctx.drawImage(frame, height > width ? 80 : 220, 10, 30, 30) //วาดlogo
    }else{
      ctx.drawImage(frame,  0, 0, width,height) //วาดframe
    }
    
    return canvas.toDataURL('image/png')
  }
  const handlePhotoUrl = async (url) => {
    setLoading(true)
    // if (frameAlign === 'vertical') {
    //   const photoFrame = await drawImage({ w: 1200, h: 1800, src: url })
    //   setVerticalPhoto(url)
    //   setVerticalLogoPhoto(photoFrame)
    // } 
    // else {
    //   const photoFrame = await drawImage({ w: 1800, h: 1200, src: url })
    //   setHorizontalPhoto(url)
    //   setHorizontalLogoPhoto(photoFrame)
    // }
    if(selectedLogo === 'logo'){
      const photoLogoVertical = await drawImage({ w: 119, h: 170, src: url })
      const photoLogoHorizontal = await drawImage({ w: 259, h: 170, src: url })
      setLogoUrl(url)
      setVerticalLogoPhoto(photoLogoVertical)
      setHorizontalLogoPhoto(photoLogoHorizontal)
    }
    else if(selectedLogo === 'frame'){
      const photoFrameVertical = await drawImage({ w: 119, h: 170, src: url })
      // const photoFrameHorizontal = await drawImage({ w: 259, h: 170, src: url })
      setVerticalPhoto(url)
      setVerticalFramePhoto(photoFrameVertical)
      // setHorizontalPhoto(url)
      // setHorizontalFramePhoto(photoFrameHorizontal)
    }
    setLoading(false)
  }

  const handlePhotoUrlHorizontal = async (url) => {
    setLoading(true)
      const photoFrameHorizontal = await drawImage({ w: 259, h: 170, src: url })
      setHorizontalPhoto(url)
      setHorizontalFramePhoto(photoFrameHorizontal)
    
    setLoading(false)
  }

  const content = (
    <Row style={{width: 340, padding: 15}}>
      <Col><FormattedMessage id='app.mango.text'/></Col>
    </Row>
  )

  const getFileName = (name) => {
    if(selectedLogo === 'logo'){
      setLogoFileName(name)
    }
    if(selectedLogo === 'frame'){
      setFrameFileName(name)
    }
  };
  const getFileNameHorizontal = (name) => {
      setFrameFileNameHorizontal(name)
    
  };

  return (
    <Modal bodyStyle={{ padding: '24px' }} closable footer={null} onCancel={handleClose} visible={visible} width={460} style={{ top: '3em' }}>
      <p className='header'>
        <FormattedMessage id='app.watermark' />
      </p>
      <p style={{ color: 'rgba(17, 17, 17, 0.40)', marginBottom: '10px' }}>
        <FormattedMessage id='app.watermark1' />
      </p>

      <Select placeholder={<FormattedMessage id='app.chooseWatermarkType'/>} style={{ width: '100%' }} onChange={(v) => setSelectedLogo(v)}>
        <Select.Option value='logo'><FormattedMessage id='app.eventLogo'/></Select.Option>
        <Select.Option value='frame'><FormattedMessage id='app.frame'/></Select.Option>
      </Select>

      {/* <Alert
        message={
          <div>
            <Icon type='info-circle' theme='filled' style={{ color: '#28C61A', fontSize: '20px' }} />
            <span style={{ marginLeft: '8px' }}><FormattedMessage id='app.prepare'/></span>
          </div>
        }
        description={
          <div style={{ color: 'rgba(17, 17, 17, 0.40)', lineHeight: '16px', marginTop: '10px' }}>
            <p><FormattedMessage id='app.prepare1'/></p>
            <ul style={{ paddingInlineStart: '1.8em' }}>
              <li>1,200 x 1,800 px for portrait photos</li>
              <li>1,800 x 1,200 px for landscape photos</li>
            </ul>
          </div>
        }
        type='success'
        closable
        afterClose={handleClose}
        style={{ backgroundColor: 'rgba(40, 198, 26, 0.10)', border: '1px', borderRadius: '25px', margin: '20px 0px', paddingBottom: '5px' }}
      /> */}

      {/* <Radio.Group
        buttonStyle='solid'
        onChange={handleFrameAlign}
        size='large'
        value={frameAlign}
        style={{ backgroundColor: '#d9d9d9', borderRadius: '100px', marginTop: '8px', padding: '4px', textAlign: 'center', width: '100%' }}
      >
        <Row align='middle' gutter={[16, 0]} justify='center' type='flex'>
          <Col span={12}>
            <Radio.Button value='vertical' style={{ backgroundColor: frameAlign === 'vertical' ? '#FDBE1A' : '#d9d9d9', width: '100%' }}>
              {`Portrait `}
              <span style={{ paddingLeft: '2px', verticalAlign: 'middle' }}>
                {next ? (
                  verticalPhoto ? (
                    <Icon type='check-circle' theme='filled' style={{ color: 'green' }} />
                  ) : (
                    <div style={{ width: '16px', height: '16px', border: '1px dashed black', borderRadius: '50%', display: 'inline-block' }} />
                  )
                ) : (
                  ''
                )}
              </span>
            </Radio.Button>
          </Col>
          <Col span={12}>
            <Radio.Button value='horizontal' style={{ backgroundColor: frameAlign === 'horizontal' ? '#FDBE1A' : '#d9d9d9', width: '100%' }}>
              {`Landscape `}
              <span style={{ paddingLeft: '2px', verticalAlign: 'middle' }}>
                {next ? (
                  horizontalPhoto ? (
                    <Icon type='check-circle' theme='filled' style={{ color: 'green' }} />
                  ) : (
                    <div style={{ width: '16px', height: '16px', border: '1px dashed black', borderRadius: '50%', display: 'inline-block' }} />
                  )
                ) : (
                  ''
                )}
              </span>
            </Radio.Button>
          </Col>
        </Row>
      </Radio.Group> */}
      {selectedLogo === '' ? (
        <></>
      ) : selectedLogo === 'logo' ? (
        <>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
            <div
              style={{
                alignItems: 'center',
                background: 'rgba(253, 190, 26, 0.10)',
                border: '0.50px #FDBE1A dashed',
                borderRadius: '12px',
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                padding: '24px 16px',
                height:' 140px'
              }}
            >
              {logoFileName ? 
              <>
               <Row style={{ marginTop: '0rem' }}>
                <Col span={23}>{logoFileName}</Col>
                <Col span={1}>
                  <Icon
                    type='delete'
                    onClick={() => {
                      setVerticalLogoPhoto()
                      setHorizontalLogoPhoto()
                      setLogoFileName()
                    }}
                  />
                </Col>
              </Row>
              </> :
               <>
               <CloudImageUploader
                accept='image/png'
                loading={loading}
                onChange={handlePhotoUrl}
                path='events/cover'
                value={verticalPhoto}
                style={{ borderRadius: '8px', height: '100%', width: '100%' }}
                getFileName={getFileName}
              >
                {
                  <Row align='middle' justify='center' type='flex' style={{ textAlign: 'center' }}>
                    <Col span={24}>
                      <Icon type='upload' style={{ backgroundColor: '#FDBE1A', borderRadius: '99px', padding: '8px' }} />
                    </Col>
                    <Col span={24} style={{ color: 'rgba(17, 17, 17, 0.60)', marginTop: '0.5em' }}>
                      <p>
                        <strong style={{ color: '#FDBE1A' }}><FormattedMessage id='app.uploadCover'/></strong>
                        <span style={{ marginLeft: '0.5em' }}><FormattedMessage id='app.orDragAndDrop'/></span>
                      </p>
                      <p><FormattedMessage id='app.PNGonly'/></p>
                    </Col>
                  </Row>
                }
              </CloudImageUploader>
              </>}
              
            </div>
          </div>

          <Row style={{ marginTop: '0.5rem' }}>
            <h3>
              <FormattedMessage id='app.preview' />{' '}
              <Popover placement='right' content={content}>
                <ExclamationCircleOutlined />
              </Popover>
            </h3>
          </Row>

          <Row align='middle' justify='center' type='flex' style={{ marginTop: '12px', textAlign: 'center' }}>
            <Col span={7.5}>
              <img src={verticalLogoPhoto ?? `/images/frame-example-vertical-5.png`} width={120} height={170} style={{ borderRadius: '8px' }} />
            </Col>
            <Col span={1}>{/* <Icon type='arrow-right' /> */}</Col>
            {/* รูปฝั่งขวา*/}
            <Col span={15.5}>
              <img src={horizontalLogoPhoto ?? `/images/frame-example-horizontal-5.png`} width={259} height={170} style={{ borderRadius: '8px' }} />
            </Col>
          </Row>

          {/* //////////////////////////////////ปุ่มยกเลิก และ บันทึก */}
          <Row gutter={[16, 0]} type='flex' style={{ marginTop: '20px' }}>
            <Col span={12}>
              <Button
                onClick={handleClose}
                style={{
                  backgroundColor: 'rgba(17, 17, 17, 0.05)',
                  border: 'none',
                  color: 'black',
                  fontSize: '16px',
                  height: '40px',
                  width: '100%',
                }}
              >
                {intl.formatMessage({ id: 'app.cancel', defaultMessage: 'Cancel' })}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                loading={save}
                onClick={handleSave}
                style={{
                  backgroundColor: 'black',
                  border: 'none',
                  color: 'white',
                  fontSize: '16px',
                  height: '40px',
                  width: '100%',
                }}
              >
                {intl.formatMessage({ id: 'app.save', defaultMessage: 'Save' })}
              </Button>
              {/* {next ? (
                <Button
                  loading={save}
                  onClick={handleSave}
                  style={{
                    backgroundColor: 'black',
                    border: 'none',
                    color: 'white',
                    fontSize: '16px',
                    height: '40px',
                    width: '100%',
                  }}
                >
                  {intl.formatMessage({ id: 'app.save', defaultMessage: 'Save' })}
                </Button>
              ) : (
                <Button
                  loading={save}
                  onClick={handleNext}
                  style={{
                    backgroundColor: 'black',
                    border: 'none',
                    color: 'white',
                    fontSize: '16px',
                    height: '40px',
                    width: '100%',
                  }}
                >
                  {intl.formatMessage({ id: 'app.next', defaultMessage: 'next' })}
                </Button>
              )} */}
            </Col>
          </Row>
        </>
      ) : (
        <>
          {' '}
          <div style={{marginTop:'2rem'}}><h3><FormattedMessage id='app.vertical' /></h3></div>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '0.5rem' }}>
            <div
              style={{
                alignItems: 'center',
                background: 'rgba(253, 190, 26, 0.10)',
                border: '0.50px #FDBE1A dashed',
                borderRadius: '12px',
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                padding: '24px 16px',
                height:'140px'
              }}
            >
              {verticalFramePhoto ? <>
                <Row>
                <Col span={23}>{frameFileName}</Col>
                <Col span={1}>
                  <Icon
                    type='delete'
                    onClick={() => {
                      setVerticalFramePhoto()
                    }}
                  />
                </Col>
              </Row>
              </> : <>
              <CloudImageUploader
                accept='image/png'
                loading={loading}
                onChange={handlePhotoUrl}
                path='events/cover'
                value={verticalPhoto}
                style={{ borderRadius: '8px', height: '100%', width: '100%' }}
                getFileName={getFileName}
                orientationFrame={'vertical'}
              >
                {
                  <Row align='middle' justify='center' type='flex' style={{ textAlign: 'center' }}>
                    <Col span={24}>
                      <Icon type='upload' style={{ backgroundColor: '#FDBE1A', borderRadius: '99px', padding: '8px' }} />
                    </Col>
                    <Col span={24} style={{ color: 'rgba(17, 17, 17, 0.60)', marginTop: '0.5em' }}>
                      <p>
                        <strong style={{ color: '#FDBE1A' }}><FormattedMessage id='app.uploadCover'/></strong>
                        <span style={{ marginLeft: '0.5em' }}><FormattedMessage id='app.orDragAndDrop'/> </span>
                      </p>
                      <p>1,200 x 1,800 px</p>
                      <p><FormattedMessage id='app.PNGonly'/></p>
                    </Col>
                  </Row>
                }
              </CloudImageUploader></>
              }
             
            </div>
          </div>

          <div style={{marginTop:'2rem'}}><h3><FormattedMessage id='app.horizontal'/></h3></div>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '0.5rem' }}>
            <div
              style={{
                alignItems: 'center',
                background: 'rgba(253, 190, 26, 0.10)',
                border: '0.50px #FDBE1A dashed',
                borderRadius: '12px',
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                padding: '24px 16px',
                height:'140px'
              }}
            >
              {horizontalFramePhoto ? <>
                <Row>
                <Col span={23}>{frameFileNameHorizontal}</Col>
                <Col span={1}>
                  <Icon
                    type='delete'
                    onClick={() => {
                      setHorizontalFramePhoto()
                    }}
                  />
                </Col>
              </Row>
             
              </> : 
              <CloudImageUploader
                accept='image/png'
                loading={loading}
                onChange={handlePhotoUrlHorizontal}
                path='events/cover'
                value={verticalPhoto}
                style={{ borderRadius: '8px', height: '100%', width: '100%' }}
                getFileName={getFileNameHorizontal}
                orientationFrame={'horizontal'}
              >
                {
                  <Row align='middle' justify='center' type='flex' style={{ textAlign: 'center' }}>
                    <Col span={24}>
                      <Icon type='upload' style={{ backgroundColor: '#FDBE1A', borderRadius: '99px', padding: '8px' }} />
                    </Col>
                    <Col span={24} style={{ color: 'rgba(17, 17, 17, 0.60)', marginTop: '0.5em' }}>
                      
                      <p>
                        <strong style={{ color: '#FDBE1A' }}><FormattedMessage id='app.uploadCover'/></strong>
                        <span style={{ marginLeft: '0.5em' }}><FormattedMessage id='app.orDragAndDrop'/></span>
                      </p>
                      <p>1,800 x 1,200 px</p>
                      <p><FormattedMessage id='app.PNGonly'/></p>
                    </Col>
                  </Row>
                }
              </CloudImageUploader>
              }
              
            </div>
          </div>
          {/* {verticalFramePhoto && (
            <>
              <Row style={{ marginTop: '2rem' }}>
                <Col span={23}><FormattedMessage id='app.vertical'/> : {frameFileName}</Col>
                <Col span={1}>
                  <Icon
                    type='delete'
                    onClick={() => {
                      setVerticalFramePhoto()
                    }}
                  />
                </Col>
              </Row>{' '}
            </>
          )} */}
          {/* {horizontalFramePhoto && (
            <>
              <Row style={{ marginTop: verticalFramePhoto ? '0.5rem' : '2rem' }}>
                <Col span={23}><FormattedMessage id='app.horizontal' /> : {frameFileNameHorizontal}</Col>
                <Col span={1}>
                  <Icon
                    type='delete'
                    onClick={() => {
                      setHorizontalFramePhoto()
                    }}
                  />
                </Col>
              </Row>{' '}
            </>
          )} */}
          <Row style={{ marginTop: '0.5rem' }}>
            <h3>
              <FormattedMessage id='app.preview' />{' '}
              <Popover placement='right' content={content}>
                <ExclamationCircleOutlined />
              </Popover>
            </h3>
          </Row>
          <Row align='middle' justify='center' type='flex' style={{ marginTop: '12px', textAlign: 'center' }}>
            <Col span={7.5}>
              <img src={verticalFramePhoto ?? `/images/frame-example-vertical-5.png`} width={120} height={170} style={{ borderRadius: '8px' }} />
            </Col>
            <Col span={1}>{/* <Icon type='arrow-right' /> */}</Col>
            {/* รูปฝั่งขวา*/}
            <Col span={15.5}>
              <img src={horizontalFramePhoto ?? `/images/frame-example-horizontal-5.png`} width={259} height={170} style={{ borderRadius: '8px' }} />
            </Col>
          </Row>
          {/* //////////////////////////////////ปุ่มยกเลิก และ บันทึก */}
          <Row gutter={[16, 0]} type='flex' style={{ marginTop: '20px' }}>
            <Col span={12}>
              <Button
                onClick={handleClose}
                style={{
                  backgroundColor: 'rgba(17, 17, 17, 0.05)',
                  border: 'none',
                  color: 'black',
                  fontSize: '16px',
                  height: '40px',
                  width: '100%',
                }}
              >
                {intl.formatMessage({ id: 'app.cancel', defaultMessage: 'Cancel' })}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                loading={save}
                onClick={handleSave}
                style={{
                  backgroundColor: 'black',
                  border: 'none',
                  color: 'white',
                  fontSize: '16px',
                  height: '40px',
                  width: '100%',
                }}
              >
                {intl.formatMessage({ id: 'app.save', defaultMessage: 'Save' })}
              </Button>
              {/* {next ? (
                <Button
                  loading={save}
                  onClick={handleSave}
                  style={{
                    backgroundColor: 'black',
                    border: 'none',
                    color: 'white',
                    fontSize: '16px',
                    height: '40px',
                    width: '100%',
                  }}
                >
                  {intl.formatMessage({ id: 'app.save', defaultMessage: 'Save' })}
                </Button>
              ) : (
                <Button
                  loading={save}
                  onClick={handleNext}
                  style={{
                    backgroundColor: 'black',
                    border: 'none',
                    color: 'white',
                    fontSize: '16px',
                    height: '40px',
                    width: '100%',
                  }}
                >
                  {intl.formatMessage({ id: 'app.next', defaultMessage: 'next' })}
                </Button>
              )} */}
            </Col>
          </Row>
        </>
      )}
    </Modal>
  )
}

const UploadPhotoModal = ({ event, user, onClose, visible }) => {
  const handleClose = () => {
    onClose(false)
  }
  return (
    <Modal bodyStyle={{ padding: '24px' }} closable footer={null} onCancel={handleClose} visible={visible} width={720}>
      <FormattedMessage id="app.uploadPhoto" tagName="p" className="header"/>
      {/* <p style={{ color: 'rgba(17, 17, 17, 0.40)', marginBottom: '10px' }}>Upload constraints go here</p> */}
      {visible && <PhotoUploadTools event={event} />}
    </Modal>
  )
}

const ConsentUploadModal = ({ event, photographerId, onClose, visible, addToUploadList, condText, btnText }) => {
  const handleClose = () => {
    onClose(false)
  }
  return (
    <Modal bodyStyle={{ padding: '24px' }} closable footer={null} onCancel={handleClose} visible={visible} width={520}>
      <p style={{ color: 'rgba(17, 17, 17, 0.40)', marginBottom: '10px' }}>{condText} {event.title}</p>
      <Button
        onClick={() => addToUploadList(event._id, photographerId)}
        size='large'
        style={{ backgroundColor: 'black', color: 'white', fontWeight: 600, height: '35px', padding: '0 15px' }}
      >
        {btnText}
      </Button>
    </Modal>
  )
}

const EventManage = ({ match }) => {
  useScreenView('event-manage')
  const intl = useIntl()
  const [editCoverModal, setEditCoverModal] = useState(false)
  const [editEventModal, setEditEventModal] = useState(false)
  const [invitationPhotographerModal, setInvitationPhotographerModal] = useState(false)
  const [photoSharingModal, setPhotoSharingModal] = useState(false)
  const [editFrameModal, setEditFrameModal] = useState(false)
  const [uploadPhotoModal, setUploadPhotoModal] = useState(false)
  const [consentUploadModal, setConsentUpload] = useState(false)
  const [consentCheck, setConsentCheck] = useState(false)
  const [isOwner, setIsOwner] = useState(false)
  // const [widthScreen,setWidthScreen] = useState(window.innerWidth)

  const { error, loading, data, refetch } = useQuery(eventQuery, {
    variables: {
      _id: match.params.eventId,
    },
    fetchPolicy: 'network-only',
  })
  const { data:dataCurrentAdmin } = useQuery(currentAdminUserQuery,{ fetchPolicy: 'network-only'})

  
  // const [joinUpload] = useMutation(replyUploadMutation, {
  //   refetchQueries: [{
  //     query: eventQuery,
  //     variables: { _id: match.params.eventId },
  //   }],
  // })

  const [startUpload] = useMutation(startUploadMutation)

  const {
    routerStore: {
      location: { pathname },
    },
  } = useStores()
  const { user,userLoading } = useUser()

  useEffect(() => {
    if (data && dataCurrentAdmin) {
      const isInUploads = data.event.uploads.find((v) => v.photographerId === dataCurrentAdmin.currentAdminUser._id) ? false : true
      setConsentCheck(isInUploads ? false : true)
      setConsentUpload(isInUploads)
      setIsOwner(data.event.createdByUserId === dataCurrentAdmin.currentAdminUser._id ? true : false)
    }
    if (user.role === 'admin'){
      setConsentCheck(true)
      setConsentUpload(true)
      setIsOwner(true)
    }
  }, [data, setIsOwner, setConsentCheck, setConsentUpload, user,dataCurrentAdmin])

  // const setWidthScreenRealTime = () => {
  //   setWidthScreen(window.innerWidth);
  // };

  // useEffect(() => {
  //   setWidthScreenRealTime(); // Initial call to set dimensions
  //   window.addEventListener("resize", setWidthScreenRealTime);

  //   // return () => {
  //   //   window.removeEventListener("resize", updateDimensions);
  //   // };
  // }, []);
  // console.log('user',user)
  if (error) return 'Error...'
  if (loading) return <Spin style={{ display: 'block', lineHeight: '100vh' }} />

  const { event } = data ?? {}
  const photoCount = event?.uploads?.find((e) => e.photographerId === user._id)?.photoCount ?? 0
  const eventUpload = event?.uploads?.map((e) => e.photographer)
  const adminCount = eventUpload?.filter((e) => e.role === 'admin').length
  // const photographerCount = eventUpload?.filter((e) => e.role !== 'admin').length
  // console.log(adminCount);
  // console.log(event?.uploads?.find((e) => e.photographerId === user._id)?.photoCount );
  // const eventCredit = _get(event, 'creditBalance.total', 0)
  // const photoDownloadMode = _get(event, 'photoDownloadMode')
  // const creditsAvailable = photoDownloadMode === 'priced' ? undefined : eventCredit
  // console.log(isOwner);
  const handleEditCoverModal = (v) => {
    setEditCoverModal(v)
    refetch()
  }
  const handleEditEventModal = (v) => {
    setEditEventModal(v)
    refetch()
  }
  const handleInvitationPhotographerModal = (v) => {
    setInvitationPhotographerModal(v)
  }
  const handlePhotoSharingModal = (v) => {
    setPhotoSharingModal(v)
  }
  const handleEditFrameModal = (v) => {
    setEditFrameModal(v)
    refetch()
  }
  const handleUploadPhotoModal =  async (v, event, user) => {
    if (event && user && user.role === 'admin') {
      await startUpload({
        variables: {
          eventId: event?._id,
          photographerId: user?._id,
          approved: true,
        },
      })
      }
      setUploadPhotoModal(v)
      refetch()
      }
  const handleConsentUploadModal = (v) => {
    setConsentUpload(v)
    refetch()
  }

  // const handleJoinEvent = async (e, reply) => {
  //   e.preventDefault()
  //   try {
  //     await joinUpload({
  //       variables: {
  //         _id: user._id,
  //         record: { joined: reply },
  //       },
  //     })
  //   } catch (error) {
  //     message.error(error.message, 5)
  //   }
  // }

  // const addToUploadList = useCallback(async () => {
  //   // await startUpload({
  //   //   variables: {
  //   //     eventId: _id,
  //   //     photographerId: createdByUserId,
  //   //     approved: true
  //   //   },
  //   // })
  // },[event])

  const addToUploadList = async (eventId, photographerId) => {
    if (eventId && photographerId) {
      await startUpload({
        variables: {
          eventId: eventId,
          photographerId: photographerId,
          approved: true,
        },
      })
      setConsentUpload(false)
      setConsentCheck(true)
    }
  }
  const bookIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 17" fill="none">
    <path d="M17.6423 1.71693C17.045 1.61404 16.4401 1.56108 15.834 1.5586C13.7659 1.5569 11.741 2.14991 10.0006 3.26693C8.25576 2.16457 6.23118 1.58612 4.16731 1.60026C3.56118 1.60275
     2.95631 1.65571 2.35897 1.7586C2.16331 1.79233 1.98612 1.89481 1.85932 2.04759C1.73251 2.20037 1.66442 2.3934 1.66731 2.59193V12.5919C1.66552 12.7144 1.69075 12.8357 1.74118 12.9473C1.79162 
     13.0589 1.86603 13.158 1.95912 13.2376C2.05221 13.3171 2.16169 13.3752 2.27977 13.4076C2.39785 13.4401 2.52163 13.4461 2.64231 13.4253C3.83649 13.2185 5.05998 13.2528 6.2407 13.5262C7.42142 
     13.7995 8.53549 14.3064 9.51731 15.0169L9.61731 15.0753H9.70897C9.80139 15.1138 9.90052 15.1336 10.0006 15.1336C10.1008 15.1336 10.1999 15.1138 10.2923 15.0753H10.384L10.484 15.0169C11.4589 
     14.2905 12.5698 13.7672 13.7508 13.4781C14.9317 13.1889 16.1587 13.1398 17.359 13.3336C17.4796 13.3544 17.6034 13.3484 17.7215 13.316C17.8396 13.2835 17.9491 13.2254 18.0422 13.1459C18.1353 
     13.0663 18.2097 12.9672 18.2601 12.8556C18.3105 12.744 18.3358 12.6227 18.334 12.5003V2.50026C18.3253 2.31039 18.252 2.12919 18.1262 1.98671C18.0004 1.84423 17.8296 1.74904 17.6423 1.71693ZM9.16731 
     12.7919C7.62554 11.9808 5.90941 11.5575 4.16731 11.5586C3.89231 11.5586 3.61731 11.5586 3.33397 11.5586V3.22526C3.61152 3.20927 3.88976 3.20927 4.16731 3.22526C5.94513 3.2233 7.68415 3.74501 9.16731 
     4.72526V12.7919ZM16.6673 11.5919C16.384 11.5919 16.109 11.5919 15.834 11.5919C14.0919 11.5909 12.3757 12.0142 10.834 12.8253V4.72526C12.3171 3.74501 14.0562 3.2233 15.834 3.22526C16.1115 3.20927 16.3898
     3.20927 16.6673 3.22526V11.5919ZM17.6423 15.0503C17.045 14.9474 16.4401 14.8944 15.834 14.8919C13.7659 14.8902 11.741 15.4832 10.0006 16.6003C8.26024 15.4832 6.23534 14.8902 4.16731 14.8919C3.56118 14.8944 
     2.95631 14.9474 2.35897 15.0503C2.2505 15.0675 2.14649 15.106 2.05294 15.1635C1.95939 15.2211 1.87814 15.2965 1.81386 15.3856C1.74958 15.4747 1.70354 15.5756 1.67839 15.6825C1.65324 15.7894 1.64947 15.9002 
     1.66731 16.0086C1.70966 16.225 1.83605 16.4158 2.0188 16.5392C2.20155 16.6626 2.42576 16.7085 2.64231 16.6669C3.83649 16.4602 5.05998 16.4945 6.2407 16.7678C7.42142 17.0412 8.53549 17.5481 9.51731 18.2586C9.65844
     18.3591 9.82739 18.4131 10.0006 18.4131C10.1739 18.4131 10.3428 18.3591 10.484 18.2586C11.4658 17.5481 12.5799 17.0412 13.7606 16.7678C14.9413 16.4945 16.1648 16.4602 17.359 16.6669C17.5755 16.7085 17.7997 16.6626
     17.9825 16.5392C18.1652 16.4158 18.2916 16.225 18.334 16.0086C18.3518 15.9002 18.348 15.7894 18.3229 15.6825C18.2977 15.5756 18.2517 15.4747 18.1874 15.3856C18.1231 15.2965 18.0419 15.2211 17.9483 15.1635C17.8548 15.106 17.7508 15.0675 17.6423 15.0503Z" fill="white"/>
   </svg>
  );
  const BookIcon = () => (
    <Icon component={bookIcon} />
  );

  const handleDownloadManual = () => {
    const url = `${process.env.REACT_APP_BASE_URI}/userManual.pdf`
    fetch(url)
      .then((res)=> res.blob())
      .then((blob)=>{
        const blobURL = window.URL.createObjectURL(new Blob([blob]))
        const fileName = url.split('/').pop();
        const aTag = document.createElement('a')
        aTag.href = blobURL
        aTag.setAttribute('download',fileName)
        document.body.appendChild(aTag)
        aTag.click()
        aTag.remove()
      })
    
  }

  return (
    <>
      <S.EventCover isOwner={isOwner} image={event.coverUrl ?? '/images/default-event-cover.jpg'}>
        {/* <img src={event.coverUrl ?? '/images/default-event-cover.jpg'} alt='event-cover' style={{ backgroundImage: 'linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 1) 100%)', backgroundSize: 'cover',height: 'inherit', maxHeight: '640px', objectFit: 'cover', position: 'relative', width: '100%' }} /> */}
        <div className='event-cover-box'>
          <div className='event-cover'/>
          <div className='event-cover-background'/>
        </div>
      </S.EventCover>
      <S.Event isOwner={isOwner}>
        <div className='event-box'>
          <Row
            align='bottom'
            className='title-row'
            gutter={[0, 12]}
            justify='start'
            type='flex'
          >
            <Col className='event-title' xs={24} lg={17}>
              {event.title}
            </Col>
            <Col className='event-title-button' xs={24} lg={7} style={{display : isOwner ? '':'none'}}>
              <Row align='middle' gutter={[8, 0]} justify='start' type='flex' style={{ visibility: isOwner ? 'visible' : 'hidden'}}>
                <Col>
                  <Button
                    className='transparent-button'
                    onClick={() => handleEditCoverModal(true)}
                    type='primary'
                    style={{ fontSize: '1em', height: '3em', padding: '0 1.5em' }}
                  >
                    <FormattedMessage id='app.setCover' />
                  </Button>
                </Col>
                <Col>
                  <Button
                    className='transparent-button'
                    onClick={() => handleEditEventModal(true)}
                    type='primary'
                    style={{ fontSize: '1em', height: '3em', padding: '0 1.5em' }}
                  >
                    <Icon type='edit' /> <FormattedMessage id='app.editEvent' />
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row
            align='middle'
            className='manage-row'
            justify='start'
            type='flex'
            style={{display : isOwner ? '':'none'}}
            // style={{ visibility: isOwner ? 'visible' : 'hidden' }}
          >
            <Col xs={14} lg={4}>
              <Row align='bottom' justify='start' type='flex'>
                <Col span={24} style={{ color: '#D7972C' }}>
                  <FormattedMessage id='app.photographers' />
                </Col>
                <Col span={24} style={{ fontSize: '1.25em', fontWeight: 400 }}>
                  <FormattedMessage id='app.photographerCount' defaultMessage='{count} pax' values={{ count: event.uploads.length - adminCount }} />
                </Col>
              </Row>
            </Col>
            <Col xs={10} lg={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button className='black-button' onClick={() => handleInvitationPhotographerModal(true)} style={{ fontSize: '0.9em' }}>
                <FormattedMessage id='app.invitephotographers'/>
              </Button>
            </Col>
            <Col xs={24} lg={1}>
              <div className='divider' />
            </Col>
            {isOwner && (
              <>
                <Col xs={16} lg={3}>
                  <Row align='bottom' justify='start' type='flex'>
                    <Col span={24} style={{ color: '#D7972C' }}>
                      <FormattedMessage id='app.photoCounts'/>
                    </Col>
                    <Col span={24} style={{ fontSize: '1.25em', fontWeight: 400 }}>
                      <FormattedMessage id='app.photoCount' defaultMessage='{count} photos' values={{ count: isOwner ? event.photoCount : photoCount }} />
                    </Col>
                  </Row>
                </Col>
                <Col xs={8} lg={4} style={{ display: 'inline-block', textAlign: 'end' }}>
                  <Button className='black-button' onClick={() => handlePhotoSharingModal(true)} style={{ fontSize: '0.9em' }}>
                    <FormattedMessage id='app.eventQrCode' defaultMessage='Event QR Code'/>
                  </Button>
                </Col>
                <Col xs={24} lg={1}>
                  <div className='divider' />
                </Col>
                <Col xs={16} lg={3}>
                  <Row align='bottom' justify='start' type='flex'>
                    <Col span={24} style={{ color: '#D7972C' }}>
                      <FormattedMessage id='app.watermark'/>
                    </Col>
                    <Col span={24} style={{ fontSize: '1.25em', fontWeight: 400 }}>
                      {/* {event?.photoFrame?.portraitPreviewUrl && event?.photoFrame?.landscapePreviewUrl ? (
                        <FormattedMessage id='app.yes' defaultMessage='Yes' />
                      ) : (
                        <FormattedMessage id='app.no' defaultMessage='No' />
                      )} */}
                    </Col>
                  </Row>
                </Col>
                <Col xs={8} lg={4} style={{ display: 'inline-block', textAlign: 'end' }}>
                  <Button className='black-button' onClick={() => handleEditFrameModal(true)} style={{ fontSize: '0.9em' }}>
                    <FormattedMessage id='app.edit'/>
                  </Button>
                </Col>
              </>
            )}
          </Row>
        </div>
        <Row className='event-photos-row' align='bottom' type='flex'>
          <Col xs={10} sm={12}>
            <p className='header'><FormattedMessage id='app.eventPhotos'/></p>
            <p className='description'>
              {intl.formatMessage(
                { id: 'app.totalPhotos', defaultMessage: 'Total {photoCount} {photoCount, plural, one {photo} other {photos} }' },
                { photoCount: isOwner ? event.photoCount : photoCount }
              )}
            </p>
          </Col>
          <Col xs={14} sm={12} style={{ textAlign: 'right' }}>
            {consentCheck && (
               <>
               <Button
                 className='black-button'
                 onClick={() => handleDownloadManual()}
                 style={{ borderRadius: '1.6em', fontSize: '1em', height: '3em',marginRight:'1em' }}
               >
                 <BookIcon />
                 {` ${intl.formatMessage({ id: 'app.userManual', defaultMessage: 'user manual' })}`}
               </Button>
              <Button
                className='yellow-button'
                onClick={() => handleUploadPhotoModal(true, event._id, user._id)}
                // onClick={()=> addToUploadList(event._id, user._id)}
                style={{ borderRadius: '1.6em', fontSize: '1em', height: '3em' }}
              >
                <Icon type='plus' />
                {` ${intl.formatMessage({ id: 'app.upload', defaultMessage: 'Upload' })}`}
              </Button>
               </>
            )}
          </Col>
        </Row>
        {consentCheck && (
          <EventPhotos
            event={event}
            handleInvitationPhotographerModal={handleInvitationPhotographerModal}
            handleUploadPhotoModal={handleUploadPhotoModal}
            isOwner={isOwner}
            dataCurrentAdmin={dataCurrentAdmin}
          />
        )}
        <EventCoverModal
          event={event}
          onClose={handleEditCoverModal}
          visible={editCoverModal}
        />
        <EditEventModal
          eventId={match.params.eventId}
          eventTitle={event.title}
          eventWelcomeMessage={event.welcomeMessage}
          eventLang={event.language}
          onClose={handleEditEventModal}
          visible={editEventModal}
        />
        <InvitationPhotographerModal
          eventId={match.params.eventId}
          onClose={handleInvitationPhotographerModal}
          visible={invitationPhotographerModal}
        />
        <PhotoSharingModal
          eventId={match.params.eventId}
          event={event}
          onClose={handlePhotoSharingModal}
          visible={photoSharingModal}
        />
        {user.role !== 'admin' && <ConsentUploadModal
          addToUploadList={addToUploadList}
          event={event}
          onClose={handleConsentUploadModal}
        photographerId={dataCurrentAdmin?.currentAdminUser._id}
          visible={consentUploadModal}
          condText={`${intl.formatMessage({ id: 'app.consentUpload' })}` }
          btnText={`${intl.formatMessage({ id: 'app.confirmBtn' })}`}
        />}
        <EditFrameModal
          event={event}
          onClose={handleEditFrameModal}
          visible={editFrameModal}
        />
        <UploadPhotoModal
          event={event}
          onClose={handleUploadPhotoModal}
          visible={uploadPhotoModal}
          user={user._id}
        />
      </S.Event>
    </>
  )
}

export default EventManage
