import React, { createContext, useContext, useState } from 'react'

const headerContext = createContext()

const useHeaderProvider = () => {
  const [createEventModal, setCreateEventModal] = useState(false)
  const createEvent = {
    isOpen: createEventModal,
    open: () => setCreateEventModal(true),
    close: () => setCreateEventModal(false)
  }

  return {
    createEvent
  }
}

export const useHeader = () => {
  return useContext(headerContext)
}

export const HeaderProvider = ({ children }) => {
  return (
    <headerContext.Provider value={useHeaderProvider()}>
      {children}
    </headerContext.Provider>
  )
}