import React from 'react'
import compose from 'recompose/compose'
import { useIntl, FormattedMessage } from 'react-intl'
import {
  Button,
  Form,
  Input,
  message,
} from 'antd'

import useOmiseOffsiteCredit from '~/hooks/useOmiseOffsiteCredit'
import useOmiseOffsitePhoto from '~/hooks/useOmiseOffsitePhoto'
import * as S from '~/styles'

const OmiseTruemoney = ({
  form: { getFieldDecorator, validateFields },
  orderTarget,
  price,
  eventId,
  orderId,
  packageId,
  returnUri,
}) => {
  const intl = useIntl()

  const [CreditOmiseLoaded, CreditPaymentLoading, CreditPay] = useOmiseOffsiteCredit('omise_truemoney', (err) => {
    const { graphQLErrors } = err
    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        message.error(intl.formatMessage({ id: `api.${error.message}` }), 5)
      })
    } else if (err.message) {
      message.error(err.message, 5)
    } else {
      message.error(err, 5)
    }
  })

  const [PhotoOmiseLoaded, PhotoPaymentLoading, PhotoPay] = useOmiseOffsitePhoto('omise_truemoney', (err) => {
    const { graphQLErrors } = err
    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        message.error(intl.formatMessage({ id: `api.${error.message}` }), 5)
      })
    } else if (err.message) {
      message.error(err.message, 5)
    } else {
      message.error(err, 5)
    }
  })

  const handleCreditCheckout = (e) => {
    e.preventDefault()
    validateFields(async (err, data) => {
      if (!err) {
        const { mobileNumber } = data
        CreditPay('truemoney', {
          eventId,
          packageId,
          returnUri,
        }, {
          amount: price,
          mobile_number: mobileNumber,
        })
      }
    })
  }

  const handlePhotoCheckout = (e) => {
    e.preventDefault()
    validateFields(async (err, data) => {
      if (!err) {
        const { mobileNumber } = data
        PhotoPay('truemoney', {
          orderId,
          returnUri,
        }, {
          amount: price,
          mobile_number: mobileNumber,
        })
      }
    })
  }

  const button = (
    <Button
      type="primary"
      onClick={orderTarget === 'credit'
        ? handleCreditCheckout
        : orderTarget === 'photo'
          ? handlePhotoCheckout
          : null}
      loading={!CreditOmiseLoaded || CreditPaymentLoading || !PhotoOmiseLoaded || PhotoPaymentLoading}
      disabled={!CreditOmiseLoaded || CreditPaymentLoading || !PhotoOmiseLoaded || PhotoPaymentLoading}
    >{`ชำระ ${price.toLocaleString()} บาท`}
    </Button>
  )

  return (
    <S.EventCard title="ทรูมันนี่วอลเล็ท">
      <Form layout="vertical">
        <Form.Item
          label={<FormattedMessage id="app.payment.method.omise_truemoney.mobileNumber" defaultMessage="TrueMoney Wallet mobile number" />}
          labelCol={{
            xs: { span: 24 },
            sm: { span: 12 },
          }}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 12 },
          }}
          extra={<FormattedMessage id="app.payment.method.omise_truemoney.mobileNumberExtra" defaultMessage="10 digits mobile number (TrueMoney Wallet ID)" />}
        >
          {getFieldDecorator('mobileNumber', {
            rules: [{
              required: true,
              message: <FormattedMessage id="app.payment.method.omise_truemoney.mobileRequired" defaultMessage="Please enter TrueMoney Wallet mobile number" />,
            }, {
              pattern: /^\d{10}$/,
              message: <FormattedMessage id="app.payment.method.omise_truemoney.mobileInvalid" defaultMessage="Please enter 10 digits mobile number" />,
            }],
          })(
            <Input pattern="^\d{10}$" />,
          )}
        </Form.Item>
        <div className="desktopOnly flexCenter">
          {button}
        </div>
        <div className="mobileOnly column">
          {button}
        </div>
      </Form>
    </S.EventCard>
  )
}

export default compose(
  Form.create(),
)(OmiseTruemoney)
