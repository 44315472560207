import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { compose } from 'recompose'
import { useIntl } from 'react-intl'
import { useQuery, useMutation } from '@apollo/client'
import moment from 'moment'
import _get from 'lodash/get'
import {
  Form,
  Input,
  message,
  Button,
} from 'antd'

import uploadQuery from '~/graphql/queries/upload.gql'
import updateAdminUserMutation from '~/graphql/mutations/updateAdminUser.gql'
import authAdminMutation from '~/graphql/mutations/authAdminUser.gql'
import { useStores } from '~/contexts'
import PageNotAuthorized from '~/pages/PageNotAuthorized'
import * as S from '~/styles'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 9 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 15 },
  },
}
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 15,
      offset: 9,
    },
  },
}

const PasswordCreate = ({
  form: {
    getFieldDecorator, validateFields, getFieldValue,
  },
}) => {
  const {
    userStore: user,
    routerStore: { location, history },
  } = useStores()
  const { query: { invitationToken } } = location
  const intl = useIntl()
  const [confirmDirty, setConfirmDirty] = useState(false)
  const { loading, error, data } = useQuery(uploadQuery, {
    variables: {
      invitationToken,
    },
    fetchPolicy: 'network-only',
  })
  const [updateAdminUser] = useMutation(updateAdminUserMutation)
  const [authAdminUser] = useMutation(authAdminMutation)
  if (loading) return 'Loading...'
  if (error) return 'Error...'
  if (data && !data.upload) return <PageNotAuthorized />
  const photographerId = _get(data, 'upload.photographerId')
  const activated = _get(data, 'upload.photographer.activated')
  const email = _get(data, 'upload.photographer.profile.email')
  const { location: { state: { from: { pathname } } } } = history
  if (activated) return <Redirect to={{ pathname: '/login', state: { from: pathname, email } }} />

  const handleFormSubmit = (e) => {
    e.preventDefault()
    validateFields(async (err, values) => {
      if (!err) {
        const {
          record: {
            password,
            profile: {
              email,
              phone,
            },
          },
        } = values
        try {
          await updateAdminUser({
            variables: {
              _id: photographerId,
              invitationToken,
              record: {
                password,
                activated: true,
                tokenExpiredOn: moment(),
                profile: {
                  phone,
                },
              },
            },
          })
          const { data: { authAdminUser: { token } } } = await authAdminUser({
            variables: {
              username: `${email}`.toLowerCase(),
              password,
            },
          })
          await user.login({ token })
          history.replace(pathname)
          message.info(`Successfully registered ${email}`, 5)
        } catch (error) {
          error.graphQLErrors.forEach((error) => message.error(intl.formatMessage({
            id: `api.errors.${error.message}`,
            defaultMessage: 'Error',
          }), 5),
          )
        }
      }
    })
  }
  const handleConfirmBlur = (e) => {
    const { value } = e.target
    setConfirmDirty(confirmDirty || !!value)
  }
  const checkPassword = (rule, value, callback) => {
    if (value && value !== getFieldValue('record.password')) {
      callback(new Error('รหัสผ่านไม่ตรงกัน'))
    } else {
      callback()
    }
  }
  const checkConfirm = (rule, value, callback) => {
    if (value && confirmDirty) {
      validateFields(['record.confirmPassword'], { force: true })
    }
    callback()
  }
  return (
    <S.Container fluid>
      <S.PageTitle>
        <h1>ยินดีต้อนรับเข้าสู่ระบบจัดการภาพ</h1>
        <h2>สําหรับการเข้าสู่ระบบครั้งแรก โปรดสร้างรหัสผ่านของท่าน เพื่อการเข้าสู่ระบบในครั้งถัดไป</h2>
      </S.PageTitle>
      <S.RegisterWrapper>
        <Form onSubmit={handleFormSubmit} className="registerForm" colon={false} style={{ padding: 10 }}>
          <Form.Item {...formItemLayout} label="อีเมล">
            {getFieldDecorator('record.profile.email', {
              initialValue: email,
              rules: [{ required: true, message: 'กรุณากรอกอีเมล' }],
            })(
              <Input
                placeholder="example@email.com"
                disabled
              />,
            )}
          </Form.Item>
          <Form.Item {...formItemLayout} label="รหัสผ่านใหม่">
            {getFieldDecorator('record.password', {
              rules: [{ required: true, message: 'กรุณากรอกรหัสผ่าน' }, { min: 8, message: 'ความยาว 8 อักขระขึ้นไป' }, { validator: checkConfirm }],
            })(
              <Input
                type="password"
                placeholder="ความยาว 8 อักขระขึ้นไป"
              />,
            )}
          </Form.Item>
          <Form.Item {...formItemLayout} label="ยืนยันรหัสผ่านใหม่">
            {getFieldDecorator('record.confirmPassword', {
              rules: [{ required: true, message: 'กรุณากรอกรหัสผ่าน' }, { min: 8, message: 'ความยาว 8 อักขระขึ้นไป' }, { validator: checkPassword }],
            })(
              <Input
                type="password"
                placeholder="ความยาว 8 อักขระขึ้นไป"
                onBlur={handleConfirmBlur}
              />,
            )}
          </Form.Item>
          <Form.Item {...formItemLayout} label="เบอร์โทร">
            {getFieldDecorator('record.profile.phone', {
              rules: [{ required: true, message: 'กรุณากรอกเบอร์โทร' }],
            })(
              <Input
                // placeholder="example@email.com"
              />,
            )}
          </Form.Item>
          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit" className="registerFormButton">
              ดำเนินการต่อ
            </Button>
          </Form.Item>
        </Form>
      </S.RegisterWrapper>
    </S.Container>
  )
}

export default compose(
  Form.create(),
  observer,
)(PasswordCreate)
