import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useQuery } from '@apollo/client'
import _pickBy from 'lodash/pickBy'

import featuredPhotosQuery from '~/graphql/queries/featuredPhotos.gql'
import ResponsiveGallery from '~/components/ResponsiveGallery'
import PhotoView from '~/components/PhotoView'
import { useStores } from '~/contexts'
import * as S from '~/styles'

const FeaturedResults = observer(({ eventId }) => {
  const [currentPhoto, setCurrentPhoto] = useState()
  const { routerStore: { location } } = useStores()
  const { query: { pId } } = location
  const { loading, error, data } = useQuery(featuredPhotosQuery, {
    variables: {
      eventId,
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (currentPhoto !== pId) {
      location.query = _pickBy({
        ...location.query,
        pId: currentPhoto,
      })
    }
  }, [currentPhoto]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleThumbnailClick = (item) => {
    const { photo } = item
    setCurrentPhoto(photo._id)
  }

  const handlePhotoChange = (photo) => {
    setCurrentPhoto(photo && photo._id)
  }

  if (loading) return 'Loading...'
  if (error) return 'Error...'
  if (data && !data.photos) return 'No Result'
  const { photos } = data

  return (
    <S.Results>
      {photos.length > 0 && (
        <>
          <p>ภาพบรรยากาศงาน</p>
          <ResponsiveGallery
            photos={photos}
            onClick={handleThumbnailClick}
          />
          <PhotoView
            photos={photos}
            photoId={pId}
            onPhotoChange={handlePhotoChange}
          />
        </>
      )}
    </S.Results>
  )
})

export default FeaturedResults
