import React, { useState, useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Button, Col, Row, Avatar, Dropdown, Menu, Drawer, message } from 'antd'
import { UserOutlined, MenuOutlined } from '@ant-design/icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage } from 'react-intl'
import { signOut } from 'firebase/auth'
import { auth } from '../services/firebase'
import { useHeader } from '../services/header'
import { useUser } from '../contexts'
// import UserIcon from '~/components/UserIcon'
import Navbar from '~/components/Navbar'
import EventCreateModal from './EventCreateModal'
import LocaleSwitcher from '~/components/LocaleSwitcher'
import LocaleSwitcherMobile from '~/components/LocaleSwitcherMobile'
import * as S from '../styles'
import { HashLink } from 'react-router-hash-link';


const Header = observer(({ headerColor }) => {
  const history = useHistory()
  const { createEvent } = useHeader()
  const { user, updateUser } = useUser()
  const [visible, setVisible] = useState(false)

  const handleClick = () => {
    setVisible(true) 
  }

  const onClose = () => {
    setVisible(false)
  }

  const onClickLogout = async () => {
    try {
      await signOut(auth)
      window.recaptchaVerifier = undefined
      updateUser()
      history.push('/')
    } catch (error) {
      return message.error(error.message)
    }
  }
  const menuItems = (
    <Menu>
      <Menu.Item style={{ borderRadius: '20px' }}>
        <Link
          to='/'
          onClick={onClickLogout}
          // onClick={() => updateUser()}
          style={{ color: 'red' }}
        >
          {/* <FontAwesomeIcon icon={['fas', 'sign-out-alt']} style={{ marginRight: 7 }} /> */}
          <FormattedMessage id='app.logout' defaultMessage='Logout' />
        </Link>
      </Menu.Item>
    </Menu>
  )

  const title = (
    <S.MobileHeader>
      <Col span={12} justify='end'>
        <Link to='/'>
          <img src='/images/mangomango_logo2.svg' alt='Pixmory' style={{ width: '90px', textAlign:'left'}} />
        </Link>
      </Col>
      {/* <Avatar size={64} icon={<UserIcon />} /> */}
      <div className="mobileHeader__layout">
        {/* <h4 className="mobileHeader__user-role">{user.role}</h4> */}
        {/* <p className="mobileHeader__username">{user.username}</p> */}
        {/* <Button
          type="primary"
          className="mobileHeader__logout-button"
          onClick={() => {
            updateUser()
            onClose()
          }}
        >
        <FormattedMessage id="app.logout" defaultMessage="Logout" />
        </Button> */}
      </div>
    </S.MobileHeader>
  )


  return (
    <S.Header headercolor={headerColor}>
        <Drawer
        title={title}
        placement="left"
        closable={false}
        onClose={onClose}
        visible={visible}
        className="mobileOnly"
        onClick={onClose}
      >
        <Navbar/>
      </Drawer>
      <Row align='middle' className='header' justify='space-between' type='flex'>
        <div className='desktopOnly' style={{ width: '100%' }}>
          <Col>
            <Link to='/'>
              <img src='/images/mangomango_logo2.svg' alt='Pixmory' style={{ width: '200px' }} />
            </Link>
          </Col>       
          {!user || !user._id ? (
            <Col className='smallDisplay' style={{ width: '100%' }}>
              <Row align='middle' gutter={[32, 0]} justify='end' type='flex'>
                <Col >
                      <HashLink smooth to="/#about-us">
                      <FormattedMessage id="app.aboutUs" defaultMessage="About us" />
                      </HashLink>
                </Col>
                <Col>
                    <HashLink smooth to="/#feature">
                      <FormattedMessage id="app.features" defaultMessage="Features" />
                      </HashLink>
                </Col>
                <Col>
                    <HashLink smooth to="/#pricing">
                      <FormattedMessage id="app.pricing" defaultMessage="Pricing" />
                      </HashLink>
                </Col>
                <Col>
                  <Button type='primary' style={{ backgroundColor: 'black', border: '#FDBE1A', fontSize: '16px', height: '50px', padding: '0px 20px' }}>
                    <Link to='/login'><FormattedMessage id="app.login" defaultMessage="login" /></Link>
                  </Button>
                </Col>
                {/* <Col>
                  <Button type='primary' style={{ backgroundColor: 'black', border: 'black', fontSize: '16px', height: '50px', padding: '0px 20px' }}>
                    <Link to='/register'><FormattedMessage id="app.starFreeTrial" defaultMessage="starFreeTrial" /></Link>
                  </Button>
                </Col> */}
                
                <Col>
                  <LocaleSwitcher/>
                </Col>
              </Row>
            </Col>
          ) : (
            <Col className='smallDisplay' style={{ width: '100%' }}>
              <Row align='middle' gutter={[16, 0]} justify='end' type='flex'>
                <Col>
                  {/* <Button type='primary' style={{ backgroundColor: headerColor === 'white' ? '#FDBE1A' : 'rgba(17, 17, 17, 0.05)', border: '#FDBE1A', color: 'black', fontSize: '16px', height: '50px', padding: '0px 30px' }}> */}
                    <Link  to='/events'><FormattedMessage id="app.home" defaultMessage="home" /></Link>
                  {/* </Button> */}
                </Col>
                <Col>
                  {/* <Button type='primary' style={{ backgroundColor: headerColor === 'white' ? 'white' : 'rgba(17, 17, 17, 0.05)', border: '#FDBE1A', color: 'black', fontSize: '16px', height: '50px', padding: '0px 30px' }}> */}
                    <Link to='/credits/buy/step1' style={{ color: 'black', fontSize: '16px', height: '50px', padding: '0px 0px 10px 20px' }}>
                      <FormattedMessage id='app.BuyPackage' defaultMessage="BuyPackage"/>
                    </Link>
                  {/* </Button> */}
                </Col>
                <Col>
                    <Link to='/mycredit' style={{ color: 'black', fontSize: '16px', height: '50px', padding: '0px 20px' }}>
                      <FormattedMessage id='app.myCredit' defaultMessage="my credit"/>
                    </Link>
                </Col>
                <Col>
                  <Button type='primary' onClick={() => createEvent.open()} style={{ backgroundColor: 'black', border: 'black', fontSize: '16px', height: '50px', padding: '0px 30px' }}>
                    <FormattedMessage id="app.starFreeTrial" defaultMessage="starFreeTrial" />
                  </Button>
                </Col>
                <Col>
                  <LocaleSwitcher/>
                </Col>
                <Col>
                  <Dropdown trigger={['click']} overlay={menuItems} placement="bottomLeft"><Avatar style={{ backgroundColor: '#FFF4D7' , color: '#FDBE1A'}} size="large" icon={<UserOutlined />} /></Dropdown>
                </Col>
              </Row>
            </Col>
          )}
        </div>
        <div className='mobileOnly' style={{ width: '100%' }}>
          <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Col>
              <Link to='/'>
                <img src='/images/mangomango_logo2.svg' alt='Pixmory' style={{ width: '100px' }} />
              </Link>
            </Col>
            {!user || !user._id ? (
              <Col className='smallDisplay'>
                <Row align='middle' gutter={[16, 0]} justify='end' type='flex'>
                  <Button type='primary' style={{ backgroundColor: '#ECB31E', border: '#FDBE1A', color: 'black', fontSize: '16px', height: '45px',padding: '0px 10px' }}>
                    <Link to='/login'><FormattedMessage id="app.login" defaultMessage="login" /></Link>
                  </Button>
                  <Button type='primary' style={{ backgroundColor: 'black', border: 'black', fontSize: '16px', height: '45px', marginLeft: '0.5em', padding: '0px 10px' }}>
                    <Link to='/register'><FormattedMessage id="app.starFreeTrial" defaultMessage="starFreeTrial" /></Link>
                  </Button>
                  <Col>
                    <LocaleSwitcherMobile/>
                  </Col>
                </Row>
                
              </Col>
            ) : (
              <Col className='smallDisplay'>
                <Row align='middle' gutter={[16, 0]} justify='end' type='flex'> 
                  <Col>
                    <LocaleSwitcherMobile/>
                  </Col>
                <div onClick={handleClick} className="header__menu-button" style={{ marginLeft: '10px' }}>
                  <MenuOutlined  />
                </div>
                </Row>
              </Col>
            )}
          </div>
        </div>
      </Row>
      <EventCreateModal onClose={() => createEvent.close()} visible={createEvent.isOpen} />
    </S.Header>
    // <S.Header>
    //   <PageHeader
    //     className='header'
    //     ghost={false}
    //     title={
    //       <Link to='/'>
    //         <div className='desktopOnly'>
    //           <img src='/images/logo.svg' alt='Pixmory' style={{ width: '200px' }} />
    //         </div>
    //         <div className='mobileOnly'>
    //           <img src='/images/logo2.svg' alt='Pixmory' style={{ width: '80px' }} />
    //         </div>
    //       </Link>
    //     }
    //     extra={[
          
    //       ...[!user ? (
    //         <>
    //           <div className='desktopOnly'>
    //             <Button type='primary' style={{ color: 'black', fontSize: '16px', height: '50px', padding: '0px 30px' }}>
    //               <Link to='/login'>เข้าสู่ระบบ</Link>
    //             </Button>
    //           </div>
    //           <div className='mobileOnly'>
    //             <Button type='primary' style={{ color: 'black', fontSize: '16px', height: '45px', padding: '0px 10px' }}>
    //               <Link to='/login'>เข้าสู่ระบบ</Link>
    //             </Button>
    //           </div>
    //         </>
    //       ) : (
    //         <>
    //           <div className='desktopOnly'>
    //             <Button type='primary' style={{ color: 'black', fontSize: '16px', height: '50px', padding: '0px 30px' }}>
    //               <Link to='/credits/buy'>ซื้อแพ็กเกจ</Link>
    //             </Button>
    //           </div>
    //           <div className='mobileOnly'>
    //             <Button type='primary' style={{ color: 'black', fontSize: '16px', height: '45px', padding: '0px 10px' }}>
    //               <Link to='/credits/buy'>ซื้อแพ็กเกจ</Link>
    //             </Button>
    //           </div>
    //         </>
    //       )],
    //       ...[!user ? (
    //         <>
    //           <div className='desktopOnly'>
    //             <Button type='primary' style={{ backgroundColor: 'black', border: 'black', fontSize: '16px', height: '50px', padding: '0px 30px' }}>
    //               <Link to='/register'>สร้างงานฟรี q</Link>
    //             </Button>
    //           </div>
    //           <div className='mobileOnly'>
    //             <Button type='primary' style={{ backgroundColor: 'black', border: 'black', fontSize: '16px', height: '45px', padding: '0px 10px' }}>
    //               <Link to='/register'>สร้างงานฟรี q</Link>
    //             </Button>
    //           </div>
    //         </>
    //       ) : (
    //         <>
    //           <div className='desktopOnly'>
    //             <Button type='primary' onClick={() => createEvent.open()} style={{ backgroundColor: 'black', border: 'black', fontSize: '16px', height: '50px', padding: '0px 30px' }}>
    //               สร้างงานฟรี
    //             </Button>
    //           </div>
    //           <div className='mobileOnly'>
    //             <Button type='primary' onClick={() => createEvent.open()} style={{ backgroundColor: 'black', border: 'black', fontSize: '16px', height: '45px', padding: '0px 10px' }}>
    //               สร้างงานฟรี
    //             </Button>
    //           </div>
    //         </>
    //       )]
    //     ]}
    //   />
    //   <EventCreateModal onClose={() => createEvent.close()} visible={createEvent.isOpen} />
    // </S.Header>
  )
})

export default Header

// import React, { Fragment, useState } from 'react'
// import { observer } from 'mobx-react-lite'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import {
//   Avatar,
//   Button,
//   Col,
//   Drawer,
//   Menu,
//   Row,

// } from 'antd'
// // import { MenuOutlined } from '@ant-design/icons'
// import { Link } from 'react-router-dom'
// import { FormattedMessage } from 'react-intl'

// import { useStores } from '~/contexts'
// import * as S from '~/styles'
// // import Cart from '~/components/Cart'
// import UserIcon from '~/components/UserIcon'
// import Navbar from '~/components/Navbar'
// // import LocaleSwitcher from '~/components/LocaleSwitcher'
// // import LocaleSwitcherMobile from '~/components/LocaleSwitcherMobile'

// const Header = observer(() => {
//   const { userStore: user } = useStores()
//   const currentUser = user.initUser() && user
//   const [visible, setVisible] = useState(false)

//   const handleClick = () => {
//     setVisible(true)
//   }

//   const onClose = () => {
//     setVisible(false)
//   }

//   const loginMenu = (
//     <Menu>
//       <Menu.Item>
//         <Link to="/login">
//           <FontAwesomeIcon icon={['fas', 'sign-in-alt']} style={{ marginRight: 7 }} />
//           <FormattedMessage id="app.login" defaultMessage="Login" />
//         </Link>
//       </Menu.Item>
//     </Menu>
//   )

//   const menuItems = (
//     <Menu>
//       <Menu.Item>
//         <Link to="/">
//           <FontAwesomeIcon icon={['fas', 'home']} style={{ marginRight: 7 }} />
//           <FormattedMessage id="app.home" defaultMessage="Home" />
//         </Link>
//       </Menu.Item>
//       <Menu.Item>
//         <Link to="/events">
//           <FontAwesomeIcon icon={['fas', 'chart-area']} style={{ marginRight: 7 }} />
//           <FormattedMessage id="app.dashboard" defaultMessage="Dashboard" />
//         </Link>
//       </Menu.Item>
//       <Menu.Item>
//         <Link to="/" onClick={() => currentUser.logout()}>
//           <FontAwesomeIcon icon={['fas', 'sign-out-alt']} style={{ marginRight: 7 }} />
//           <FormattedMessage id="app.logout" defaultMessage="Logout" />
//         </Link>
//       </Menu.Item>
//     </Menu>
//   )

//   const title = (
//     <S.MobileHeader>
//       <Avatar size={64} icon={<UserIcon />} />
//       <div className="mobileHeader__layout">
//         {/* <h4 className="mobileHeader__user-role">{currentUser.role}</h4> */}
//         <p className="mobileHeader__username">{currentUser.username}</p>
//         <Button
//           type="primary"
//           className="mobileHeader__logout-button"
//           onClick={() => {
//             currentUser.logout()
//             onClose()
//           }}
//         >
//           <FormattedMessage id="app.logout" defaultMessage="Logout" />
//         </Button>
//       </div>
//     </S.MobileHeader>
//   )

//   return (
//     <Fragment>
//       <Drawer
//         title={title}
//         placement="left"
//         closable={false}
//         onClose={onClose}
//         visible={visible}
//         className="mobileOnly"
//         onClick={onClose}
//       >
//         <Navbar />
//       </Drawer>
//       <S.Header  style={{ width: '100%', backgroundColor: '#FDBE1A' }}>
//         <Row type="flex" justify="space-between" style={{ width: '100%' }}>
//           <Col className="desktopOnly"><Link to="/">
//               <img src="/images/logo.svg" alt="Pixmory" className="header__logo" />
//             </Link>
//           </Col>
//           <Col className="desktopOnly">
//             <div style={{ marginRight: 20, textAlign: 'center'}}>
//               <Link to='/login'>เข้าสู่ระบบ</Link>
//               {/* <LocaleSwitcher/> */}
//             </div>
//             <Button type="primary" shape="round">สร้างงานฟรี</Button></Col>
            
            
            
//             {/* {!currentUser && (
//             <span className="flex" style={{ marginRight: 20 }}>
//               <Cart />
//             </span>
//             )} */}
//             {/* {!currentUser
//               ? (
//                 <Dropdown trigger={['click']} overlay={loginMenu} placement="bottomLeft">
//                   <Button className="header__menu-button">
//                     <MenuOutlined />
//                   </Button>
//                 </Dropdown>
//               ) : (
//                 <Radio.Group>
//                   <Dropdown trigger={['click']} overlay={menuItems} placement="bottomLeft">
//                     <Radio.Button value="default" className="header__menu-button">{currentUser.username}</Radio.Button>
//                   </Dropdown>
//                   <Tooltip
//                     placement="bottom"
//                   >
//                     <Radio.Button value="default" className="header__menu-button">
//                       <UserIcon />
//                     </Radio.Button>
//                   </Tooltip>
//                 </Radio.Group>
//               )} */}
//        <Row type="flex" justify="space-between" style={{ width: '100%' }}>
//           <Col className="mobileOnly">
//             <Link to="/">
//               <img src="/images/logo2.svg" alt="Pixmory" className="header__logo" style={{width: '90px'}} />
//             </Link> </Col>
//             <Col className="mobileOnly">
//             <div style={{ marginRight: 20}}>
//             <Link to='/login'>เข้าสู่ระบบ</Link>
//               {/* <LocaleSwitcher/> */}
//             </div>
//             <Button type="primary" shape="round">สร้างงานฟรี</Button>
//             </Col>
//             </Row>
          
//             {/* {!currentUser && (
//             <span className="flex" style={{ marginRight: 20 }}>
//               <Cart />
//             </span>
//             )} */}
//             {/* {!currentUser
//               ? (
//                 <Dropdown trigger={['click']} overlay={loginMenu} placement="bottomLeft">
//                   <Button className="header__menu-button">
//                     <MenuOutlined />
//                   </Button>
//                 </Dropdown>
//               ) : ( 
//                 <Radio.Group>
//                   <Dropdown trigger={['click']} overlay={menuItems} placement="bottomLeft">
//                     <Radio.Button value="default" className="header__menu-button">{currentUser.username}</Radio.Button>
//                   </Dropdown>
//                   <Tooltip
//                     placement="bottom"
//                   >
//                     <Radio.Button value="default" className="header__menu-button">
//                       <UserIcon />
//                     </Radio.Button>
//                   </Tooltip>
//                 </Radio.Group>
//               )} */}
//           {/* <Row type="flex" align="middle" style={{ padding: '10px' }}>
//             {!currentUser && (
//               <Row gutter={[8, 8]}>
//                 <Col span={8}>
//                   <LocaleSwitcherMobile/>
//                 </Col>
//                 <Col span={8} style={{ marginLeft: '20px' }}>
//                   <span>
//                     <Cart />
//                   </span>
//                 </Col>
//             </Row>
//             )}
//             {!currentUser
//               ? (
//                 <Dropdown trigger={['click']} overlay={loginMenu} placement="bottomRight">
//                   <Button className="header__menu-button">
//                     <MenuOutlined />
//                   </Button>
//                 </Dropdown>
//               ) : (
//                 <div>
//                  <LocaleSwitcherMobile/>
//                 <Button onClick={handleClick} className="header__menu-button" style={{ marginLeft: '10px' }}>
//                   <MenuOutlined  />
//                 </Button>
//             </div>
//               )}
//           </Row> */}
//         </Row>
//       </S.Header>
//     </Fragment>
//   )
// })

// export default Header
